import { useApolloClient, useQuery } from '@apollo/client';
import {
    GlobalParticipantAggregatedRankingsFacetsCharts as ChartQueryType,
    GlobalParticipantAggregatedRankingsFacetsChartsVariables as ChartQueryVars,
} from '../../definitions/GlobalParticipantAggregatedRankingsFacetsCharts';
import {
    GlobalParticipantAggregatedRankingsFacetsV2 as QueryType,
    GlobalParticipantAggregatedRankingsFacetsV2Variables as QueryVars,
} from '../../definitions/GlobalParticipantAggregatedRankingsFacetsV2';
import { flattenError, useParallelQueries } from '../../utils';
import GlobalParticipantAggregatedRankingsFacetsChartsQuery from './globalParticipantAggregatedRankingsFacetsCharts.gql';
import GlobalParticipantAggregatedRankingsFacetsV2Query from './globalParticipantAggregatedRankingsFacetsV2.gql';

export const useGlobalParticipantAggregatedRankingsFacetsQuery = (
    variables: QueryVars
) => {
    const { data, loading, error } = useQuery<QueryType, QueryVars>(
        GlobalParticipantAggregatedRankingsFacetsV2Query,
        { variables }
    );

    const result =
        data?.globalParticipantByGpId?.[0]?.aggregatedRankingFacetsV2;

    return {
        loading,
        data: result,
        error: error && flattenError(error),
    };
};

export const useGlobalParticipantAggregatedRankingsFacetsChartsQuery = (
    variables: ChartQueryVars
) => {
    const { data, loading, error } = useQuery<ChartQueryType, ChartQueryVars>(
        GlobalParticipantAggregatedRankingsFacetsChartsQuery,
        { variables }
    );

    const result =
        data?.globalParticipantByGpId?.[0]?.aggregatedRankingFacetsV2;

    return {
        loading,
        data: result,
        error: error && flattenError(error),
    };
};

export const useGlobalParticipantAggregatedRankingsFacetsBatchQuery = (
    variables: QueryVars[]
) => {
    const client = useApolloClient();

    const { data, loading, error } = useParallelQueries<QueryType, QueryVars>(
        GlobalParticipantAggregatedRankingsFacetsV2Query,
        { variables, client }
    );

    return {
        loading,
        data: data?.map(
            facets =>
                facets?.globalParticipantByGpId?.[0]?.aggregatedRankingFacetsV2
        ),
        error,
    };
};
