import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { DATE_FORMAT_LONG, EMPTY_CHAR } from 'src/constants';
import { DOWNLOADS, SKIPS, SAVES } from 'src/constants/metrics';
import { isAbsolutePeriod } from 'src/utils/datePeriod';
import {
    PrimaryDimension,
    TimeseriesData,
    TimeseriesPointData,
    TimeseriesSummary,
    Timeseries,
} from './types';

const dateDiff = (fromDate: string, toDate?: string) => {
    let diff = EMPTY_CHAR;
    if (toDate) {
        const momentToDate = dayjs.utc(toDate);
        const momentFromDate = dayjs.utc(fromDate);

        diff = momentFromDate.isSameOrAfter(momentToDate, 'day')
            ? (momentFromDate.diff(momentToDate, 'days') + 1).toString()
            : EMPTY_CHAR;
    }

    return diff;
};

const getPointValue = (
    item: TimeseriesPointData,
    primaryDimension: PrimaryDimension
) => {
    if (primaryDimension === PrimaryDimension.Saves)
        return item.saves ? item.saves : 0;

    if (primaryDimension === PrimaryDimension.Skips)
        return item.skipRate ? item.skipRate : 0;

    return item.value ? item.value : 0;
};

const sumNullable = (
    left?: number | null,
    right?: number | null
): number | null => {
    if (
        left !== null &&
        left !== undefined &&
        right !== null &&
        right !== undefined
    )
        return left + right;
    if (left !== null && left !== undefined) return left;
    if (right !== null && right !== undefined) return right;
    return null;
};

export const getTimeseriesSummary = ({
    id,
    title,
    name,
    code,
    streams,
    downloads,
    className,
    isrc,
}: {
    id: string;
    name: string;
    streams?: TimeseriesPointData[] | null;
    downloads?: TimeseriesPointData[] | null;
    title?: string;
    className?: string;
    code?: string | number;
    isrc?: string;
}): TimeseriesSummary => {
    const downloadsSummary = downloads?.reduce<number | null>(
        (sum, series) => sumNullable(sum, series.value),
        null
    );
    const streamsSummary = streams?.reduce<{
        streams: number | null;
        skipRate: number | null;
        saves: number | null;
    }>(
        (sum, series) => ({
            streams: sumNullable(sum.streams, series.value),
            skipRate: sumNullable(sum.skipRate, series.skipRate),
            saves: sumNullable(sum.saves, series.saves),
        }),
        { streams: null, skipRate: null, saves: null }
    );

    let skips: null | undefined | number;
    if (streamsSummary) {
        const skipsLength =
            sumBy(streams, stream => (stream.skipRate ? 1 : 0)) || 1;

        skips =
            streamsSummary.skipRate !== null
                ? streamsSummary.skipRate / skipsLength
                : null;
    }

    return {
        id,
        name,
        title,
        code,
        downloads: downloadsSummary,
        streams: streamsSummary?.streams,
        saves: streamsSummary?.saves,
        skips,
        isrc,
        ...(className ? { className } : {}),
    };
};

export const getTimeseriesData = ({
    data,
    primaryDimension,
    datePeriod,
    releaseDate,
}: {
    data: TimeseriesPointData[];
    primaryDimension: PrimaryDimension;
    datePeriod: string;
    releaseDate?: string;
}): TimeseriesData =>
    data.map((item, index) => {
        const { saves, skipRate, value, date } = item;
        const absolutePeriod = isAbsolutePeriod(datePeriod);

        return {
            x: absolutePeriod ? new Date(date).getTime() : index + 1,
            y: getPointValue(
                { saves, skipRate, date, value },
                primaryDimension
            ),
            z: absolutePeriod
                ? dateDiff(date, releaseDate)
                : dayjs.utc(date).format(DATE_FORMAT_LONG),
        };
    });

interface SummaryItem {
    streams?: number | null;
    saves?: number | null;
    downloads?: number | null;
    skips?: number | null;
}

export const getSummaryValue = (item: SummaryItem, potMetric: string) => {
    if (potMetric === SAVES) return item.saves ? item.saves : 0;

    if (potMetric === SKIPS) return item.skips ? item.skips : 0;

    if (potMetric === DOWNLOADS) return item.downloads ? item.downloads : 0;

    return item.streams ? item.streams : 0;
};

export const sortBySummaryData =
    (potMetric: string) =>
    (a: Timeseries | SummaryItem, b: Timeseries | SummaryItem) => {
        const firstSummary = 'summary' in a ? a.summary : a;
        const secondSummary = 'summary' in b ? b.summary : b;
        const firstItem = getSummaryValue(firstSummary, potMetric);
        const secondItem = getSummaryValue(secondSummary, potMetric);

        return firstItem <= secondItem ? 1 : -1;
    };
