import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    SongProductsQuery,
    SongProductsQueryVariables,
} from '../../definitions/SongProductsQuery';
import { selectSongProducts } from '../../selectors';
import { flattenError } from '../../utils';
import SongProducts from './songProducts.gql';

export const useSongProductsQuery = (variables: SongProductsQueryVariables) => {
    const { data, loading, error } = useQuery<
        SongProductsQuery,
        SongProductsQueryVariables
    >(SongProducts, { variables });

    return {
        loading,
        data: useMemo(() => selectSongProducts(data), [data]),
        error: error && flattenError(error),
    };
};
