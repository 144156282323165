import { useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { MarketRanksQuery } from 'src/apollo/definitions/MarketRanksQuery';
import {
    SongAggregatedRankingsV2Query,
    SongAggregatedRankingsV2QueryVariables,
} from 'src/apollo/definitions/SongAggregatedRankingsV2Query';
import { useMarketRanksQuery } from 'src/apollo/queries/chart/marketRanks';
import { SongAggregatedRanking, SongMetadata } from 'src/apollo/selectors';
import { STORE_IDS_BY_NAME } from 'src/constants/stores';
import { AppContext } from 'src/context';
import { selectSongRankingsV2 } from '../../selectors';
import { useParallelQueries } from '../../utils';
import songAggregatedRankingsV2Query from './songAggregatedRankingsV2.gql';

export const useSongAggregatedRankingsQuery = (publicId?: string) => {
    const { chartsById, chartsLoading } = useContext(AppContext);

    const variables = { publicSoundRecordingId: publicId ?? '' };

    const { data, loading, error } = useQuery<
        SongAggregatedRankingsV2Query,
        SongAggregatedRankingsV2QueryVariables
    >(songAggregatedRankingsV2Query, {
        variables,
        skip: chartsLoading || !publicId,
    });

    return {
        loading: loading || chartsLoading,
        data: data && selectSongRankingsV2(chartsById, data),
        error,
    };
};

export const getRankingsWithMarketRanks = (
    data: SongAggregatedRanking[],
    marketRanksData?: MarketRanksQuery
): SongAggregatedRanking[] =>
    data
        .map(ranking => {
            const chartMarketRanks = marketRanksData?.marketRanks.find(
                item =>
                    item.storeId ===
                        STORE_IDS_BY_NAME[ranking.chart.definition.platform] &&
                    item.countryCode === ranking.country
            );

            return {
                ...ranking,
                marketSize: chartMarketRanks?.marketRank,
            };
        })
        .sort((a, b) => {
            // Handle potential undefined values
            const marketSizeA = a.marketSize || 0;
            const marketSizeB = b.marketSize || 0;

            // Sort in ascending order
            return marketSizeA - marketSizeB;
        });

export const useSongAggregatedRankingsWithMarketQuery = (
    publicId: string | undefined,
    storeIds: number[]
) => {
    const { data, loading, error } = useSongAggregatedRankingsQuery(publicId);
    const {
        data: marketRanksData,
        loading: marketRanksLoading,
        error: marketError,
    } = useMarketRanksQuery({ storeIds });

    const rankingsWithMarketRanks = getRankingsWithMarketRanks(
        data || [],
        marketRanksData
    );

    return {
        data: rankingsWithMarketRanks,
        loading: loading || marketRanksLoading,
        error: error || marketError,
    };
};

export const useSongAggregatedRankingsQueries = (songs: SongMetadata[]) => {
    const client = useApolloClient();
    const { chartsById } = useContext(AppContext);

    const songsArray = Array.isArray(songs) ? songs : [];
    const variables = songsArray.reduce<
        SongAggregatedRankingsV2QueryVariables[]
    >(
        (result, { publicId }) =>
            publicId
                ? [...result, { publicSoundRecordingId: publicId }]
                : result,
        []
    );

    const {
        data = [],
        loading,
        error,
    } = useParallelQueries<
        SongAggregatedRankingsV2Query,
        SongAggregatedRankingsV2QueryVariables
    >(songAggregatedRankingsV2Query, { client, variables });

    const result = songsArray.map(({ isrc, publicId }) => {
        const rankings = data.find(
            ({ publicSoundRecording }) => publicSoundRecording?.id === publicId
        );
        return {
            isrc,
            rankings: rankings
                ? selectSongRankingsV2(chartsById, rankings)
                : [],
        };
    });

    return {
        loading,
        data: result,
        error,
    };
};
