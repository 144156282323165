import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import CountryFlag from 'src/components/countryFlag';
import ExpandedIconWithNumberCell from './expandedIconWithNumberCell';

export const CLASSNAME = 'StorefrontCountCellV2';

interface ToggleHandler {
    (playlistId: string, storeId?: number, isrc?: string): void;
}

GridTable.defineColumnTemplate('storefrontCountriesV2', {
    align: 'left',
    minWidth: 'min-content',
    maxWidth: 'max-content',
    Cell: ({
        data: { isrc, playlistId, storeId, storefrontCount, topMarket },
        rowKey,
        context: {
            props: { expandedRowTables },
        },
        column: { definition },
    }) => {
        const size = get(definition, 'data-size') || 16;

        if (!storefrontCount)
            return <CountryFlag code={topMarket} size={size} />;

        const isExpanded = expandedRowTables
            ? rowKey in expandedRowTables
            : false;
        const handleToggle = get(definition, 'data-onToggle') as
            | ToggleHandler
            | undefined;

        return (
            <ExpandedIconWithNumberCell
                expanded={isExpanded}
                value={storefrontCount}
                onClick={() => handleToggle?.(playlistId, storeId, isrc)}
                className={CLASSNAME}
            />
        );
    },
});
