import { useQuery } from '@apollo/client';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { CompanyBrands } from 'src/apollo/definitions/CompanyBrands';
import { selectCompany } from 'src/apollo/selectors/product';
import { INSIGHTS_NEW_BRANDS } from 'src/constants/featuresFlags';
import { flattenError } from '../../utils';
import companyBrandsQuery from './companyBrands.gql';

export const useCompanyBrandsQuery = (skip?: boolean) => {
    const isNewBrands = useFeatureFlag(INSIGHTS_NEW_BRANDS);
    const { data, loading, error } = useQuery<CompanyBrands>(
        companyBrandsQuery,
        { fetchPolicy: 'cache-first', skip }
    );

    return {
        loading,
        data: selectCompany(data, isNewBrands),
        error: error && flattenError(error),
    };
};
