import React, { FC } from 'react';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import { EMPTY_CHAR } from 'src/constants';

export const CLASSNAME = 'FormattedChange';
export const POSITION_CLASSNAME = `${CLASSNAME}-position`;
export const TREND_CLASSNAME = `${CLASSNAME}-trend`;
export const TREND_CHANGE_CLASSNAME = `${TREND_CLASSNAME}Change`;
export const TREND_CHANGE_ICON_CLASSNAME = `${TREND_CHANGE_CLASSNAME}Icon`;
export const TEST_ID = CLASSNAME;

export interface Props {
    position?: number | null | string;
    change?: number | null | string;
    size?: 12 | 16;
    useEmptyChar?: boolean;
}

const FormattedChange: FC<Props> = ({
    position,
    change,
    size = 12,
    useEmptyChar = false,
}) => {
    const hasChange = typeof change === 'number';
    const isNew = position === null;
    const hasTrendIcon = hasChange || isNew;
    let icon = 'arrowRight';
    let classNameModifier = 'zero';

    if (hasChange) {
        if (change > 0) {
            icon = 'arrowUp';
            classNameModifier = 'positive';
        } else if (change < 0) {
            icon = 'arrowDown';
            classNameModifier = 'negative';
        }
    }

    if (isNew) {
        icon = 'dot';
        classNameModifier = 'new';
    }

    if (useEmptyChar && !position && !hasTrendIcon) {
        return (
            <div data-testid={CLASSNAME} className={cx(CLASSNAME, 'empty')}>
                {EMPTY_CHAR}
            </div>
        );
    }

    return (
        <div className={cx(CLASSNAME, `s${size}`)} data-testid={TEST_ID}>
            {position && (
                <span className={POSITION_CLASSNAME}>{`#${position}`}</span>
            )}
            {hasTrendIcon && (
                <GlyphIcon
                    name={
                        icon as 'arrowRight' | 'arrowUp' | 'arrowDown' | 'dot'
                    }
                    size={size}
                    className={cx(
                        TREND_CHANGE_ICON_CLASSNAME,
                        classNameModifier
                    )}
                />
            )}
            {hasChange && (
                <span className={cx(TREND_CHANGE_CLASSNAME, classNameModifier)}>
                    {Math.abs(change)}
                </span>
            )}
        </div>
    );
};

export default FormattedChange;
