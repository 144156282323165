import {
    SoundRecordingStreamsBreakdown,
    SoundRecordingStreamsBreakdown_globalSoundRecordingByIsrc_analytics_streamsBreakdown_sourceOfStreams_active as SourceData,
} from 'src/apollo/definitions/SoundRecordingStreamsBreakdown';
import { selectSources } from 'src/utils/sources';

const EMPTY_SOURCE_DATA: SourceData = {
    total: 0,
    value: null,
    growthPercentage: null,
};
const EMPTY_DATA = {
    sourceOfStreams: {
        active: EMPTY_SOURCE_DATA,
        passive: EMPTY_SOURCE_DATA,
        collection: EMPTY_SOURCE_DATA,
        unknown: EMPTY_SOURCE_DATA,
    },
    streamsBySubscription: {
        subscription: EMPTY_SOURCE_DATA,
        adSupported: EMPTY_SOURCE_DATA,
        midTier: EMPTY_SOURCE_DATA,
    },
};

export const selectStreamsBreakdownSourceOfStreamsSources = (
    series: SoundRecordingStreamsBreakdown[]
) => {
    const [first] = series;

    return selectSources(
        first?.globalSoundRecordingByIsrc?.analytics?.streamsBreakdown
            .sourceOfStreams.sources
    );
};

export const selectStreamsBreakdownStreamsBySubscriptionSources = (
    series: SoundRecordingStreamsBreakdown[]
) => {
    const [first] = series;

    return selectSources(
        first?.globalSoundRecordingByIsrc?.analytics?.streamsBreakdown
            .streamsBySubscription.sources
    );
};

export interface StreamsBreakdownSourceOfStreams {
    active: { value: number; total: number };
    passive: { value: number; total: number };
    collection: { value: number; total: number };
    unknown: { value: number; total: number };
}

export interface StreamsBreakdownStreamsBySubscription {
    subscription: { value: number; total: number };
    adSupported: { value: number; total: number };
    midTier: { value: number; total: number };
}

export const selectStreamsBreakdownSourceOfStreams = (
    series: SoundRecordingStreamsBreakdown[]
) =>
    series.map(({ globalSoundRecordingByIsrc }) => {
        const data = globalSoundRecordingByIsrc?.analytics
            ? globalSoundRecordingByIsrc.analytics.streamsBreakdown
            : EMPTY_DATA;

        const { sourceOfStreams = EMPTY_DATA.sourceOfStreams } = data;

        const {
            active = EMPTY_SOURCE_DATA,
            passive = EMPTY_SOURCE_DATA,
            collection = EMPTY_SOURCE_DATA,
            unknown = EMPTY_SOURCE_DATA,
        } = sourceOfStreams;

        const result = {
            collection: {
                value: (collection.value || 0) * 100,
                total: collection.total,
            },
            active: { value: (active.value || 0) * 100, total: active.total },
            passive: {
                value: (passive.value || 0) * 100,
                total: passive.total,
            },
            unknown: {
                value: (unknown.value || 0) * 100,
                total: unknown.total,
            },
        };

        if (result.unknown.value === 100) {
            result.unknown.value = 0;
            result.unknown.total = 0;
        }

        return result;
    });

export const selectStreamsBreakdownStreamsBySubscription = (
    series: SoundRecordingStreamsBreakdown[]
) =>
    series.map(({ globalSoundRecordingByIsrc }) => {
        const data = globalSoundRecordingByIsrc?.analytics
            ? globalSoundRecordingByIsrc.analytics.streamsBreakdown
            : EMPTY_DATA;

        const { streamsBySubscription = EMPTY_DATA.streamsBySubscription } =
            data;

        const {
            subscription = EMPTY_SOURCE_DATA,
            adSupported = EMPTY_SOURCE_DATA,
            midTier = EMPTY_SOURCE_DATA,
        } = streamsBySubscription;

        return {
            subscription: {
                value: (subscription.value || 0) * 100,
                total: subscription.total,
            },
            adSupported: {
                value: (adSupported.value || 0) * 100,
                total: adSupported.total,
            },
            midTier: {
                value: (midTier.value || 0) * 100,
                total: midTier.total,
            },
        };
    });
