import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { selectStarredChannels } from 'src/apollo/selectors/channel';
import {
    StarredChannelsQuery as Query,
    StarredChannelsQueryVariables as QueryVars,
} from '../../definitions/StarredChannelsQuery';
import starredChannelsQuery from './starredChannels.gql';

export const useStarredChannelsQuery = (variables: QueryVars) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        starredChannelsQuery,
        { variables }
    );

    const memoizedData = useMemo(
        () => data && selectStarredChannels(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
