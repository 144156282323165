import React, { FC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { Source } from 'src/constants/stores';
import { selectSourcesByTypes } from 'src/utils/sources';
import { CLASSNAME, TERMS } from './constants';
import SourceItem, {
    CLASSNAME_DATE,
    CLASSNAME_ROW,
    CLASSNAME_STATUS,
} from './sourceItem';

export interface Props {
    id?: string;
    sources: Source[];
    sourceTypes?: string[];
}

export const CLASSNAME_CONTAINER = `${CLASSNAME}-container`;
const CLASSNAME_TABLE_HEADER = `${CLASSNAME}-tableHeader`;

const SourcesModalContent: FC<Props> = ({ sources, sourceTypes }) => {
    const selectedSources = sourceTypes
        ? selectSourcesByTypes(sources, sourceTypes)
        : sources;

    return (
        <div data-testid={CLASSNAME_CONTAINER} className={CLASSNAME_CONTAINER}>
            <div className={cx(CLASSNAME_TABLE_HEADER, CLASSNAME_ROW)}>
                <div />
                <div className={CLASSNAME_STATUS}>
                    {formatMessage(TERMS.STATUS)}
                </div>
                <div className={CLASSNAME_DATE}>
                    {formatMessage(TERMS.LAST_DATA_UPDATE)}
                </div>
            </div>
            {selectedSources.map(source => (
                <SourceItem key={source.storeId} {...source} />
            ))}
        </div>
    );
};

export default SourcesModalContent;
