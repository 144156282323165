import React, { FC, useRef, useState } from 'react';
import { BsOverlay, BsTooltip } from '@theorchard/suite-components';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import CoverArt, { Shape } from 'src/components/coverArt';
import { EMPTY_CHAR } from 'src/constants';
import { createHref } from 'src/utils/route';

const CLASSNAME = 'LinkList';
export const CLASSNAME_ITEM = `${CLASSNAME}-item`;
export const CLASSNAME_COMMA = `${CLASSNAME}-comma-separated`;
export const CLASSNAME_CONTAINER = `${CLASSNAME}-container`;
export const CLASSNAME_OBSOLETE_LINK = `${CLASSNAME}-obsolete-link`;
export const TEST_ID = CLASSNAME;
export const TEST_ID_WITH_IMAGE = `${CLASSNAME}-item-with-image`;
export const TEST_ID_ITEM = `${CLASSNAME}-item-test-id`;

type Separator = 'comma';
export interface Props {
    links?: {
        id: string;
        name?: string;
        imageUrl?: string | null;
        paramUniqueIdentifier?: string;
        obsolete?: string | null;
    }[];
    route: string;
    params?: object;
    separator?: Separator;
    maxLength?: number;
    showImagePreview?: boolean;
    imagePreviewShape?: Shape;
    className?: string;
}

const LinkList: FC<Props> = ({
    className: externalClassName = '',
    links,
    route,
    separator,
    params = {},
    maxLength,
    showImagePreview,
    imagePreviewShape,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    const isValueOverflow = Boolean(
        maxLength && links && links.length > maxLength
    );

    const handleFocus = (element: HTMLElement) => {
        const isOverflowing = element.offsetWidth < element.scrollWidth;
        setShowTooltip(isOverflowing || isValueOverflow);
    };

    const handleBlur = () => setShowTooltip(false);

    const renderedLinks = (links || []).map(
        (
            { id, name, imageUrl, paramUniqueIdentifier = 'id', obsolete },
            index
        ) => {
            const invalidId = id === EMPTY_CHAR;
            if (invalidId) return <span key={name}>{name}</span>;

            if (showImagePreview)
                return (
                    <span
                        key={id}
                        data-testid={TEST_ID_WITH_IMAGE}
                        className={cx('d-flex pr-2', {
                            [CLASSNAME_OBSOLETE_LINK]: obsolete === 'true',
                        })}
                    >
                        {showImagePreview && (
                            <CoverArt
                                url={imageUrl}
                                width="20"
                                shape={imagePreviewShape}
                            />
                        )}
                        <Link
                            key={id}
                            className={CLASSNAME_ITEM}
                            to={createHref(route, {
                                ...params,
                                [paramUniqueIdentifier]: id,
                            })}
                            ref={index === 0 ? target : null}
                            onMouseEnter={e => handleFocus(e.currentTarget)}
                            onFocus={e => handleFocus(e.currentTarget)}
                            onMouseLeave={handleBlur}
                            onBlur={handleBlur}
                        >
                            {name}
                        </Link>
                    </span>
                );

            return (
                <Link
                    key={id}
                    data-testid={TEST_ID_ITEM}
                    className={CLASSNAME_ITEM}
                    to={createHref(route, { ...params, id })}
                    ref={index === 0 ? target : null}
                    onMouseEnter={e => handleFocus(e.currentTarget)}
                    onFocus={e => handleFocus(e.currentTarget)}
                    onMouseLeave={handleBlur}
                    onBlur={handleBlur}
                >
                    {name}
                </Link>
            );
        }
    );

    const overlay = (
        <BsOverlay
            target={target.current}
            show={showTooltip}
            placement="bottom-start"
        >
            {props => (
                <BsTooltip
                    id={CLASSNAME}
                    {...props}
                    arrowProps={{
                        ref: props.arrowProps.ref,
                        style: { display: 'none' },
                    }}
                >
                    {links?.map(item => item.name).join(', ')}
                </BsTooltip>
            )}
        </BsOverlay>
    );

    return (
        <div
            className={cx(CLASSNAME, externalClassName, {
                [CLASSNAME_COMMA]: separator === 'comma',
                [CLASSNAME_CONTAINER]: showImagePreview,
            })}
            data-testid={TEST_ID}
        >
            {maxLength
                ? renderedLinks.filter((_, index) => index < maxLength)
                : renderedLinks}
            {isValueOverflow && '...'}
            {isValueOverflow && overlay}
        </div>
    );
};

export default LinkList;
