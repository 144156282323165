import { PAST_7, PAST_28, PAST_6M, PAST_365 } from 'src/constants/periods';
import { STORES_BY_ID } from 'src/constants/stores';
import { GlobalParticipantPlacementsBreakdownQuery } from '../definitions/GlobalParticipantPlacementsBreakdownQuery';
import {
    GlobalSoundRecordingPlacementsBreakdownQuery,
    GlobalSoundRecordingPlacementsBreakdownQuery_globalSoundRecordingByIsrc_playlistPlacementsBreakdown as PlacementsBreakdownResult,
    GlobalSoundRecordingPlacementsBreakdownQuery_globalSoundRecordingByIsrc_playlistPlacementsBreakdown_stores as BreakdownValue,
} from '../definitions/GlobalSoundRecordingPlacementsBreakdownQuery';
import { DeltaGrowthPeriod, PlaylistType } from '../definitions/globalTypes';

interface PlacementsBreakdownValue {
    placementsCount: number;
    streams: number;
}

interface PlacementsStoreBreakdownValue {
    placementsCount: number;
    streams: number;
    id: number;
}

export interface PlacementsBreakdown {
    stores: Record<string, PlacementsStoreBreakdownValue | null>;
    types: {
        editorial: PlacementsBreakdownValue | null;
        userGenerated?: PlacementsBreakdownValue | null;
        personalized: PlacementsBreakdownValue | null;
        radio?: PlacementsBreakdownValue | null;
        station?: PlacementsBreakdownValue | null;
        chart?: PlacementsBreakdownValue | null;
        algorithmic: PlacementsBreakdownValue | null;
        curated: PlacementsBreakdownValue | null;
        none?: PlacementsBreakdownValue | null;
        radioStations: PlacementsBreakdownValue | null;
        totalStreams: number;
    };
    totalStoresStreams: number;
    totalPlacementsCount: number;
}

export interface PlacementBreakdownWithIsrc extends PlacementsBreakdown {
    isrc?: string;
}

export const selectBreakdownStreams = (
    value: BreakdownValue,
    dateFilter: string
) => {
    let streams =
        value.streams.find(
            stream => stream.period === DeltaGrowthPeriod._ALL_TIME
        )?.value ?? 0;
    if (dateFilter === PAST_7)
        streams =
            value.streams.find(
                stream => stream.period === DeltaGrowthPeriod._7_DAYS
            )?.value ?? 0;
    else if (dateFilter === PAST_28)
        streams =
            value.streams.find(
                stream => stream.period === DeltaGrowthPeriod._28_DAYS
            )?.value ?? 0;
    else if (dateFilter === PAST_6M)
        streams =
            value.streams.find(
                stream => stream.period === DeltaGrowthPeriod._183_DAYS
            )?.value ?? 0;
    else if (dateFilter === PAST_365)
        streams =
            value.streams.find(
                stream => stream.period === DeltaGrowthPeriod._365_DAYS
            )?.value ?? 0;

    return streams;
};

const selectBreakdownValue = (
    values: BreakdownValue[],
    key: string,
    dateFilter: string
): PlacementsBreakdownValue | null => {
    const value = values.find(val => val.id === key);
    if (!value) return null;

    return {
        placementsCount: value.totalCount ?? 0,
        streams: selectBreakdownStreams(value, dateFilter),
    };
};

const selectBreakdownCombinedValue = (
    breakdowns: BreakdownValue[],
    keys: string[],
    dateFilter: string
): PlacementsBreakdownValue | null => {
    const values = breakdowns.filter(({ id }) => keys.includes(id));
    if (values.length === 0) return null;

    return {
        placementsCount: values.reduce(
            (sum, { totalCount }) => sum + totalCount,
            0
        ),
        streams: values.reduce(
            (sum, val) => sum + selectBreakdownStreams(val, dateFilter),
            0
        ),
    };
};

const selectBreakdownTotal = (values: BreakdownValue[], dateFilter: string) =>
    values.reduce(
        (total, value) => total + selectBreakdownStreams(value, dateFilter),
        0
    );

const selectStoresFromBreakdownResult = (
    stores: BreakdownValue[],
    dateFilter: string
) =>
    stores.reduce(
        (result, value) => {
            const { stores: resultStores, totalStoresStreams } = result;
            const { id, totalCount } = value;
            const storeId = Number(id);
            const store = STORES_BY_ID[storeId];
            if (!store) return result;

            const streams = selectBreakdownStreams(value, dateFilter);
            return {
                stores: {
                    ...resultStores,
                    [store]: {
                        id: storeId,
                        placementsCount: totalCount ?? 0,
                        streams,
                    },
                },
                totalStoresStreams: totalStoresStreams + streams,
            };
        },
        {
            stores: {},
            totalStoresStreams: 0,
        }
    );

export const selectBreakdown = (
    data: PlacementsBreakdownResult,
    dateFilter: string
): PlacementsBreakdown => ({
    ...selectStoresFromBreakdownResult(data.stores, dateFilter),
    types: {
        editorial: selectBreakdownValue(
            data.types,
            PlaylistType.EDITORIAL,
            dateFilter
        ),
        personalized: selectBreakdownValue(
            data.types,
            PlaylistType.PERSONALIZED,
            dateFilter
        ),
        algorithmic: selectBreakdownValue(
            data.types,
            PlaylistType.ALGORITHMIC,
            dateFilter
        ),
        curated: selectBreakdownValue(
            data.types,
            PlaylistType.CURATED,
            dateFilter
        ),
        radioStations: selectBreakdownCombinedValue(
            data.types,
            [PlaylistType.RADIO, PlaylistType.STATION],
            dateFilter
        ),
        totalStreams: selectBreakdownTotal(data.types, dateFilter),
    },
    totalPlacementsCount: data.totalCount,
});

export const selectSoundRecordingPlacementBreakdown = (
    data: GlobalSoundRecordingPlacementsBreakdownQuery,
    dateFilter: string
): PlacementBreakdownWithIsrc | null => {
    const breakdown =
        data.globalSoundRecordingByIsrc?.playlistPlacementsBreakdown;
    if (!breakdown) return null;

    return {
        isrc: data.globalSoundRecordingByIsrc?.isrc,
        ...selectBreakdown(breakdown, dateFilter),
    };
};

export const selectSoundRecordingPlacementsBreakdown = (
    data: GlobalSoundRecordingPlacementsBreakdownQuery[],
    dateFilter: string
) => data.map(gsr => selectSoundRecordingPlacementBreakdown(gsr, dateFilter));

export const selectParticipantPlacementBreakdown = (
    data: GlobalParticipantPlacementsBreakdownQuery,
    dateFilter: string
) => {
    const breakdown =
        data.globalParticipantByGpId?.[0]?.playlistPlacementsBreakdown;
    if (!breakdown) return null;

    return selectBreakdown(breakdown, dateFilter);
};

export const selectParticipantPlacementsBreakdown = (
    data: GlobalParticipantPlacementsBreakdownQuery[],
    dateFilter: string
) =>
    data.map(breakdown =>
        selectParticipantPlacementBreakdown(breakdown, dateFilter)
    );
