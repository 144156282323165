import React from 'react';
import { GridTable, Tooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { uniqueId } from 'lodash';
import LinkList from 'src/components/linkList';
import { EMPTY_CHAR, ROUTE_PARTICIPANT } from 'src/constants';
import { ArtistsTypes } from 'src/constants/artists';

export const CLASSNAME = 'ParticipantLinkCellWithArtistTypeCell';

GridTable.defineColumnTemplate('participantLinkWithArtistType', {
    align: 'left',
    Cell: ({ data: { participants } }) => {
        const isNoParticipantsInfo =
            !participants || participants?.every(({ name }) => !name);
        if (isNoParticipantsInfo) return <span>{EMPTY_CHAR}</span>;

        const performers = participants.filter(
            ({ participatedAs }) => participatedAs === ArtistsTypes.performer
        );
        const featured = participants.filter(
            ({ participatedAs }) => participatedAs === ArtistsTypes.featuring
        );
        const remixers = participants.filter(
            ({ participatedAs }) => participatedAs === ArtistsTypes.remixer
        );
        const orderedParticipants = [...performers, ...featured, ...remixers];

        const message = orderedParticipants
            .map(({ name, participatedAs }) => {
                if (participatedAs)
                    return `${name} (${formatMessage(
                        `participant.${participatedAs}`
                    )})`;

                return name;
            })
            .join(', ');

        const links = orderedParticipants.map(({ id = '', name = '' }) => ({
            id,
            name,
        }));

        return (
            <Tooltip
                id={`${CLASSNAME}_${uniqueId()}`}
                className={CLASSNAME}
                message={message}
            >
                <LinkList
                    links={links}
                    route={ROUTE_PARTICIPANT}
                    separator="comma"
                />
            </Tooltip>
        );
    },
});
