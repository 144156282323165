import { formatMessage } from '@theorchard/suite-frontend';
import { CatalogSong } from 'src/apollo/selectors/catalog';
import { getCountries, GLOBAL } from 'src/constants/countries';
import {
    CsvColumnConfig,
    exportGridToCsv,
    getTrendScoreForCsv,
} from 'src/utils/csv';
import { catalogFilename } from 'src/utils/exportCsv';
import { formatPercentageCsv } from 'src/utils/formatNumber';
import {
    ACCOUNT,
    ARTIST_NAME,
    COUNTRY,
    CREATIONS_1,
    CREATIONS_28,
    CREATIONS_7,
    CREATIONS_ALL,
    CREATIONS_GROWTH_1,
    CREATIONS_GROWTH_28,
    CREATIONS_GROWTH_7,
    GROWTH_1,
    GROWTH_28,
    GROWTH_7,
    IMPRINT,
    ISRC,
    RELEASE_DATE,
    SONG_NAME,
    STORE_NAME,
    STREAMS_1,
    STREAMS_28,
    STREAMS_7,
    STREAMS_ALL,
    TIKTOK_TREND_SCORE,
    VIEWS_1,
    VIEWS_28,
    VIEWS_7,
    VIEWS_ALL,
    VIEWS_GROWTH_1,
    VIEWS_GROWTH_28,
    VIEWS_GROWTH_7,
} from './constants';

export const exportCsvForGridTable = (
    songs: CatalogSong[],
    visibleColumns: string[],
    countries?: string[]
) => {
    const country = countries ? getCountries(countries) : GLOBAL;

    const configurations: CsvColumnConfig<CatalogSong>[] = [
        {
            columnName: 'songName',
            label: formatMessage(SONG_NAME),
        },
        {
            columnName: 'country',
            label: formatMessage(COUNTRY),
            valueFormatter: () => country,
            forceInclude: true,
        },
        {
            columnName: 'isrc',
            label: formatMessage(ISRC),
            forceInclude: true,
        },
        {
            columnName: 'artistName',
            label: formatMessage(ARTIST_NAME),
            valueFormatter: row =>
                row.participants?.map(({ name }) => name).join(', '),
        },
        {
            columnName: 'imprint',
            label: formatMessage(IMPRINT),
        },
        {
            columnName: 'account',
            label: formatMessage(ACCOUNT),
            valueFormatter: row =>
                row.accounts?.map(({ name }) => name).join(', '),
        },
        {
            columnName: 'storeName',
            label: formatMessage(STORE_NAME),
        },
        {
            columnName: 'releaseDate',
            label: formatMessage(RELEASE_DATE),
        },
        {
            columnName: 'tikTokTrendScore',
            label: formatMessage(TIKTOK_TREND_SCORE),
            valueFormatter: row => getTrendScoreForCsv(row.tikTokTrendScores),
        },
        {
            columnName: 'streams1Day',
            label: formatMessage(STREAMS_1),
        },
        {
            columnName: 'growthPercentage1Day',
            label: formatMessage(GROWTH_1),
            valueFormatter: row =>
                formatPercentageCsv(row.growthPercentage1Day),
        },
        {
            columnName: 'streams7Days',
            label: formatMessage(STREAMS_7),
        },
        {
            columnName: 'growthPercentage7Days',
            label: formatMessage(GROWTH_7),
            valueFormatter: row =>
                formatPercentageCsv(row.growthPercentage7Days),
        },
        {
            columnName: 'streams28Days',
            label: formatMessage(STREAMS_28),
        },
        {
            columnName: 'growthPercentage28Days',
            label: formatMessage(GROWTH_28),
            valueFormatter: row =>
                formatPercentageCsv(row.growthPercentage28Days),
        },
        {
            columnName: 'streamsAllTime',
            label: formatMessage(STREAMS_ALL),
        },
        {
            columnName: 'views1Day',
            label: formatMessage(VIEWS_1),
        },
        {
            columnName: 'viewsGrowthPercentage1Day',
            label: formatMessage(VIEWS_GROWTH_1),
            valueFormatter: row =>
                formatPercentageCsv(row.viewsGrowthPercentage1Day),
        },
        {
            columnName: 'views7Days',
            label: formatMessage(VIEWS_7),
        },
        {
            columnName: 'viewsGrowthPercentage7Days',
            label: formatMessage(VIEWS_GROWTH_7),
            valueFormatter: row =>
                formatPercentageCsv(row.viewsGrowthPercentage7Days),
        },
        {
            columnName: 'views28Days',
            label: formatMessage(VIEWS_28),
        },
        {
            columnName: 'viewsGrowthPercentage28Days',
            label: formatMessage(VIEWS_GROWTH_28),
            valueFormatter: row =>
                formatPercentageCsv(row.viewsGrowthPercentage28Days),
        },
        {
            columnName: 'viewsAllTime',
            label: formatMessage(VIEWS_ALL),
        },
        {
            columnName: 'creations1Day',
            label: formatMessage(CREATIONS_1),
        },
        {
            columnName: 'creationsGrowthPercentage1Day',
            label: formatMessage(CREATIONS_GROWTH_1),
            valueFormatter: row =>
                formatPercentageCsv(row.creationsGrowthPercentage1Day),
        },
        {
            columnName: 'creations7Days',
            label: formatMessage(CREATIONS_7),
        },
        {
            columnName: 'creationsGrowthPercentage7Days',
            label: formatMessage(CREATIONS_GROWTH_7),
            valueFormatter: row =>
                formatPercentageCsv(row.creationsGrowthPercentage7Days),
        },
        {
            columnName: 'creations28Days',
            label: formatMessage(CREATIONS_28),
        },
        {
            columnName: 'creationsGrowthPercentage28Days',
            label: formatMessage(CREATIONS_GROWTH_28),
            valueFormatter: row =>
                formatPercentageCsv(row.creationsGrowthPercentage28Days),
        },
        {
            columnName: 'creationsAllTime',
            label: formatMessage(CREATIONS_ALL),
        },
    ];

    exportGridToCsv(songs, visibleColumns, configurations, catalogFilename());
};
