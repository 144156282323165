import React from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { CLASSNAME } from './constants';

export const CLASSNAME_FOOTER = `${CLASSNAME}-footer`;
export const CLASSNAME_LEGEND = `${CLASSNAME}-legend`;

const PopoverFooter = () => (
    <div className={CLASSNAME_FOOTER}>
        <div className={CLASSNAME_LEGEND}>
            <div className="dot dot-sm bg-success" />
            {formatMessage('sources.noIssues')}
        </div>
        <div className={CLASSNAME_LEGEND}>
            <div className="dot dot-sm bg-danger" />
            {formatMessage('sources.errors.unreliable')}
        </div>
    </div>
);

export default PopoverFooter;
