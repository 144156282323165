import React, { FC } from 'react';
import { Tag } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import CoverArt, { Shape } from 'src/components/coverArt';
import { Width } from 'src/components/coverArt/coverArt';

export interface RecentSearchedItemProps {
    imageUrl?: string | null;
    coverShape?: Shape;
    coverWidth?: Width;
    title: string;
    type: string;
    url: string;
}

export const CLASSNAME = 'RecentSearchedItem';
export const CLASSNAME_CONTAINER = `${CLASSNAME}-container`;
const CLASSNAME_IMAGE = `${CLASSNAME}-image`;
const CLASSNAME_IMAGE_LETTERBOX = `${CLASSNAME_IMAGE}--letterbox`;
const CLASSNAME_METADATA = `${CLASSNAME}-metadata`;
const CLASSNAME_ENTITY_DATA = `${CLASSNAME}-entityData`;
const CLASSNAME_LINK = `${CLASSNAME}-link`;
const CLASSNAME_TAG = `${CLASSNAME}-tag`;
const CLASSNAME_TAG_BOLDER = `${CLASSNAME_TAG}__bolder`;

const RecentSearchedItem: FC<RecentSearchedItemProps> = ({
    children,
    coverShape,
    imageUrl,
    coverWidth,
    title,
    type,
    url,
}) => {
    return (
        <div className={CLASSNAME_CONTAINER}>
            <div
                className={cx(CLASSNAME_IMAGE, {
                    [CLASSNAME_IMAGE_LETTERBOX]:
                        coverShape && coverShape === 'letterbox',
                })}
            >
                <CoverArt
                    url={imageUrl}
                    shape={coverShape}
                    width={coverWidth}
                    usePlaceholder
                />
            </div>
            <div className={CLASSNAME_METADATA}>
                <Link
                    className={CLASSNAME_LINK}
                    to={{
                        pathname: url,
                        state: { shouldLogPageVisit: true },
                    }}
                >
                    <span>{title}</span>
                </Link>
                <div className={CLASSNAME_ENTITY_DATA}>
                    <Tag
                        text={formatMessage(type)}
                        className={cx(CLASSNAME_TAG, CLASSNAME_TAG_BOLDER)}
                    />
                    {children}
                </div>
            </div>
        </div>
    );
};

export default RecentSearchedItem;
