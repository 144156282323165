import React, { FC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import ErrorIcon100 from 'src/components/infoMessageV2/icons/100x100/errorIcon100';
import NoDataIcon100 from 'src/components/infoMessageV2/icons/100x100/noDataIcon100';
import ErrorIcon200 from 'src/components/infoMessageV2/icons/200x200/errorIcon200';
import NoDataIcon200 from 'src/components/infoMessageV2/icons/200x200/noDataIcon200';

export type MessageType = 'error' | 'noData';

export interface Props {
    message?: string;
    title?: string;
    type?: MessageType;
    Icon?: React.ComponentType;
    size?: 100 | 200;
    Footer?: React.ComponentType;
}

export const CLASSNAME = 'InfoMessageV2';

const getData = (type?: MessageType, size = 100) => {
    switch (type) {
        case 'error':
            return {
                title: formatMessage('error.network.message'),
                message: formatMessage('error.network.subtext'),
                Icon: size == 100 ? ErrorIcon100 : ErrorIcon200,
            };
        case 'noData':
            return {
                title: formatMessage('error.noData.message'),
                message: formatMessage('error.noData.subtext'),
                Icon: size == 100 ? NoDataIcon100 : NoDataIcon200,
            };
        default:
            return { title: null, message: null };
    }
};

const InfoMessageV2: FC<Props> = ({
    message,
    type,
    title,
    Icon,
    Footer,
    size = 100,
}) => {
    const data = getData(type, size);

    const infoTitle = title || data.title;
    const infoMessage = message || data.message;
    const InfoIcon = Icon || data.Icon;

    return (
        <div className={CLASSNAME}>
            {InfoIcon && <InfoIcon />}
            <div className={`${CLASSNAME}-title`}>{infoTitle}</div>
            <div className={`${CLASSNAME}-message`}>{infoMessage}</div>
            {Footer && <Footer />}
        </div>
    );
};

export default InfoMessageV2;
