import { formatMessage } from '@theorchard/suite-frontend';
import { SortDirection } from 'src/components/table/types';

export const CHANNELS_PAGE_SIZE = 50;

export const CHANNEL_NAME = 'channel.youtubeChannel';
export const LABEL_NAME = 'catalog.labelName';
export const COUNTRY = 'default.country.country';
export const SUBSCRIBERS = 'video.subscribers';
export const ALL_TIME_VIEWS = 'catalog.allTimeViews';
export const ALL_TIME_REVENUE = `${formatMessage(
    'catalog.allTimeRevenue'
)} ($)`;
export const AVG_VIEW_DURATION = 'catalog.avgViewDuration';
export const MONTH_VIEWS = (month: string) =>
    formatMessage('catalog.monthViews', { month });
export const MONTH_CHANGE = (month: string) =>
    formatMessage('catalog.monthChange', { month });
export const ARTIST_NAME = 'catalog.artistName';
export const DEFAULT_SORT_FIELD = 'views_1_month_back';
export const DEFAULT_SORT_DIR: SortDirection = 'desc';

export const CATALOG_CHANNELS_SORTING_FIELDS_MAP = {
    subscribers: 'subscriber_count',
    allTimeViews: 'total_views',
    allTimeRevenue: 'total_estimated_gross_revenue',
    avgViewDuration: 'average_view_duration_seconds',
    month1Views: 'views_1_month_back',
    month1Change: 'growth_2_to_1_months_back',
    month2Views: 'views_2_months_back',
    month2Change: 'growth_3_to_2_months_back',
    month3Views: 'views_3_months_back',
    month3Change: 'growth_4_to_3_months_back',
};
