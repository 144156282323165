import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { selectPlaylistStreamsByStore } from 'src/apollo/selectors/playlist';
import {
    GlobalParticipantTotalStreamsVsPlaylistStreamsByStore as QueryData,
    GlobalParticipantTotalStreamsVsPlaylistStreamsByStoreVariables as QueryVars,
} from '../../definitions/GlobalParticipantTotalStreamsVsPlaylistStreamsByStore';
import Query from './globalParticipantTotalStreamsVsPlaylistStreamsByStore.gql';

export const useGlobalParticipantTotalStreamsVsPlaylistStreamsByStore = (
    variables: QueryVars,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<QueryData, QueryVars>(Query, {
        variables,
        skip,
    });
    const result = useMemo(() => {
        const stores =
            data?.globalParticipantByGpId?.[0]?.analytics
                ?.totalVsPlaylistStreamsByStore;
        return selectPlaylistStreamsByStore(stores);
    }, [data]);

    return {
        loading,
        data: result,
        error,
    };
};
