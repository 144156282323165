import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { EMPTY_CHAR } from 'src/constants';
import { formatPercentage, normalizeNumber } from 'src/utils';

GridTable.defineColumnTemplate('percentageChange', {
    align: 'right',
    Cell: ({ value }) => {
        const normalizedValue = normalizeNumber(value);

        if (!normalizedValue) return <span>{EMPTY_CHAR}</span>;

        const className =
            normalizedValue >= 0 ? 'percentageIncrease' : 'percentageDecrease';
        return (
            <span className={className}>
                {formatPercentage(normalizedValue)}
            </span>
        );
    },
});
