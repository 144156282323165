import { VideoAnalyticsTopTrafficQuery_video_analytics_topTrafficSources_videoTrafficSources as TrafficSource } from 'src/apollo/definitions/VideoAnalyticsTopTrafficQuery';

export interface VideoTrafficSource {
    source: string;
    views: number;
    premiumViews: number;
    averageViewDuration: number;
    averageViewPercentage: number;
    watchTime: number;
    premiumWatchTime: number;
}

export const selectTopVideoTrafficSources = (
    data?: TrafficSource[]
): VideoTrafficSource[] =>
    (data ?? [])
        .filter(source => source.views && source.source)
        .map(s => ({
            source: s.source,
            views: s.views,
            premiumViews: s.premiumViews,
            averageViewDuration: s.averageViewDurationSeconds,
            averageViewPercentage: s.averageViewDurationPercentage,
            watchTime: s.watchTimeMinutes ? s.watchTimeMinutes / 60 : 0,
            premiumWatchTime: s.premiumWatchTimeMinutes
                ? s.premiumWatchTimeMinutes / 60
                : 0,
        }));
