import React, { VFC } from 'react';
import { Tooltip } from '@theorchard/suite-components';
import { filterData } from '@theorchard/suite-frontend';
import { take, uniqueId } from 'lodash';
import { getCompanyIcon } from 'src/utils/accounts';
import { MAX_DEFAULT_BRANDS } from './constants';
import { Props } from './types';

export const CLASSNAME = 'BrandIconStack';
export const CLASSNAME_ICON = `${CLASSNAME}-icon`;
export const CLASSNAME_MORE = `${CLASSNAME}-more`;
export const TEST_ID = CLASSNAME;
export const TEST_ID_TOOLTIP = `${CLASSNAME}-tooltip`;

const BrandIconStack: VFC<Props> = ({
    className,
    brands,
    limit = MAX_DEFAULT_BRANDS,
}) => {
    const filteredBrands = filterData(brands);
    const isMore = filteredBrands.length > limit;
    const tooltip = filteredBrands.map(({ label }) => label).join(', ');
    const tooltipId = `${CLASSNAME}${
        className ? '_' + className + '_' : '_'
    }${uniqueId()}`;

    if (!tooltip) return null;

    return (
        <Tooltip
            testId={TEST_ID_TOOLTIP}
            id={tooltipId}
            className={className}
            message={tooltip}
        >
            <div className={CLASSNAME} data-testid={TEST_ID}>
                {take(filteredBrands, limit).map(({ name }) => (
                    <div key={name} className={CLASSNAME_ICON}>
                        {getCompanyIcon(name)}
                    </div>
                ))}
                {isMore && (
                    <div className={CLASSNAME_MORE}>
                        {`+${filteredBrands.length - limit}`}
                    </div>
                )}
            </div>
        </Tooltip>
    );
};

export default BrandIconStack;
