import { useMutation } from '@apollo/client';
import { ClearRecentlyVisitedItems } from '../definitions/ClearRecentlyVisitedItems';
import { flattenError } from '../utils';
import clearRecentItems from './clearRecentItems.gql';

type ClearRecentlyVisitedItemsResult = [
    () => Promise<unknown>,
    { loading: boolean; error: Error | undefined }
];

export const useClearRecentVisitedItems =
    (): ClearRecentlyVisitedItemsResult => {
        const [clear, result] = useMutation<ClearRecentlyVisitedItems>(
            clearRecentItems,
            {
                update(cache) {
                    cache.modify({
                        fields: {
                            recentlyVisitedItems() {
                                return [];
                            },
                        },
                    });
                },
            }
        );

        return [
            clear,
            {
                loading: result.loading,
                error: result.error && flattenError(result.error),
            },
        ];
    };
