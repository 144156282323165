import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { createHref } from '@theorchard/suite-frontend';
import { Link } from 'react-router-dom';
import PlatformIcon from 'src/components/platformIcon';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_CHART } from 'src/constants';
import { getDefinition } from 'src/constants/charts';

GridTable.defineColumnTemplate('chartLink', {
    align: 'left',
    Cell: ({ data }) => {
        const chart = data.chart?.definition ?? getDefinition(data);

        return (
            <Link
                className="ChartLinkCell d-inlineflex width100 align-items-center"
                to={createHref(ROUTE_CHART, {
                    id: chart.key,
                    country: data.country,
                })}
                onClick={e => e.stopPropagation()}
            >
                <PlatformIcon platform={chart.platform} />
                <TruncatedText value={chart.label} />
            </Link>
        );
    },
});
