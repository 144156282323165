import { normalizeNumber } from './formatNumber';
import { ensureString, useRouteParams } from './route';
import {
    PAGINATION_BUTTON,
    ROWS_PER_PAGE,
    trackPaginationEvent,
} from './segment';

export interface PaginationProps {
    route?: string;
    defaultLimit?: number;
}

export const usePagination = ({
    route,
    defaultLimit = 50,
}: PaginationProps = {}) => {
    const [{ limit, offset, page: tab }, setParams] = useRouteParams(route);

    const pageLimit = normalizeNumber(ensureString(limit)) ?? defaultLimit;
    const pageOffset = normalizeNumber(ensureString(offset)) ?? 0;
    const currentPage =
        pageLimit && pageOffset ? Math.floor(pageOffset / pageLimit) : 0;

    return {
        pageLimit,
        pageOffset,
        currentPage,
        onPageChange(page: number) {
            trackPaginationEvent(PAGINATION_BUTTON, {
                tab,
                value: page > currentPage ? 'next' : 'prev',
            });
            setParams({
                offset: page * pageLimit,
            });
        },
        onPageLimitChange(size: number) {
            trackPaginationEvent(ROWS_PER_PAGE, {
                tab,
                value: String(size),
            });
            setParams({
                limit: size,
            });
        },
    };
};
