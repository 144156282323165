import { useMutation, MutationTuple } from '@apollo/client';
import {
    RecordVideoVisit,
    RecordVideoVisitVariables,
} from 'src/apollo/definitions/RecordVideoVisit';
import { flattenError } from 'src/apollo/utils';
import {
    RecordChannelVisit,
    RecordChannelVisitVariables,
} from '../definitions/RecordChannelVisit';
import {
    RecordGlobalParticipantVisit,
    RecordGlobalParticipantVisitVariables,
} from '../definitions/RecordGlobalParticipantVisit';
import {
    RecordGlobalSoundRecordingVisit,
    RecordGlobalSoundRecordingVisitVariables,
} from '../definitions/RecordGlobalSoundRecordingVisit';
import {
    RecordProductVisit,
    RecordProductVisitVariables,
} from '../definitions/RecordProductVisit';
import recordChannelVisitMutation from './recordChannelVisit.gql';
import recordGlobalParticipantVisitMutation from './recordGlobalParticipantVisit.gql';
import recordGlobalSoundRecordingVisitMutation from './recordGlobalSoundRecordingVisit.gql';
import recordProductVisitMutation from './recordProductVisit.gql';
import RecordVideoVisitMutation from './recordVideoVisit.gql';

export const useRecordChannelVisitMutation = (
    variables: RecordChannelVisitVariables
) => {
    const [visit, visitResult] = useMutation<
        RecordChannelVisit,
        RecordChannelVisitVariables
    >(recordChannelVisitMutation, { variables });

    const { loading } = visitResult;
    const { error } = visitResult;

    return [
        visit,
        {
            loading,
            error: error && flattenError(error),
        },
    ];
};

export const useRecordParticipantVisitMutation = (
    variables?: RecordGlobalParticipantVisitVariables
) => {
    const [visit, visitResult] = useMutation<
        RecordGlobalParticipantVisit,
        RecordGlobalParticipantVisitVariables
    >(recordGlobalParticipantVisitMutation, { variables });

    const { loading } = visitResult;
    const { error } = visitResult;

    return [
        visit,
        {
            loading,
            error: error && flattenError(error),
        },
    ];
};

export const useRecordSoundRecordingVisitMutation = (
    variables?: RecordGlobalSoundRecordingVisitVariables
): MutationTuple<
    RecordGlobalSoundRecordingVisit,
    RecordGlobalSoundRecordingVisitVariables
> => {
    const [visit, visitResult] = useMutation<
        RecordGlobalSoundRecordingVisit,
        RecordGlobalSoundRecordingVisitVariables
    >(recordGlobalSoundRecordingVisitMutation, { variables });

    return [visit, visitResult];
};

export const useRecordVideoVisit = (variables?: RecordVideoVisitVariables) => {
    const [visit, visitResult] = useMutation<
        RecordVideoVisit,
        RecordVideoVisitVariables
    >(RecordVideoVisitMutation, { variables });

    const { loading } = visitResult;
    const { error } = visitResult;

    return [
        visit,
        {
            loading,
            error: error && flattenError(error),
        },
    ];
};

export const useRecordProductVisitMutation = (
    variables: RecordProductVisitVariables
) => {
    const [visit, visitResult] = useMutation<
        RecordProductVisit,
        RecordProductVisitVariables
    >(recordProductVisitMutation, { variables });

    return [
        visit,
        {
            loading: visitResult.loading,
            error: visitResult.error && flattenError(visitResult.error),
        },
    ];
};
