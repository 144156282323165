import { asError } from '@theorchard/suite-frontend';
import dayjs from 'dayjs';
import { Logger } from 'src/utils/logger';
import { ensureString, parseParams } from 'src/utils/route';
import {
    API_AUTH_CALLBACK_PATH,
    API_AUTH_SCOPES,
    API_AUTH_URL,
    API_PLAY_URL,
    API_PLAYLISTS_URL,
    PLAYER_NAME,
    STORAGE_AUTH_STATE_NAME,
    STORAGE_ENABLED_NAME,
    STORAGE_TOKEN_NAME,
} from './constants';

interface AppAuthState {
    playlistId?: string;
    trackUri?: string;
    path: string;
    key: string;
}

interface ApiOptions {
    storage?: Storage;
    persistedStorage?: Storage;
    location?: Location;
    request?: typeof fetch;
    logger?: Logger;
    history?: History;
}

interface AuthStateOptions {
    trackUri?: string;
    playlistId?: string;
}

export class SpotifyWebApiClass {
    static instance: SpotifyWebApiClass;

    clientId: string | null = null;

    player: Spotify.Player | null = null;

    deviceId?: string;

    private storage: Storage;

    private persistedStorage: Storage;

    private location: Location;

    private request: typeof fetch;

    private logger: Logger;

    private history: History;

    public currentPlaylistId?: string;

    public currentPlaylistTracks?: string[];

    public errorLoadingPlaylistTracks?: Error;

    private onReadyCallback: (() => void)[];

    constructor({
        storage = sessionStorage,
        persistedStorage = localStorage,
        location = window.location,
        request = window.fetch.bind(window),
        logger = console,
        history = window.history,
    }: ApiOptions = {}) {
        this.storage = storage;
        this.persistedStorage = persistedStorage;
        this.location = location;
        this.request = request;
        this.logger = logger;
        this.history = history;
        this.onReadyCallback = [];
    }

    isEnabled() {
        return this.persistedStorage.getItem(STORAGE_ENABLED_NAME) === 'true';
    }

    enable(value: boolean) {
        this.persistedStorage.setItem(STORAGE_ENABLED_NAME, value.toString());
        if (value) this.authorize();
        else {
            this.player?.disconnect();
            this.removeAccessToken();
        }
    }

    getAccessToken() {
        try {
            const token = this.storage.getItem(STORAGE_TOKEN_NAME);
            if (!token) return null;

            const [expiresAt, accessToken] = token.split('|');
            if (
                !expiresAt ||
                dayjs.utc(parseInt(expiresAt, 10)).isBefore(dayjs.utc())
            )
                return null;
            return accessToken;
        } catch (error) {
            return null;
        }
    }

    setAccessToken(token: string, expiresAt: number) {
        this.storage.setItem(STORAGE_TOKEN_NAME, `${expiresAt}|${token}`);
    }

    removeAccessToken() {
        this.storage.removeItem(STORAGE_TOKEN_NAME);
    }

    createAuthState(trackUri?: string, playlistId?: string) {
        const path = `${this.location.pathname}${this.location.search}`;
        const state = {
            key: btoa(path),
            path,
            ...(trackUri ? { trackUri } : {}),
            ...(playlistId ? { playlistId } : {}),
        };
        this.storage.setItem(STORAGE_AUTH_STATE_NAME, JSON.stringify(state));
        return state.key;
    }

    decodeAuthState(state: string): AppAuthState | null {
        const existing = this.storage.getItem(STORAGE_AUTH_STATE_NAME);
        this.storage.removeItem(STORAGE_AUTH_STATE_NAME);
        if (!existing) return null;
        const appState = JSON.parse(existing);
        if (appState.key !== state) return null;
        return appState;
    }

    authorize(authStateOptions?: AuthStateOptions) {
        const scopes = encodeURIComponent(API_AUTH_SCOPES.join(' '));
        const redirectUri = encodeURIComponent(
            `${this.location.origin}${API_AUTH_CALLBACK_PATH}`
        );
        const state = this.createAuthState(
            authStateOptions?.trackUri,
            authStateOptions?.playlistId
        );

        this.location.assign(
            `${API_AUTH_URL}?response_type=token&client_id=${this.clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${state}`
        );
    }

    async playTracks(
        trackUris?: (string | undefined)[],
        skipAuthorization = false,
        playlistId?: string
    ) {
        const token = this.getAccessToken();
        if (!token || !this.player || !this.deviceId) {
            if (skipAuthorization) return false;
            return this.authorize({ trackUri: trackUris ? trackUris[0] : '' });
        }

        try {
            const requestResult = await this.request(
                `${API_PLAY_URL}?device_id=${this.deviceId}`,
                {
                    method: 'PUT',
                    body: JSON.stringify({ uris: trackUris }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            this.currentPlaylistId = playlistId;

            this.currentPlaylistTracks = trackUris?.map(track => String(track));

            return requestResult;
        } catch (error) {
            this.logger.error(asError(error));
            throw error;
        }
    }

    async getPlaylistTracks(playlistId?: string, skipAuthorization = false) {
        const token = this.getAccessToken();
        if (!token || !this.player || !this.deviceId) {
            if (skipAuthorization) return false;
            return this.authorize({ playlistId });
        }

        try {
            const response = await this.request(
                `${API_PLAYLISTS_URL}/${playlistId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const data =
                response instanceof Response ? await response?.json() : {};

            if (data.error) {
                this.errorLoadingPlaylistTracks = data.error;
                return [];
            }

            this.currentPlaylistTracks = data?.tracks?.items.map(
                (trackItem: Spotify.ISpotifyTrack) => trackItem?.track?.uri
            );
            this.errorLoadingPlaylistTracks = undefined;

            return this.currentPlaylistTracks;
        } catch (e) {
            const error = asError(e);
            this.logger.error(error);
            this.errorLoadingPlaylistTracks = error;
            throw error;
        }
    }

    parseCallbackParams() {
        const {
            state,
            error,
            access_token: accessToken,
            expires_in: expiresIn,
        } = parseParams(this.location.hash.substring(1));

        return {
            expiresIn: ensureString(expiresIn),
            state: ensureString(state),
            error: ensureString(error),
            accessToken: ensureString(accessToken),
        };
    }

    validateTokenFromParams() {
        const { expiresIn, state, error, accessToken } =
            this.parseCallbackParams();
        if (error) {
            this.logger.error(error);
            return null;
        }

        if (!expiresIn || !state || !accessToken) {
            this.logger.error('Token validation failed. Invalid arguments.');
            return null;
        }
        const expiresAt = dayjs
            .utc()
            .add(parseInt(expiresIn, 10), 'seconds')
            .valueOf();
        if (!expiresAt) {
            this.logger.error('Token validation failed. Invalid expiration.');
            return null;
        }

        const appState = this.decodeAuthState(state);
        if (!appState) {
            this.logger.error('Token validation failed. Invalid state.');
            return null;
        }

        return { appState, expiresAt, accessToken };
    }

    handleCallback() {
        if (this.location.pathname !== API_AUTH_CALLBACK_PATH) return null;

        const token = this.validateTokenFromParams();

        if (token) this.setAccessToken(token.accessToken, token.expiresAt);
        this.history.replaceState(
            {},
            document.title,
            token?.appState.path ?? '/'
        );

        return token;
    }

    init(clientId: string) {
        this.clientId = clientId;

        if (!this.isEnabled()) return;
        const token = this.handleCallback();

        const script = window.document.createElement('script');
        script.src = 'https://sdk.scdn.co/spotify-player.js';
        script.async = true;
        script.type = 'text/javascript';
        window.document.body.append(script);

        window.onSpotifyWebPlaybackSDKReady = async () => {
            const spotifyAccessToken = this.getAccessToken();
            if (!spotifyAccessToken) return;

            const spotifyPlayer = new Spotify.Player({
                name: PLAYER_NAME,
                getOAuthToken: callback => callback(spotifyAccessToken),
            });

            const handleAuthError = ({ message }: Spotify.PlayerState) => {
                this.logger.error(message);
                this.removeAccessToken();
            };

            spotifyPlayer.addListener('initialization_error', handleAuthError);
            spotifyPlayer.addListener('authentication_error', handleAuthError);
            spotifyPlayer.addListener('account_error', handleAuthError);
            spotifyPlayer.addListener('playback_error', handleAuthError);

            const readyOrNot = new Promise<string | undefined>(resolve => {
                spotifyPlayer.addListener('ready', ({ device_id }) => {
                    this.logger.info(
                        `Spotify: Ready with Device ID: ${device_id}`
                    );
                    resolve(device_id);
                });
                spotifyPlayer.addListener('not_ready', ({ device_id }) => {
                    this.logger.info(
                        `Spotify: Device ID has gone offline: ${device_id}`
                    );
                    resolve(device_id);
                });
            });

            await spotifyPlayer.connect();
            this.player = spotifyPlayer;

            this.deviceId = await readyOrNot;
            if (this.deviceId && token?.appState.trackUri) {
                await this.playTracks([token.appState.trackUri], true);
                return;
            }

            if (this.deviceId && token?.appState.playlistId) {
                const tracksPlaylistResponse = await this.getPlaylistTracks(
                    token.appState.playlistId,
                    true
                );
                this.currentPlaylistId = token?.appState.playlistId;

                if (this.errorLoadingPlaylistTracks) return;

                this.playTracks(
                    tracksPlaylistResponse || [],
                    true,
                    token.appState.playlistId
                );
            }

            this.onReadyCallback.forEach(callback => callback());
            this.onReadyCallback = [];
        };
    }

    clearState() {
        this.currentPlaylistTracks = undefined;
        this.errorLoadingPlaylistTracks = undefined;
    }

    onReady(callback: () => void) {
        if (this.player !== null) callback();
        else this.onReadyCallback.push(callback);
    }
}
const instance = new SpotifyWebApiClass();
export default instance;
