import React from 'react';
import { formatMessage, formatNumber } from '@theorchard/suite-frontend';
import { Entity } from 'src/apollo/selectors/types';
import CoverArt from 'src/components/coverArt';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_VIDEO, ROUTE_SONG } from 'src/constants';
import { getVideoType } from 'src/utils/videos';

export const CLASSNAME = 'RelatedContent-entity';
const CLASSNAME_VALUE = `${CLASSNAME}-value`;
const CLASSNAME_TEXT = `${CLASSNAME}-text`;
const CLASSNAME_METADATA = `${CLASSNAME}-metadata`;
const CLASSNAME_HEADER = `${CLASSNAME}-header`;

export const SONG_TEST_ID = `${CLASSNAME}Song`;
export const VIDEO_TEST_ID = `${CLASSNAME}Video`;

export const SOUND_RECORDING_TERM = 'catalog.songName';
export const STREAMS_TERM = 'chart.streams';
export const VIDEO_TERM = 'video.video';
export const VIEWS_TERM = 'video.views';

export interface EntityLinkProps {
    entity: Entity;
    value: number;
    channelOwner?: string;
    videoContentType?: string;
}

const VideoLink: React.FC<EntityLinkProps> = ({
    entity,
    value,
    videoContentType,
    channelOwner,
}) => (
    <div className={CLASSNAME} data-testid={VIDEO_TEST_ID}>
        <CoverArt url={entity.imageUrl} shape="landscape" />
        <div className={CLASSNAME_METADATA}>
            <div className={CLASSNAME_HEADER}>
                {`${formatMessage(VIDEO_TERM)} - ${getVideoType(
                    channelOwner,
                    videoContentType
                )}`}
            </div>
            <div className={CLASSNAME_TEXT}>
                <TruncatedText
                    useLink
                    route={ROUTE_VIDEO}
                    params={{ id: entity.id }}
                    value={entity.name}
                />
            </div>
        </div>
        <div className={CLASSNAME_VALUE}>
            <div className={CLASSNAME_HEADER}>{formatMessage(VIEWS_TERM)}</div>
            <div className={CLASSNAME_TEXT}>{formatNumber(value)}</div>
        </div>
    </div>
);

const SongLink: React.FC<EntityLinkProps> = ({ entity, value }) => (
    <div className={CLASSNAME} data-testid={SONG_TEST_ID}>
        <CoverArt url={entity.imageUrl} />
        <div className={CLASSNAME_METADATA}>
            <div className={CLASSNAME_HEADER}>
                {formatMessage(SOUND_RECORDING_TERM)}
            </div>
            <div className={CLASSNAME_TEXT}>
                <TruncatedText
                    useLink
                    route={ROUTE_SONG}
                    params={{ isrc: entity.id }}
                    value={entity.name}
                />
            </div>
        </div>
        <div className={CLASSNAME_VALUE}>
            <div className={CLASSNAME_HEADER}>
                {formatMessage(STREAMS_TERM)}
            </div>
            <div className={CLASSNAME_TEXT}>{formatNumber(value)}</div>
        </div>
    </div>
);

export enum RelatedEntityTypes {
    SoundRecording,
    Video,
}
export interface RelatedEntityProps {
    entity: Entity;
    value: number;
    type: RelatedEntityTypes;
    channelOwner?: string;
    videoContentType?: string;
}

const RelatedEntity: React.FC<RelatedEntityProps> = ({
    entity,
    value,
    type,
    channelOwner,
    videoContentType,
}) => {
    if (type === RelatedEntityTypes.SoundRecording)
        return <SongLink entity={entity} value={value} />;
    if (type === RelatedEntityTypes.Video)
        return (
            <VideoLink
                entity={entity}
                value={value}
                channelOwner={channelOwner}
                videoContentType={videoContentType}
            />
        );
    return <></>;
};

export default RelatedEntity;
