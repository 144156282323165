import { useMemo } from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { selectSongPlaylists } from 'src/apollo/selectors/playlist';
import {
    SoundRecordingPlaylistPlacements as QueryData,
    SoundRecordingPlaylistPlacementsVariables as QueryVars,
} from '../../definitions/SoundRecordingPlaylistPlacements';
import query from './soundRecordingPlaylistPlacements.gql';

export const useSoundRecordingPlaylistPlacementsQuery = (
    variables: QueryVars,
    dateFilter?: string,
    options?: QueryHookOptions<QueryData, QueryVars>
) => {
    const { data, loading, error } = useQuery<QueryData, QueryVars>(query, {
        variables,
        ...options,
    });

    const memoizedData = useMemo(
        () => data && selectSongPlaylists(data, dateFilter),
        [data, dateFilter]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
