import { QueryHookOptions, useQuery } from '@apollo/client';
import {
    ProductAggregateStreamsQuery as Query,
    ProductAggregateStreamsQueryVariables as QueryVars,
} from '../../definitions/ProductAggregateStreamsQuery';
import { selectProductAggregateStreams } from '../../selectors';
import { flattenError } from '../../utils';
import productAggregateStreamsQuery from './aggregateStreams.gql';

export const useProductAggregateStreamsQuery = (
    variables: QueryVars,
    options: QueryHookOptions<Query, QueryVars> = {}
) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        productAggregateStreamsQuery,
        { ...options, variables }
    );

    return {
        loading,
        data: data && selectProductAggregateStreams(data),
        error: error && flattenError(error),
    };
};
