import React, { ReactNode, FC } from 'react';
import { useFeatureFlag } from '@theorchard/suite-frontend';

export interface Props {
    featureFlag: string;
    fallback?: ReactNode;
}

const FeatureFlagPanel: FC<Props> = ({ featureFlag, fallback, children }) => {
    const isFeatureEnabled = useFeatureFlag(featureFlag);

    if (isFeatureEnabled) return <>{children}</>;

    return <>{fallback}</>;
};

export default FeatureFlagPanel;
