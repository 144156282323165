import { useQuery } from '@apollo/client';
import {
    SettingsProfileQuery,
    SettingsProfileQueryVariables,
    SettingsProfileQuery_identityById_profiles as Profile,
} from '../definitions/SettingsProfileQuery';
import settingsProfileQuery from './settingsProfile.gql';

interface SettingsProfileQueryResult {
    loading: boolean;
    settingsProfile?: Profile;
}

export const useSettingsProfileQuery = (
    identityId: string
): SettingsProfileQueryResult => {
    const { data, loading } = useQuery<
        SettingsProfileQuery,
        SettingsProfileQueryVariables
    >(settingsProfileQuery, { variables: { identityId } });

    const [settingsProfile] = data?.identityById?.profiles ?? [];

    return {
        loading,
        settingsProfile,
    };
};
