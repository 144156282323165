import React, { FC } from 'react';
import { GridTable, GridTableCellProps } from '@theorchard/suite-components';
import { get } from 'lodash';
import { PlaylistType } from 'src/apollo/selectors/playlist';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import PlatformIcon from 'src/components/platformIcon';
import PlaylistLink from 'src/components/playlistLink';
import PlaylistTypeTooltip from 'src/components/playlistTypeTooltip';
import TruncatedText from 'src/components/truncatedText';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import { STORES_BY_ID } from 'src/constants/stores';
import { getPlaylistDescription } from '../utils';

const PlaylistLinkCell: FC<GridTableCellProps<PlaylistType>> = ({
    data: { curator, playlistType, storeId, playlistUri, playlistName },
    column: { definition },
}) => {
    const withPlaylistTypeIcon = get(
        definition,
        'data-withPlaylistTypeIcon',
        true
    );
    const description = getPlaylistDescription(curator, playlistType.name);
    const platform = storeId ? STORES_BY_ID[storeId] : null;

    return (
        <div className="PlaylistLinkCell">
            <PlatformIcon storeId={storeId} platform={platform} />
            <div className="PlaylistLinkCell-name-container">
                <PlaylistLink playlistUrl={playlistUri} title={playlistName} />
                <div className="PlaylistLinkCell-description">
                    {description && (
                        <TruncatedText
                            className="PlaylistLinkCell-description-text"
                            disableTooltipPointerEvents
                            value={description}
                        />
                    )}
                    <FeatureFlagPanel
                        featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                        fallback={
                            withPlaylistTypeIcon && (
                                <PlaylistTypeTooltip type={playlistType.type} />
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};

GridTable.defineColumnTemplate('playlistLink', {
    align: 'left',
    Cell: PlaylistLinkCell,
});

export default PlaylistLinkCell;
