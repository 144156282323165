import React from 'react';
import { SkeletonLoader } from '@theorchard/suite-components';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { ERROR_TYPES } from 'src/constants';
import {
    BUTTON_CLASSNAME,
    BUTTON_QUARTENARY_CLASSNAME,
    BUTTON_SMALL_CLASSNAME,
} from 'src/constants/buttonsClassnames';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

export const CLASSNAME = 'LinkWithLoader';
export const LINK_CLASSNAME = `${CLASSNAME}-link`;
export const LOADER_CLASSNAME = `${CLASSNAME}-loader`;

export interface LinkWithLoaderProps extends LinkProps {
    to: string;
    loading?: boolean;
    errorType?: string | null;
}
const LinkWithLoader: React.FC<LinkWithLoaderProps> = ({
    children,
    to,
    loading,
    errorType,
    className,
    ...linkProps
}) => {
    const shouldHideLink =
        errorType &&
        [ERROR_TYPES.NO_RESULTS, ERROR_TYPES.NO_RESULTS_IN_CATALOG].includes(
            errorType
        );
    const isColorV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);

    if (shouldHideLink) {
        return null;
    }

    return loading ? (
        <SkeletonLoader className={cx(LOADER_CLASSNAME, className)} />
    ) : (
        <Link
            className={cx(LINK_CLASSNAME, className, {
                [BUTTON_CLASSNAME]: isColorV2Enabled,
                [BUTTON_QUARTENARY_CLASSNAME]: isColorV2Enabled,
                [BUTTON_SMALL_CLASSNAME]: isColorV2Enabled,
            })}
            to={to}
            {...linkProps}
        >
            {children}
        </Link>
    );
};

export default LinkWithLoader;
