import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { BsOverlay } from '@theorchard/suite-components';
import cx from 'classnames';
import TruncatedText from 'src/components/truncatedText';
import { getCompanyIcon } from 'src/utils/accounts';
import Menu from './menu';
import { PopupState, Props } from './types';

export const CLASSNAME = 'AccountsInfoPopup';
export const CLASSNAME_ACCOUNT = `${CLASSNAME}-account`;
export const CLASSNAME_ACCOUNT_WITH_LOGO = `${CLASSNAME_ACCOUNT}-with-logo`;
export const CLASSNAME_ACCOUNT_ACTIVE = `${CLASSNAME_ACCOUNT}-active`;
export const CLASSNAME_ACCOUNT_MORE = `${CLASSNAME_ACCOUNT}-more`;
export const CLASSNAME_POPUP = `${CLASSNAME}-popup`;

const AccountsDetailsPopup: FC<Props> = ({
    accounts,
    placement = 'bottom-start',
    showTitle = true,
}) => {
    const [{ isOpen, isFixed }, setPopupState] = useState<PopupState>({
        isOpen: false,
        isFixed: false,
    });
    const target = useRef<HTMLDivElement | null>(null);
    const menu = useRef<HTMLDivElement | null>(null);

    const handleClick = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setPopupState({
            isFixed: true,
            isOpen: true,
        });
    };

    const handleFocus = () => {
        if (!isFixed) setPopupState(state => ({ ...state, isOpen: true }));
    };

    const handleBlur = () => {
        if (!isFixed) setPopupState(state => ({ ...state, isOpen: false }));
    };

    useEffect(() => {
        if (isFixed) {
            const handleClickOutside = (event: Event) => {
                if (
                    menu.current &&
                    !menu.current.contains(event.target as Node)
                )
                    setPopupState({
                        isFixed: false,
                        isOpen: false,
                    });
            };
            document.addEventListener('click', handleClickOutside, true);

            return () => {
                document.removeEventListener('click', handleClickOutside, true);
            };
        }
    }, [isFixed]);

    if (!accounts?.length) return null;

    const { brandAbbrName } = accounts[0];
    const logo = getCompanyIcon(brandAbbrName);
    const isMultiAccounts = accounts.length > 1;
    const firstAccountName = accounts[0].labelName || accounts[0].name;
    const title = isMultiAccounts ? accounts[0].name : firstAccountName;

    return (
        <>
            <div
                onClick={handleClick}
                onMouseEnter={handleFocus}
                onFocus={handleFocus}
                onMouseLeave={handleBlur}
                onBlur={handleBlur}
                className={CLASSNAME}
            >
                <div
                    ref={target}
                    className={cx(CLASSNAME_ACCOUNT, {
                        [CLASSNAME_ACCOUNT_WITH_LOGO]: !!logo,
                        [CLASSNAME_ACCOUNT_ACTIVE]: isOpen,
                    })}
                >
                    {logo}
                    {showTitle && (
                        <TruncatedText value={title} withOverlay={false} />
                    )}
                    {isMultiAccounts && showTitle ? (
                        <span className={CLASSNAME_ACCOUNT_MORE}>{`+${
                            accounts.length - 1
                        }`}</span>
                    ) : null}
                </div>
            </div>
            <BsOverlay
                target={target.current}
                show={isOpen}
                placement={placement}
            >
                {({ arrowProps, show: _show, popper, ...props }) => (
                    <div
                        className={CLASSNAME_POPUP}
                        {...props}
                        data-testid={CLASSNAME_POPUP}
                    >
                        <Menu accounts={accounts} forwardedRef={menu} />
                    </div>
                )}
            </BsOverlay>
        </>
    );
};

export default AccountsDetailsPopup;
