import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { selectUGCVideos } from 'src/apollo/selectors';
import {
    TopUGCQuery,
    TopUGCQueryVariables,
} from '../../definitions/TopUGCQuery';
import { useParallelQueries } from '../../utils';
import topUgcQuery from './topUgc.gql';

export const useVideoUGCQuery = (variables: TopUGCQueryVariables) => {
    const { data, loading, error } = useQuery<
        TopUGCQuery,
        TopUGCQueryVariables
    >(topUgcQuery, { variables });

    const memoizedData = useMemo(() => data && selectUGCVideos(data), [data]);

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useBatchUGCQuery = (variables: TopUGCQueryVariables[]) => {
    const client = useApolloClient();
    const { data, loading, error } = useParallelQueries<
        TopUGCQuery,
        TopUGCQueryVariables
    >(topUgcQuery, { variables, client });

    const memoizedData = useMemo(
        () => data && data.map(d => selectUGCVideos(d)),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
