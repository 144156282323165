import { useMemo } from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import {
    TopGlobalParticipantsMetadataQuery as Query,
    TopGlobalParticipantsMetadataQueryVariables as QueryVars,
} from 'src/apollo/definitions/TopGlobalParticipantsMetadataQuery';
import { selectTopGlobalParticipantsMetadata } from 'src/apollo/selectors';
import topGlobalParticipantsMetadataQuery from './topGlobalParticipantsMetadata.gql';

export const useTopGlobalParticipantsMetadataQuery = (
    variables: QueryVars,
    options?: QueryHookOptions
) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        topGlobalParticipantsMetadataQuery,
        { variables, fetchPolicy: 'cache-first', ...options }
    );

    const memoizedData = useMemo(
        () => data && selectTopGlobalParticipantsMetadata(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
