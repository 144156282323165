import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { compact } from 'lodash';
import {
    GlobalProductQuery as Query,
    GlobalProductQueryVariables as Vars,
} from 'src/apollo/definitions/GlobalProductQuery';
import { selectProduct } from '../../selectors';
import { flattenError, useParallelQueries } from '../../utils';
import productQuery from './globalProduct.gql';

export const useProductQuery = (id?: string) => {
    const { data, loading, error } = useQuery<Query>(productQuery, {
        variables: { id },
        skip: !id,
    });
    return {
        loading,
        data: data && selectProduct(data),
        error: error && flattenError(error),
    };
};

export const useProductBatchQuery = (ids?: string[]) => {
    const client = useApolloClient();
    const variables: Vars[] = Array.isArray(ids)
        ? ids.map<Vars>(id => ({ id }))
        : [];
    const { data, loading, error } = useParallelQueries<Query, Vars>(
        productQuery,
        { variables, client }
    );
    const productsData = useMemo(
        () => compact(data?.map(product => selectProduct(product))),
        [data]
    );

    return {
        loading,
        data: productsData,
        error,
    };
};
