import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { selectPlaylistPlacement } from 'src/apollo/selectors/playlist';
import {
    PlaylistPlacement as QueryData,
    PlaylistPlacementVariables as QueryVars,
} from '../../definitions/PlaylistPlacement';
import query from './playlistPlacement.gql';

export const usePlaylistPlacementQuery = (
    variables: QueryVars,
    dateFilter?: string,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<QueryData, QueryVars>(query, {
        variables,
        skip,
    });

    const memoizedData = useMemo(
        () => data && selectPlaylistPlacement(data, dateFilter),
        [data, dateFilter]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
