import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { selectOfferTypesIconKeys } from 'src/apollo/selectors';
import OfferTypeIcon from 'src/components/offerTypeIcon';

GridTable.defineColumnTemplate('offerType', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { offerType } }) => {
        const offerKeys = selectOfferTypesIconKeys(offerType);

        return (
            <div className="OfferTypeCell">
                {offerKeys.map(item => (
                    <OfferTypeIcon key={item.name} offerTypeKey={item} />
                ))}
            </div>
        );
    },
});
