import React, { FC, ReactElement } from 'react';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';

export const CLASSNAME = 'InfoMessage';
export const CLASSNAME_TITLE = `${CLASSNAME}-title`;
export const CLASSNAME_BODY = `${CLASSNAME}-body`;

export interface Props {
    message: string;
    className?: string;
    body?: string;
    Icon?: React.ComponentType | ReactElement;
}

const InfoMessage: FC<Props> = ({
    message,
    className,
    body,
    Icon = <GlyphIcon size={16} name="warning" />,
}) => (
    <div className={cx(CLASSNAME, className)}>
        {Icon}
        <h4 className={CLASSNAME_TITLE}>{message}</h4>
        {body && <p className={CLASSNAME_BODY}>{body}</p>}
    </div>
);

export default InfoMessage;
