import { useQuery } from '@apollo/client';
import { selectVideoMetrics } from 'src/apollo/selectors/videoMetrics';
import {
    VideoAnalyticsDailyQuery,
    VideoAnalyticsDailyQueryVariables,
} from '../../definitions/VideoAnalyticsDailyQuery';
import { flattenError } from '../../utils';
import videoAnalyticsDailyQuery from './videoAnalyticsDaily.gql';

export const useVideoAnalyticsDailyQuery = (
    variables: VideoAnalyticsDailyQueryVariables
) => {
    const { data, loading, error } = useQuery<
        VideoAnalyticsDailyQuery,
        VideoAnalyticsDailyQueryVariables
    >(videoAnalyticsDailyQuery, { variables });

    return {
        loading,
        data: selectVideoMetrics(data?.video?.analytics.daily),
        error: error && flattenError(error),
    };
};
