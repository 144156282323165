import React from 'react';

export const CLASSNAME = 'StreamActiveIcon';

const StreamActiveIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className={CLASSNAME}
    >
        {/* eslint-disable max-len */}
        <path fill="#fff" d="M0 0H24V24H0z" />
        <circle cx="12" cy="12" r="11" stroke="#1C1C1C" strokeWidth="2" />
        <path
            fill="#1C1C1C"
            d="M12.008 16.12c-.72 0-1.335-.108-1.847-.324-.512-.216-.9-.5-1.164-.852a2.013 2.013 0 01-.42-1.152.23.23 0 01.072-.168.227.227 0 01.18-.084H9.92c.088 0 .16.02.216.06.056.04.107.096.155.168.08.272.26.5.54.684.288.184.68.276 1.176.276.56 0 .98-.092 1.26-.276.288-.192.432-.452.432-.78a.733.733 0 00-.215-.54c-.144-.144-.364-.268-.66-.372-.288-.112-.72-.244-1.296-.396-.944-.224-1.636-.52-2.076-.888-.44-.368-.66-.896-.66-1.584 0-.464.128-.876.384-1.236.256-.368.62-.656 1.091-.864.48-.208 1.037-.312 1.668-.312.665 0 1.236.116 1.716.348.489.232.857.524 1.105.876.255.344.392.688.408 1.032a.272.272 0 01-.072.18.244.244 0 01-.18.072h-1.14c-.176 0-.296-.072-.36-.216-.04-.256-.197-.468-.469-.636-.272-.176-.608-.264-1.008-.264-.44 0-.787.084-1.043.252-.248.168-.373.412-.373.732 0 .216.065.396.193.54.128.144.328.272.6.384.271.104.667.224 1.188.36.712.16 1.276.34 1.692.54.424.2.736.452.936.756.2.304.3.692.3 1.164 0 .52-.145.972-.433 1.356-.28.376-.68.664-1.2.864-.511.2-1.107.3-1.787.3z"
        />
        {/* eslint-enable max-len */}
    </svg>
);

export default StreamActiveIcon;
