import { filterData } from '@theorchard/suite-frontend';
import dayjs from 'dayjs';
import { get, keys, sumBy } from 'lodash';
import {
    TikTokTimeSeries,
    TikTokTimeSeries_globalSoundRecordingByIsrc_tiktok_timeseries_items as TimeSeries,
} from 'src/apollo/definitions/TikTokTimeSeries';
import dateDiff from 'src/apollo/selectors/dates';
import { ChartPoint } from 'src/components/graph';
import { DATE_FORMAT_LONG } from 'src/constants';
import { isAbsolutePeriod } from 'src/utils/datePeriod';

type TikTokTimeSeriesData = Record<
    string,
    { isrc: string; data: ChartPoint[] }
>;

export interface TiktokTimeSeries {
    isrc: string;
    type: string;
    data: ChartPoint[];
    total: number;
}

const getTimeseriesData = (
    prevResult: TikTokTimeSeriesData,
    { date, ...fields }: TimeSeries,
    type: string,
    index: number,
    datePeriod: string
) => {
    const prevValue =
        prevResult[type].data[prevResult[type].data.length - 1]?.y;
    const value: number | null = get(fields, type);

    const change = prevValue && value ? value - prevValue : null;

    return [
        ...prevResult[type].data,
        {
            x: isAbsolutePeriod(datePeriod)
                ? new Date(date).getTime()
                : index + 1,
            y: value,
            z: isAbsolutePeriod(datePeriod)
                ? dateDiff(date)
                : dayjs.utc(date).format(DATE_FORMAT_LONG),
            change,
        },
    ];
};

export const selectTiktokTimeSeries = (
    queryData: TikTokTimeSeries,
    datePeriod: string
): TiktokTimeSeries[] => {
    const isrc = queryData.globalSoundRecordingByIsrc?.isrc ?? '';
    const timeSeriesData: (ChartPoint & { change?: number | null })[] = [];
    const defaultTimeseriesData = {
        views: { isrc, data: timeSeriesData },
        creations: { isrc, data: timeSeriesData },
        favorites: { isrc, data: timeSeriesData },
        shares: { isrc, data: timeSeriesData },
        likes: { isrc, data: timeSeriesData },
        comments: { isrc, data: timeSeriesData },
    };

    const timeseries: TikTokTimeSeriesData = filterData(
        queryData.globalSoundRecordingByIsrc?.tiktok?.timeseries?.items
    ).reduce((result, item, index) => {
        const newObject = { ...result };

        newObject.creations.data = getTimeseriesData(
            result,
            item,
            'creations',
            index,
            datePeriod
        );
        newObject.comments.data = getTimeseriesData(
            result,
            item,
            'comments',
            index,
            datePeriod
        );
        newObject.favorites.data = getTimeseriesData(
            result,
            item,
            'favorites',
            index,
            datePeriod
        );
        newObject.likes.data = getTimeseriesData(
            result,
            item,
            'likes',
            index,
            datePeriod
        );
        newObject.shares.data = getTimeseriesData(
            result,
            item,
            'shares',
            index,
            datePeriod
        );
        newObject.views.data = getTimeseriesData(
            result,
            item,
            'views',
            index,
            datePeriod
        );

        return newObject;
    }, defaultTimeseriesData);

    return keys(timeseries).map(key => ({
        type: key,
        ...timeseries[key],
        total: sumBy(timeseries[key].data, 'y'),
    }));
};
