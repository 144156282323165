import { useLazyQuery, useQuery } from '@apollo/client';
import {
    LabelManager,
    LabelManagerVariables,
} from 'src/apollo/definitions/LabelManager';
import { flattenError } from '../../utils';
import labelManagerQuery from './labelManager.gql';

export const useLabelManagerQuery = (variables: LabelManagerVariables) => {
    const { data, loading, error } = useQuery<
        LabelManager,
        LabelManagerVariables
    >(labelManagerQuery, { variables });

    return {
        loading,
        data,
        error: error && flattenError(error),
    };
};

export const useLabelManagerLazyQuery = () => {
    const result = useLazyQuery<LabelManager, LabelManagerVariables>(
        labelManagerQuery
    );

    return result;
};
