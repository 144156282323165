import { sortBy } from 'lodash';
import { VideoAnalyticsTopCountriesQuery_video_analytics_topCountries_countries as TopCountry } from 'src/apollo/definitions/VideoAnalyticsTopCountriesQuery';

export interface TopVideoCountry {
    key: string;
    value: number;
    position?: number;
    highlighted: boolean;
}

export const selectActiveCountries = (countries?: TopCountry[]) =>
    sortBy(
        (countries ?? [])
            .filter(country => country.countryCode && country.views)
            .map(country => ({
                key: country.countryCode,
                value: country.views,
            })),
        country => country.value
    )
        .reverse()
        .map((country, i) => ({ ...country, position: i + 1 }));

export const selectVideoCountries = (
    countries?: TopCountry[],
    selectedCountries?: string[]
): TopVideoCountry[] => {
    if (selectedCountries && selectedCountries.length) {
        const countryList = selectActiveCountries(countries).map(c => ({
            ...c,
            highlighted: selectedCountries.some(country => country === c.key),
        }));
        if (!countryList.find(c => c.highlighted))
            selectedCountries.map((c, i) =>
                countryList.push({
                    key: c,
                    value: 0,
                    highlighted: true,
                    position: i,
                })
            );
        return countryList;
    }
    return selectActiveCountries(countries).map(c => ({
        ...c,
        highlighted: false,
    }));
};
