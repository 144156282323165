import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import VideoLinkWithTypeCell from './videoLinkWithTypeCell';

GridTable.defineColumnTemplate('thumbnailVideoLinkWithType', {
    align: 'left',
    sortable: false,
    Cell: ({ data, ...rest }) => (
        <div
            className="ThumbnailVideoLinkWithTypeCell CoverArtCell"
            data-testid="ThumbnailVideoLinkWithTypeCell"
        >
            <CoverArt url={data.video.imageUrl} shape="landscape" width="60" />
            <VideoLinkWithTypeCell data={data} {...rest} />
        </div>
    ),
});
