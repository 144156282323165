import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_PARTICIPANT } from 'src/constants';
import EmptyCell from './emptyCell';

GridTable.defineColumnTemplate('globalParticipantLink', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { globalParticipant } }) => {
        if (!globalParticipant) return <EmptyCell />;
        return (
            <TruncatedText
                useLink
                route={ROUTE_PARTICIPANT}
                params={{ id: globalParticipant.id }}
                value={globalParticipant.name}
            />
        );
    },
});
