import { useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import {
    TopGlobalSoundRecordingFamiliesQuery,
    TopGlobalSoundRecordingFamiliesQueryVariables,
} from 'src/apollo/definitions/TopGlobalSoundRecordingFamiliesQuery';
import { TopGlobalSoundRecordingsWithTikTokV2QueryVariables } from 'src/apollo/definitions/TopGlobalSoundRecordingsWithTikTokV2Query';
import {
    selectTopGlobalSoundRecordingFamilies,
    selectTopGlobalSoundRecordingsWithTikTok,
    selectTopGlobalSoundRecordingsWithTikTokV2,
    TopGlobalSoundRecordingsWithTikTokQuery as Query,
    TopGlobalSoundRecordingsWithTikTokQueryV2,
} from 'src/apollo/selectors/catalog';
import { useDistributorsFilterOptions } from 'src/components/distributorFilter/utils';
import {
    SONGS_PAGE_SIZE,
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_DIR,
} from 'src/pages/catalog/pages/songs/constants';
import { TopGlobalSoundRecordingsWithTikTokQueryVariables as QueryVars } from '../../definitions/TopGlobalSoundRecordingsWithTikTokQuery';
import { useEmployeeIdentity, usePrefetchQuery } from '../../utils';
import topGlobalSoundRecordingFamiliesQuery from './topGlobalSoundRecordingFamilies.gql';
import topGlobalSoundRecordingsWithTikTokQuery from './topGlobalSoundRecordingsWithTikTok.gql';
import topGlobalSoundRecordingsWithTikTokQueryV2 from './topGlobalSoundRecordingsWithTikTokV2.gql';

export const useTopGlobalSoundRecordingsQuery = (
    variables: QueryVars,
    store?: string | undefined,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        topGlobalSoundRecordingsWithTikTokQuery,
        { variables, skip }
    );
    const memoizedData = useMemo(
        () => data && selectTopGlobalSoundRecordingsWithTikTok(data, store),
        [data, store]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useTopGlobalSoundRecordingFamilies = (
    variables: TopGlobalSoundRecordingFamiliesQueryVariables,
    store?: string | undefined,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<
        TopGlobalSoundRecordingFamiliesQuery,
        TopGlobalSoundRecordingFamiliesQueryVariables
    >(topGlobalSoundRecordingFamiliesQuery, { variables, skip });

    const memoizedData = useMemo(
        () => data && selectTopGlobalSoundRecordingFamilies(data, store),
        [data, store]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useTopGlobalSoundRecordingsQueryV2 = (
    variables: TopGlobalSoundRecordingsWithTikTokV2QueryVariables,
    store?: string | undefined
) => {
    const { data, loading, error } = useQuery<
        TopGlobalSoundRecordingsWithTikTokQueryV2,
        TopGlobalSoundRecordingsWithTikTokV2QueryVariables
    >(topGlobalSoundRecordingsWithTikTokQueryV2, { variables });
    const memoizedData = useMemo(
        () => data && selectTopGlobalSoundRecordingsWithTikTokV2(data, store),
        [data, store]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchTopGlobalSoundRecordingsQuery = () => {
    const { isEmployee = false } = useEmployeeIdentity();
    const { selectedValue } = useDistributorsFilterOptions();

    usePrefetchQuery<Query, QueryVars>(
        topGlobalSoundRecordingsWithTikTokQuery,
        {
            variables: {
                limit: SONGS_PAGE_SIZE,
                orderBy: DEFAULT_SORT_FIELD,
                orderDir: DEFAULT_SORT_DIR,
                countries: [],
                distributors: selectedValue,
                globalParticipantIds: [],
                isEmployee,
                labelIds: [],
                storeIds: [],
                subaccountIds: [],
            },
        }
    );
};

interface PrefetchQueryOptions {
    skip: boolean;
}

export const prefetchTopGlobalSoundRecordingsQuery = async (
    client: ApolloClient<object>,
    variables: Partial<QueryVars> = {},
    options: PrefetchQueryOptions = { skip: false }
) => {
    const { skip } = options;

    return (
        !skip &&
        (await client.query({
            query: topGlobalSoundRecordingsWithTikTokQuery,
            variables: {
                limit: SONGS_PAGE_SIZE,
                orderBy: DEFAULT_SORT_FIELD,
                orderDir: DEFAULT_SORT_DIR,
                countries: [],
                globalParticipantIds: [],
                isEmployee: false,
                labelIds: [],
                storeIds: [],
                subaccountIds: [],
                ...variables,
            },
        }))
    );
};
