import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { createHref } from '@theorchard/suite-frontend';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import PlatformIcon from 'src/components/platformIcon';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_SONG } from 'src/constants';
import { getDefinition } from 'src/constants/charts';
import { PAGE_CHARTS } from 'src/constants/page';

GridTable.defineColumnTemplate('chartHighlightLink', {
    align: 'left',
    Cell: ({ data, column: { definition } }) => {
        const { chartKey } = data;
        const { platform, label, name } = getDefinition(data.chart);
        const isrc = get(definition, 'data-isrc') as string | undefined;
        const country = get(definition, 'data-country') as string | undefined;
        const isCompareMode = get(definition, 'data-isCompareMode') as
            | boolean
            | undefined;

        const link = isrc
            ? createHref(ROUTE_SONG, {
                  isrc,
                  page: PAGE_CHARTS,
                  country,
                  chart: chartKey,
              })
            : '#';

        return (
            <Link className="d-inlineflex align-items-center" to={link}>
                <PlatformIcon platform={platform} />
                <TruncatedText value={isCompareMode ? name : label} />
            </Link>
        );
    },
});
