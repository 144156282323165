import { GlobalSoundRecordingSearchOrderByField } from 'src/apollo/definitions/globalTypes';

export const PARAM_LABEL_NAME = 'labelName';
export const PARAM_PARTICIPANT_NAME = 'participantName';
export const PARAM_COUNTRIES = 'countries';
export const PARAM_VENDOR_IDS = 'vendorIds';
export const PARAM_GLOBAL_PARTICIPANT_IDS = 'gpIds';
export const PARAM_RELEASE_DATE = 'releaseDate';
export const PARAM_QUERY_TYPE = 'queryType';
export const PARAM_CATALOG_ONLY = 'catalog';

export const PARAM_TERM = 'term';
export const PARAM_LIMIT = 'limit';
export const PARAM_OFFSET = 'offset';
export const PARAM_ORDER_BY = 'orderBy';
export const PARAM_ORDER_DIR = 'orderDir';

export const FILTER_PARAMS = [
    PARAM_QUERY_TYPE,
    PARAM_LABEL_NAME,
    PARAM_PARTICIPANT_NAME,
    PARAM_COUNTRIES,
    PARAM_VENDOR_IDS,
    PARAM_GLOBAL_PARTICIPANT_IDS,
    PARAM_RELEASE_DATE,
    `${PARAM_RELEASE_DATE}From`,
    `${PARAM_RELEASE_DATE}To`,
];

export const SEARCH_SONG_SORTING_FIELDS_MAP: Record<string, string> = {
    releaseDate: GlobalSoundRecordingSearchOrderByField.RELEASE_DATE,
    streams7Days: GlobalSoundRecordingSearchOrderByField.STREAMS_7_DAYS,
    streams28Days: GlobalSoundRecordingSearchOrderByField.STREAMS_28_DAYS,
    streamsAllTime: GlobalSoundRecordingSearchOrderByField.STREAMS_ALL_TIME,
    popularity: GlobalSoundRecordingSearchOrderByField.POPULARITY,
    score: GlobalSoundRecordingSearchOrderByField.SCORE,
};
