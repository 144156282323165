import { useQuery } from '@apollo/client';
import { selectTopVideoTrafficSources } from 'src/apollo/selectors/videoTrafficSources';
import {
    VideoAnalyticsTopTrafficQuery,
    VideoAnalyticsTopTrafficQueryVariables,
} from '../../definitions/VideoAnalyticsTopTrafficQuery';
import { flattenError } from '../../utils';
import topVideoTrafficSourcesQuery from './videoAnalyticsTopTraffic.gql';

export const useVideoAnalyticsTopTrafficQuery = (
    variables: VideoAnalyticsTopTrafficQueryVariables
) => {
    const { data, loading, error } = useQuery<
        VideoAnalyticsTopTrafficQuery,
        VideoAnalyticsTopTrafficQueryVariables
    >(topVideoTrafficSourcesQuery, { variables });

    return {
        loading,
        data: selectTopVideoTrafficSources(
            data?.video?.analytics.topTrafficSources.videoTrafficSources
        ),
        error: error && flattenError(error),
    };
};
