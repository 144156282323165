import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import {
    PlaylistPlacementPositionTimeseries,
    PlaylistPlacementPositionTimeseriesVariables,
} from 'src/apollo/definitions/PlaylistPlacementPositionTimeseries';
import { selectPlaylistPlacementPositionTimeseries } from 'src/apollo/selectors/playlist';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import PlaylistPlacementPositionTimeseriesQuery from './playlistPlacementPositionTimeseries.gql';

export const usePlaylistPlacementPositionTimeseries = (
    variables: PlaylistPlacementPositionTimeseriesVariables,
    peakPosition: number | null,
    skipPositions?: boolean
) => {
    const skip = skipPositions || !variables.playlistId;
    const isColorSchemaV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);
    const {
        loading,
        data: timeSeriesData,
        error,
    } = useQuery<
        PlaylistPlacementPositionTimeseries,
        PlaylistPlacementPositionTimeseriesVariables
    >(PlaylistPlacementPositionTimeseriesQuery, { variables, skip });

    const data = useMemo(
        () =>
            selectPlaylistPlacementPositionTimeseries(
                timeSeriesData?.playlistPlacementPositionTimeseries?.items,
                peakPosition,
                isColorSchemaV2Enabled
            ),
        [timeSeriesData, peakPosition]
    );

    return { loading, data, error };
};
