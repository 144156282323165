import { useApolloClient } from '@apollo/client';
import {
    AccountDemographics,
    AccountDemographicsVariables,
} from '../../definitions/AccountDemographics';
import { useParallelQueries } from '../../utils';
import AccountDemographicsBreakdownQuery from './accountDemographics.gql';

export const useAccountBatchDemographicsBreakdownQuery = (
    variables: AccountDemographicsVariables[],
    skip?: boolean
) => {
    const client = useApolloClient();
    const { loading, data, error } = useParallelQueries<
        AccountDemographics,
        AccountDemographicsVariables
    >(AccountDemographicsBreakdownQuery, { variables, client, skip });

    return { loading, data, error };
};
