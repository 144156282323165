import { useQuery } from '@apollo/client';
import { LABEL_MANAGERS_LIMIT } from 'src/constants/catalog';
import {
    LabelManagers,
    LabelManagersVariables,
} from '../../definitions/LabelManagers';
import { selectLabelManagers } from '../../selectors/labelManager';
import { flattenError } from '../../utils';
import { useAppRecentsQuery } from '../appRecents';
import labelManagersQuery from './labelManagers.gql';

export const useLabelManagersQuery = () => {
    const { data, loading, error } = useQuery<
        LabelManagers,
        LabelManagersVariables
    >(labelManagersQuery, {
        variables: { limit: LABEL_MANAGERS_LIMIT },
        fetchPolicy: 'cache-first',
    });

    return {
        loading,
        data: data && selectLabelManagers(data.labelManagers),
        error: error && flattenError(error),
    };
};

export const useRecentLabelManagersQuery = () => {
    const { data, loading, error } = useAppRecentsQuery();

    return {
        loading,
        data: data.recentLabelManagers,
        error,
    };
};
