import { filterData, formatMessage } from '@theorchard/suite-frontend';
import { sortBy, sumBy } from 'lodash';
import { AccountTopContent as AccountTopContentQuery } from 'src/apollo/definitions/AccountTopContent';
import { AccountTopCountries } from 'src/apollo/definitions/AccountTopCountries';
import { AccountTopStores as AccountTopStoreQuery } from 'src/apollo/definitions/AccountTopStores';
import { AccountTotalSummary } from 'src/apollo/definitions/AccountTotalSummary';
import { AccountYoutubeVideos } from 'src/apollo/definitions/AccountYoutubeVideos';
import {
    AccountTopContentType,
    VendorType,
} from 'src/apollo/definitions/globalTypes';
import { OrchardLabelDetails_orchardLabel } from 'src/apollo/definitions/OrchardLabelDetails';
import { RecentReleases } from 'src/apollo/definitions/RecentReleases';
import { TopAccounts } from 'src/apollo/definitions/TopAccounts';
import { TopAccountsCount } from 'src/apollo/definitions/TopAccountsCount';
import { selectAccounts } from 'src/apollo/selectors/utils';
import { nonNullable } from 'src/apollo/utils';
import { Account } from 'src/components/accountsDetailsPopup';

export interface Label {
    vendorId: number;
    subaccountId: number;
    name: string;
    logoUrl?: string;
    type: VendorType | null;
    vendorName: string | null;
}

export interface CatalogAccountCount {
    totalCount: number;
    account?: Pick<Label, 'vendorId' | 'subaccountId'>;
}

export interface CatalogAccount {
    id: string;
    label: Label;
    accounts: Account[];
    labelManagerName?: string | null;
    brandAbbrName?: string;

    streams28Days?: number | null;
    growthPercentage28Days?: number | null;

    trackDownloads28Days?: number | null;
    trackDownloadsGrowthPercentage28Days?: number | null;

    albumDownloads28Days?: number | null;
    albumDownloadsGrowthPercentage28Days?: number | null;
}

export interface VendorIdType {
    vendorId: number;
    /**
     * This field should be 0 for a Vendor.
     */
    subaccountId: number;
}

export interface AccountMetric {
    trackStreamsAllTime?: number | null;
    trackStreams7DaysPercentage?: number | null;

    aggregatedDownloadsAllTime?: number | null;
    aggregatedDownloads7DaysPercentage?: number | null;
}

export interface CatalogAccountDetails {
    vendorId: number;
    subaccountId: number;
    name: string;
    tier?: number | string;
    type: VendorType | null;
    labelManagerName?: string | null;

    accountMetric?: AccountMetric | null;

    vendorName: string | null;
    vendorType?: VendorType | null;

    brand?: string;
    brandAbbrName?: string;
    parentCompany?: string;
    parentCompanyAbbrName?: string;
}

interface ParentCompanyBrandType {
    displayName: string;
    name: string;
}

interface ServiceTierType {
    displayName: string;
    name: string;
}

interface CompanyBrandType {
    displayName: string;
    name: string;
    parentCompany: ParentCompanyBrandType | null;
}

interface VendorDetailsType {
    name: string;
    type: VendorType | null;
    serviceTier?: ServiceTierType | null;
    companyBrand: CompanyBrandType | null;
}

interface SubaccountDetailsType {
    vendor: Omit<VendorDetailsType, 'type'> & {
        type?: VendorType | null;
    };
}

export type DataDetailsType = VendorDetailsType | SubaccountDetailsType;

export interface AccountRecentReleases {
    upc: string;
    imageUrl?: string;
    storeId?: number;
    growthPercentage: number | null;
    name: string;
    releaseDate: string | null;
    format?: string;
}

export const selectVendorDetails = (details: DataDetailsType) => {
    const isSubaccountType = 'vendor' in details;
    const baseData = isSubaccountType ? details.vendor : details;
    const type = isSubaccountType ? null : details.type;
    const vendorName = isSubaccountType ? details.vendor.name : null;
    const vendorType = isSubaccountType ? details.vendor.type : null;
    const { serviceTier, companyBrand } = baseData;

    return {
        type,
        vendorName,
        vendorType,
        tier: serviceTier?.displayName,
        brand: companyBrand?.displayName,
        brandAbbrName: companyBrand?.name,
        parentCompany: companyBrand?.parentCompany?.displayName,
        parentCompanyAbbrName: companyBrand?.parentCompany?.name,
    };
};

export const selectAccountDetails = (
    data: OrchardLabelDetails_orchardLabel
): CatalogAccountDetails => {
    const {
        id: { vendorId, subaccountId },
        name,
        analytics,
    } = data;

    const baseDetails = {
        vendorId,
        subaccountId,
        name,
        labelManagerName: analytics?.labelManager?.name,
        accountMetric: analytics && {
            trackStreamsAllTime: analytics?.trackStreamsAllTime,
            trackStreams7DaysPercentage:
                analytics?.trackStreams7Days?.growthPercentage,
            aggregatedDownloadsAllTime: analytics?.aggregatedDownloadsAllTime,
            aggregatedDownloads7DaysPercentage:
                analytics?.aggregatedDownloads7Days?.growthPercentage,
        },
    };

    return {
        ...baseDetails,
        ...selectVendorDetails(data),
    };
};

export const selectCatalogAccountsCount = (
    data: TopAccountsCount
): CatalogAccountCount => {
    const {
        topAccounts: { accounts, totalCount },
    } = data;

    return {
        totalCount,
        account: accounts?.[0]?.id,
    };
};

export const selectCatalogAccounts = (data: TopAccounts) => {
    const {
        topAccounts: { accounts, totalCount, labelsCount, subaccountsCount },
    } = data;

    return {
        totalCount,
        labelsCount,
        subaccountsCount,
        accounts: filterData(accounts).map<CatalogAccount>(account => {
            const {
                uuid,
                name,
                id: { vendorId, subaccountId },
                analytics,
            } = account;
            const trackStreams28Days = analytics?.trackStreams28Days;
            const albumDownloads = analytics?.albumDownloads;
            const trackDownloads = analytics?.trackDownloads;
            const labelManager = analytics?.labelManager;
            const { type, vendorName, brandAbbrName } =
                selectVendorDetails(account);

            return {
                id: uuid,
                label: {
                    name,
                    vendorId,
                    subaccountId,
                    type,
                    vendorName,
                },
                labelManagerName: labelManager?.name,
                brandAbbrName,
                accounts: selectAccounts([account], name),

                streams28Days: trackStreams28Days?.value,
                albumDownloads28Days: albumDownloads?.value,
                trackDownloads28Days: trackDownloads?.value,

                growthPercentage28Days: trackStreams28Days?.growthPercentage,
                albumDownloadsGrowthPercentage28Days:
                    albumDownloads?.growthPercentage,
                trackDownloadsGrowthPercentage28Days:
                    trackDownloads?.growthPercentage,
            };
        }),
    };
};

export const selectRecentReleases = (
    data?: RecentReleases
): AccountRecentReleases[] => {
    const orchardLabel = data?.orchardLabel;
    if (!orchardLabel) return [];

    return filterData(
        orchardLabel?.products.products.map(
            ({
                upc,
                productName,
                format,
                releaseDate,
                imageLocation,
                streamsGrowthPeriods,
                globalProduct,
            }) => {
                const imageUrl =
                    imageLocation || globalProduct?.publicImageUrl || '';
                return {
                    upc: upc || '',
                    name: productName,
                    imageUrl,
                    releaseDate,
                    format: format || '',
                    growthPercentage: streamsGrowthPeriods[0].growthPercentage,
                };
            }
        )
    );
};

interface AccountTopContentData {
    value: number;
    name: string;
    imageUrl?: string;
    percent: number;
}

export interface AccountTopContent {
    products: (AccountTopContentData & { upc: string })[];
    songs: (AccountTopContentData & { isrc: string })[];
    artists: (AccountTopContentData & { id: string })[];
}

export const selectAccountTopContent = (
    data: AccountTopContentQuery | undefined,
    summaryData?: AccountTotalSummary | null,
    type?: AccountTopContentType | null
): AccountTopContent | undefined => {
    const topContent = data?.orchardLabel?.topContent;

    if (!topContent) return undefined;

    const { topNArtists, topNProducts, topNSongs } = topContent;
    const metrics = summaryData?.orchardLabel?.summary?.items[0];

    const songsAllTimeValues =
        type === AccountTopContentType.STREAMS
            ? metrics?.streams
            : metrics?.trackDownloads;
    const productsAllTimeValues =
        type === AccountTopContentType.STREAMS
            ? metrics?.streams
            : metrics?.albumDownloads;

    const songs = topNSongs.map(({ globalSoundRecording, value }) => ({
        value,
        name: globalSoundRecording.name,
        isrc: globalSoundRecording.isrc,
        imageUrl: nonNullable(globalSoundRecording.imageUrl),
        percent: value / (songsAllTimeValues || 1),
    }));

    const products = topNProducts.map(({ value, product }) => {
        const { globalProduct, imageLocation, productName, upc } = product;
        const imageUrl = imageLocation || globalProduct?.publicImageUrl || '';

        return {
            value,
            name: productName,
            upc: upc || '',
            imageUrl: nonNullable(imageUrl),
            percent: value / (productsAllTimeValues || 1),
        };
    });

    const artists = topNArtists.map(({ value, globalParticipant }) => ({
        value,
        name: globalParticipant.name || '',
        id: globalParticipant.id,
        imageUrl: nonNullable(globalParticipant.imageUrl),
        percent: 0,
    }));

    return { artists, products, songs };
};

export const selectAccountTopCountriesTopStores = (
    data: AccountTopCountries | AccountTopStoreQuery | undefined,
    summaryData?: AccountTotalSummary | null,
    type?: AccountTopContentType | null
) => {
    const topContent = data?.orchardLabel?.topContent;
    if (!topContent) return [];

    const { streams, albumDownloads, trackDownloads } =
        summaryData?.orchardLabel?.summary?.items[0] || {};

    const allTimeValues =
        type === AccountTopContentType.STREAMS
            ? Number(streams)
            : Number(trackDownloads) + Number(albumDownloads);

    if ('topNCountries' in topContent) {
        return filterData(
            topContent.topNCountries?.map(({ countryCode, value }) => ({
                id: countryCode,
                value,
                percent: value / (allTimeValues || 1),
            }))
        );
    }

    if ('topNStores' in topContent) {
        return filterData(
            topContent.topNStores?.map(({ store: { storeId }, value }) => ({
                id: String(storeId),
                value,
                percent: value / (allTimeValues || 1),
            }))
        );
    }

    return [];
};

export const selectAccountYoutubeVideos = (
    data: AccountYoutubeVideos | undefined,
    days: number
) => {
    const items = filterData(data?.orchardLabel?.videoViewsTimeseries?.items);

    if (items.length === 0) return { series: [], total: null, average: null };

    const series = [
        {
            name: 'youtubeVideos',
            id: 'youtubeVideos',
            title: formatMessage('accounts.youtubeVideosShort'),
            data: sortBy(
                items.map(({ date, views }) => ({
                    date,
                    y: views,
                    x: new Date(date).getTime(),
                })),
                'x'
            ),
        },
    ];

    const total = sumBy(items, 'views');
    const average = total / days;

    return {
        series,
        total,
        average,
    };
};
