import { SortDirection } from 'src/components/table/types';

export const ACCOUNTS_PAGE_SIZE = 50;
export const SUBACCOUNTS_STORAGE_KEY = 'catalogSubaccounts';

export const CATALOG_ACCOUNT_SORTING_FIELDS = {
    STREAMS_28_DAYS: 'streams_28_days',
    GROWTH_28_DAYS: 'streams_growth_percentage_28_day',
    TRACK_DOWNLOADS_28_DAYS: 'track_downloads_28_days',
    TRACK_DOWNLOADS_GROWTH_28_DAYS: 'track_downloads_growth_percentage_28_day',
    ALBUM_DOWNLOADS_28_DAYS: 'album_downloads_28_days',
    ALBUM_DOWNLOADS_GROWTH_28_DAYS: 'album_downloads_growth_percentage_28_day',
};

export const CATALOG_ACCOUNT_SORTING_FIELDS_MAP: Record<string, string> = {
    streams28Days: CATALOG_ACCOUNT_SORTING_FIELDS.STREAMS_28_DAYS,
    growthPercentage28Days: CATALOG_ACCOUNT_SORTING_FIELDS.GROWTH_28_DAYS,
    trackDownloads28Days:
        CATALOG_ACCOUNT_SORTING_FIELDS.TRACK_DOWNLOADS_28_DAYS,
    trackDownloadsGrowthPercentage28Days:
        CATALOG_ACCOUNT_SORTING_FIELDS.TRACK_DOWNLOADS_GROWTH_28_DAYS,
    albumDownloads28Days:
        CATALOG_ACCOUNT_SORTING_FIELDS.ALBUM_DOWNLOADS_28_DAYS,
    albumDownloadsGrowthPercentage28Days:
        CATALOG_ACCOUNT_SORTING_FIELDS.ALBUM_DOWNLOADS_GROWTH_28_DAYS,
};

export const DEFAULT_SORT_FIELD =
    CATALOG_ACCOUNT_SORTING_FIELDS.STREAMS_28_DAYS;
export const DEFAULT_SORT_DIR: SortDirection = 'desc';
export const DEFAULT_SORT_ORDER = {
    key: DEFAULT_SORT_FIELD,
    direction: DEFAULT_SORT_DIR,
};

export const STREAMS_28 = 'catalog.streams28';
export const GROWTH_28 = 'catalog.growthPercentage28Days';
export const TRACK_DOWNLOADS_28 = 'catalog.trackDownloads28Days';
export const TRACK_DOWNLOADS_GROWTH_28 =
    'catalog.trackDownloadsGrowthPercentage28Days';
export const ALBUM_DOWNLOADS_28 = 'catalog.albumDownloads28Days';
export const ALBUM_DOWNLOADS_GROWTH_28 =
    'catalog.albumDownloadsGrowthPercentage28Days';
export const LABEL_MANAGER = 'catalog.labelManager';
export const ACCOUNT = 'account.account';
export const TYPE = 'product.metadata.type';
export const ID = 'ID';

export const HEADERS = [
    ACCOUNT,
    LABEL_MANAGER,
    STREAMS_28,
    GROWTH_28,
    TRACK_DOWNLOADS_28,
    TRACK_DOWNLOADS_GROWTH_28,
    ALBUM_DOWNLOADS_28,
    ALBUM_DOWNLOADS_GROWTH_28,
];

export const DEFAULT_CATALOG_ACCOUNTS_VISIBLE_COLUMNS = HEADERS.map(
    (header: string) => ({
        header,
        visible: true,
    })
);
