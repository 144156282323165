import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import {
    SoundRecordingStreamsBreakdown,
    SoundRecordingStreamsBreakdownVariables,
} from '../../definitions/SoundRecordingStreamsBreakdown';
import {
    selectStreamsBreakdownSourceOfStreams,
    selectStreamsBreakdownSourceOfStreamsSources,
    selectStreamsBreakdownStreamsBySubscription,
    selectStreamsBreakdownStreamsBySubscriptionSources,
} from '../../selectors/soundRecordingStreamsBreakdown';
import { useParallelQueries } from '../../utils';
import SoundRecordingStreamsBreakdownQuery from './soundRecordingStreamsBreakdown.gql';

export const useBatchStreamsBreakdownQuery = (
    variables: SoundRecordingStreamsBreakdownVariables[]
) => {
    const client = useApolloClient();
    const { loading, data, error } = useParallelQueries<
        SoundRecordingStreamsBreakdown,
        SoundRecordingStreamsBreakdownVariables
    >(SoundRecordingStreamsBreakdownQuery, { variables, client });

    return { loading, data, error };
};

export const useBatchSourceOfStreamsQuery = (
    variables: SoundRecordingStreamsBreakdownVariables[]
) => {
    const { loading, data, error } = useBatchStreamsBreakdownQuery(variables);

    const memoizedData = useMemo(
        () =>
            data
                ? {
                      series: selectStreamsBreakdownSourceOfStreams(data),
                      sources:
                          selectStreamsBreakdownSourceOfStreamsSources(data),
                  }
                : undefined,
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useBatchSourceOfSubscriptionQuery = (
    variables: SoundRecordingStreamsBreakdownVariables[]
) => {
    const { loading, data, error } = useBatchStreamsBreakdownQuery(variables);

    const memoizedData = useMemo(
        () =>
            data
                ? {
                      series: selectStreamsBreakdownStreamsBySubscription(data),
                      sources:
                          selectStreamsBreakdownStreamsBySubscriptionSources(
                              data
                          ),
                  }
                : undefined,
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
