import React from 'react';
import {
    CoverArt,
    GridTable,
    Tag,
    Tooltip,
} from '@theorchard/suite-components';
import cx from 'classnames';
import { uniqueId } from 'lodash';
import { concatNames } from 'src/apollo/selectors';
import LinkList from 'src/components/linkList';
import { TEST_ID_TOOLTIP } from 'src/components/table/cells/brandTagCell';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_PARTICIPANT, ROUTE_SONG } from 'src/constants';
import { getBrandIconValue } from 'src/utils/accounts';

const CLASSNAME = 'ThumbnailSongAndArtistLinkAndBrandCell';
const CLASSNAME_SONG_DATA = `${CLASSNAME}-songData`;
const CLASSNAME_DATA = `${CLASSNAME}-data`;
const CLASSNAME_BRAND_CONTAINER = `${CLASSNAME}-brandContainer`;

GridTable.defineColumnTemplate('thumbnailSongAndArtistLinkAndBrand', {
    align: 'left',
    Cell: ({ data }) => {
        const areParticipantsAvailable = data.participants?.length !== 0;
        const brandTooltip = data.brands?.[0]?.label;
        const brandTooltipId = `${CLASSNAME}_${uniqueId()}`;

        return (
            <div className={CLASSNAME}>
                <div className={cx(CLASSNAME_SONG_DATA, 'CoverArtCell')}>
                    <CoverArt url={data.imageUrl} width="40" />
                    <div className={CLASSNAME_DATA}>
                        <TruncatedText
                            value={data.songName ?? data.name}
                            useLink={Boolean(data.isrc)}
                            route={ROUTE_SONG}
                            params={{ isrc: data.isrc }}
                        />
                        {areParticipantsAvailable && (
                            <Tooltip
                                id={`availableParticipants_${uniqueId()}`}
                                message={concatNames(data.participants)}
                            >
                                <LinkList
                                    route={ROUTE_PARTICIPANT}
                                    links={data.participants}
                                    separator="comma"
                                    className="SongMetaDataCell-links"
                                />
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className={CLASSNAME_BRAND_CONTAINER}>
                    {brandTooltip && (
                        <Tooltip
                            id={brandTooltipId}
                            message={brandTooltip}
                            testId={TEST_ID_TOOLTIP}
                        >
                            <Tag
                                brand={getBrandIconValue(
                                    data.brands?.[0].brandAbbrName
                                )}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    },
});
