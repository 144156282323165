import { flatMap, sortBy, uniqBy } from 'lodash';
import { SoundRecordingRelatedContent_globalSoundRecordingByIsrc as GSRData } from 'src/apollo/definitions/SoundRecordingRelatedContent';
import {
    VideoRelatedContent_video as VideoData,
    VideoRelatedContent_video_globalSoundRecording_videos as VideoEntity,
    VideoRelatedContent_video_globalSoundRecording as GlobalSoundRecordingEntity,
} from 'src/apollo/definitions/VideoRelatedContent';
import { toEntity } from 'src/apollo/selectors';
import { Entity } from 'src/apollo/selectors/types';
import { filterData, nonNullable } from 'src/apollo/utils';
import { EMPTY_CHAR } from 'src/constants';
import { Source } from 'src/constants/stores';
import { selectSources } from 'src/utils/sources';

export enum RelatedEntityTypes {
    SoundRecording,
    Video,
}

export interface RelatedEntity {
    entity: Entity;
    value: number;
    type: RelatedEntityTypes;
    channelOwner?: string;
    videoContentType?: string;
}

export const selectVideos = (videos: VideoEntity[]): RelatedEntity[] =>
    videos.map(v => ({
        entity: toEntity({
            id: v.videoId,
            name: v.name ?? EMPTY_CHAR,
            imageUrl: `https://img.youtube.com/vi/${v.videoId}/mqdefault.jpg`,
        }),
        value: v.analytics.daily.aggregate.views ?? 0,
        type: RelatedEntityTypes.Video,
        channelOwner: nonNullable(v.channel?.channelOwner),
        videoContentType: nonNullable(v.videoContentType),
    }));

export const selectGlobalSoundRecording = (
    gsr: Omit<GlobalSoundRecordingEntity, 'videos'> | null
) =>
    gsr && (gsr.types ?? []).includes('music')
        ? [
              {
                  entity: toEntity({
                      id: gsr.isrc,
                      name: gsr.name ?? EMPTY_CHAR,
                      imageUrl: gsr.imageUrl,
                  }),
                  value: (gsr.analytics?.streams.aggregate.items ?? []).reduce(
                      (total, val) => total + (val?.value ?? 0),
                      0
                  ),
                  type: RelatedEntityTypes.SoundRecording,
              },
          ]
        : [];

export const selectVideoRelatedContent = (
    data: VideoData | undefined,
    videoID: string
) =>
    !data
        ? null
        : sortBy(
              uniqBy(
                  flatMap(data.globalSoundRecording ?? [], gsr => [
                      ...selectVideos(filterData(gsr?.videos)),
                      ...selectGlobalSoundRecording(gsr),
                  ]),
                  item => item.entity.id
              ),
              item => item.value ?? 0
          )
              .reverse()
              .filter(item => item.entity.id !== videoID);

export const selectSoundRecordingRelatedContent = (
    data: GSRData | undefined,
    isrc: string | undefined
) =>
    !data || !isrc
        ? null
        : sortBy(
              uniqBy(
                  [
                      ...selectVideos(filterData(data.videos)),
                      ...selectGlobalSoundRecording(data),
                  ],
                  item => item.entity.id
              ),
              item => item.value ?? 0
          )
              .reverse()
              .filter(item => item.entity.id !== isrc);

export const selectRelatedContentSources = (
    series: GSRData | undefined | null
): Source[] =>
    selectSources([
        ...filterData(series?.analytics?.streams.sources),
        ...filterData(series?.videos?.[0]?.analytics?.daily.sources),
    ]);
