import {
    MutationHookOptions,
    MutationTuple,
    OperationVariables,
    useMutation,
} from '@apollo/client';
import {
    HEADER_PROFILE_TYPE,
    HEADER_PROFILE_ID,
    HEADER_PROFILE_UUID,
    useIdentity,
} from '@theorchard/suite-frontend';
import { DocumentNode } from 'graphql';
import { useSettingsProfileQuery } from '../queries/settingsProfile';

export const useSettingsMutation = <
    TData = unknown,
    TVariables = OperationVariables
>(
    query: DocumentNode,
    { context, ...options }: MutationHookOptions<TData, TVariables> = {}
): MutationTuple<TData, TVariables> => {
    const { id: identityId } = useIdentity();
    const { loading, settingsProfile } = useSettingsProfileQuery(identityId);

    const [mutation, mutationResult] = useMutation<TData, TVariables>(query, {
        ...options,
        context: {
            ...context,
            headers: {
                [HEADER_PROFILE_TYPE]: 'SettingsProfile',
                [HEADER_PROFILE_ID]: settingsProfile?.profileId,
                [HEADER_PROFILE_UUID]: settingsProfile?.profileUUID,
            },
        },
    });
    return [
        async mutationOptions => {
            if (!settingsProfile && !loading)
                throw new Error(
                    'Invalid operation. User does not have a required "SettingsProfile".'
                );

            return await mutation(mutationOptions);
        },
        mutationResult,
    ];
};
