import { useQuery } from '@apollo/client';
import {
    MarketRanksQuery,
    MarketRanksQueryVariables,
} from 'src/apollo/definitions/MarketRanksQuery';
import marketRanksQuery from 'src/apollo/queries/chart/marketRanks.gql';
export const useMarketRanksQuery = (variables: MarketRanksQueryVariables) => {
    const { data, loading, error } = useQuery<
        MarketRanksQuery,
        MarketRanksQueryVariables
    >(marketRanksQuery, { variables });

    return {
        data,
        loading,
        error,
    };
};
