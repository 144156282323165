export const CLASSNAME = 'SourcesStatusV2';
export const STATUS_ERROR_CLASSNAME = `${CLASSNAME}-error`;
export const STATUS_UPDATED_CLASSNAME = `${CLASSNAME}-updated`;
export const STATUS_WARNING_CLASSNAME = `${CLASSNAME}-warning`;

export const TERMS = {
    ERROR: 'sources.error',
    UPDATED: 'sources.updated',
    STATUS: 'sources.types.status',
    LAST_DATA_UPDATE: 'sources.lastDataUpdate',
    MISSING_DATES: 'sources.missingDates',
    STORES_STATUS: 'sources.modal.storesStatus',
};
