import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import NumberWithPercentageAndArrowCell from '../numberWithPercentageAndArrowCell';

GridTable.defineColumnTemplate('creations7DaysChange', {
    align: 'right',
    Cell: props => (
        <NumberWithPercentageAndArrowCell
            {...props}
            percentageProperty="creationsGrowthPercentage7Days"
            currentValueProperty="creations7Days"
        />
    ),
});
