import { useQuery } from '@apollo/client';
import { CHANNELS_LIMIT } from 'src/constants/catalog';
import { Channels, ChannelsVariables } from '../../definitions/Channels';
import { flattenError, filterData } from '../../utils';
import channelsQuery from './channels.gql';

export const useChannelsQuery = () => {
    const { data, loading, error } = useQuery<Channels, ChannelsVariables>(
        channelsQuery,
        { variables: { limit: CHANNELS_LIMIT } }
    );

    return {
        loading,
        data: data && filterData(data.channels),
        error: error && flattenError(error),
    };
};
