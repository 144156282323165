import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { SpotifyPlayButton } from 'src/components/spotifyPlayer';
import EmptyCell from './emptyCell';

GridTable.defineColumnTemplate('songPlay', {
    align: 'right',
    sortable: false,
    minWidth: 'auto',
    maxWidth: 'max-content',
    Cell: ({ data: { spotifyId } }) => {
        if (!spotifyId) return <EmptyCell />;

        const spotifyIds = Array.isArray(spotifyId) ? spotifyId : [spotifyId];

        return <SpotifyPlayButton trackIds={spotifyIds} />;
    },
});
