import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import CoverArt from 'src/components/coverArt';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_PARTICIPANT } from 'src/constants';
import { TEST_ID as artistSearchResultsId } from 'src/pages/searchES/pages/artistResults/artistResultsGridTable';

GridTable.defineColumnTemplate('thumbnailLocalParticipantLink', {
    align: 'left',
    sortable: false,
    Cell: ({
        data: { id, imageUrl, name },
        context: {
            props: { testId },
        },
    }) => {
        const shouldLogPageVisit = testId === artistSearchResultsId;

        return (
            <div
                className="ThumbnailParticipantLinkCell CoverArtCell"
                data-testid="ThumbnailParticipantLinkCell"
            >
                <CoverArt
                    url={imageUrl}
                    shape="round"
                    width="30"
                    usePlaceholder={true}
                />
                <TruncatedText
                    useLink
                    shouldLogPageVisit={shouldLogPageVisit}
                    route={ROUTE_PARTICIPANT}
                    params={{ id }}
                    value={name}
                />
            </div>
        );
    },
});
