import React from 'react';
import { GridTable, HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { CheckedFolderGlyph, GlyphIcon } from '@theorchard/suite-icons';
import { streamingOfferTypes } from 'src/apollo/selectors';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import EmptyCell from './emptyCell';

GridTable.defineColumnTemplate('myCatalogStreamingOfferType', {
    align: 'center',
    sortable: false,
    HeaderText: () => null,
    Cell: ({ data }) => {
        const { isPartOfCatalog, offerTypes } = data;
        const streamTypes = streamingOfferTypes.some(type =>
            (offerTypes ?? []).includes(type)
        );
        if (isPartOfCatalog === false) return <EmptyCell />;
        if (streamTypes)
            return (
                <HelpTooltip
                    id="in-my-catalog-tooltip"
                    message={formatMessage('comparison.inMyCatalogHint')}
                >
                    <div className="MyCatalogCell-tooltip">
                        <FeatureFlagPanel
                            featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                            fallback={<CheckedFolderGlyph />}
                        >
                            <GlyphIcon name="inCatalog" size={16} />
                        </FeatureFlagPanel>
                    </div>
                </HelpTooltip>
            );
        return <EmptyCell />;
    },
});
