import React, { FC } from 'react';
import { Tooltip } from '@theorchard/suite-components';
import { uniqueId } from 'lodash';
import { getPlaylistTypeDefinition } from './utils';

export interface Props {
    type?: string;
}

export const CLASSNAME = 'PlaylistTypeTooltip';
export const TEST_ID = 'OverlayTooltip';

const PlaylistTypeTooltip: FC<Props> = ({ type }) => {
    const definition = getPlaylistTypeDefinition(type);
    if (!definition) return null;

    const { icon, text } = definition;
    return (
        <Tooltip
            testId={TEST_ID}
            id={`${CLASSNAME}_${uniqueId()}`}
            className={CLASSNAME}
            placement="top"
            message={text}
        >
            {icon}
        </Tooltip>
    );
};

export default PlaylistTypeTooltip;
