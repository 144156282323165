import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { EMPTY_CHAR } from 'src/constants';

GridTable.defineColumnTemplate('position', {
    align: 'right',
    Cell: ({ data: { position, size } }) => {
        if (!position) return <span>{EMPTY_CHAR}</span>;
        if (!size) return <span>{position}</span>;

        return (
            <span>
                {formatMessage('playlists.info.position', {
                    position,
                    total: size,
                })}
            </span>
        );
    },
});
