import { useMutation } from '@apollo/client';
import { CreateGlobalParticipantLink } from 'src/apollo/definitions/CreateGlobalParticipantLink';
import { CreateGlobalProductLink } from 'src/apollo/definitions/CreateGlobalProductLink';
import { CreateGlobalSoundRecordingLink } from 'src/apollo/definitions/CreateGlobalSoundRecordingLink';
import { CreateVideoLink } from 'src/apollo/definitions/CreateVideoLink';
import createGlobalSoundRecordingLink from 'src/apollo/mutations/universalLinks/createGlobalSoundRecordingLink.gql';
import {
    selectUniversalLinksEngine,
    UniversalLinkInput,
} from 'src/apollo/selectors/universalLinks';
import { flattenError } from 'src/apollo/utils';
import { ensureString } from 'src/utils/route';

type Query = CreateGlobalSoundRecordingLink &
    CreateGlobalProductLink &
    CreateGlobalParticipantLink &
    CreateVideoLink;

export const useCreateUniversalLink = (props: UniversalLinkInput) => {
    const engine = selectUniversalLinksEngine(props);
    const [createLink, { loading, error, data }] = useMutation<Query>(
        engine?.query || createGlobalSoundRecordingLink,
        {
            variables: engine?.variables,
        }
    );

    return {
        createLink,
        data: ensureString(engine?.selector(data)),
        loading,
        error: error && flattenError(error),
    };
};
