import { formatMessage } from '@theorchard/suite-frontend';
import { PERIODS } from 'src/constants/periods';
import { STORE_DISPLAY_NAMES_BY_ID } from 'src/constants/stores';

export const ALL_STORES_TERM = 'stores.allStores';

export const getStoreName = (storeId?: number) =>
    storeId
        ? STORE_DISPLAY_NAMES_BY_ID[storeId]
        : formatMessage(ALL_STORES_TERM);

export const getPeriodName = (period: string) =>
    formatMessage(...PERIODS[period]);
