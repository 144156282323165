import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    PlaylistPlacementStreamsTimeseries,
    PlaylistPlacementStreamsTimeseriesVariables,
} from 'src/apollo/definitions/PlaylistPlacementStreamsTimeseries';
import { selectPlaylistPlacementPositionStreams } from 'src/apollo/selectors/playlist';
import PlaylistPlacementStreamsTimeseriesQuery from './playlistPlacementStreamsTimeseries.gql';

export const usePlaylistPlacementStreamsTimeseries = (
    variables: PlaylistPlacementStreamsTimeseriesVariables
) => {
    const {
        loading,
        data: streamsData,
        error,
    } = useQuery<
        PlaylistPlacementStreamsTimeseries,
        PlaylistPlacementStreamsTimeseriesVariables
    >(PlaylistPlacementStreamsTimeseriesQuery, {
        variables,
        skip: !variables.playlistId,
    });

    const data = useMemo(
        () =>
            selectPlaylistPlacementPositionStreams(
                streamsData?.playlistPlacementStreamsTimeseries?.items
            ),
        [streamsData]
    );

    return { loading, data, error };
};
