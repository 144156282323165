import { useQuery } from '@apollo/client';
import { flattenError } from '@theorchard/suite-frontend';
import {
    TikTokTimeSeriesVariables,
    TikTokTimeSeries,
} from 'src/apollo/definitions/TikTokTimeSeries';
import { selectTiktokTimeSeries } from 'src/apollo/selectors/tiktok';
import tikTokTimeSeriesQuery from './tikTokTimeSeries.gql';

export const useTikTokTimeSeriesQuery = (
    variables: TikTokTimeSeriesVariables,
    datePeriod: string,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<
        TikTokTimeSeries,
        TikTokTimeSeriesVariables
    >(tikTokTimeSeriesQuery, { variables, skip });

    return {
        loading,
        data: data && selectTiktokTimeSeries(data, datePeriod),
        error: error && flattenError(error),
    };
};
