import React from 'react';
import { Button, CoverArt, GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { get } from 'lodash';
import { PlaylistType } from 'src/apollo/selectors/playlist';
import PlaylistLinkCell from './playlistLinkCell';

interface ShowDetailsHandler {
    (data: PlaylistType): void;
}

GridTable.defineColumnTemplate('thumbnailPlaylistLink', {
    align: 'left',
    Cell: ({ data, ...rest }) => {
        const {
            column: { definition },
        } = rest;
        return (
            <div className="ThumbnailPlaylistLinkCell CoverArtCell">
                <CoverArt url={data.imageLocation} width="40" />
                <PlaylistLinkCell data={data} {...rest} />
                {data.storefrontCount ? null : (
                    <Button
                        className="ThumbnailPlaylistLinkCell-details"
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();

                            const onShowDetails = get(definition, [
                                'data-onShowDetails',
                            ]) as ShowDetailsHandler | undefined;
                            onShowDetails?.(data);
                        }}
                        variant="link"
                        size="sm"
                    >
                        {formatMessage('playlist.details')}
                    </Button>
                )}
            </div>
        );
    },
});
