import { useAppRecentsQuery } from '../appRecents';

export const useRecentChannelsQuery = () => {
    const { data, loading, error } = useAppRecentsQuery();

    return {
        loading,
        data: data.recentChannels,
        error,
    };
};
