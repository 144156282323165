export const EVENT_SESSION_EXPIRED = 'SessionExpired';
export const EVENT_HELP_CLICKED = 'Click - Help';
export const TRACKABLE_SECTIONS = {
    POT: 'Performance Over Time',
    TABLE: 'table',
    TOP_UGC: 'Top UGC',
    TOP_AUDIENCE: 'Top Audience',
    AUDIENCE_GRAPH: 'Audience Graph',
    STREAMS_BY_SUBSCRIPTION: 'Streams by Subscription',
    SOURCE_OF_STREAMS: 'Source of Streams',
    COMPARISON_STORES: 'Comparison Stores Streams',
    RELATED_CONTENT: 'Related Content',
};
