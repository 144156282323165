import React from 'react';
import { Tooltip } from '@theorchard/suite-components';
import { CountryFlag as Country } from '@theorchard/suite-icons';
import { uniqueId } from 'lodash';
import { EMPTY_CHAR } from 'src/constants';
import { getCountryName } from 'src/constants/countries';

export const CLASSNAME = 'CountryFlag';

export interface Props {
    code?: string | null;
    className?: string;
    size?: 16 | 24;
}

const CountryFlag: React.FC<Props> = ({ code, size, className }) => {
    if (!code) return <>{EMPTY_CHAR}</>;
    const message = getCountryName(code);
    const tooltipId = `${CLASSNAME}${
        className ? '_' + className + '_' : '_'
    }${code}_${uniqueId()}`;

    return (
        <div data-testid={CLASSNAME}>
            <Tooltip id={tooltipId} message={message} className={className}>
                <Country showTitle={false} countryCode={code} size={size} />
            </Tooltip>
        </div>
    );
};

export default CountryFlag;
