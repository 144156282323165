import { Segment } from '@theorchard/suite-frontend';

export const trackOrchardGoBannerDownload = (properties: object) => {
    Segment.trackEvent(`Click app download`, {
        category: 'Engagement',
        component: 'mobile modal',
        url: window.location.href,
        ...properties,
    });
};

export const trackOrchardGoBannerDisplaying = (properties: object) => {
    Segment.trackEvent(`View mobile modal`, {
        category: 'View',
        component: 'mobile modal',
        url: window.location.href,
        ...properties,
    });
};

export const trackOrchardGoBannerClose = (properties: object) => {
    Segment.trackEvent(`Click exit`, {
        category: 'Engagement',
        component: 'mobile modal',
        url: window.location.href,
        ...properties,
    });
};

export const trackOrchardGoBannerChoseBrowser = (properties: object) => {
    Segment.trackEvent(`Click browser link `, {
        category: 'Engagement',
        component: 'mobile modal',
        url: window.location.href,
        ...properties,
    });
};
