import { filterData } from '@theorchard/suite-frontend';
import { nonNullable } from 'src/apollo/utils';
import { Source, SOURCE_STREAMS, STORE_SOURCES } from 'src/constants/stores';

interface DataSourceError {
    types: (string | null)[] | null;
    message?: string | null;
    code?: string | null;
}

interface DataSource {
    storeId: number;
    storeName: string;
    error?: DataSourceError | null;
}

export const selectSourcesByTypes = (
    sources: Source[],
    supportedTypes: string[] = []
) => {
    return sources.filter(store =>
        supportedTypes.some(type =>
            (STORE_SOURCES[store.storeId] ?? [SOURCE_STREAMS]).includes(type)
        )
    );
};

export const selectSourcesByStoreIds = (
    sources: Source[],
    storeIds: number[] = []
) => sources.filter(({ storeId }) => storeIds.includes(storeId));

export const selectSources = (sources?: (DataSource | null)[]): Source[] =>
    filterData(sources).map(source => ({
        ...source,
        error: source.error
            ? {
                  code: nonNullable(source.error.code),
                  message: nonNullable(source.error.message),
                  types: filterData(source.error.types),
              }
            : undefined,
    }));
