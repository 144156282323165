import React, { FC, MouseEventHandler } from 'react';
import cx from 'classnames';
import LinkWithLoader, {
    LinkWithLoaderProps,
} from 'src/components/linkWithLoader/linkWithLoader';
import {
    BUTTON_CLASSNAME,
    BUTTON_SIZE,
    BUTTON_VARIANT,
} from 'src/constants/buttonsClassnames';
import { PAGE_OVERVIEW } from 'src/constants/page';
import { useRouteParams } from 'src/utils/route';
import { CLICK_SEE_ALL, trackEngagementEvent } from 'src/utils/segment';

const CLASSNAME = 'SeeAllLink';

export interface Props extends LinkWithLoaderProps {
    to: string;
    className?: string;
    fromComponent?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const SeeAll: FC<Props> = ({
    className,
    to,
    children,
    fromComponent,
    ...linkWithLoaderProps
}) => {
    const [routeParams] = useRouteParams();

    return (
        <LinkWithLoader
            {...linkWithLoaderProps}
            to={to}
            className={cx(
                className,
                CLASSNAME,
                BUTTON_CLASSNAME,
                BUTTON_VARIANT.QUARTENARY,
                BUTTON_SIZE.SMALL
            )}
            onClick={event => {
                const { onClick } = linkWithLoaderProps;

                trackEngagementEvent(CLICK_SEE_ALL, {
                    tab: routeParams?.page ?? PAGE_OVERVIEW,
                    component: fromComponent,
                });
                onClick?.(event);
            }}
        >
            {children}
        </LinkWithLoader>
    );
};

export default SeeAll;
