import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { EMPTY_CHAR, ROUTE_SONG } from 'src/constants';

GridTable.defineColumnTemplate('trackLink', {
    align: 'left',
    Cell: ({ data: { isrc, trackName } }) => (
        <TruncatedText
            value={trackName ?? EMPTY_CHAR}
            maxLength={30}
            useLink={!!isrc}
            route={ROUTE_SONG}
            params={{ isrc }}
        />
    ),
});
