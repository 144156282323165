import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { GlyphIcon } from '@theorchard/suite-icons';
import { get } from 'lodash';
import CountryFlag from 'src/components/countryFlag';

interface ToggleHandler {
    (playlistId: string, storeId?: number): void;
}

GridTable.defineColumnTemplate('storefrontCountries', {
    align: 'left',
    minWidth: 'min-content',
    maxWidth: 'max-content',
    Cell: ({
        data: { playlistId, storeId, storefrontCount, topMarket },
        rowKey,
        context: {
            props: { expandedRowTables },
        },
        column: { definition },
    }) => {
        const size = get(definition, 'data-size') || 16;

        if (!storefrontCount)
            return <CountryFlag code={topMarket} size={size} />;

        const isExpanded = expandedRowTables
            ? rowKey in expandedRowTables
            : false;
        const handleToggle = get(definition, 'data-onToggle') as
            | ToggleHandler
            | undefined;
        const iconName = isExpanded ? 'caretUp' : 'caretDown';

        return (
            <div
                role="presentation"
                className="StorefrontCountCell"
                onClick={() => handleToggle?.(playlistId, storeId)}
            >
                <span className="StorefrontCountCell-count">
                    {storefrontCount}
                </span>
                <GlyphIcon name={iconName} size={12} />
            </div>
        );
    },
});
