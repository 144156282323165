import { useApolloClient } from '@apollo/client';
import {
    AppRecentsQuery_appRecents as AppRecents,
    AppRecentsQuery,
} from '../definitions/AppRecentsQuery';
import {
    RecentChannel,
    RecentCompareParticipant,
    RecentLabel,
    RecentLabelManager,
    RecentParticipant,
    RecentSoundRecording,
    RecentPlaylistTypeSelection,
    RecentCompanyBrand,
    RecentServiceTier,
    DEFAULT_APP_RECENTS,
} from '../queries/appRecents';
import QUERY from '../queries/appRecents.gql';

export const useAppRecentsMutation = () => {
    const client = useApolloClient();

    return (variables: Partial<AppRecents>) => {
        const cache = client.cache.readQuery<AppRecentsQuery>({
            query: QUERY,
        });
        const existingRecents = cache?.appRecents ?? DEFAULT_APP_RECENTS;

        client.cache.writeQuery({
            query: QUERY,
            data: {
                appRecents: {
                    ...existingRecents,
                    ...variables,
                },
            },
        });
    };
};

export const useRecentLabelsMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (label: RecentLabel) => {
        updateRecents({
            recentLabels: [label],
        });
    };
};

export const useRecentLabelManagersMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (labelManager: RecentLabelManager) => {
        updateRecents({
            recentLabelManagers: [labelManager],
        });
    };
};

export const useRecentParticipantMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (participant: RecentParticipant) => {
        updateRecents({
            recentParticipants: [participant],
        });
    };
};

export const useRecentCompanyBrandMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (companyBrands: RecentCompanyBrand[]) => {
        updateRecents({
            recentCompanyBrands: companyBrands,
        });
    };
};

export const useRecentServiceTierMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (serviceTier: RecentServiceTier) => {
        updateRecents({
            recentServiceTiers: [serviceTier],
        });
    };
};

export const useRecentCompareParticipantMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (participant: RecentCompareParticipant) => {
        updateRecents({
            recentCompareParticipants: [participant],
        });
    };
};

export const useRecentSoundRecordingMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (soundRecording: RecentSoundRecording) => {
        updateRecents({
            recentSoundRecordings: [soundRecording],
        });
    };
};

export const useRecentChannelMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (channel: RecentChannel) => {
        updateRecents({
            recentChannels: [channel],
        });
    };
};

export const useRecentPlaylistTypeSelectionMutation = () => {
    const updateRecents = useAppRecentsMutation();

    return (playlistType: RecentPlaylistTypeSelection) => {
        updateRecents({
            recentPlaylistTypeSelection: playlistType,
        });
    };
};
