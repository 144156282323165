import { DocumentNode } from '@apollo/client';
import {
    CreateVideoLink,
    CreateVideoLinkVariables,
} from 'src/apollo/definitions/CreateVideoLink';
import createVideoLink from 'src/apollo/mutations/universalLinks/createVideoLink.gql';

export interface VideoParams {
    type: 'video';
    id: string;
    countryFilter?: string[];
    performanceMetric?: string;
    dateFilter?: string;
    dateFilterStart?: string;
    dateFilterEnd?: string;
    page?: string;
    claimsFilter?: string;
}

export interface VideoLinkData {
    query: DocumentNode;
    variables: CreateVideoLinkVariables;
    selector: (res?: CreateVideoLink | null) => string | undefined;
    type: 'video';
}

export const selectVideoLink = (data: VideoParams): VideoLinkData => ({
    query: createVideoLink,
    variables: {
        id: data.id,
        page: data.page,
        countries: data.countryFilter,
        metric: data.performanceMetric,
        period: data.dateFilter,
        claims: data.claimsFilter,
        startDate: data.dateFilterStart,
        endDate: data.dateFilterEnd,
    },
    selector: res => res?.createVideoLink,
    type: 'video',
});
