import React from 'react';
import { Button, GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import { PlaylistType } from 'src/apollo/selectors/playlist';
import CoverArt from 'src/components/coverArt';
import { fallbackImage } from 'src/constants';
import PlaylistLinkCell from './playlistLinkCell';

interface ShowDetailsHandler {
    (data: PlaylistType): void;
}

GridTable.defineColumnTemplate('thumbnailPlaylistPlacementLink', {
    align: 'left',
    Cell: ({ data, ...rest }) => {
        const {
            column: { definition },
        } = rest;
        return (
            <div className="ThumbnailPlaylistPlacementLinkCell CoverArtCell">
                <CoverArt
                    url={data.imageLocation}
                    width="30"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = fallbackImage;
                    }}
                />
                <Button
                    className="ThumbnailPlaylistLinkCell"
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        const onShowDetails = get(definition, [
                            'data-onShowDetails',
                        ]) as ShowDetailsHandler | undefined;
                        onShowDetails?.(data);
                    }}
                    variant="link"
                    size="sm"
                >
                    <PlaylistLinkCell
                        data={{
                            playlistId: data.playlistId,
                            playlistName: data.playlistName,
                            playlistType: data.playlistType,
                            playlistUri: '',
                        }}
                        {...rest}
                    />
                </Button>
            </div>
        );
    },
});
