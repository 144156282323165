import React, { VFC } from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GridTableColumnTextProps } from './types';

const ShazamsHeaderCell: VFC<GridTableColumnTextProps> = () => (
    <>
        <span>{formatMessage('chart.shazams')}</span>
        <HelpTooltip
            id="shazam-tooltip"
            message={formatMessage('chart.shazamsTooltip')}
            tooltipPlacement="right"
        />
    </>
);

export default ShazamsHeaderCell;
