import { BRAND_AWAL } from 'src/constants';

export const isMobile = (): boolean => {
    const userAgent =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );

    return userAgent;
};

export const isIosDevice = () =>
    Boolean(navigator.userAgent.match(/iPhone|iPad/i));

export const isAndroidDevice = () =>
    Boolean(navigator.userAgent.match(/Android/i));

export const androidAppLink =
    'https://play.google.com/store/apps/details?id=com.theorchard.OrchardGo&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3DEmail%2520Signature%26anid%3Dadmob';
export const iosAppLink =
    'https://apps.apple.com/app/apple-store/id1423292062?pt=118711274&ct=Email%20Signature&mt=8';
export const androidAWALAppLink =
    'https://play.google.com/store/apps/details?id=com.theorchard.AWAL';
export const iosAWALAppLink =
    'https://apps.apple.com/us/app/awalgo/id1576528108';

export const getMobileAppLink = (brand?: string) => {
    const isAWALUser = brand === BRAND_AWAL;

    if (isAWALUser) return isIosDevice() ? iosAWALAppLink : androidAWALAppLink;

    return isIosDevice() ? iosAppLink : androidAppLink;
};
