import { useQuery } from '@apollo/client';
import { selectChannelVideo } from 'src/apollo/selectors/videoMetrics';
import {
    ChannelAnalyticsTopVideosQuery,
    ChannelAnalyticsTopVideosQueryVariables,
} from '../../definitions/ChannelAnalyticsTopVideosQuery';
import { flattenError } from '../../utils';
import topChannelVideosQuery from './channelAnalyticsTopVideos.gql';

export const useChannelAnalyticsTopVideosQuery = (
    variables: ChannelAnalyticsTopVideosQueryVariables
) => {
    const {
        data,
        loading: loadingMetrics,
        error: metricsError,
    } = useQuery<
        ChannelAnalyticsTopVideosQuery,
        ChannelAnalyticsTopVideosQueryVariables
    >(topChannelVideosQuery, { variables });

    const channelVideos =
        data?.channel?.analytics.topVideos.channelVideos ?? [];
    const videos = selectChannelVideo(channelVideos);

    return {
        loading: loadingMetrics,
        data: videos,
        error: metricsError && flattenError(metricsError),
    };
};
