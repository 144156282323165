import React, { FC } from 'react';
import { GridTable, GridTableSortBy } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { CatalogSong } from 'src/apollo/selectors/catalog';
import { useEmployeeIdentity } from 'src/apollo/utils';
import {
    TerndScoreHeaderCell,
    TiktokHeaderCell,
} from 'src/components/table/cells';
import { SortDirection } from 'src/components/table/types';
import { INSIGHTS_TIKTOK_TREND_SCORE_COLUMN } from 'src/constants/featuresFlags';
import { Source } from 'src/constants/stores';
import CatalogGridTable from 'src/pages/catalog/components/catalogTable';
import { usePagination } from 'src/utils';
import { getRoute } from 'src/utils/route';
import { CATALOG_SONGS_SORTING_FIELDS_MAP, SONGS_PAGE_SIZE } from './constants';
import { exportCsvForGridTable } from './exportCsv';

export const CLASSNAME = 'CatalogSongsTable';

interface Props {
    songs: CatalogSong[];
    isFiltered: boolean;
    loading: boolean;
    onClearFilters: () => void;
    onSort: (name: string, direction: SortDirection) => void;
    songsInFilter: number | undefined;
    sortBy: GridTableSortBy;
    sources: Source[];
    country?: string[];
    isAccountMode?: boolean;
    shouldShowSoundFamiliesData?: boolean;
}

const CatalogTable: FC<Props> = ({
    isFiltered,
    onClearFilters,
    loading,
    onSort,
    songs,
    songsInFilter,
    sortBy,
    sources,
    country,
    isAccountMode = false,
    shouldShowSoundFamiliesData = false,
}) => {
    const { isEmployee, features } = useEmployeeIdentity();
    const { pageLimit, currentPage, onPageChange, onPageLimitChange } =
        usePagination({ route: getRoute(), defaultLimit: SONGS_PAGE_SIZE });
    const isTrendScoreEnabled =
        isEmployee && features[INSIGHTS_TIKTOK_TREND_SCORE_COLUMN];
    const showAccountColumn = isAccountMode && isEmployee;

    const onExportCsv = (visibleColumns: string[]) => {
        exportCsvForGridTable(songs, visibleColumns, country);
    };

    return (
        <CatalogGridTable
            name={CLASSNAME}
            className={CLASSNAME}
            data={songs}
            rowKey="isrc"
            loading={loading}
            sortBy={sortBy}
            isFiltered={isFiltered}
            sources={sources}
            itemsCount={songsInFilter}
            columnNameToSortNameMap={CATALOG_SONGS_SORTING_FIELDS_MAP}
            defaultColumnDefs={{
                sortable: true,
                defaultSortDirection: 'desc',
                align: 'right',
            }}
            showTikTokIndicator
            paginated
            pageSize={pageLimit}
            page={currentPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageLimitChange}
            onClearFilters={onClearFilters}
            onSort={onSort}
            onExportCsv={onExportCsv}
        >
            <GridTable.Column
                name="songName"
                template={
                    shouldShowSoundFamiliesData
                        ? 'thumbnailSongLinkToCompareWithIsrc'
                        : 'thumbnailSongLinkWithIsrc'
                }
                title={formatMessage('catalog.songName')}
                sortable={false}
                minWidth="170px"
                maxWidth="4fr"
                category={formatMessage('catalog.metadata')}
                visibility="visible-locked"
                fixed
            />
            <GridTable.Column
                name="artistName"
                template="participantLink"
                title={formatMessage('catalog.artistName')}
                minWidth="130px"
                maxWidth="4fr"
                category={formatMessage('catalog.metadata')}
            />
            <GridTable.Column
                name="imprint"
                template="truncatedText"
                title={formatMessage('chart.imprint')}
                sortable={false}
                minWidth="130px"
                maxWidth="4fr"
                category={formatMessage('catalog.metadata')}
            />
            <GridTable.Column
                name="account"
                template="accountDetails"
                title={formatMessage('account.account')}
                sortable={false}
                minWidth="240px"
                maxWidth="5fr"
                category={formatMessage('catalog.metadata')}
                visibility={showAccountColumn ? 'visible' : 'collapse'}
            />
            <GridTable.Column
                name="storeName"
                template="truncatedText"
                title={formatMessage('playlist.store')}
                sortable={false}
                minWidth="130px"
                maxWidth="4fr"
                category={formatMessage('catalog.metadata')}
                visibility="collapse"
            />
            <GridTable.Column
                name="releaseDate"
                title={formatMessage('catalog.releaseDate')}
                template="date"
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.metadata')}
            />
            <GridTable.Column
                name="tikTokTrendScore"
                className="TiktokCell"
                template="tiktokTrendScore"
                title={formatMessage('catalog.tikTokTrendScore')}
                HeaderText={TerndScoreHeaderCell}
                minWidth="150px"
                maxWidth="150px"
                category={formatMessage('catalog.metadata')}
                visibility={isTrendScoreEnabled ? 'visible' : 'hidden'}
            />
            <GridTable.Column
                name="streams1Day"
                template="number"
                title={formatMessage('catalog.streams1')}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.streaming')}
            />
            <GridTable.Column
                name="growthPercentage1Day"
                template="growthPercentage"
                title={formatMessage('catalog.growthPercentage1Day')}
                tooltip={formatMessage('catalog.changePercentageCalculated')}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.streaming')}
            />

            <GridTable.Column
                name="streams7Days"
                template="number"
                title={formatMessage('catalog.streams7')}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.streaming')}
            />
            <GridTable.Column
                name="growthPercentage7Days"
                template="growthPercentage"
                title={formatMessage('catalog.growthPercentage7Days')}
                tooltip={formatMessage('catalog.changePercentageCalculated')}
                maxWidth="120px"
                category={formatMessage('catalog.streaming')}
            />

            <GridTable.Column
                name="streams28Days"
                template="number"
                title={formatMessage('catalog.streams28')}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.streaming')}
            />

            <GridTable.Column
                name="growthPercentage28Days"
                template="growthPercentage"
                title={formatMessage('catalog.growthPercentage28Days')}
                tooltip={formatMessage('catalog.changePercentageCalculated')}
                maxWidth="120px"
                category={formatMessage('catalog.streaming')}
            />

            <GridTable.Column
                name="streamsAllTime"
                template="number"
                title={formatMessage('catalog.streamsAll')}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.streaming')}
            />

            <GridTable.Column
                name="views1Day"
                template="number"
                title={formatMessage('catalog.views1')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.tikTokViews')}
                visibility="collapse"
            />

            <GridTable.Column
                name="viewsGrowthPercentage1Day"
                template="growthPercentage"
                title={formatMessage('catalog.viewsGrowthPercentage1Day')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                tooltip={formatMessage(
                    'catalog.changePercentageViewsCalculated'
                )}
                maxWidth="120px"
                category={formatMessage('catalog.tikTokViews')}
                visibility="collapse"
            />

            <GridTable.Column
                name="views7Days"
                template="number"
                title={formatMessage('catalog.views7')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.tikTokViews')}
                visibility="collapse"
            />

            <GridTable.Column
                name="viewsGrowthPercentage7Days"
                template="growthPercentage"
                title={formatMessage('catalog.viewsGrowthPercentage7Days')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                tooltip={formatMessage(
                    'catalog.changePercentageViewsCalculated'
                )}
                maxWidth="120px"
                category={formatMessage('catalog.tikTokViews')}
                visibility="collapse"
            />

            <GridTable.Column
                name="views28Days"
                template="number"
                title={formatMessage('catalog.views28')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.tikTokViews')}
                visibility="collapse"
            />

            <GridTable.Column
                name="viewsGrowthPercentage28Days"
                template="growthPercentage"
                title={formatMessage('catalog.viewsGrowthPercentage28Days')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                tooltip={formatMessage(
                    'catalog.changePercentageViewsCalculated'
                )}
                maxWidth="120px"
                category={formatMessage('catalog.tikTokViews')}
                visibility="collapse"
            />

            <GridTable.Column
                name="viewsAllTime"
                template="number"
                title={formatMessage('catalog.viewsAll')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.tikTokViews')}
                visibility="collapse"
            />

            <GridTable.Column
                name="creations1Day"
                template="number"
                title={formatMessage('catalog.creations1')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.tikTokCreations')}
                visibility="collapse"
            />

            <GridTable.Column
                name="creationsGrowthPercentage1Day"
                template="growthPercentage"
                title={formatMessage('catalog.creationsGrowthPercentage1Day')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                tooltip={formatMessage(
                    'catalog.changePercentageCreationsCalculated'
                )}
                maxWidth="120px"
                category={formatMessage('catalog.tikTokCreations')}
                visibility="collapse"
            />
            <GridTable.Column
                name="creations7Days"
                template="number"
                title={formatMessage('catalog.creations7')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.tikTokCreations')}
                visibility="collapse"
            />
            <GridTable.Column
                name="creationsGrowthPercentage7Days"
                template="growthPercentage"
                title={formatMessage('catalog.creationsGrowthPercentage7Days')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                tooltip={formatMessage(
                    'catalog.changePercentageCreationsCalculated'
                )}
                maxWidth="120px"
                category={formatMessage('catalog.tikTokCreations')}
                visibility="collapse"
            />
            <GridTable.Column
                name="creations28Days"
                template="number"
                title={formatMessage('catalog.creations28')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.tikTokCreations')}
                visibility="collapse"
            />

            <GridTable.Column
                name="creationsGrowthPercentage28Days"
                template="growthPercentage"
                title={formatMessage('catalog.creationsGrowthPercentage28Days')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                tooltip={formatMessage(
                    'catalog.changePercentageCreationsCalculated'
                )}
                maxWidth="120px"
                category={formatMessage('catalog.tikTokCreations')}
                visibility="collapse"
            />

            <GridTable.Column
                name="creationsAllTime"
                template="number"
                title={formatMessage('catalog.creationsAll')}
                className="TiktokCell"
                HeaderText={TiktokHeaderCell}
                minWidth="120px"
                maxWidth="120px"
                category={formatMessage('catalog.tikTokCreations')}
                visibility="collapse"
            />
        </CatalogGridTable>
    );
};

export default CatalogTable;
