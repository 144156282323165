import dayjs from 'dayjs';

export const formatDate = (
    value?: string | number | Date | dayjs.Dayjs | null,
    format?: string
) => {
    if (!value) return value;

    const date = dayjs(value);
    return date.isValid() ? date.format(format) : value;
};

export const formatIsoDate = (value?: string | number | Date | null) => {
    if (!value && value !== 0) return '';

    const parsedDate = new Date(value);
    if (Number.isNaN(parsedDate.valueOf())) return String(value);

    const formattedDate = [
        parsedDate.getFullYear(),
        String(parsedDate.getMonth() + 1).padStart(2, '0'),
        String(parsedDate.getDate()).padStart(2, '0'),
    ].join('-');

    return formattedDate;
};
