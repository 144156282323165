import React from 'react';
import { GridTable, HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';

const CLASSNAME = 'TitleWithHelpTooltipCell';

GridTable.defineColumnTemplate('titleWithHelpTooltip', {
    align: 'left',
    sortable: false,
    Cell: ({ data }) => {
        return (
            <div className={CLASSNAME} data-testid={CLASSNAME}>
                {formatMessage(data.metric)}{' '}
                <HelpTooltip
                    id={data.tooltipText}
                    message={formatMessage(data.tooltipText)}
                />
            </div>
        );
    },
});
