import React from 'react';
import { Badge, GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import {
    ArrowDownGlyph,
    ArrowUpGlyph,
    GlyphIcon,
} from '@theorchard/suite-icons';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import { EMPTY_CHAR } from 'src/constants';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

GridTable.defineColumnTemplate('change', {
    align: 'right',
    sortable: true,
    Cell: ({ value }) => {
        const getContent = () => {
            if (value === null)
                return (
                    <div className="newEntry">
                        <FeatureFlagPanel
                            featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                            fallback={
                                <Badge variant="info">
                                    {formatMessage(
                                        'placement.new'
                                    ).toUpperCase()}
                                </Badge>
                            }
                        >
                            <GlyphIcon name="dot" size={12} />
                        </FeatureFlagPanel>
                    </div>
                );
            if (value === undefined || typeof value === 'string')
                return EMPTY_CHAR;

            if (value === 0)
                return (
                    <div className="noChange">
                        <div className="Glyph" /> 0
                    </div>
                );

            if (value > 0)
                return (
                    <span className="moveUp">
                        <FeatureFlagPanel
                            featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                            fallback={<ArrowUpGlyph />}
                        >
                            <GlyphIcon name="arrowUp" size={12} />
                        </FeatureFlagPanel>
                        {value}
                    </span>
                );

            const absValue = Math.abs(value);
            return (
                <span className="moveDown">
                    <FeatureFlagPanel
                        featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                        fallback={<ArrowDownGlyph />}
                    >
                        <GlyphIcon name="arrowDown" size={12} />
                    </FeatureFlagPanel>
                    {absValue}
                </span>
            );
        };
        return <div className="ChangeCell">{getContent()}</div>;
    },
});
