import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    OrchardLabel,
    OrchardLabelVariables,
} from 'src/apollo/definitions/OrchardLabel';
import { selectOrchardLabel } from 'src/apollo/selectors/labels';
import { flattenError } from '../../utils';
import orchardLabelQuery from './orchardLabel.gql';

export const useOrchardLabelQuery = <T extends string | number>(
    labelId?: T,
    subaccountId?: T
) => {
    const [getById, { data, loading, error }] = useLazyQuery<
        OrchardLabel,
        OrchardLabelVariables
    >(orchardLabelQuery);
    const vendorIdValue = Number(labelId) || 0;
    const subaccountIdValue = Number(subaccountId) || 0;
    const isLabelDefined = vendorIdValue || subaccountIdValue;

    useEffect(() => {
        if (isLabelDefined)
            getById({
                variables: {
                    id: {
                        vendorId: vendorIdValue,
                        subaccountId: subaccountIdValue,
                    },
                },
            });
    }, [getById, isLabelDefined, vendorIdValue, subaccountIdValue]);

    return {
        loading,
        data:
            isLabelDefined && data?.orchardLabel
                ? selectOrchardLabel(data.orchardLabel)
                : undefined,
        error: error && flattenError(error),
    };
};
