import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import CountryFlagWithTooltip from 'src/components/countryFlag';
import { EMPTY_CHAR } from 'src/constants';

GridTable.defineColumnTemplate('countryFlagWithTooltip', {
    align: 'left',
    sortable: false,
    Cell: ({ value, column: { definition } }) => {
        const size = get(definition, 'data-size') || 16;

        return typeof value === 'string' ? (
            <CountryFlagWithTooltip code={value} size={size} />
        ) : (
            <span>{EMPTY_CHAR}</span>
        );
    },
});
