import {
    getCurrentLocale,
    formatMessage,
    formatNumber,
} from '@theorchard/suite-frontend';
import { EMPTY_CHAR } from 'src/constants';

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_FRACTIONAL_DIGITS = 2;
export const NUMERALS: Record<string, [number, string][]> = {
    [DEFAULT_LOCALE]: [
        [1000, 'K'],
        [1000000, 'M'],
        [1000000000, 'B'],
        [1000000000000, 'T'],
    ],
};

export interface Options {
    fractionalDigits?: number;
    locale?: string;
    min?: number;
}

export const normalizeNumber = (
    value?: string | number | null
): number | null => {
    if (value === null) return null;

    const parsedNumber = Number(value);
    const result = Number.isFinite(parsedNumber) ? parsedNumber : null;

    return result;
};

export const formatDecimal = (value: number | null, precision = 2) => {
    if (typeof value !== 'number') return value;
    return value.toFixed(precision);
};

export const formatPercentageCsv = (value: number | null | undefined) => {
    if (typeof value !== 'number') return value;
    return (value * 100).toFixed(2);
};

export const formatPercentage = (value: number) =>
    value >= 1.0
        ? `${Math.round(value * 100)}%`
        : `${Math.round(value * 1000) / 10}%`;

export const formatUncompressedNumber = (
    value: number,
    options: Options = {}
) => {
    const {
        locale = getCurrentLocale(),
        fractionalDigits = DEFAULT_FRACTIONAL_DIGITS,
        min = 0,
    } = options;

    if (value === null || value < min) return EMPTY_CHAR;

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: fractionalDigits,
        maximumFractionDigits: fractionalDigits,
    }).format(value);
};

export const formatDollars = (
    value: number | null,
    locale = getCurrentLocale()
) =>
    value === null
        ? EMPTY_CHAR
        : new Intl.NumberFormat(locale, {
              style: 'currency',
              currency: 'USD',
              currencyDisplay: 'symbol',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }).format(value);

export const formatMinutes = (value: number | null) => {
    if (value === null) return EMPTY_CHAR;

    const hours = Math.floor(value / 60);
    const minutes = Math.round(value % 60).toLocaleString(undefined, {
        minimumIntegerDigits: 2,
    });

    return `${hours}:${minutes}`;
};

export const formatMinutesMessage = (value: number) =>
    formatMessage('video.minutes', { value: formatMinutes(value) });

export const formatNumberHoursMessage = (value: number) =>
    formatMessage('video.hours', { value: formatNumber(value) });

export const formatNumberChange = (value: number) => {
    const formatted = formatNumber(value);
    return value > 0 ? `+${formatted}` : formatted;
};

export const formatPercentageChange = (value: number) => {
    const formatted = formatPercentage(value);
    if (value > 0) return `+${formatted}`;
    return formatted;
};

export const getFormatter = (
    format: string | undefined
): ((value: number) => string) => {
    if (format === 'percentage') return formatPercentage;
    if (format === 'time') return formatMinutesMessage;
    if (format === 'hours') return formatNumberHoursMessage;
    if (format === 'defaultChange') return formatNumberChange;
    if (format === 'percentageChange') return formatPercentageChange;
    return formatNumber;
};

export const getNumberOrDash = (value?: number | null) => {
    if (value === undefined || value === null || Number.isNaN(value))
        return EMPTY_CHAR;

    return formatNumber(value);
};

export const getPercentageOrDash = (value?: number | null) => {
    if (value === undefined || value === null || Number.isNaN(value))
        return EMPTY_CHAR;

    return formatPercentage(value);
};
