import { formatMessage } from '@theorchard/suite-frontend';
import { minBy, uniq } from 'lodash';
import {
    GlobalParticipantSongsWithTikTokQuery_topGlobalSoundRecordingsTiktok,
    GlobalParticipantSongsWithTikTokQuery_topGlobalSoundRecordingsTiktok_catalogProducts,
} from 'src/apollo/definitions/GlobalParticipantSongsWithTikTokQuery';
import { TopGlobalSoundRecordingFamiliesQuery } from 'src/apollo/definitions/TopGlobalSoundRecordingFamiliesQuery';
import {
    TopGlobalSoundRecordingsWithTikTokV2Query_topGlobalSoundRecordingsTiktok,
    TopGlobalSoundRecordingsWithTikTokV2Query_topGlobalSoundRecordingsTiktok_catalogLabelsV2,
} from 'src/apollo/definitions/TopGlobalSoundRecordingsWithTikTokV2Query';
import { selectAccounts } from 'src/apollo/selectors/utils';
import { Account } from 'src/components/accountsDetailsPopup/types';
import { TOTAL_COMPARE_IDS_COUNT } from 'src/constants/account';
import { STORE_DISPLAY_NAMES_BY_ID } from 'src/constants/stores';
import {
    TopGlobalSoundRecordingsWithTikTokQuery_topGlobalSoundRecordingsTiktok,
    TopGlobalSoundRecordingsWithTikTokQuery_topGlobalSoundRecordingsTiktok_catalogLabelsV2,
} from '../definitions/TopGlobalSoundRecordingsWithTikTokQuery';
import { filterData } from '../utils';
import { Entity } from './types';
import { isSMEProduct, selectImageUrl, toEntity } from './utils';

type UnionQueryResultWithTikTok =
    TopGlobalSoundRecordingsWithTikTokQuery_topGlobalSoundRecordingsTiktok &
        GlobalParticipantSongsWithTikTokQuery_topGlobalSoundRecordingsTiktok;

type UnionQueryResultWithTikTokV2 =
    TopGlobalSoundRecordingsWithTikTokV2Query_topGlobalSoundRecordingsTiktok &
        GlobalParticipantSongsWithTikTokQuery_topGlobalSoundRecordingsTiktok;

interface UnionQueryResultWithAccountTikTok
    extends Omit<UnionQueryResultWithTikTok, 'catalogProducts'> {
    // TODO: replace this type one from definitions
    catalogProducts:
        | (
              | (GlobalParticipantSongsWithTikTokQuery_topGlobalSoundRecordingsTiktok_catalogProducts & {
                    imprint?: string | null;
                    deletions?: string | null;
                    releaseDate?: string | null;
                })
              | null
          )[]
        | null;
    catalogLabelsV2: TopGlobalSoundRecordingsWithTikTokQuery_topGlobalSoundRecordingsTiktok_catalogLabelsV2[];
}

interface UnionQueryResultWithAccountTikTokV2
    extends Omit<UnionQueryResultWithTikTokV2, 'catalogProducts'> {
    // TODO: replace this type one from definitions
    catalogProducts:
        | (
              | (GlobalParticipantSongsWithTikTokQuery_topGlobalSoundRecordingsTiktok_catalogProducts & {
                    imprint?: string | null;
                    deletions?: string | null;
                    releaseDate?: string | null;
                })
              | null
          )[]
        | null;
    catalogLabelsV2: TopGlobalSoundRecordingsWithTikTokV2Query_topGlobalSoundRecordingsTiktok_catalogLabelsV2[];
}

export type TopGlobalSoundRecordingsWithTikTokQueryResult = Omit<
    UnionQueryResultWithAccountTikTok,
    'imageUrl'
> &
    Partial<Pick<UnionQueryResultWithAccountTikTok, 'imageUrl'>>;

export type TopGlobalSoundRecordingsWithTikTokQueryResultV2 = Omit<
    UnionQueryResultWithAccountTikTokV2,
    'imageUrl'
> &
    Partial<Pick<UnionQueryResultWithAccountTikTokV2, 'imageUrl'>>;

type UnionQueryResult =
    TopGlobalSoundRecordingsWithTikTokQuery_topGlobalSoundRecordingsTiktok &
        GlobalParticipantSongsWithTikTokQuery_topGlobalSoundRecordingsTiktok;

interface UnionQueryResultWithAccount
    extends Omit<UnionQueryResult, 'catalogProducts'> {
    catalogProducts:
        | (
              | (GlobalParticipantSongsWithTikTokQuery_topGlobalSoundRecordingsTiktok_catalogProducts & {
                    imprint?: string | null;
                    deletions?: string | null;
                    releaseDate?: string | null;
                })
              | null
          )[]
        | null;
    catalogLabelsV2: TopGlobalSoundRecordingsWithTikTokQuery_topGlobalSoundRecordingsTiktok_catalogLabelsV2[];
}

export type TopGlobalSoundRecordingsQueryResult = Omit<
    UnionQueryResultWithAccount,
    'imageUrl'
> &
    Partial<Pick<UnionQueryResultWithAccount, 'imageUrl'>>;

export interface TopGlobalSoundRecordingsQuery {
    topGlobalSoundRecordings: TopGlobalSoundRecordingsQueryResult[];
}

export interface TopGlobalSoundRecordingsWithTikTokQuery {
    topGlobalSoundRecordingsTiktok: TopGlobalSoundRecordingsWithTikTokQueryResult[];
}

export interface TopGlobalSoundRecordingsWithTikTokQueryV2 {
    topGlobalSoundRecordingsTiktok: TopGlobalSoundRecordingsWithTikTokQueryResultV2[];
}

export interface TrendScore {
    country?: string | null;
    score?: number | null;
}

export const capTikTokScoreMax100 = (
    top5_tiktok_scores_by_country: TrendScore[]
): TrendScore[] =>
    top5_tiktok_scores_by_country.map(scoreItem =>
        (scoreItem.score ?? 0) > 100
            ? {
                  ...scoreItem,
                  score: 100,
              }
            : scoreItem
    );

export interface CatalogSong {
    growthPercentage1Day: number | null;
    growthPercentage28Days: number | null;
    growthPercentage7Days: number | null;
    growthPercentage183Days: number | null;
    growthPercentage365Days: number | null;
    imageUrl: string | null;
    imprint: string | null;
    isrc: string;
    labelName: string | null;
    participants: Entity[];
    releaseDate: string | null;
    storeName: string;
    songName: string;
    streams1Day: number | null;
    streams28Days: number | null;
    streams183Days: number | null;
    streams365Days: number | null;
    streams7Days: number | null;
    streamsAllTime: number | null;
    views1Day?: number | null;
    views7Days?: number | null;
    views28Days?: number | null;
    viewsAllTime?: number | null;
    viewsGrowthPercentage1Day?: number | null;
    viewsGrowthPercentage28Days?: number | null;
    viewsGrowthPercentage7Days?: number | null;
    creations1Day?: number | null;
    creations7Days?: number | null;
    creations28Days?: number | null;
    creationsAllTime?: number | null;
    creationsGrowthPercentage1Day?: number | null;
    creationsGrowthPercentage28Days?: number | null;
    creationsGrowthPercentage7Days?: number | null;
    accounts?: Account[] | null;
    tikTokTrendScores?: TrendScore[] | null;
}

export interface SongFinMode {
    growthPercentage1Day: number | null;
    growthPercentage28Days: number | null;
    growthPercentage7Days: number | null;
    growthPercentage183Days: number | null;
    growthPercentage365Days: number | null;
    imageUrl: string | null;
    imprint: string | null;
    isrc: string;
    ids: string[];
    labelName: string | null;
    participants: Entity[];
    releaseDate: string | null;
    storeName: string;
    songName: string;
    streams1Day: number | null;
    streams28Days: number | null;
    streams183Days: number | null;
    streams365Days: number | null;
    streams7Days: number | null;
    streamsAllTime: number | null;
    views1Day?: number | null;
    views7Days?: number | null;
    views28Days?: number | null;
    viewsAllTime?: number | null;
    viewsGrowthPercentage1Day?: number | null;
    viewsGrowthPercentage28Days?: number | null;
    viewsGrowthPercentage7Days?: number | null;
    creations1Day?: number | null;
    creations7Days?: number | null;
    creations28Days?: number | null;
    creationsAllTime?: number | null;
    creationsGrowthPercentage1Day?: number | null;
    creationsGrowthPercentage28Days?: number | null;
    creationsGrowthPercentage7Days?: number | null;
    accounts?: Account[] | null;
    tikTokTrendScores?: TrendScore[] | null;
}

export interface CatalogResults {
    totalCount: number;
    items: CatalogSong[];
}

export interface SongFinModeResults {
    totalCount: number;
    items: SongFinMode[];
}

export interface CatalogSongV2 {
    growthPercentage1Day: number | null;
    growthPercentage28Days: number | null;
    growthPercentage7Days: number | null;
    activeGrowthPercentage7Days: number | null;
    growthPercentage183Days: number | null;
    growthPercentage365Days: number | null;
    imageUrl: string | null;
    imprint: string | null;
    isrc: string;
    labelName: string | null;
    participants: Entity[];
    releaseDate: string | null;
    storeName: string;
    songName: string;
    streams1Day: number | null;
    streams1DayPrev: number | null;
    streams28Days: number | null;
    streams28DaysPrev: number | null;
    streams183Days: number | null;
    streams183DaysPrev: number | null;
    streams365Days: number | null;
    streams365DaysPrev: number | null;
    streams7Days: number | null;
    activeStreams7Days: number | null;
    streams7DaysPrev: number | null;
    activeStreams7DaysPrev: number | null;
    streamsAllTime: number | null;
    views1Day?: number | null;
    views1DayPrev?: number | null;
    views7Days?: number | null;
    views7DaysPrev?: number | null;
    views28Days?: number | null;
    views28DaysPrev?: number | null;
    viewsAllTime?: number | null;
    viewsGrowthPercentage1Day?: number | null;
    viewsGrowthPercentage28Days?: number | null;
    viewsGrowthPercentage7Days?: number | null;
    creations1Day?: number | null;
    creations1DayPrev?: number | null;
    creations7Days?: number | null;
    creations7DaysPrev?: number | null;
    creations28Days?: number | null;
    creations28DaysPrev?: number | null;
    creationsAllTime?: number | null;
    creationsGrowthPercentage1Day?: number | null;
    creationsGrowthPercentage28Days?: number | null;
    creationsGrowthPercentage7Days?: number | null;
    accounts?: Account[] | null;
    tikTokTrendScores?: TrendScore[] | null;
}

export interface CatalogResultsV2 {
    totalCount: number;
    items: CatalogSongV2[];
}

export const selectTopGlobalSoundRecordings = (
    data: TopGlobalSoundRecordingsQuery,
    store?: string
): CatalogResults => {
    const [first] = data.topGlobalSoundRecordings;
    const totalCount = first?.analytics?.streams.totalCount ?? 0;

    return {
        totalCount,
        items: data.topGlobalSoundRecordings.map(
            ({
                isrc,
                name,
                imageUrl,
                catalogProducts,
                catalogLabelsV2,
                globalParticipants,
                analytics,
                labelName,
                releaseDateV2,
            }) => {
                const productsWithoutDeletions = catalogProducts?.filter(
                    product => product?.deletions === 'false'
                );
                const earliestProductWithoutDeletions = minBy(
                    productsWithoutDeletions,
                    item => item?.releaseDate && Date.parse(item?.releaseDate)
                );
                return {
                    isrc,
                    imageUrl:
                        selectImageUrl(
                            imageUrl,
                            earliestProductWithoutDeletions?.imageLocation
                                ? earliestProductWithoutDeletions.imageLocation
                                : catalogProducts?.[0]?.imageLocation,
                            isSMEProduct(
                                catalogProducts?.[0]?.notForDistribution
                            )
                        ) ?? null,
                    imprint: uniq(
                        catalogProducts?.map(item => item?.imprint)
                    ).join(', '),
                    songName: name,
                    participants: filterData(globalParticipants).map(toEntity),
                    labelName,
                    accounts: selectAccounts(catalogLabelsV2, labelName),
                    storeName: store
                        ? STORE_DISPLAY_NAMES_BY_ID[Number(store)]
                        : formatMessage('stores.allStores'),
                    releaseDate: releaseDateV2 ?? null,
                    streams1Day:
                        analytics?.streams.growthPeriods[0].value ?? null,
                    streams7Days:
                        analytics?.streams.growthPeriods[1].value ?? null,
                    streams28Days:
                        analytics?.streams.growthPeriods[2].value ?? null,
                    streams183Days:
                        analytics?.streams?.growthPeriods?.[3]?.value ?? null,
                    streams365Days:
                        analytics?.streams?.growthPeriods?.[4]?.value ?? null,
                    streamsAllTime:
                        analytics?.streams.aggregate.allTime ?? null,
                    growthPercentage1Day:
                        analytics?.streams.growthPeriods[0].growthPercentage ??
                        null,
                    growthPercentage7Days:
                        analytics?.streams.growthPeriods[1].growthPercentage ??
                        null,
                    growthPercentage28Days:
                        analytics?.streams.growthPeriods[2].growthPercentage ??
                        null,
                    growthPercentage183Days:
                        analytics?.streams?.growthPeriods?.[3]
                            ?.growthPercentage ?? null,
                    growthPercentage365Days:
                        analytics?.streams?.growthPeriods?.[4]
                            ?.growthPercentage ?? null,
                    tikTokTrendScores: capTikTokScoreMax100(
                        filterData(
                            analytics?.tiktokScores
                                ?.top5_tiktok_scores_by_country
                        )
                    ),
                };
            }
        ),
    };
};

export const selectTopGlobalSoundRecordingsWithTikTok = (
    data: TopGlobalSoundRecordingsWithTikTokQuery,
    store?: string
): CatalogResults => {
    const [first] = data.topGlobalSoundRecordingsTiktok;
    const totalCount = first?.analytics?.streams.totalCount ?? 0;

    return {
        totalCount,
        items: data.topGlobalSoundRecordingsTiktok.map(
            ({
                isrc,
                name,
                imageUrl,
                catalogProducts,
                catalogLabelsV2,
                globalParticipants,
                analytics,
                labelName,
                releaseDateV2,
            }) => {
                const productsWithoutDeletions = catalogProducts?.filter(
                    product => product?.deletions === 'false'
                );
                const earliestProductWithoutDeletions = minBy(
                    productsWithoutDeletions,
                    item => item?.releaseDate && Date.parse(item?.releaseDate)
                );

                return {
                    isrc,
                    imageUrl:
                        selectImageUrl(
                            imageUrl,
                            earliestProductWithoutDeletions?.imageLocation
                                ? earliestProductWithoutDeletions.imageLocation
                                : catalogProducts?.[0]?.imageLocation,
                            isSMEProduct(
                                catalogProducts?.[0]?.notForDistribution
                            )
                        ) ?? null,
                    songName: name,
                    participants: filterData(globalParticipants).map(toEntity),
                    labelName,
                    imprint: uniq(
                        catalogProducts?.map(item => item?.imprint)
                    ).join(', '),
                    accounts: selectAccounts(catalogLabelsV2, labelName),
                    storeName: store
                        ? STORE_DISPLAY_NAMES_BY_ID[Number(store)]
                        : formatMessage('stores.allStores'),
                    releaseDate: releaseDateV2 ?? null,
                    streams1Day:
                        analytics?.streams.growthPeriods[0].value ?? null,
                    streams7Days:
                        analytics?.streams.growthPeriods[1].value ?? null,
                    streams28Days:
                        analytics?.streams.growthPeriods[2].value ?? null,
                    streams183Days:
                        analytics?.streams?.growthPeriods?.[3]?.value ?? null,
                    streams365Days:
                        analytics?.streams?.growthPeriods?.[4]?.value ?? null,
                    streamsAllTime:
                        analytics?.streams.aggregate.allTime ?? null,
                    growthPercentage1Day:
                        analytics?.streams.growthPeriods[0].growthPercentage ??
                        null,
                    growthPercentage7Days:
                        analytics?.streams.growthPeriods[1].growthPercentage ??
                        null,
                    growthPercentage28Days:
                        analytics?.streams.growthPeriods[2].growthPercentage ??
                        null,
                    growthPercentage183Days:
                        analytics?.streams?.growthPeriods?.[3]
                            ?.growthPercentage ?? null,
                    growthPercentage365Days:
                        analytics?.streams?.growthPeriods?.[4]
                            ?.growthPercentage ?? null,

                    views1Day:
                        analytics?.tiktokAggregations?.views_1_day ?? null,
                    views7Days:
                        analytics?.tiktokAggregations?.views_7_days ?? null,
                    views28Days:
                        analytics?.tiktokAggregations?.views_28_days ?? null,
                    viewsAllTime:
                        analytics?.tiktokAggregations?.views_all_time ?? null,

                    creations1Day:
                        analytics?.tiktokAggregations?.creations_1_day ?? null,
                    creations7Days:
                        analytics?.tiktokAggregations?.creations_7_days ?? null,
                    creations28Days:
                        analytics?.tiktokAggregations?.creations_28_days ??
                        null,
                    creationsAllTime:
                        analytics?.tiktokAggregations?.creations_all_time ??
                        null,

                    viewsGrowthPercentage1Day:
                        analytics?.tiktokAggregations
                            ?.views_growth_percentage_1_day ?? null,
                    viewsGrowthPercentage7Days:
                        analytics?.tiktokAggregations
                            ?.views_growth_percentage_7_days ?? null,
                    viewsGrowthPercentage28Days:
                        analytics?.tiktokAggregations
                            ?.views_growth_percentage_28_days ?? null,

                    creationsGrowthPercentage1Day:
                        analytics?.tiktokAggregations
                            ?.creations_growth_percentage_1_day ?? null,
                    creationsGrowthPercentage7Days:
                        analytics?.tiktokAggregations
                            ?.creations_growth_percentage_7_days ?? null,
                    creationsGrowthPercentage28Days:
                        analytics?.tiktokAggregations
                            ?.creations_growth_percentage_28_days ?? null,

                    tikTokTrendScores: capTikTokScoreMax100(
                        filterData(
                            analytics?.tiktokScores
                                ?.top5_tiktok_scores_by_country
                        )
                    ),
                };
            }
        ),
    };
};

export const selectTopGlobalSoundRecordingFamilies = (
    data: TopGlobalSoundRecordingFamiliesQuery,
    store?: string
): SongFinModeResults => {
    return {
        totalCount: data.topGlobalSoundRecordingFamilies.totalCount,
        items: data.topGlobalSoundRecordingFamilies.items.map(
            ({
                analytics: {
                    mainIsrc,
                    streams,
                    soundRecordingsByStreams,
                    tiktokAggregations,
                    tiktokScores,
                },
            }) => {
                const main = mainIsrc[0].soundRecording;
                const songsToCompare =
                    soundRecordingsByStreams.length > TOTAL_COMPARE_IDS_COUNT
                        ? soundRecordingsByStreams.slice(0, -1)
                        : soundRecordingsByStreams;
                const idsToCompare = songsToCompare
                    .slice(1)
                    .map(({ soundRecording }) => soundRecording.isrc);

                return {
                    isrc: main.isrc,
                    ids: idsToCompare,
                    imageUrl:
                        selectImageUrl(
                            main.imageUrl,
                            main.catalogProducts?.[0]?.imageLocation,
                            isSMEProduct(
                                main.catalogProducts?.[0]?.notForDistribution
                            )
                        ) ?? null,
                    imprint: uniq(
                        main.catalogProducts?.map(item => item?.imprint)
                    ).join(', '),
                    songName: main.name,
                    participants: filterData(main.globalParticipants).map(
                        toEntity
                    ),
                    labelName: main.labelName,
                    accounts: selectAccounts(
                        main.catalogLabelsV2,
                        main.labelName
                    ),
                    storeName: store
                        ? STORE_DISPLAY_NAMES_BY_ID[Number(store)]
                        : formatMessage('stores.allStores'),
                    releaseDate: main.releaseDateV2 ?? null,
                    streams1Day: streams.growthPeriods[0].value ?? null,
                    streams7Days: streams.growthPeriods[1].value ?? null,
                    streams28Days: streams.growthPeriods[2].value ?? null,
                    streams183Days: streams?.growthPeriods?.[3]?.value ?? null,
                    streams365Days: streams?.growthPeriods?.[4]?.value ?? null,
                    streamsAllTime: streams.aggregate.allTime ?? null,
                    growthPercentage1Day:
                        streams.growthPeriods[0].growthPercentage ?? null,
                    growthPercentage7Days:
                        streams.growthPeriods[1].growthPercentage ?? null,
                    growthPercentage28Days:
                        streams.growthPeriods[2].growthPercentage ?? null,
                    growthPercentage183Days:
                        streams?.growthPeriods?.[3]?.growthPercentage ?? null,
                    growthPercentage365Days:
                        streams?.growthPeriods?.[4]?.growthPercentage ?? null,

                    views1Day: tiktokAggregations?.views_1_day ?? null,
                    views7Days: tiktokAggregations?.views_7_days ?? null,
                    views28Days: tiktokAggregations?.views_28_days ?? null,
                    viewsAllTime: tiktokAggregations?.views_all_time ?? null,

                    creations1Day: tiktokAggregations?.creations_1_day ?? null,
                    creations7Days:
                        tiktokAggregations?.creations_7_days ?? null,
                    creations28Days:
                        tiktokAggregations?.creations_28_days ?? null,
                    creationsAllTime:
                        tiktokAggregations?.creations_all_time ?? null,

                    viewsGrowthPercentage1Day:
                        tiktokAggregations?.views_growth_percentage_1_day ??
                        null,
                    viewsGrowthPercentage7Days:
                        tiktokAggregations?.views_growth_percentage_7_days ??
                        null,
                    viewsGrowthPercentage28Days:
                        tiktokAggregations?.views_growth_percentage_28_days ??
                        null,

                    creationsGrowthPercentage1Day:
                        tiktokAggregations?.creations_growth_percentage_1_day ??
                        null,
                    creationsGrowthPercentage7Days:
                        tiktokAggregations?.creations_growth_percentage_7_days ??
                        null,
                    creationsGrowthPercentage28Days:
                        tiktokAggregations?.creations_growth_percentage_28_days ??
                        null,

                    tikTokTrendScores: capTikTokScoreMax100(
                        filterData(tiktokScores?.top5_tiktok_scores_by_country)
                    ),
                };
            }
        ),
    };
};

export const selectTopGlobalSoundRecordingsWithTikTokV2 = (
    data: TopGlobalSoundRecordingsWithTikTokQueryV2,
    store?: string
): CatalogResultsV2 => {
    const [first] = data.topGlobalSoundRecordingsTiktok;
    const totalCount = first?.analytics?.streams.totalCount ?? 0;

    return {
        totalCount,
        items: data.topGlobalSoundRecordingsTiktok.map(
            ({
                isrc,
                name,
                imageUrl,
                catalogProducts,
                catalogLabelsV2,
                globalParticipants,
                analytics,
                labelName,
                releaseDateV2,
            }) => {
                const productsWithoutDeletions = catalogProducts?.filter(
                    product => product?.deletions === 'false'
                );
                const earliestProductWithoutDeletions = minBy(
                    productsWithoutDeletions,
                    item => item?.releaseDate && Date.parse(item?.releaseDate)
                );

                return {
                    isrc,
                    imageUrl:
                        selectImageUrl(
                            imageUrl,
                            earliestProductWithoutDeletions?.imageLocation
                                ? earliestProductWithoutDeletions.imageLocation
                                : catalogProducts?.[0]?.imageLocation,
                            isSMEProduct(
                                catalogProducts?.[0]?.notForDistribution
                            )
                        ) ?? null,
                    songName: name,
                    participants: filterData(globalParticipants).map(toEntity),
                    labelName,
                    imprint: uniq(
                        catalogProducts?.map(item => item?.imprint)
                    ).join(', '),
                    accounts: selectAccounts(catalogLabelsV2, labelName),
                    storeName: store
                        ? STORE_DISPLAY_NAMES_BY_ID[Number(store)]
                        : formatMessage('stores.allStores'),
                    releaseDate: releaseDateV2 ?? null,
                    streams1Day:
                        analytics?.streams.growthPeriods[0].value ?? null,
                    streams1DayPrev:
                        analytics?.streams.growthPeriods[0].prevValue ?? null,
                    streams7Days:
                        analytics?.streams.growthPeriods[1].value ?? null,
                    streams7DaysPrev:
                        analytics?.streams.growthPeriods[1].prevValue ?? null,
                    activeStreams7Days:
                        analytics?.streams.growthPeriods[1].activeValue ?? null,
                    activeStreams7DaysPrev:
                        analytics?.streams.growthPeriods[1].prevActiveValue ??
                        null,
                    streams28Days:
                        analytics?.streams.growthPeriods[2].value ?? null,
                    streams28DaysPrev:
                        analytics?.streams.growthPeriods[2].prevValue ?? null,
                    streams183Days:
                        analytics?.streams?.growthPeriods?.[3]?.value ?? null,
                    streams183DaysPrev:
                        analytics?.streams?.growthPeriods?.[3]?.prevValue ??
                        null,
                    streams365Days:
                        analytics?.streams?.growthPeriods?.[4]?.value ?? null,
                    streams365DaysPrev:
                        analytics?.streams?.growthPeriods?.[4]?.prevValue ??
                        null,
                    streamsAllTime:
                        analytics?.streams.aggregate.allTime ?? null,
                    growthPercentage1Day:
                        analytics?.streams.growthPeriods[0].growthPercentage ??
                        null,
                    growthPercentage7Days:
                        analytics?.streams.growthPeriods[1].growthPercentage ??
                        null,
                    activeGrowthPercentage7Days:
                        analytics?.streams.growthPeriods[1]
                            .activeGrowthPercentage ?? null,
                    growthPercentage28Days:
                        analytics?.streams.growthPeriods[2].growthPercentage ??
                        null,
                    growthPercentage183Days:
                        analytics?.streams?.growthPeriods?.[3]
                            ?.growthPercentage ?? null,
                    growthPercentage365Days:
                        analytics?.streams?.growthPeriods?.[4]
                            ?.growthPercentage ?? null,

                    views1Day:
                        analytics?.tiktokAggregations?.views_1_day ?? null,
                    views1DayPrev:
                        analytics?.tiktokAggregations?.views_1_day_prev ?? null,
                    views7Days:
                        analytics?.tiktokAggregations?.views_7_days ?? null,
                    views7DaysPrev:
                        analytics?.tiktokAggregations?.views_7_days_prev ??
                        null,
                    views28Days:
                        analytics?.tiktokAggregations?.views_28_days ?? null,
                    views28DaysPrev:
                        analytics?.tiktokAggregations?.views_28_days_prev ??
                        null,
                    viewsAllTime:
                        analytics?.tiktokAggregations?.views_all_time ?? null,

                    creations1Day:
                        analytics?.tiktokAggregations?.creations_1_day ?? null,
                    creations1DayPrev:
                        analytics?.tiktokAggregations?.creations_1_day_prev ??
                        null,
                    creations7Days:
                        analytics?.tiktokAggregations?.creations_7_days ?? null,
                    creations7DaysPrev:
                        analytics?.tiktokAggregations?.creations_7_days_prev ??
                        null,
                    creations28Days:
                        analytics?.tiktokAggregations?.creations_28_days ??
                        null,
                    creations28DaysPrev:
                        analytics?.tiktokAggregations?.creations_28_days_prev ??
                        null,
                    creationsAllTime:
                        analytics?.tiktokAggregations?.creations_all_time ??
                        null,

                    viewsGrowthPercentage1Day:
                        analytics?.tiktokAggregations
                            ?.views_growth_percentage_1_day ?? null,
                    viewsGrowthPercentage7Days:
                        analytics?.tiktokAggregations
                            ?.views_growth_percentage_7_days ?? null,
                    viewsGrowthPercentage28Days:
                        analytics?.tiktokAggregations
                            ?.views_growth_percentage_28_days ?? null,

                    creationsGrowthPercentage1Day:
                        analytics?.tiktokAggregations
                            ?.creations_growth_percentage_1_day ?? null,
                    creationsGrowthPercentage7Days:
                        analytics?.tiktokAggregations
                            ?.creations_growth_percentage_7_days ?? null,
                    creationsGrowthPercentage28Days:
                        analytics?.tiktokAggregations
                            ?.creations_growth_percentage_28_days ?? null,

                    tikTokTrendScores: capTikTokScoreMax100(
                        filterData(
                            analytics?.tiktokScores
                                ?.top5_tiktok_scores_by_country
                        )
                    ),
                };
            }
        ),
    };
};
