import React, { FC } from 'react';
import { GridTableCellProps, GridTable } from '@theorchard/suite-components';
import { GlyphIcon } from '@theorchard/suite-icons';
import { get } from 'lodash';
import { TiktokTopSounds } from 'src/apollo/queries/soundRecording/tiktokTopSounds';

const TikTokIdCell: FC<GridTableCellProps<TiktokTopSounds>> = ({ data }) => {
    const Icon = (
        <a href={data.url} target="_blank" rel="noopener noreferrer">
            <GlyphIcon name="externalLink" size={12} />
        </a>
    );

    if (get(data, 'creationId'))
        return (
            <div className="CreationIdCell">
                <div>
                    <span>{get(data, 'creationId')}</span>
                    {Icon}
                </div>
                <span>{data.soundId}</span>
            </div>
        );

    return (
        <div className="SoundIdCell">
            <span>{data.soundId}</span>
            {Icon}
        </div>
    );
};

GridTable.defineColumnTemplate('tiktokId', {
    align: 'left',
    sortable: false,
    Cell: TikTokIdCell,
});

export default TikTokIdCell;
