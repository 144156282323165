import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import NumberWithPercentageAndArrowCell from '../numberWithPercentageAndArrowCell';

GridTable.defineColumnTemplate('creations28DaysChange', {
    align: 'right',
    Cell: props => (
        <NumberWithPercentageAndArrowCell
            {...props}
            percentageProperty="creationsGrowthPercentage28Days"
            currentValueProperty="creations28Days"
        />
    ),
});
