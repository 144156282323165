import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { EMPTY_CHAR } from 'src/constants';

GridTable.defineColumnTemplate('participant', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { participants } }) => {
        const message = participants?.map(({ name }) => name).join(', ');
        if (!message) return <span>{EMPTY_CHAR}</span>;

        return <TruncatedText value={message} />;
    },
});
