import { SortDirection } from 'src/components/table/types';

export const SONGS_PAGE_SIZE = 50;

export const SONG_NAME = 'catalog.songName';
export const COUNTRY = 'country.country';
export const ARTIST_NAME = 'catalog.artistName';
export const ISRC = 'catalog.isrc';
export const IMPRINT = 'chart.imprint';
export const STORE_NAME = 'playlist.store';
export const RELEASE_DATE = 'catalog.releaseDate';
export const STREAMS_1 = 'catalog.streams1';
export const GROWTH_1 = 'catalog.growthPercentage1Day';
export const STREAMS_7 = 'catalog.streams7';
export const GROWTH_7 = 'catalog.growthPercentage7Days';
export const STREAMS_28 = 'catalog.streams28';
export const GROWTH_28 = 'catalog.growthPercentage28Days';
export const STREAMS_ALL = 'catalog.streamsAll';
export const VIEWS_1 = 'catalog.views1';
export const VIEWS_GROWTH_1 = 'catalog.viewsGrowthPercentage1Day';
export const VIEWS_7 = 'catalog.views7';
export const VIEWS_GROWTH_7 = 'catalog.viewsGrowthPercentage7Days';
export const VIEWS_28 = 'catalog.views28';
export const VIEWS_GROWTH_28 = 'catalog.viewsGrowthPercentage28Days';
export const VIEWS_ALL = 'catalog.viewsAll';
export const CREATIONS_1 = 'catalog.creations1';
export const CREATIONS_GROWTH_1 = 'catalog.creationsGrowthPercentage1Day';
export const CREATIONS_7 = 'catalog.creations7';
export const CREATIONS_GROWTH_7 = 'catalog.creationsGrowthPercentage7Days';
export const CREATIONS_28 = 'catalog.creations28';
export const CREATIONS_GROWTH_28 = 'catalog.creationsGrowthPercentage28Days';
export const CREATIONS_ALL = 'catalog.creationsAll';
export const METADATA = 'catalog.metadata';
export const STREAMING = 'catalog.streaming';
export const TIKTOK_VIEWS = 'catalog.tikTokViews';
export const TIKTOK_CREATIONS = 'catalog.tikTokCreations';
export const TIKTOK_TREND_SCORE = 'catalog.tikTokTrendScore';
export const ACCOUNT = 'account.account';

export const DEFAULT_SORT_FIELD = 'streams_7_days';
export const DEFAULT_SORT_DIR: SortDirection = 'desc';

export const CATALOG_SONGS_SORTING_FIELDS_MAP: Record<string, string> = {
    releaseDate: 'release_date',
    tikTokTrendScore: 'max_tiktok_score',
    streams1Day: 'streams_1_day',
    growthPercentage1Day: 'growth_percentage_1_day',
    streams7Days: 'streams_7_days',
    growthPercentage7Days: 'growth_percentage_7_days',
    streams28Days: 'streams_28_days',
    growthPercentage28Days: 'growth_percentage_28_days',
    streamsAllTime: 'streams_all_time',
    views1Day: 'views_1_day',
    viewsGrowthPercentage1Day: 'views_growth_percentage_1_day',
    views7Days: 'views_7_days',
    viewsGrowthPercentage7Days: 'views_growth_percentage_7_days',
    views28Days: 'views_28_days',
    viewsGrowthPercentage28Days: 'views_growth_percentage_28_days',
    viewsAllTime: 'views_all_time',
    creations1Day: 'creations_1_day',
    creationsGrowthPercentage1Day: 'creations_growth_percentage_1_day',
    creations7Days: 'creations_7_days',
    creationsGrowthPercentage7Days: 'creations_growth_percentage_7_days',
    creations28Days: 'creations_28_days',
    creationsGrowthPercentage28Days: 'creations_growth_percentage_28_days',
    creationsAllTime: 'creations_all_time',
};
