import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';

GridTable.defineColumnTemplate('claimStatus', {
    align: 'center',
    sortable: false,
    Cell: ({ data: { claimStatus } }) => (
        <span className="ClaimStatusCell">
            {formatMessage(`video.${claimStatus.toLowerCase()}`)}
        </span>
    ),
});
