import React from 'react';
import { GridTable, Tooltip } from '@theorchard/suite-components';
import { ExternalLinkGlyph, GlyphIcon } from '@theorchard/suite-icons';
import { uniqueId } from 'lodash';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import TruncatedText from 'src/components/truncatedText';
import { EMPTY_CHAR } from 'src/constants';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import { YoutubeTypeLinks, buildYoutubeLink } from 'src/utils/charts';
import EmptyCell from './emptyCell';

GridTable.defineColumnTemplate('externalChannelsLink', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { channelId, channelName } }) => {
        const id = channelId;
        const name = channelName || (id && `[${id}]`) || EMPTY_CHAR;
        if (!id) return <EmptyCell />;
        const externalLink = buildYoutubeLink({
            channel: id,
            type: YoutubeTypeLinks.channel,
        });

        return (
            <div className="ExternalChannelLinkCell ExternalLinkCell d-flex justify-content-between align-items-center">
                <TruncatedText value={name} />
                <a
                    className="ExternalLinkCell-link d-flex d-print-none align-items-center"
                    href={externalLink}
                    onClick={event => event.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Tooltip
                        id={`externalChannelsLink_${uniqueId()}`}
                        message={externalLink}
                    >
                        <FeatureFlagPanel
                            featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                            fallback={<ExternalLinkGlyph className="ml-2" />}
                        >
                            <GlyphIcon
                                name="externalLink"
                                size={12}
                                className="ml-2"
                            />
                        </FeatureFlagPanel>
                    </Tooltip>
                </a>
            </div>
        );
    },
});
