import { DocumentNode } from '@apollo/client';
import {
    CreateGlobalParticipantLink,
    CreateGlobalParticipantLinkVariables,
} from 'src/apollo/definitions/CreateGlobalParticipantLink';
import { PotGraphType } from 'src/apollo/definitions/globalTypes';
import createGlobalParticipantLink from 'src/apollo/mutations/universalLinks/createGlobalParticipantLink.gql';

export interface ParticipantParams {
    type: 'participant';
    id: string;
    countryFilter?: string[];
    storeFilter?: number[];
    potMetric?: string;
    potDimension?: string;
    dateFilter?: string;
    dateFilterStart?: string;
    dateFilterEnd?: string;
    page?: string;
    playlistAppearances?: string;
    curatorFilter?: string;
    playlistTypeFilter?: string[];
    territoryFilter?: string;
    chart?: string;
    chartMode?: string;
    song?: string;
    potGraphType?: PotGraphType;
}

export interface ParticipantLinkData {
    query: DocumentNode;
    variables: CreateGlobalParticipantLinkVariables;
    selector: (res?: CreateGlobalParticipantLink | null) => string | undefined;
    type: 'participant';
}

export const selectParticipantLink = (
    data: ParticipantParams
): ParticipantLinkData => ({
    query: createGlobalParticipantLink,
    variables: {
        id: data.id,
        countries: data.countryFilter,
        storeIds: data.storeFilter,
        pot: data.potMetric,
        dimension: data.potDimension,
        period: data.dateFilter,
        startDate: data.dateFilterStart,
        endDate: data.dateFilterEnd,
        page: data.page,
        playlistAppearances: data.playlistAppearances,
        playlistCuratorMkt: data.curatorFilter,
        playlistTypes: data.playlistTypeFilter,
        playlistTerritory: data.territoryFilter,
        chart: data.chart,
        chartMode: data.chartMode,
        song: data.song,
        potGraphType: data.potGraphType,
    },
    selector: res => res?.createGlobalParticipantLink,
    type: 'participant',
});
