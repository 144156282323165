import { MutationTuple } from '@apollo/client';
import { useSettingsMutation } from 'src/apollo/utils';
import { CreateUser, CreateUserVariables } from '../../definitions/CreateUser';
import createUserMutation from './createUser.gql';

export const useCreateUser = (): MutationTuple<
    CreateUser,
    CreateUserVariables
> => {
    const [createUser, state] = useSettingsMutation<
        CreateUser,
        CreateUserVariables
    >(createUserMutation);

    return [createUser, state];
};
