import { flatMap } from 'lodash';
import { selectTimeseries } from 'src/apollo/selectors';
import { STORE_DISPLAY_NAMES_BY_ID, STORES_BY_ID } from 'src/constants/stores';
import { PrimaryDimension, TimeseriesSelector } from '../types';
import { getTimeseriesData, getTimeseriesSummary } from '../utils';

export const selectTimeseriesByStore: TimeseriesSelector = ({
    data,
    primaryDimension,
    datePeriod,
}) =>
    flatMap(data, ({ isrc, downloads, streams, releaseDate }) => {
        const storeStreams = streams?.streamsByStore?.stores;
        const storeDownloads = downloads?.downloadsByStore?.stores;

        const primaryData =
            primaryDimension === PrimaryDimension.Downloads
                ? storeDownloads
                : storeStreams;

        return (
            primaryData?.map(store => {
                const itemStreams = storeStreams?.find(
                    ps => ps.storeId === store.storeId
                );
                const itemDownloads = storeDownloads?.find(
                    pd => pd.storeId === store.storeId
                );
                const name = STORES_BY_ID[store.storeId];
                const id = `${isrc}_${name}`;

                return {
                    id,
                    name,
                    title: `${
                        STORE_DISPLAY_NAMES_BY_ID[store.storeId]
                    } ${primaryDimension}`,
                    data: getTimeseriesData({
                        data: selectTimeseries(store.items),
                        primaryDimension,
                        datePeriod,
                        releaseDate,
                    }),
                    summary: getTimeseriesSummary({
                        id,
                        name,
                        title: STORE_DISPLAY_NAMES_BY_ID[store.storeId],
                        streams: itemStreams?.items
                            ? selectTimeseries(itemStreams?.items)
                            : null,
                        downloads: itemDownloads?.items,
                    }),
                };
            }) ?? []
        );
    });
