import React from 'react';
import { GridTable, HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import EmptyCell from 'src/components/table/cells/emptyCell';

GridTable.defineColumnTemplate('inCatalog', {
    align: 'center',
    HeaderText: () => null,
    maxWidth: 'max-content',
    sortable: false,
    Cell: ({ data: { isPartOfCatalog } }) =>
        isPartOfCatalog ? (
            <HelpTooltip
                id={'in-catalog-tooltip'}
                message={formatMessage('comparison.inMyCatalogHint')}
            >
                <div data-testid={'InCatalogCell'}>
                    <GlyphIcon name={'inCatalog'} size={16} />
                </div>
            </HelpTooltip>
        ) : (
            <EmptyCell />
        ),
});
