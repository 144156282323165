import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { TopAccountsCount } from 'src/apollo/definitions/TopAccountsCount';
import { selectCatalogAccountsCount } from 'src/apollo/selectors/accounts';
import { usePrefetchQuery } from 'src/apollo/utils';
import topAccountsCountQuery from './topAccountsCount.gql';

export const useTopAccountsCountQuery = () => {
    const { data, loading, error } = useQuery<TopAccountsCount>(
        topAccountsCountQuery,
        { fetchPolicy: 'cache-first' }
    );
    const memoizedData = useMemo(
        () => data && selectCatalogAccountsCount(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchTopAccountsCountQuery = () => {
    const variables = {};
    usePrefetchQuery(topAccountsCountQuery, { variables });
};
