import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import ChannelLinkCell from './channelLinkCell';

GridTable.defineColumnTemplate('thumbnailChannelLink', {
    align: 'left',
    sortable: false,
    Cell: ({ data, ...rest }) => (
        <div
            className="ThumbnailChannelLinkCell CoverArtCell"
            data-testid="ThumbnailChannelLinkCell"
        >
            <CoverArt url={data.channel?.imageUrl} shape="round" width="30" />
            <ChannelLinkCell data={data} {...rest} />
        </div>
    ),
});
