import { formatMessage } from '@theorchard/suite-frontend';
import { TopUGCQuery } from 'src/apollo/definitions/TopUGCQuery';
import { EMPTY_CHAR } from 'src/constants';
import { Source } from 'src/constants/stores';
import { selectSources } from 'src/utils/sources';
import { Entity } from './types';
import { toEntity } from './utils';
import { youtubeVideoUrl } from './video';

export interface UGCVideo {
    video: Entity;
    channel: Entity;
    releaseDate: string;
    allTimeViews: number;
    percentageOfReference: number | null;
    claimDate: string;
    claimPolicy: {
        monetize: string[];
        track: string[];
        block: string[];
    };
    claimType: string | null;
    claimStatus: string;
}

export interface VideoTopUGCResults {
    totalCount: number;
    items: UGCVideo[];
    sources: Source[];
}

const selectPolicy = (value: string | undefined) =>
    (value?.split(',') ?? []).filter(val => val.length === 2);

export const selectClaimPolicy = (value: string | null) => {
    const policies = value?.split('|');
    return {
        monetize: selectPolicy(policies?.[0]),
        track: selectPolicy(policies?.[1]),
        block: selectPolicy(policies?.[2]),
    };
};

export const selectUGCVideos = (data: TopUGCQuery): VideoTopUGCResults => {
    const items: UGCVideo[] = data.ugcVideos.ugcVideoMetrics.map(video => ({
        video: toEntity({
            id: video.videoId,
            name: video.videoName,
            imageUrl: youtubeVideoUrl(video.videoId),
        }),
        channel: toEntity({
            id: video.channelId,
            name: video.channelName,
        }),
        releaseDate: video.releaseDate,
        allTimeViews: video.views,
        percentageOfReference: video.percentage,
        claimDate: video.claimCreatedDate ?? EMPTY_CHAR,
        claimPolicy: selectClaimPolicy(video.claimPolicy),
        claimType: formatMessage(
            `video.${video.claimType?.toLowerCase()}Claim`
        ),
        claimStatus: video.claimStatus ?? '',
    }));

    return {
        totalCount: data.ugcVideos.totalCount,
        sources: selectSources(data.ugcVideos.sources) ?? [],
        items,
    };
};
