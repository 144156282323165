import { GlobalParticipantSongsQueryV2 } from 'src/apollo/definitions/GlobalParticipantSongsQueryV2';
import { Entity } from 'src/apollo/selectors/types';
import {
    isSMEProduct,
    selectImageUrl,
    toEntity,
} from 'src/apollo/selectors/utils';
import { filterData } from 'src/apollo/utils';

export interface ParticipantSong {
    growthPercentage1Day: number | null;
    growthPercentage28Days: number | null;
    growthPercentage7Days: number | null;
    imageUrl: string | null;
    isrc: string;
    participants: Entity[];
    releaseDate: string | null;
    songName: string;
    streams1Day: number | null;
    streams1DayPrev: number | null;
    streams28Days: number | null;
    streams28DaysPrev: number | null;
    streams7Days: number | null;
    streams7DaysPrev: number | null;
    activeStreams7Days: number | null;
    activeStreams7DaysPrev: number | null;
    activeGrowthPercentage7Days: number | null;
    streamsAllTime: number | null;
}

export interface ParticipantSongs {
    totalCount: number;
    items: ParticipantSong[];
}

export const selectParticipantSongs = (
    data: GlobalParticipantSongsQueryV2
): ParticipantSongs => {
    const [first] = data.topGlobalSoundRecordings;
    const totalCount = first?.analytics?.streams.totalCount ?? 0;

    return {
        totalCount,
        items: data.topGlobalSoundRecordings.map(
            ({
                isrc,
                name,
                imageUrl,
                catalogProducts,
                globalParticipants,
                analytics,
                releaseDateV2,
            }) => ({
                isrc,
                imageUrl:
                    selectImageUrl(
                        imageUrl,
                        catalogProducts?.[0]?.imageLocation,
                        isSMEProduct(catalogProducts?.[0]?.notForDistribution)
                    ) ?? null,
                songName: name,
                participants: filterData(globalParticipants).map(toEntity),
                releaseDate: releaseDateV2 ?? null,
                streams1Day: analytics?.streams.growthPeriods[0].value ?? null,
                streams1DayPrev:
                    analytics?.streams.growthPeriods[0].prevValue ?? null,
                streams7Days: analytics?.streams.growthPeriods[1].value ?? null,
                streams7DaysPrev:
                    analytics?.streams.growthPeriods[1].prevValue ?? null,
                activeStreams7Days:
                    analytics?.streams.growthPeriods[1].activeValue ?? null,
                activeStreams7DaysPrev:
                    analytics?.streams.growthPeriods[1].prevActiveValue ?? null,
                streams28Days:
                    analytics?.streams.growthPeriods[2].value ?? null,
                streams28DaysPrev:
                    analytics?.streams.growthPeriods[2].prevValue ?? null,
                streamsAllTime: analytics?.streams.aggregate.allTime ?? null,
                growthPercentage1Day:
                    analytics?.streams.growthPeriods[0].growthPercentage ??
                    null,
                growthPercentage7Days:
                    analytics?.streams.growthPeriods[1].growthPercentage ??
                    null,
                activeGrowthPercentage7Days:
                    analytics?.streams.growthPeriods[1]
                        .activeGrowthPercentage ?? null,
                growthPercentage28Days:
                    analytics?.streams.growthPeriods[2].growthPercentage ??
                    null,
            })
        ),
    };
};
