import { flatMap } from 'lodash';
import { selectTimeseries } from 'src/apollo/selectors';
import { PrimaryDimension, TimeseriesSelector } from '../types';
import { getTimeseriesData, getTimeseriesSummary } from '../utils';

export const selectTimeseriesByProduct: TimeseriesSelector = ({
    data,
    primaryDimension,
    datePeriod,
}) =>
    flatMap(data, ({ isrc, downloads, streams, releaseDate }) => {
        const productStreams = streams?.streamsByProduct?.products;
        const productDownloads = downloads?.downloadsByProduct?.products;

        const primaryData =
            primaryDimension === PrimaryDimension.Downloads
                ? productDownloads
                : productStreams;

        return (
            primaryData?.map(item => {
                const itemStreams = productStreams?.find(
                    ps => ps.product.productId === item.product.productId
                );
                const itemDownloads = productDownloads?.find(
                    pd => pd.product.productId === item.product.productId
                );
                const id = `${isrc}_${item.product.productId}`;
                const name = `${item.product.productId}`;

                return {
                    id,
                    name,
                    title: `${item.product.productName} ${primaryDimension}`,
                    data: getTimeseriesData({
                        data: selectTimeseries(item.items),
                        primaryDimension,
                        datePeriod,
                        releaseDate,
                    }),
                    summary: getTimeseriesSummary({
                        id,
                        name,
                        code: item.product.upc ?? undefined,
                        title: item.product.productName,
                        streams: itemStreams?.items
                            ? selectTimeseries(itemStreams.items)
                            : null,
                        downloads: itemDownloads?.items
                            ? selectTimeseries(itemDownloads.items)
                            : null,
                    }),
                };
            }) ?? []
        );
    });
