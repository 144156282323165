import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { formatMinutes, normalizeNumber } from 'src/utils';

GridTable.defineColumnTemplate('duration', {
    align: 'right',
    Cell: ({ value }) => {
        const normalizedValue = normalizeNumber(value);

        return (
            <div className="DurationCell">
                {formatMessage('video.minutes', {
                    value: formatMinutes(normalizedValue),
                })}
            </div>
        );
    },
});
