import React from 'react';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { Illustration } from '@theorchard/suite-icons';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

function NoDataIcon200() {
    const isColorSchemeV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);

    return isColorSchemeV2Enabled ? (
        <Illustration name={'emptyState'} size={200} />
    ) : (
        <svg
            data-testid="NoDataIcon200"
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1595_5156"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="20"
                y="20"
                width="160"
                height="160"
            >
                <circle cx="100" cy="100" r="80" fill="#F3F0F0" />
            </mask>
            <g mask="url(#mask0_1595_5156)">
                <circle cx="100" cy="100" r="80" fill="#F3F0F0" />
                <circle
                    cx="100"
                    cy="100"
                    r="80"
                    fill="#EDE9E8"
                    fillOpacity="0.01"
                />
                <g filter="url(#filter0_d_1595_5156)">
                    <rect
                        x="51"
                        y="51"
                        width="98"
                        height="98"
                        rx="49"
                        fill="white"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80 117.143C80 115.565 81.2792 114.286 82.8571 114.286H117.143C118.721 114.286 120 115.565 120 117.143C120 118.721 118.721 120 117.143 120H82.8571C81.2792 120 80 118.721 80 117.143Z"
                    fill="#A8A0A0"
                />
                <path
                    d="M82.8571 91.4286C82.8571 89.8506 84.1363 88.5714 85.7143 88.5714H94.2857C95.8637 88.5714 97.1429 89.8506 97.1429 91.4286V105.714C97.1429 107.292 95.8637 108.571 94.2857 108.571H85.7143C84.1363 108.571 82.8571 107.292 82.8571 105.714V91.4286Z"
                    fill="#A8A0A0"
                />
                <path
                    d="M102.857 82.8571C102.857 81.2792 104.136 80 105.714 80H114.286C115.864 80 117.143 81.2792 117.143 82.8571V105.714C117.143 107.292 115.864 108.571 114.286 108.571H105.714C104.136 108.571 102.857 107.292 102.857 105.714V82.8571Z"
                    fill="#A8A0A0"
                />
                <g filter="url(#filter1_i_1595_5156)">
                    <circle
                        cx="100"
                        cy="100"
                        r="80"
                        fill="#EDE9E8"
                        fillOpacity="0.01"
                    />
                </g>
            </g>
            <g filter="url(#filter2_d_1595_5156)">
                <path
                    d="M50.4254 149.689C50.23 149.372 49.77 149.372 49.5746 149.689L45.48 156.317C45.4392 156.384 45.3835 156.439 45.3174 156.48L38.6887 160.575C38.3724 160.77 38.3724 161.23 38.6887 161.425L45.3174 165.52C45.3835 165.561 45.4392 165.616 45.48 165.683L49.5746 172.311C49.77 172.628 50.23 172.628 50.4254 172.311L54.52 165.683C54.5608 165.616 54.6165 165.561 54.6826 165.52L61.3113 161.425C61.6276 161.23 61.6276 160.77 61.3113 160.575L54.6826 156.48C54.6165 156.439 54.5608 156.384 54.52 156.317L50.4254 149.689Z"
                    fill="#80BFFF"
                />
            </g>
            <g filter="url(#filter3_d_1595_5156)">
                <path
                    d="M152.575 34.6887C152.77 34.3724 153.23 34.3724 153.425 34.6887L155.993 38.8448C156.033 38.9109 156.089 38.9666 156.155 39.0074L160.311 41.5746C160.628 41.77 160.628 42.23 160.311 42.4254L156.155 44.9926C156.089 45.0334 156.033 45.0891 155.993 45.1552L153.425 49.3113C153.23 49.6276 152.77 49.6276 152.575 49.3113L150.007 45.1552C149.967 45.0891 149.911 45.0334 149.845 44.9926L145.689 42.4254C145.372 42.23 145.372 41.77 145.689 41.5746L149.845 39.0074C149.911 38.9666 149.967 38.9109 150.007 38.8448L152.575 34.6887Z"
                    fill="#80BFFF"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_1595_5156"
                    x="31"
                    y="36"
                    width="138"
                    height="138"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.698039 0 0 0 0 0.698039 0 0 0 0 0.698039 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1595_5156"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1595_5156"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_i_1595_5156"
                    x="20"
                    y="20"
                    width="160"
                    height="160"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5.236" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.894118 0 0 0 0 0.878431 0 0 0 0 0.87451 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_1595_5156"
                    />
                </filter>
                <filter
                    id="filter2_d_1595_5156"
                    x="22.4512"
                    y="137.451"
                    width="55.0977"
                    height="55.0972"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.568627 0 0 0 0 0.811765 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1595_5156"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1595_5156"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_1595_5156"
                    x="129.451"
                    y="22.4514"
                    width="47.0977"
                    height="47.0972"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.568627 0 0 0 0 0.811765 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1595_5156"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1595_5156"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default NoDataIcon200;
