import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { useFollowChannelMutation } from 'src/apollo/mutations';
import StarredIcon from 'src/components/starredIcon';

GridTable.defineColumnTemplate('starredChannel', {
    align: 'left',
    HeaderText: () => null,
    maxWidth: 'max-content',
    sortable: false,
    Cell: ({ data: { channel, following } }) => {
        const channelId = channel.id;
        const [setFollowing] = useFollowChannelMutation({ channelId });

        const handleStarClick = () => {
            setFollowing(!following);
        };

        return (
            <div
                role="button"
                className="StarredCell"
                tabIndex={0}
                onClick={handleStarClick}
                onKeyDown={handleStarClick}
            >
                <StarredIcon filled={following} />
            </div>
        );
    },
});
