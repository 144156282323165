import { gql, useMutation } from '@apollo/client';
import {
    GlobalSoundRecordingFollow,
    GlobalSoundRecordingFollowVariables,
    GlobalSoundRecordingFollow_followGlobalSoundRecording,
} from '../definitions/GlobalSoundRecordingFollow';
import {
    GlobalSoundRecordingUnfollow,
    GlobalSoundRecordingUnfollowVariables,
    GlobalSoundRecordingUnfollow_unfollowGlobalSoundRecording,
} from '../definitions/GlobalSoundRecordingUnfollow';
import { flattenError } from '../utils';
import globalSoundRecordingFollow from './globalSoundRecordingFollow.gql';
import globalSoundRecordingUnfollow from './globalSoundRecordingUnfollow.gql';

type FollowVariables = GlobalSoundRecordingUnfollowVariables &
    GlobalSoundRecordingFollowVariables;

type FollowMutationResult = [
    (value: boolean) => Promise<unknown>,
    {
        loading: boolean;
        error: Error | undefined;
    }
];

const UPDATE_FRAGMENT = gql`
    fragment UpdateGSR on GlobalSoundRecording {
        isrc
        following
    }
`;

export const useGlobalSoundRecordingFollowMutation = (
    variables: FollowVariables
): FollowMutationResult => {
    const { isrc } = variables;
    const [setFollowing, followingResult] = useMutation<
        GlobalSoundRecordingFollow,
        GlobalSoundRecordingFollowVariables
    >(globalSoundRecordingFollow, {
        variables,
        update: (cache, { data }) => {
            cache.writeFragment({
                data: data?.followGlobalSoundRecording,
                id: isrc,
                fragment: UPDATE_FRAGMENT,
            });
        },
        optimisticResponse: {
            __typename: 'Mutation',
            followGlobalSoundRecording: {
                isrc,
                following: true,
                __typename: 'GlobalSoundRecording',
            } as GlobalSoundRecordingFollow_followGlobalSoundRecording,
        } as GlobalSoundRecordingFollow,
    });

    const [setUnfollowing, unfollowingResult] = useMutation<
        GlobalSoundRecordingUnfollow,
        GlobalSoundRecordingUnfollowVariables
    >(globalSoundRecordingUnfollow, {
        variables,
        update: (cache, { data }) => {
            cache.writeFragment({
                data: data?.unfollowGlobalSoundRecording,
                id: isrc,
                fragment: UPDATE_FRAGMENT,
            });
        },
        optimisticResponse: {
            __typename: 'Mutation',
            unfollowGlobalSoundRecording: {
                isrc,
                following: false,
                __typename: 'GlobalSoundRecording',
            } as GlobalSoundRecordingUnfollow_unfollowGlobalSoundRecording,
        } as GlobalSoundRecordingUnfollow,
    });

    const error = followingResult.error || unfollowingResult.error;
    const loading = followingResult.loading || unfollowingResult.loading;

    return [
        async (value: boolean) => {
            if (value) return await setFollowing({ variables });
            return await setUnfollowing({ variables });
        },
        {
            loading,
            error: error && flattenError(error),
        },
    ];
};
