import { useQuery } from '@apollo/client';
import {
    ProductRankingsQuery,
    ProductRankingsQueryVariables,
    ProductRankingsQuery_publicProduct_chartRankingsHistory,
} from '../../definitions/ProductRankingsQuery';
import { flattenError } from '../../utils';
import productRankingsQuery from './productRankings.gql';

export interface PublicProductChartRankings {
    chartId: string;
    timestamp: string | null;
    position: number;
}

export const selectProductRankings = (
    chartId: string,
    rankings: ProductRankingsQuery_publicProduct_chartRankingsHistory[]
) =>
    rankings.map<PublicProductChartRankings>(({ chartDate, position }) => ({
        chartId,
        timestamp: chartDate,
        position,
    }));

export const useProductRankingsQuery = (
    variables: ProductRankingsQueryVariables
) => {
    const { id, chartId } = variables;
    const { data, loading, error } = useQuery<ProductRankingsQuery>(
        productRankingsQuery,
        { variables: { id, chartId } }
    );

    const product = data?.publicProduct;

    return {
        loading,
        data: product
            ? selectProductRankings(chartId || '', product.chartRankingsHistory)
            : undefined,
        error: error && flattenError(error),
    };
};
