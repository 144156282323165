import React, { FC } from 'react';

const Icon: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
            className={className}
        >
            <g filter="url(#filter0_d_6634_17166)">
                <path
                    fill="#FFA166"
                    d="M25.575 12.689a.5.5 0 01.85 0l3.331 5.392a.5.5 0 00.163.163l5.392 3.33a.5.5 0 010 .851l-5.392 3.331a.5.5 0 00-.163.163l-3.33 5.392a.5.5 0 01-.851 0l-3.331-5.392a.5.5 0 00-.163-.163l-5.392-3.33a.5.5 0 010-.851l5.392-3.331a.5.5 0 00.163-.163l3.33-5.392z"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_d_6634_17166"
                    width="51.094"
                    height="51.097"
                    x="0.453"
                    y="0.451"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="8"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.631373 0 0 0 0 0.4 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_6634_17166"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_6634_17166"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
};

export default Icon;
