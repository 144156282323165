import { FieldPolicy } from '@apollo/client';
import { uniqBy } from 'lodash';
import { AppRecentsQuery_appRecents as AppRecents } from 'src/apollo/definitions/AppRecentsQuery';
import { DEFAULT_APP_RECENTS } from 'src/apollo/queries';
import { DEFAULT_FILTER_PLAYLIST_TYPE } from 'src/constants/playlists';

const MAX_RECENT_SOUND_RECORDINGS = 8;
const MAX_RECENT_LABELS = 3;
const MAX_RECENT_LABEL_MANAGERS = 3;
const MAX_RECENT_PARTICIPANTS = 3;
const MAX_RECENT_COMPARE_PARTICIPANTS = 5;
const MAX_RECENT_CHANNELS = 3;
const MAX_RECENT_COMPARE_VIDEOS = 5;
const MAX_RECENT_SERVICE_TIERS = 2;

const stackAndSlice = <T>(
    existing: T[] | undefined,
    incoming: T[] | undefined,
    idFn: (item: T) => string,
    maxItems: number
) => uniqBy([...(incoming || []), ...(existing || [])], idFn).slice(0, maxItems);

const replaceOrDefault = <T>(
    existing?: T[],
    incoming?: T[],
    defaultValue: T[] = []
) => incoming || existing || defaultValue;

const appRecents: FieldPolicy = {
    read: (existing?: AppRecents): AppRecents => existing || DEFAULT_APP_RECENTS,
    merge: (
        existing: Partial<AppRecents> = {},
        incoming: Partial<AppRecents> = {}
    ): AppRecents => ({
        recentLabels: stackAndSlice(
            existing.recentLabels,
            incoming.recentLabels,
            item => `${item.subaccountId}-${item.vendorId}`,
            MAX_RECENT_LABELS
        ),
        recentLabelManagers: stackAndSlice(
            existing.recentLabelManagers,
            incoming.recentLabelManagers,
            item => item.profileId,
            MAX_RECENT_LABEL_MANAGERS
        ),
        recentParticipants: stackAndSlice(
            existing.recentParticipants,
            incoming.recentParticipants,
            item => item.id,
            MAX_RECENT_PARTICIPANTS
        ),
        recentCompanyBrands: replaceOrDefault(
            existing.recentCompanyBrands,
            incoming.recentCompanyBrands
        ),
        recentServiceTiers: stackAndSlice(
            existing.recentServiceTiers,
            incoming.recentServiceTiers,
            item => item.uuid,
            MAX_RECENT_SERVICE_TIERS
        ),
        recentCompareParticipants: stackAndSlice(
            existing.recentCompareParticipants,
            incoming.recentCompareParticipants,
            item => item.id,
            MAX_RECENT_COMPARE_PARTICIPANTS,
        ),
        recentSoundRecordings: stackAndSlice(
            existing.recentSoundRecordings,
            incoming.recentSoundRecordings,
            item => item.isrc,
            MAX_RECENT_SOUND_RECORDINGS
        ),
        recentChannels: stackAndSlice(
            existing.recentChannels,
            incoming.recentChannels,
            item => item.id,
            MAX_RECENT_CHANNELS
        ),
        recentCompareVideos: stackAndSlice(
            existing.recentCompareVideos,
            incoming.recentCompareVideos,
            item => item.id,
            MAX_RECENT_COMPARE_VIDEOS,
        ),
        recentPlaylistTypeSelection: replaceOrDefault(
            existing.recentPlaylistTypeSelection,
            incoming.recentPlaylistTypeSelection,
            DEFAULT_FILTER_PLAYLIST_TYPE
        ),
    })
};

export default appRecents;
