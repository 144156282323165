import React from 'react';
import { GlyphButton, GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';

export const CLASSNAME = 'MoreDetailsCell';

interface onClickHandlerProp {
    (data: object): void;
}

GridTable.defineColumnTemplate('moreDetails', {
    align: 'center',
    Cell: ({ data, column: { definition } }) => {
        const onClickHandler = get(definition, 'data-onClickHandler') as
            | onClickHandlerProp
            | undefined;

        return (
            <div className={CLASSNAME}>
                <GlyphButton
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        onClickHandler?.(data);
                    }}
                    name="moreDetails"
                    variant="quarternary"
                />
            </div>
        );
    },
});
