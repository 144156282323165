import { useAppRecentsQuery } from '../appRecents';

export const useRecentCompanyBrandsQuery = () => {
    const { data, loading, error, refetch } = useAppRecentsQuery();

    return {
        loading,
        data: data.recentCompanyBrands,
        error,
        refetch,
    };
};
