import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import PlatformIcon from 'src/components/platformIcon';
import { EMPTY_CHAR } from 'src/constants';

GridTable.defineColumnTemplate('store', {
    align: 'center',
    sortable: false,
    Cell: ({ value }) => {
        if (typeof value === 'string') return <PlatformIcon platform={value} />;
        if (typeof value === 'number') return <PlatformIcon storeId={value} />;
        return <span>{EMPTY_CHAR}</span>;
    },
});
