import React, { FC } from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import PlatformIcon from 'src/components/platformIcon';
import { STORE_TIKTOK } from 'src/constants/stores';
import { GridTableColumnTextProps } from './types';

const TerndScoreHeaderCell: FC<GridTableColumnTextProps> = ({ title }) => (
    <div className="TiktokHeaderCell">
        <PlatformIcon
            className="TiktokHeaderCell-icon"
            platform={STORE_TIKTOK}
        />
        <div className="TiktokHeaderCell-title">
            {title}
            <HelpTooltip
                id="trendScore-tooltip"
                message={formatMessage('catalog.tikTokTrendScoreTooltip')}
                tooltipPlacement="right"
            />
        </div>
    </div>
);

export default TerndScoreHeaderCell;
