import { useAppRecentsQuery } from '../appRecents';

export const useRecentOrchardParticipantsQuery = () => {
    const { data, loading, error } = useAppRecentsQuery();

    return {
        loading,
        data: data.recentParticipants,
        error,
    };
};
