import { formatMessage } from '@theorchard/suite-frontend';
import { SongAggregatedRanking } from 'src/apollo/selectors';
import { AggregatedRanking } from 'src/apollo/selectors/types';
import { DATE_FORMAT, SORT_LAST } from 'src/constants';
import { ChartDefinition, FREQUENCY_WEEKLY } from 'src/constants/charts';
import { COUNTRY_GLOBAL, COUNTRY_UNKNOWN } from 'src/constants/countries';
import { getLocalizedName } from 'src/utils/countries';
import { formatDate } from 'src/utils/formatDate';
import { SortDirection } from './types';

export const TERM_BY_CURATOR = 'playlists.info.byCurator';
export const CHANGE_SORTKEY_MAX = 10000;
export const RELEASEDATE_SORTKEY_NULL = '1900-01-01';

export const getPlaylistDescription = (
    curator?: string | null,
    playlistTypeName?: string
) => {
    if (!curator) return playlistTypeName;

    const curatorText = formatMessage(TERM_BY_CURATOR, { curator });
    if (!playlistTypeName) return curatorText;

    return `${curatorText} \u2022 ${playlistTypeName}`;
};

export const PositionExitDateSortKey = (row: AggregatedRanking) => {
    if (row.mostRecentTimestamp && !row.position) {
        const toReturn = formatDate(row.mostRecentTimestamp, DATE_FORMAT);
        if (typeof toReturn === 'string') return toReturn;
    }
    return SORT_LAST;
};

export const TimeOnChartSortKey = (row: SongAggregatedRanking) =>
    row.chart.frequency === FREQUENCY_WEEKLY
        ? row.daysOnChart * 7 + 0.5
        : row.daysOnChart;

export const CountrySortKey = (
    row: { country?: string },
    sortDirection?: SortDirection
) => {
    const isGlobal = row.country === COUNTRY_GLOBAL;
    if (isGlobal) return sortDirection === 'desc' ? 'ZZ' : 'A';

    return getLocalizedName(row.country || COUNTRY_UNKNOWN);
};

export const ChangeSortKey = (row: {
    change?: number | null;
    position: number;
}) => {
    if (row.change === null || row.change === undefined)
        return CHANGE_SORTKEY_MAX - row.position;
    return row.change;
};

export const ReleaseDateSortKey = (row: { releaseDate?: string }) => {
    if (!row.releaseDate) return RELEASEDATE_SORTKEY_NULL;
    return row.releaseDate;
};
export const PeakEntryDateSortKey = (row: { peakTimestamp?: string }) => {
    const toReturn = formatDate(row.peakTimestamp);
    if (typeof toReturn === 'string') return toReturn;
    return RELEASEDATE_SORTKEY_NULL;
};
export const FirstEntryDateSortKey = (row: { firstTimestamp?: string }) => {
    const toReturn = formatDate(row.firstTimestamp);
    if (typeof toReturn === 'string') return toReturn;
    return RELEASEDATE_SORTKEY_NULL;
};

export const ChartSortKey = (row: {
    chart: { definition: ChartDefinition };
}) => {
    const {
        definition: { storeName, name },
    } = row.chart;
    const chartName = `${storeName} ${name}`;
    return chartName;
};
