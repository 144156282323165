import React, { FC } from 'react';
import { formatNumber } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';

interface Props {
    onClick?: () => void;
    className?: string;
    value: number;
    expanded: boolean;
}

export const CLASSNAME = 'ExpandedIconWithNumberCell';
export const CLASSNAME_COUNT = `${CLASSNAME}-count`;
export const CLASSNAME_ICON = `${CLASSNAME}-icon`;

const ExpandedIconWithNumberCell: FC<Props> = ({
    onClick,
    className,
    value,
    expanded,
}) => (
    <div
        role="presentation"
        className={cx(CLASSNAME, className)}
        onClick={onClick}
    >
        <span className={CLASSNAME_COUNT}>{formatNumber(value)}</span>
        <GlyphIcon
            name={expanded ? 'chevronUp' : 'chevronDown'}
            className={CLASSNAME_ICON}
            size={16}
        />
    </div>
);

export default ExpandedIconWithNumberCell;
