import React, { FC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import PlatformIcon from 'src/components/platformIcon';

export interface Props {
    message: string;
    storeId?: number | null;
    platform?: string;
}

export const CLASSNAME = 'NewFeatureHighlight';
const CLASSNAME_CONTAINER = `${CLASSNAME}-container`;
export const TEST_ID = CLASSNAME;

const NewFeatureHighlight: FC<Props> = ({ message, storeId, platform }) => (
    <div className={CLASSNAME} data-testid={TEST_ID}>
        <div className={CLASSNAME_CONTAINER}>
            {platform || storeId ? (
                <PlatformIcon storeId={storeId} platform={platform} />
            ) : null}
            <span>{formatMessage(message)}</span>
        </div>
    </div>
);

export default NewFeatureHighlight;
