import { PlaylistType } from 'src/apollo/definitions/globalTypes';
import { PARAM_COUNTRY_CODE as QUERY_PARAM_COUNTRY_CODE } from 'src/constants';
import {
    FILTER_COUNTRY_CODE,
    FILTER_DATE_PERIOD,
    FILTER_STORE_ID,
    QUERY_PARAM_CURRENT,
    QUERY_PARAM_DATE_PERIOD,
    QUERY_PARAM_PLAYLIST_APPEARANCES,
    QUERY_PARAM_STORE,
} from 'src/constants/params';
import { QueryParamsConfig } from 'src/utils/route';
import { PAST_7 } from './periods';

export const PLAYLIST_STORE_CHART_Y_SCALE = 1.1;
export const PLAYLIST_STORE_CHART_Y_TICKS = 5;

export const DEFAULT_PLAYLISTS_COUNT = 5;
export const DEFAULT_PLAYLISTS_TOTAL_COUNT = 50;

export const PLAYLIST_CSV_EXPORT_COUNT = 200;
export const PLAYLISTS_PAGE_SIZE = 50;
export const PLAYLISTS_TABLE_TOTAL_LIMIT = 1000;

export const STREAMS_THIS_PERIOD_FIELD = 'streamsThisPeriod';
export const COMPLETIONS_THIS_PERIOD_FIELD = 'completionsThisPeriod';

export const PLAYLIST_SORTING_FIELDS = {
    CURRENT_POSITION: 'current_position',
    PEAK_POSITION: 'peak_position',
    LAST_ADDED_ON_DATE: 'last_added_on_date',
    FIRST_PLAY_DATE: 'first_play',
    REMOVED_ON: 'removed_on',
    PLAYLIST_NAME: 'playlist_name',
    PLAYLIST_GENRES: 'playlist_genres',
    PLAYLIST_TRACK_COUNT: 'playlist_track_count',
    PLAYLIST_FOLLOWER_COUNT: 'playlist_follower_count',
    CURATOR_NAME: 'curator_name',
    PLAYLIST_TYPE: 'playlist_type',
    TOP_COUNTRY: 'top_country',
    STREAMS_ALL_TIME: 'streams_all_time',
    STREAMS_LAST_365_DAYS: 'streams_last_365_days',
    STREAMS_LAST_183_DAYS: 'streams_last_183_days',
    STREAMS_LAST_90_DAYS: 'streams_last_90_days',
    STREAMS_LAST_28_DAYS: 'streams_last_28_days',
    STREAMS_LAST_14_DAYS: 'streams_last_14_days',
    STREAMS_LAST_7_DAYS: 'streams_last_7_days',
    STREAMS_LAST_1_DAY: 'streams_last_1_day',
    COMPLETIONS_ALL_TIME: 'completion_rate_all_time',
    COMPLETIONS_LAST_365_DAYS: 'completion_rate_last_365_days',
    COMPLETIONS_LAST_28_DAYS: 'completion_rate_last_28_days',
    COMPLETIONS_LAST_183_DAYS: 'completion_rate_last_183_days',
    COMPLETIONS_LAST_7_DAYS: 'completion_rate_last_7_days',
    POSITION_CHANGE: 'position_change',
    DAYS_ON_PLAYLIST: 'days_on_playlist',
    TOTAL_PLAYLIST_STREAMS_LAST_7_DAYS: 'total_playlist_streams_last_7_days',
    TOTAL_PLAYLIST_STREAMS_LAST_28_DAYS: 'total_playlist_streams_last_28_days',
};

export const PLAYLIST_STOREFRONT_SORTING_FIELDS = {
    CURRENT_POSITION: 'current_position',
    PEAK_POSITION: 'peak_position',
    LAST_ADDED_ON_DATE: 'last_added_on_date',
    FIRST_PLAY_DATE: 'first_play',
    REMOVED_ON: 'removed_on',
    PLAYLIST_TRACK_COUNT: 'playlist_track_count',
    STREAMS_ALL_TIME: 'streams_all_time',
    STREAMS_LAST_365_DAYS: 'streams_last_365_days',
    STREAMS_LAST_183_DAYS: 'streams_last_183_days',
    STREAMS_LAST_28_DAYS: 'streams_last_28_days',
    STREAMS_LAST_7_DAYS: 'streams_last_7_days',
    COMPLETION_RATE_ALL_TIME: 'completion_rate_all_time',
    COMPLETION_RATE_LAST_365_DAYS: 'completion_rate_last_365_days',
    COMPLETION_RATE_LAST_183_DAYS: 'completion_rate_last_183_days',
    COMPLETION_RATE_LAST_28_DAYS: 'completion_rate_last_28_days',
    COMPLETION_RATE_LAST_7_DAYS: 'completion_rate_last_7_days',
    DAYS_ON_PLAYLIST: 'days_on_playlist',
};

export const QUERY_PARAM_PLAYLIST_ID = 'playlistId';
export const QUERY_PARAM_SONG_ID = 'songId';
export const QUERY_PARAM_PLAYLIST_TYPE = 'type';
export const QUERY_PARAM_CURATOR = 'curator';
export const QUERY_PARAM_STOREFRONT = 'storefront';
export const QUERY_PARAM_PLAYLIST_STORE_ID = 'playlistStoreId';
export const QUERY_PARAM_PLAYLIST_SONG_ID = 'playlistSongId';

export const FILTER_SONG_ID = 'selectedSongId';
export const FILTER_STOREFRONT = 'selectedStorefront';
export const FILTER_PLAYLIST_STORE_ID = 'selectedStoreId';
export const FILTER_PLAYLIST_SONG_ID = 'selectedPlaylistSongId';
export const FILTER_CURRENT = 'currentFilter';
export const FILTER_PLAYLIST_APPEARANCES = 'playlistAppearancesFilter';
export const FILTER_PLAYLIST_ID = 'selectedPlaylistId';
export const FILTER_PLAYLIST_TYPE = 'playlistTypeFilter';
export const FILTER_CURATOR = 'curatorFilter';
export const FILTER_ORDER_DIR = 'orderDir';
export const FILTER_ORDER_BY = 'orderBy';

export const RADIO_STATIONS_TYPE = 'RADIO_STATIONS';
export const DEFAULT_FILTER_PLAYLIST_TYPE = [
    PlaylistType.ALGORITHMIC,
    PlaylistType.CURATED,
    PlaylistType.EDITORIAL,
    PlaylistType.NEW_MUSIC_FRIDAY,
    PlaylistType.PERSONALIZED,
];

export const PLAYLIST_PAGE_QUERY_PARAMS: QueryParamsConfig = {
    [FILTER_STORE_ID]: { name: QUERY_PARAM_STORE, type: 'number[]' },
    [FILTER_CURRENT]: { name: QUERY_PARAM_CURRENT, default: 'false' },
    [FILTER_DATE_PERIOD]: { name: QUERY_PARAM_DATE_PERIOD, default: PAST_7 },
    [FILTER_COUNTRY_CODE]: { name: QUERY_PARAM_COUNTRY_CODE, type: 'string[]' },
    [FILTER_PLAYLIST_ID]: { name: QUERY_PARAM_PLAYLIST_ID, type: 'string' },
    [FILTER_SONG_ID]: { name: QUERY_PARAM_SONG_ID, type: 'string' },
    [FILTER_PLAYLIST_SONG_ID]: {
        name: QUERY_PARAM_PLAYLIST_SONG_ID,
        type: 'string',
    },
    [FILTER_PLAYLIST_TYPE]: {
        name: QUERY_PARAM_PLAYLIST_TYPE,
        type: 'string[]',
        default: DEFAULT_FILTER_PLAYLIST_TYPE,
    },
    [FILTER_STOREFRONT]: { name: QUERY_PARAM_STOREFRONT, type: 'string' },
    [FILTER_PLAYLIST_STORE_ID]: {
        name: QUERY_PARAM_PLAYLIST_STORE_ID,
        type: 'number',
    },
    [FILTER_CURATOR]: { name: QUERY_PARAM_CURATOR, type: 'string[]' },
    [FILTER_ORDER_DIR]: { name: FILTER_ORDER_DIR, type: 'string' },
    [FILTER_ORDER_BY]: { name: FILTER_ORDER_BY, type: 'string' },
    [FILTER_PLAYLIST_APPEARANCES]: {
        name: QUERY_PARAM_PLAYLIST_APPEARANCES,
        type: 'string',
    },
};
