import React, { FC } from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage, formatNumber } from '@theorchard/suite-frontend';
import PopoverFooter from 'src/components/sourcesStatus/popoverFooter';
import SourcesStatusTable from 'src/components/sourcesStatus/sourcesTable';
import { SOURCE_STREAMS, Source, SourceType } from 'src/constants/stores';
import { ColumnConfig } from './types';

export const CLASSNAME = 'InsightsGridTableSummaryRow';

type SummaryRowProps = Parameters<typeof GridTable.SummaryBar>[number];

export interface Props extends Omit<SummaryRowProps, 'onExport'> {
    itemsCount?: number;
    sources?: Source[];
    sourcesTypes?: SourceType[];
    onExport?: (visibleColumns: string[]) => void;
}

const GridTableSummaryRow: FC<Props> = ({
    itemsCount,
    sources = [],
    sourcesTypes = [SOURCE_STREAMS],
    showCustomize = true,
    showExport = true,
    showClearFilters = false,
    onClearFilters,
    onExport,
    ...rest
}) => {
    const handleExportCsv = onExport
        ? (columns: ColumnConfig[]) => {
              const visibleColumns = columns
                  .filter(
                      ({ visibility }) =>
                          visibility === 'visible' ||
                          visibility === 'visible-locked'
                  )
                  .map(({ name }) => name);
              onExport(visibleColumns);
          }
        : undefined;

    const showSource = sources.length > 0;

    return (
        <GridTable.SummaryBar
            SourceComponent={
                showSource
                    ? () => (
                          <>
                              <SourcesStatusTable
                                  sources={sources}
                                  sourceTypes={sourcesTypes}
                              />
                              <PopoverFooter />
                          </>
                      )
                    : undefined
            }
            totalCount={formatMessage('catalog.filter.results', {
                count: formatNumber(itemsCount),
            })}
            showExport={showExport}
            showSource={showSource}
            showCustomize={showCustomize}
            showTotalCount={Boolean(itemsCount)}
            showClearFilters={showClearFilters}
            onClearFilters={onClearFilters}
            onExport={handleExportCsv}
            {...rest}
        />
    );
};

export default GridTableSummaryRow;
