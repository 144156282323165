import React from 'react';
import { SkeletonLoader } from '@theorchard/suite-components';
import cx from 'classnames';
import { LoadingIndicatorCommonProps } from 'src/components/table/cells/loadingIndicators/loadingIndicators';

const CLASSNAME = 'entityLoadingIndicator';

const CLASSNAME_WITH_LABEL = `${CLASSNAME}--with-label`;
const CLASSNAME_WITH_META_DATA = `${CLASSNAME}--with-meta-data`;

interface EntityLoadingIndicatorProps extends LoadingIndicatorCommonProps {
    withLabel?: boolean;
    withMetaData?: boolean;
    type?: 'landscape';
}

const EntityLoadingIndicator: React.FC<EntityLoadingIndicatorProps> = ({
    withLabel = true,
    withMetaData = false,
    type,
    className,
}) => (
    <div
        className={cx(CLASSNAME, className, {
            [CLASSNAME_WITH_LABEL]: withLabel,
            [CLASSNAME_WITH_META_DATA]: withMetaData,
            [`${CLASSNAME}--${type}`]: type,
        })}
    >
        <SkeletonLoader className="artwork" />
        <SkeletonLoader className="name" />
        {withLabel && <SkeletonLoader className="label" />}
        {withMetaData && <SkeletonLoader className="meta-data" />}
    </div>
);

export default EntityLoadingIndicator;
