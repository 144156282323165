import { useMemo } from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { selectParticipantPlaylists } from 'src/apollo/selectors/playlist';
import {
    GlobalParticipantPlaylistPlacements as QueryData,
    GlobalParticipantPlaylistPlacementsVariables as QueryVars,
} from '../../definitions/GlobalParticipantPlaylistPlacements';
import query from './globalParticipantPlaylistPlacements.gql';

export const useGlobalParticipantPlaylistPlacementsQuery = (
    variables: QueryVars,
    dateFilter?: string,
    options?: QueryHookOptions<QueryData, QueryVars>
) => {
    const { data, loading, error } = useQuery<QueryData, QueryVars>(query, {
        variables,
        ...options,
    });

    const memoizedData = useMemo(
        () => data && selectParticipantPlaylists(data, dateFilter),
        [data, dateFilter]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
