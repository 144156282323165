import React from 'react';
import { Card } from '@theorchard/suite-components';
import { ButtonProps } from '@theorchard/suite-components/src/components/bootstrap/bootstrap';
import cx from 'classnames';
import { ERROR_TYPES } from 'src/constants';
import { SetParamsCallback } from 'src/utils/route';

export const CLASSNAME = 'Error';

export const CLASSNAME_FULL_SIZE_MODE = `${CLASSNAME}-fullSize`;

export const CLASSNAME_420_HEIGHT_MODE = `${CLASSNAME}-height420`;
export const CLASSNAME_260_HEIGHT_MODE = `${CLASSNAME}-height260`;
export const CLASSNAME_NO_BORDER = `${CLASSNAME}-no-border`;

export const CLASSNAME_CARD_BODY = `${CLASSNAME}CardBody`;

export const ERROR_MODES = {
    FULL_SIZE: 'fullSize',
    HEIGHT_260: 'height260',
    HEIGHT_420: 'height420',
};

export type TypeKeys = keyof typeof ERROR_TYPES;

export type Type = (typeof ERROR_TYPES)[TypeKeys];

export type ModeKeys = keyof typeof ERROR_MODES;

export type Mode = (typeof ERROR_MODES)[ModeKeys];

export type Size = 100 | 200;

export type ErrorConfig = {
    [key in Type]: React.ElementType;
};

export interface SharedErrorProps {
    size?: Size;
    noBorder?: boolean;
    className?: string;
    buttonProps?: ButtonProps;
}

export interface ErrorProps extends SharedErrorProps {
    type?: Type;
    mode?: Mode;
    errorConfig: ErrorConfig;
    setParams?: SetParamsCallback;
}

const Error: React.FC<ErrorProps> = ({
    errorConfig = {},
    type = ERROR_TYPES.ERROR,
    mode,
    size = 100,
    setParams,
    noBorder = false,
    className,
    buttonProps,
}) => {
    const isFullSizeMode = mode === ERROR_MODES.FULL_SIZE;
    const isHeight420Mode = mode === ERROR_MODES.HEIGHT_420;
    const isHeight260Mode = mode === ERROR_MODES.HEIGHT_260;
    const ErrorComponent = errorConfig[type];

    return (
        <Card
            className={cx(CLASSNAME, className, `${CLASSNAME}-${type}`, {
                [CLASSNAME_FULL_SIZE_MODE]: isFullSizeMode,
                [CLASSNAME_260_HEIGHT_MODE]: isHeight260Mode,
                [CLASSNAME_420_HEIGHT_MODE]: isHeight420Mode,
                [CLASSNAME_NO_BORDER]: noBorder,
            })}
        >
            <Card.Body className={CLASSNAME_CARD_BODY}>
                <ErrorComponent
                    size={size}
                    buttonProps={buttonProps}
                    setParams={setParams}
                />
            </Card.Body>
        </Card>
    );
};

export default Error;
