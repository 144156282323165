import React, { FC } from 'react';
import { GridTable, GridTableCellProps } from '@theorchard/suite-components';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { SongLinkToCompareProps } from 'src/components/table/cells/types';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_COMPARE_SONG } from 'src/constants';
import { INSIGHTS_FIN_LABEL_MODE } from 'src/constants/featuresFlags';
import { CLASSNAME as CATALOG_SONGS_TABLE } from 'src/pages/catalog/pages/songs/catalogGridTable';

const SongLinkToCompareCell: FC<
    GridTableCellProps<SongLinkToCompareProps>
> = props => {
    const {
        data: { isrc, songName, name, ids },
        context: {
            props: { name: tableName },
        },
    } = props;
    const isFinModeEnabled = useFeatureFlag(INSIGHTS_FIN_LABEL_MODE);

    return (
        <TruncatedText
            value={songName ?? name}
            useLink={Boolean(isrc)}
            route={ROUTE_COMPARE_SONG}
            params={{ isrc, ids }}
            shouldUseSearchQuery={
                isFinModeEnabled && tableName === CATALOG_SONGS_TABLE
            }
        />
    );
};

GridTable.defineColumnTemplate('songLinkToCompare', {
    align: 'left',
    Cell: SongLinkToCompareCell,
});

export default SongLinkToCompareCell;
