import { CDN_MANIFEST } from '@theorchard/suite-icons';
import {
    STORE_AMAZON,
    STORE_AMAZON_MUSIC,
    STORE_APPLE_MUSIC,
    STORE_GOOGLE,
    STORE_GOOGLE_PLAY,
    STORE_ITUNES,
    STORE_LINE_MUSIC,
    STORE_LINE_MUSIC_LONG,
    STORE_TIKTOK,
    STORE_YOUTUBE,
} from 'src/constants/stores';

export const STORES_MANIFEST = CDN_MANIFEST['stores'];
export const AVAILABLE_OSP_STORES_NAMES_LIST = Object.keys(STORES_MANIFEST);
export const AVAILABLE_OSP_STORES_NAMES =
    AVAILABLE_OSP_STORES_NAMES_LIST.reduce(
        (acc: Record<string, string>, key) => {
            acc[key] = key; // key is assigned as the value
            return acc;
        },
        {}
    );

export const STORE_ICON_NAME_BY_NAME: Record<string, string> = {
    ...AVAILABLE_OSP_STORES_NAMES,
    [STORE_APPLE_MUSIC]: AVAILABLE_OSP_STORES_NAMES[STORE_APPLE_MUSIC],
    [STORE_ITUNES]: AVAILABLE_OSP_STORES_NAMES[STORE_APPLE_MUSIC],
    [STORE_AMAZON]: AVAILABLE_OSP_STORES_NAMES[STORE_AMAZON],
    [STORE_AMAZON_MUSIC]: AVAILABLE_OSP_STORES_NAMES[STORE_AMAZON],
    [STORE_GOOGLE]: AVAILABLE_OSP_STORES_NAMES[STORE_GOOGLE],
    [STORE_GOOGLE_PLAY]: AVAILABLE_OSP_STORES_NAMES[STORE_GOOGLE],
    [STORE_TIKTOK]: AVAILABLE_OSP_STORES_NAMES.tikTok,
    [STORE_LINE_MUSIC_LONG]: AVAILABLE_OSP_STORES_NAMES.lineMusic,
    [STORE_LINE_MUSIC]: AVAILABLE_OSP_STORES_NAMES.lineMusic,
    [STORE_YOUTUBE]: AVAILABLE_OSP_STORES_NAMES.youTube,
};
