import { useState, useCallback } from 'react';

export function useToggle(initialState = false) {
    const [state, changeState] = useState(initialState);

    // Define and memorize toggler function in case we pass down the component,
    // This function change the boolean value to it's opposite value
    const toggle = useCallback(() => changeState(prevState => !prevState), []);

    const result: [boolean, () => void] = [state, toggle];

    return result;
}
