import { useApolloClient } from '@apollo/client';
import {
    selectSoundRecordingRelatedContent,
    selectRelatedContentSources,
} from 'src/apollo/selectors';
import {
    SoundRecordingRelatedContent,
    SoundRecordingRelatedContentVariables,
} from '../../definitions/SoundRecordingRelatedContent';
import { useParallelQueries } from '../../utils';
import soundRecordingRelatedContentQuery from './soundRecordingRelatedContent.gql';

export const useSoundRecordingsRelatedContentQuery = (
    variables: SoundRecordingRelatedContentVariables[]
) => {
    const client = useApolloClient();
    const { data, loading, error } = useParallelQueries<
        SoundRecordingRelatedContent,
        SoundRecordingRelatedContentVariables
    >(soundRecordingRelatedContentQuery, { variables, client });

    const relatedContent = data?.map(result => {
        const isrc = result?.globalSoundRecordingByIsrc?.isrc;
        if (!isrc) return null;

        return {
            data: selectSoundRecordingRelatedContent(
                result.globalSoundRecordingByIsrc ?? undefined,
                isrc
            ),
            isrc,
        };
    });
    const sources = selectRelatedContentSources(
        data?.[0]?.globalSoundRecordingByIsrc
    );

    return {
        loading,
        data: {
            relatedContent,
            sources,
        },
        error,
    };
};
