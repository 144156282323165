import AlbumDownloadActiveIcon from './albumDownloadActiveIcon';
import AlbumDownloadInactiveIcon from './albumDownloadInactiveIcon';
import DownloadsActiveIcon from './downloadActiveIcon';
import DownloadsInactiveIcon from './downloadInactiveIcon';
import StreamActiveIcon from './streamActiveIcon';
import StreamInactiveIcon from './streamInactiveIcon';

export const offerTypesIcons = {
    'S-Y': StreamActiveIcon,
    'S-N': StreamInactiveIcon,
    'TD-Y': DownloadsActiveIcon,
    'TD-N': DownloadsInactiveIcon,
    'AD-Y': AlbumDownloadActiveIcon,
    'AD-N': AlbumDownloadInactiveIcon,
};
