import React, { useState } from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import YoutubePolicyModal from 'src/components/youtubePolicyModal';
import { EMPTY_CHAR } from 'src/constants';

const BlockedIcon = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="16" height="16" fill="transparent" />
        <circle cx="8" cy="8" r="6" stroke="#E84031" strokeWidth="2" />
        <path d="M12 4L4 12" stroke="#E84031" strokeWidth="2" />
    </svg>
);

const TrackedIcon = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="16" height="16" fill="transparent" />
        <path
            d="M2 13L6 6.5L10 9.5L14 3"
            stroke="#4A4A4A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const MonetizedIcon = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="16" height="16" fill="transparent" />
        {/* eslint-disable-next-line max-len */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 13H10C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7H5.98999C5.43771 7 4.98999 6.55229 4.98999 6C4.98999 5.44771 5.43771 5 5.98999 5H12V3H9V1H7V3H6C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9H10C10.5523 9 11 9.44771 11 10C11 10.5523 10.5523 11 10 11H4V13H7V15H9V13Z"
            fill="#17AD41"
        />
    </svg>
);

GridTable.defineColumnTemplate('claimPolicy', {
    align: 'center',
    sortable: false,
    Cell: ({ data: { channel, video, claimPolicy } }) => {
        const [showModal, setShowModal] = useState(false);

        const handleModalClick = () => setShowModal(true);
        const handleHideModal = () => setShowModal(false);

        const renderPolicy = (
            policy: string[],
            icon: React.ReactNode,
            term: string
        ) =>
            policy.length > 0 && (
                <span>
                    {icon}
                    {policy.length === 249
                        ? ` ${formatMessage(term)}`
                        : policy.length}
                </span>
            );

        return (
            <div className="ClaimPolicyCell">
                {claimPolicy.monetize.length === 0 &&
                claimPolicy.track.length === 0 &&
                claimPolicy.block.length === 0 ? (
                    <span>{EMPTY_CHAR}</span>
                ) : (
                    <>
                        <div
                            className="ClaimPolicyCell-policy"
                            role="button"
                            tabIndex={0}
                            onClick={handleModalClick}
                            onKeyDown={handleModalClick}
                        >
                            {renderPolicy(
                                claimPolicy.monetize,
                                MonetizedIcon,
                                'video.monetizePolicy'
                            )}
                            {renderPolicy(
                                claimPolicy.track,
                                TrackedIcon,
                                'video.trackPolicy'
                            )}
                            {renderPolicy(
                                claimPolicy.block,
                                BlockedIcon,
                                'video.blockPolicy'
                            )}
                        </div>
                        {showModal && (
                            <YoutubePolicyModal
                                isOpen
                                hideModal={handleHideModal}
                                {...claimPolicy}
                                video={video}
                                channel={channel}
                            />
                        )}
                    </>
                )}
            </div>
        );
    },
});
