import React, { FC } from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { GlyphIcon } from '@theorchard/suite-icons';
import { SourceError } from 'src/constants/stores';
import { getMissingDatesTooltip, getWarningTooltipId } from './utils';

const CLASSNAME = 'MissingDates';
const TOOLTIP_OVERLAY_CLASSNAME = `${CLASSNAME}-tooltip-overlay`;

interface Props {
    storeId: number;
    error?: SourceError;
    missingDates?: string[];
}

const MissingDatesHint: FC<Props> = ({ storeId, error, missingDates }) => {
    if (error || !missingDates) return null;

    const tooltipId = getWarningTooltipId(storeId);
    const tooltipText = getMissingDatesTooltip(missingDates);

    return (
        <>
            <HelpTooltip
                id={tooltipId}
                message={tooltipText}
                overlayClassName={TOOLTIP_OVERLAY_CLASSNAME}
            >
                <GlyphIcon name="info" size={12} />
            </HelpTooltip>
        </>
    );
};

export default MissingDatesHint;
