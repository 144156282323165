import querystring from 'querystring';
import { useCallback } from 'react';
import { get } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDeepMemo } from 'src/apollo/utils';
import { ROUTES } from 'src/constants';

interface ApolloObject extends Record<string, string | string[]> {
    __typename: string;
}

export type ParamValue = string | number | (string | number)[];
export type SetParamsCallback = (
    name: string | Record<string, ParamValue | undefined>,
    value?: ParamValue
) => void;

export type QueryParamsConfig = Record<string, ParamConfig>;

interface ParamConfig {
    name: string;
    type?: 'string' | 'string[]' | 'number' | 'number[]';
    default?: string | string[] | number | number[];
}

interface UseParamsOptions<T> {
    defaultParams?: Partial<T>;
    queryParams?: QueryParamsConfig;
}

const PARAM_RGX = /:([a-zA-Z0-9]*)\??/gi;
const OPTIONAL_PARAM_RGX = /:[a-zA-Z0-9]*\?/gi;

export const ensureArray = (param?: ParamValue) => {
    if (!param) return [];
    return Array.isArray(param)
        ? param.map(p => p.toString())
        : [param.toString()];
};

export const ensureNumberArray = (param?: ParamValue) => {
    if (!param) return [];
    const array = Array.isArray(param) ? param : [param];
    return array.map(value =>
        typeof value === 'string' ? Number.parseInt(value, 10) : value
    );
};

export const ensureString = (param?: ParamValue) => {
    if (!param) return undefined;
    const single = !Array.isArray(param) ? param : param[0];
    if (typeof single === 'string') return single;
    return single?.toString();
};

export const ensureNumber = (param?: ParamValue) => {
    const value = ensureString(param);
    return value ? Number.parseInt(value, 10) : undefined;
};

export const createHref = (route: string, parameters = {}) => {
    const { __typename: type, ...props } = parameters as ApolloObject;

    const path = route
        .replace(PARAM_RGX, (match: string, name: string) => {
            const value = props[name];
            if (value === undefined) return match;
            delete props[name];
            return ensureArray(value).join(',');
        })
        .replace(OPTIONAL_PARAM_RGX, '');

    if (path.includes(':'))
        throw new Error(
            `Failed interpolating route. Result still contains required params: "${path}"`
        );

    Object.keys(props).forEach(key => {
        if (
            props[key] === undefined ||
            props[key] === null ||
            !props[key]?.toString()?.length
        )
            delete props[key];
    });

    const query = querystring.stringify(props);
    if (query) return `${path}?${query}`;
    return path;
};

export const createHrefWithSearchParams = (route: string, parameters = {}) => {
    const { __typename: type, ...props } = parameters as ApolloObject;

    const path = route
        .replace(PARAM_RGX, (match: string, name: string) => {
            const value = props[name];
            if (value === undefined) return match;
            delete props[name];
            return ensureArray(value).join(',');
        })
        .replace(OPTIONAL_PARAM_RGX, '');

    if (path.includes(':'))
        throw new Error(
            `Failed interpolating route. Result still contains required params: "${path}"`
        );

    Object.keys(props).forEach(key => {
        if (
            props[key] === undefined ||
            props[key] === null ||
            !props[key]?.toString()?.length
        )
            delete props[key];
    });

    const query = querystring.stringify(props);
    return { path, search: query };
};

const getParamValue = (config: ParamConfig, value: string | string[]) => {
    if (config.default && !value) return config.default;
    if (config.type === 'number[]') return ensureNumberArray(value);
    if (config.type === 'string[]') return ensureArray(value);
    if (config.type === 'number') return ensureNumber(value);
    return value;
};

export const parseParams = (
    search: string,
    config?: QueryParamsConfig
): Record<string, ParamValue | undefined> => {
    const rawQueryParams = querystring.parse(search.replace('?', ''));

    if (!config) return rawQueryParams;

    const configParamsNames: string[] = [];
    const parsedConfigParams = Object.keys(config).reduce((map, key) => {
        const paramConfig = get(config, key);
        const rawValue = rawQueryParams[paramConfig.name];
        const value = getParamValue(paramConfig, rawValue || '');

        configParamsNames.push(paramConfig.name);
        return { ...map, [key]: value };
    }, {});

    const existingRawParams = Object.keys(rawQueryParams).reduce(
        (result, key) =>
            configParamsNames.includes(key)
                ? result
                : {
                      ...result,
                      [key]: rawQueryParams[key],
                  },
        {}
    );

    return {
        ...existingRawParams,
        ...parsedConfigParams,
    };
};

export const useRouteParams = <T = Record<string, string | string[]>>(
    route?: string,
    options: UseParamsOptions<T> = {}
): [T, SetParamsCallback] => {
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const { defaultParams = {}, queryParams } = options;

    const memoQueryParams = useDeepMemo(() => queryParams, [queryParams]);
    const allParams = useDeepMemo(() => {
        const parsedParams = parseParams(location.search, memoQueryParams);
        return {
            ...defaultParams,
            ...params,
            ...parsedParams,
        } as T;
    }, [location.search, memoQueryParams, defaultParams, params]);

    const setParams: SetParamsCallback = useCallback(
        (parameter, parameterValue) => {
            if (route) {
                const newParams =
                    typeof parameter === 'string'
                        ? { [parameter]: parameterValue }
                        : parameter;
                const updatedParams = {
                    ...defaultParams,
                    ...params,
                    ...parseParams(history.location.search, memoQueryParams),
                    ...newParams,
                };
                const aliasedParams = Object.keys(updatedParams).reduce(
                    (map, key) => {
                        const config = get(memoQueryParams, key);
                        const value = get(updatedParams, key);

                        return {
                            ...map,
                            [config?.name || key]:
                                value === config?.default ? undefined : value,
                        };
                    },
                    {}
                );

                const url = createHref(route, aliasedParams);
                if (url !== history.location.pathname + history.location.search)
                    history.replace(url);
            }
        },
        [route, defaultParams, params, memoQueryParams, history]
    );

    return [allParams, setParams];
};

export const getLocationInfo = (location: Location) => {
    const { pathname: path } = location;
    return {
        path,
        name: path.substr(1).split('/').shift() || '/',
        url: location.href,
        search: location.search,
    };
};

export const getInsightsTitle = (title?: string | null) =>
    title ? `${title} - Insights` : undefined;

export const getPageName = () => {
    const { name, path } = getLocationInfo(window.location);

    if (path.includes('subaccount')) return 'subaccount';
    if (path.includes('compare') && path.includes('artist'))
        return 'compareArtist';
    if (path.includes('compare') && path.includes('song')) return 'compareSong';

    return name as keyof typeof ROUTES;
};

export const getRoute = () => {
    const pageName = getPageName();

    return ROUTES[pageName];
};
