import { useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import {
    TopProductResultsByFinLabelQueryVariables as QueryVarsByFinLabel,
    TopProductResultsByFinLabelQuery as QueryByFinLabel,
} from 'src/apollo/definitions/TopProductResultsByFinLabelQuery';
import {
    TopProductResultsQueryVariables as QueryVars,
    TopProductResultsQuery as Query,
} from 'src/apollo/definitions/TopProductResultsQuery';
import topProductResultsQuery from 'src/apollo/queries/product/topProductResults.gql';
import topProductResultsByFinLabelQuery from 'src/apollo/queries/product/topProductResultsByFinLabel.gql';
import { selectTopProductResults } from 'src/apollo/selectors';
import { useEmployeeIdentity, usePrefetchQuery } from 'src/apollo/utils';
import { useDistributorsFilterOptions } from 'src/components/distributorFilter/utils';
import {
    PRODUCTS_PAGE_SIZE,
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_DIR,
} from 'src/pages/catalog/pages/products/constants';

export const useTopProductResultsQuery = (
    variables: QueryVars,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        topProductResultsQuery,
        { variables, skip }
    );

    const memoizedData = useMemo(
        () => data && selectTopProductResults(data),
        [data]
    );
    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useTopProductResultsByFinLabelQuery = (
    variables: QueryVarsByFinLabel,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<
        QueryByFinLabel,
        QueryVarsByFinLabel
    >(topProductResultsByFinLabelQuery, { variables, skip });

    const memoizedData = useMemo(
        () => data && selectTopProductResults(data),
        [data]
    );
    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchTopProductsQuery = (delay = 0) => {
    const { isEmployee = false } = useEmployeeIdentity();
    const { selectedValue } = useDistributorsFilterOptions();

    usePrefetchQuery<Query, QueryVars>(topProductResultsQuery, {
        variables: {
            limit: PRODUCTS_PAGE_SIZE,
            orderBy: DEFAULT_SORT_FIELD,
            orderDir: DEFAULT_SORT_DIR,
            countries: [],
            distributors: selectedValue,
            globalParticipantIds: [],
            isEmployee,
            labelIds: [],
            multiProduct: false,
            subaccountIds: [],
        },
        delay,
    });
};

interface PrefetchQueryOptions {
    skip: boolean;
}

export const prefetchTopProductsQuery = async (
    client: ApolloClient<object>,
    variables: Partial<QueryVars> = {},
    options: PrefetchQueryOptions = { skip: false }
) => {
    const { skip } = options;

    return (
        !skip &&
        (await client.query({
            query: topProductResultsQuery,
            variables: {
                limit: PRODUCTS_PAGE_SIZE,
                orderBy: DEFAULT_SORT_FIELD,
                orderDir: DEFAULT_SORT_DIR,
                countries: [],
                globalParticipantIds: [],
                isEmployee: false,
                labelIds: [],
                multiProduct: false,
                subaccountIds: [],
                ...variables,
            },
        }))
    );
};
