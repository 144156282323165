import { useQuery } from '@apollo/client';
import { selectVideoCountries } from 'src/apollo/selectors';
import {
    VideoAnalyticsTopCountriesQuery,
    VideoAnalyticsTopCountriesQueryVariables,
} from '../../definitions/VideoAnalyticsTopCountriesQuery';
import { flattenError } from '../../utils';
import videoCountryViewsQuery from './videoAnalyticsTopCountries.gql';

export interface TopVideoCountry {
    code: string;
    value: number;
}

export const useVideoAnalyticsTopCountriesQuery = (
    variables: VideoAnalyticsTopCountriesQueryVariables,
    selectedCountries?: string[]
) => {
    const { data, loading, error } = useQuery<
        VideoAnalyticsTopCountriesQuery,
        VideoAnalyticsTopCountriesQueryVariables
    >(videoCountryViewsQuery, { variables });

    return {
        loading,
        data: selectVideoCountries(
            data?.video?.analytics.topCountries.countries,
            selectedCountries
        ),
        error: error && flattenError(error),
    };
};
