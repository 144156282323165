import React from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { SharedErrorProps } from 'src/components/error/error';
import NoResultsIcon100 from 'src/components/infoMessageV2/icons/100x100/noResultsIcon100';
import NoResultsIcon200 from 'src/components/infoMessageV2/icons/200x200/noResultsIcon200';
import InfoMessage from 'src/components/infoMessageV2/infoMessageV2';

const NoResults: React.FC<SharedErrorProps> = ({ size = 100 }) => {
    const title = formatMessage('error.noResultsFiltering.title');
    const message = formatMessage('error.noResultsFiltering.message');
    let Icon;

    switch (size) {
        default:
        case 100:
            Icon = NoResultsIcon100;
            break;
        case 200:
            Icon = NoResultsIcon200;
            break;
    }

    return <InfoMessage title={title} message={message} Icon={Icon} />;
};

export default NoResults;
