import { gql, useMutation } from '@apollo/client';
import {
    FollowChannel,
    FollowChannelVariables,
    FollowChannel_followChannel,
} from '../definitions/FollowChannel';
import {
    UnfollowChannel,
    UnfollowChannelVariables,
    UnfollowChannel_unfollowChannel,
} from '../definitions/UnfollowChannel';
import { flattenError } from '../utils';
import followChannel from './followChannel.gql';
import unfollowChannel from './unfollowChannel.gql';

type FollowCVariables = FollowChannelVariables & UnfollowChannelVariables;

type FollowCMutationResult = [
    (value: boolean) => Promise<unknown>,
    { loading: boolean; error: Error | undefined }
];

const UPDATE_FRAGMENT = gql`
    fragment UpdateChannel on Channel {
        following
    }
`;

export const useFollowChannelMutation = (
    variables: FollowCVariables
): FollowCMutationResult => {
    const { channelId } = variables;

    const [setFollowing, followingResult] = useMutation<
        FollowChannel,
        FollowChannelVariables
    >(followChannel, {
        variables,
        update: (cache, { data }) => {
            cache.writeFragment({
                data: data?.followChannel,
                id: channelId,
                fragment: UPDATE_FRAGMENT,
            });
        },
        optimisticResponse: {
            __typename: 'Mutation',
            followChannel: {
                channelId,
                following: true,
                __typename: 'Channel',
            } as FollowChannel_followChannel,
        } as FollowChannel,
    });

    const [setUnfollowing, unfollowingResult] = useMutation<
        UnfollowChannel,
        UnfollowChannelVariables
    >(unfollowChannel, {
        variables,
        update: (cache, { data }) => {
            cache.writeFragment({
                data: data?.unfollowChannel,
                id: channelId,
                fragment: UPDATE_FRAGMENT,
            });
        },
        optimisticResponse: {
            __typename: 'Mutation',
            unfollowChannel: {
                channelId,
                following: false,
                __typename: 'Channel',
            } as UnfollowChannel_unfollowChannel,
        } as UnfollowChannel,
    });

    const error = followingResult.error || unfollowingResult.error;
    const loading = followingResult.loading || unfollowingResult.loading;

    return [
        async (value: boolean) => {
            if (value) return await setFollowing({ variables });
            return await setUnfollowing({ variables });
        },
        {
            loading,
            error: error && flattenError(error),
        },
    ];
};
