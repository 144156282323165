import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { compact } from 'lodash';
import {
    SongMetadataQuery,
    SongMetadataQueryVariables,
} from '../../definitions/SongMetadataQuery';
import { selectSongMetadata } from '../../selectors';
import { flattenError, useParallelQueries } from '../../utils';
import songMetadataQuery from './songMetadata.gql';

export const useSongMetadataQuery = (variables: SongMetadataQueryVariables) => {
    const { data, loading, error } = useQuery<
        SongMetadataQuery,
        SongMetadataQueryVariables
    >(songMetadataQuery, { variables });

    const memoizedData = useMemo(
        () => data && selectSongMetadata(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error: error && flattenError(error),
    };
};

export const useSongMetadataBatchQuery = (
    variables: SongMetadataQueryVariables[]
) => {
    const client = useApolloClient();
    const { data, loading, error } = useParallelQueries<
        SongMetadataQuery,
        SongMetadataQueryVariables
    >(songMetadataQuery, { variables, client });

    return {
        loading,
        data: useMemo(
            () => data && compact(data.map(song => selectSongMetadata(song))),
            [data]
        ),
        error,
    };
};
