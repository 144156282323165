import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { CHART_OTHERS } from 'src/components/chartHighlights/constants';
import { getLocalizedName } from 'src/utils/countries';

GridTable.defineColumnTemplate('highlights', {
    align: 'left',
    Cell: ({ data }) => {
        const {
            highlights: { peak, highlightedCountry, peakedCountries },
        } = data;

        const otherCountries =
            peakedCountries.length > 1
                ? formatMessage(CHART_OTHERS, {
                      count: peakedCountries.length - 1,
                  })
                : '';

        const otherCountryNames = peakedCountries
            .slice(1)
            .map(code => getLocalizedName(code))
            .join('\n');

        return (
            <span>
                #{peak} {getLocalizedName(highlightedCountry)}
                <span title={otherCountryNames}>{otherCountries}</span>
            </span>
        );
    },
});
