import dayjs from 'dayjs';
import { groupBy, map, maxBy, minBy, orderBy, uniq } from 'lodash';
import { filterData } from 'src/apollo/utils';
import { ChartInstance } from 'src/constants/charts';
import { COUNTRIES } from 'src/constants/countries';
import { SongAggregatedRanking } from './song';

export interface SongChartHighlight {
    key: string;
    chart: ChartInstance;
    chartKey: string;
    peakPosition?: number;
    daysOnChart: number;
    currentAppearances: number;
    totalAppearances: number;
    mostRecentTimestamp?: string;
    firstTimestamp?: string;
    highlights: {
        peak: number;
        highlightedCountry: string;
        peakedCountries: string[];
    };
}

const findHighlights = (group: SongAggregatedRanking[]) => {
    const peak = minBy(group, 'peakPosition')?.peakPosition || 0;
    const orderedByPopulation = orderBy(
        uniq(
            group
                .filter(row => row.peakPosition === peak)
                .map(row => row.country)
        ),
        country => (country ? COUNTRIES[country]?.population || 0 : 0),
        'desc'
    );

    const peakedCountries = filterData(orderedByPopulation);
    const [highlightedCountry] = peakedCountries;

    return {
        peak,
        highlightedCountry,
        peakedCountries,
    };
};

export const selectSongHighlights = (
    aggregatedRankings?: SongAggregatedRanking[] | null
): SongChartHighlight[] => {
    const groupedByChart = groupBy(
        aggregatedRankings,
        row => row.chart.definition.key
    );

    const songHighlights = map(groupedByChart, (group, chartKey) => {
        const [first] = group;

        const firstRanking = minBy(group, row =>
            Date.parse(row.firstTimestamp)
        );
        const lastRanking = maxBy(
            group,
            row =>
                row.mostRecentTimestamp && Date.parse(row.mostRecentTimestamp)
        );
        const peakRanking = minBy(group, row => row.peakPosition);

        const firstTimestamp = firstRanking?.firstTimestamp;
        const mostRecentTimestamp = lastRanking?.mostRecentTimestamp;

        const highlights = findHighlights(group);

        const currentAppearances = group.filter(row => row.position).length;

        const daysOnChart =
            mostRecentTimestamp && firstTimestamp
                ? dayjs(mostRecentTimestamp).diff(firstTimestamp, 'days')
                : 0;

        return {
            ...first,
            key: chartKey,
            chartKey,
            currentAppearances,
            totalAppearances: group.length,
            highlights,
            daysOnChart,
            firstTimestamp,
            mostRecentTimestamp,
            peakPosition: peakRanking?.peakPosition,
            position: lastRanking?.position,
        };
    });

    return songHighlights;
};
