import { Distributor } from 'src/apollo/definitions/globalTypes';

export const getDistributorsArray = (distributors?: string | null) => {
    const distributorsArray = distributors
        ?.split(',')
        .reduce<Distributor[]>((result, value) => {
            if (!value) return result;

            switch (value) {
                case 'theorchard':
                    return [...result, Distributor.THEORCHARD];
                case 'sme':
                    return [...result, Distributor.SME];
                case 'awal':
                    return [...result, Distributor.AWAL];
                default:
                    return result;
            }
        }, []);
    return distributorsArray?.length ? distributorsArray : undefined;
};
