import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_PRODUCT } from 'src/constants';

GridTable.defineColumnTemplate('albumWithLink', {
    align: 'left',
    sortable: true,
    Cell: ({ data: { productName, isPartOfCatalog, upc } }) => (
        <TruncatedText
            value={productName}
            useLink={Boolean(upc) && isPartOfCatalog}
            route={ROUTE_PRODUCT}
            params={{ upc }}
            maxLength={30}
        />
    ),
});
