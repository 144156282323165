import { useRef } from 'react';
import { get, isEqual } from 'lodash';

export const filterData = <T>(
    data?: (T | null | undefined | '' | 0 | false)[] | null
) => (data ? (data.filter(item => item) as T[]) : []);

export const nonNullable = <T>(data?: T | null) =>
    data === null ? undefined : (data as T);

export const notEmpty = <TValue>(
    value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined;

export const assertDate = <T, K extends keyof T>(obj: T, name: K): string => {
    const { formatted } = get(obj, name, {});
    if (!formatted)
        throw new Error(`"${String(name)}" is an invalid date object`);
    return formatted;
};

export const assertValue = <T, K extends keyof T>(
    obj: T | null | undefined,
    name: K
): Exclude<Required<T>[K], null | undefined> => {
    const value = get(obj, name);
    if (value === undefined || value === null)
        throw new Error(`"${String(name)}" is null or undefined`);
    return value;
};

export function useDeepMemo<TKey, TValue>(
    memoFn: () => TValue,
    key: TKey
): TValue {
    const ref = useRef<{ key: TKey; value: TValue }>();

    if (!ref.current || !isEqual(key, ref.current.key))
        ref.current = { key, value: memoFn() };

    return ref.current.value;
}

const deepEqualInternal = <T>(obj1: T, obj2: T): boolean => {
    if (obj1 === obj2) return true;
    if (
        obj1 === null ||
        obj2 === null ||
        typeof obj1 !== 'object' ||
        typeof obj2 !== 'object'
    )
        return false;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) return false;

        return obj1.every(item1 =>
            obj2.some(item2 => deepEqualInternal(item1, item2))
        );
    }

    const keys1 = Object.keys(obj1) as (keyof T)[];
    const keys2 = Object.keys(obj2) as (keyof T)[];

    if (keys1.length !== keys2.length) return false;

    return keys1.every(
        key => key in obj2 && deepEqualInternal(obj1[key], obj2[key])
    );
};

export const useExtendedDeepMemo = <TKey, TValue>(
    memoFn: () => TValue,
    key: TKey
): TValue => {
    const ref = useRef<{ key: TKey; value: TValue }>();

    if (!ref.current || !deepEqualInternal(key, ref.current.key))
        ref.current = { key, value: memoFn() };

    return ref.current.value;
};
