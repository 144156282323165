import React from 'react';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { Illustration } from '@theorchard/suite-icons';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

function ErrorIcon200() {
    const isColorSchemeV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);

    return isColorSchemeV2Enabled ? (
        <Illustration name={'dataError'} size={200} />
    ) : (
        <svg
            data-testid="ErrorIcon200"
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1427_55984"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="20"
                y="20"
                width="160"
                height="160"
            >
                <circle cx="100" cy="100" r="80" fill="#F3F0F0" />
            </mask>
            <g mask="url(#mask0_1427_55984)">
                <circle cx="100" cy="100" r="80" fill="#F3F0F0" />
                <circle
                    cx="100"
                    cy="100"
                    r="80"
                    fill="#EDE9E8"
                    fillOpacity="0.01"
                />
                <g filter="url(#filter0_d_1427_55984)">
                    <rect
                        x="51"
                        y="51"
                        width="98"
                        height="98"
                        rx="49"
                        fill="white"
                    />
                </g>
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M102.336 78.6905L120.595 112.164C121.781 114.339 120.221 117 117.759 117H81.2409C78.7794 117 77.219 114.339 78.4052 112.164L96.6643 78.6906C97.8939 76.4365 101.106 76.4365 102.336 78.6905ZM99.5 86.5445C101.287 86.5445 102.736 88.0054 102.736 89.8075V98.5091C102.736 100.311 101.287 101.772 99.5 101.772C97.7127 101.772 96.2637 100.311 96.2637 98.5091V89.8075C96.2637 88.0054 97.7127 86.5445 99.5 86.5445ZM99.5 112.649C101.287 112.649 102.736 111.188 102.736 109.386C102.736 107.584 101.287 106.123 99.5 106.123C97.7127 106.123 96.2637 107.584 96.2637 109.386C96.2637 111.188 97.7127 112.649 99.5 112.649Z"
                fill="#A8A0A0"
            />
            <g filter="url(#filter1_i_1427_55984)">
                <circle
                    cx="100"
                    cy="100"
                    r="80"
                    fill="#EDE9E8"
                    fillOpacity="0.01"
                />
            </g>
            <g filter="url(#filter2_d_1427_55984)">
                <path
                    d="M50.4254 149.689C50.23 149.372 49.77 149.372 49.5746 149.689L45.48 156.317C45.4392 156.384 45.3835 156.439 45.3174 156.48L38.6887 160.575C38.3724 160.77 38.3724 161.23 38.6887 161.425L45.3174 165.52C45.3835 165.561 45.4392 165.616 45.48 165.683L49.5746 172.311C49.77 172.628 50.23 172.628 50.4254 172.311L54.52 165.683C54.5608 165.616 54.6165 165.561 54.6826 165.52L61.3113 161.425C61.6276 161.23 61.6276 160.77 61.3113 160.575L54.6826 156.48C54.6165 156.439 54.5608 156.384 54.52 156.317L50.4254 149.689Z"
                    fill="#80BFFF"
                />
            </g>
            <g filter="url(#filter3_d_1427_55984)">
                <path
                    d="M152.575 34.6887C152.77 34.3724 153.23 34.3724 153.425 34.6887L155.993 38.8448C156.033 38.9109 156.089 38.9666 156.155 39.0074L160.311 41.5746C160.628 41.77 160.628 42.23 160.311 42.4254L156.155 44.9926C156.089 45.0334 156.033 45.0891 155.993 45.1552L153.425 49.3113C153.23 49.6276 152.77 49.6276 152.575 49.3113L150.007 45.1552C149.967 45.0891 149.911 45.0334 149.845 44.9926L145.689 42.4254C145.372 42.23 145.372 41.77 145.689 41.5746L149.845 39.0074C149.911 38.9666 149.967 38.9109 150.007 38.8448L152.575 34.6887Z"
                    fill="#80BFFF"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_1427_55984"
                    x="31"
                    y="36"
                    width="138"
                    height="138"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.698039 0 0 0 0 0.698039 0 0 0 0 0.698039 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1427_55984"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1427_55984"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_i_1427_55984"
                    x="20"
                    y="20"
                    width="160"
                    height="160"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5.236" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.894118 0 0 0 0 0.878431 0 0 0 0 0.87451 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_1427_55984"
                    />
                </filter>
                <filter
                    id="filter2_d_1427_55984"
                    x="22.4512"
                    y="137.451"
                    width="55.0977"
                    height="55.0972"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.568627 0 0 0 0 0.811765 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1427_55984"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1427_55984"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_1427_55984"
                    x="129.451"
                    y="22.4514"
                    width="47.0977"
                    height="47.0972"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.568627 0 0 0 0 0.811765 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1427_55984"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1427_55984"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default ErrorIcon200;
