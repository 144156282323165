import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import BrandIconStack from 'src/components/brandIconStack';

GridTable.defineColumnTemplate('chartBrands', {
    align: 'left',
    sortable: false,
    minWidth: '68px',
    maxWidth: '68px',
    HeaderText: () => null,
    Cell: ({ data: { brands } }) => (
        <BrandIconStack className="BrandsCell" brands={brands} />
    ),
});
