import React, { FC } from 'react';
import { GridTable, GridTableCellProps } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { camelCase } from 'lodash';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_PRODUCT } from 'src/constants';
import { ProductLinkProps } from './types';

const CLASSNAME_DELETED = 'deleted';
const TEST_ID_PRODUCT_LINK_WITH_UPC_CELLS =
    'TEST_ID_PRODUCT_LINK_WITH_UPC_CELLS';

const ProductLinkWithUpcCell: FC<GridTableCellProps<ProductLinkProps>> = ({
    data: { upc, productName, deletions, format, deleted },
}) => {
    const className = cx('ProductLinkWithUpcCell', {
        [CLASSNAME_DELETED]: deletions === 'true' || deleted,
    });

    const info = format
        ? `${formatMessage(`product.format.${camelCase(format)}`)} - ${upc}`
        : upc;

    return (
        <div
            data-testid={TEST_ID_PRODUCT_LINK_WITH_UPC_CELLS}
            className={className}
        >
            <TruncatedText
                value={productName}
                useLink={!!upc}
                route={ROUTE_PRODUCT}
                params={{ upc }}
                maxLength={30}
            />
            <div className="ProductLinkWithUpcCell-upc">{info}</div>
        </div>
    );
};

GridTable.defineColumnTemplate('productLinkWithUpc', {
    align: 'left',
    Cell: ProductLinkWithUpcCell,
});

export default ProductLinkWithUpcCell;
