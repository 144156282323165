import React from 'react';
import { GlyphButton, GridTable } from '@theorchard/suite-components';
import { formatMessage, useFeatureFlag } from '@theorchard/suite-frontend';
import { get } from 'lodash';
import { PlaylistType } from 'src/apollo/selectors/playlist';
import { INSIGHTS_STORE_FRONT_FIX } from 'src/constants/featuresFlags';

export const CLASSNAME = 'PositionWithButton';
export const POSITION_CLASSNAME = `${CLASSNAME}-position`;
export const BUTTON_CLASSNAME = `${CLASSNAME}-button`;

interface onClickHandlerProp {
    (data: PlaylistType): void;
}

GridTable.defineColumnTemplate('positionWithButton', {
    align: 'right',
    Cell: ({ data, column: { definition } }) => {
        const { position, size, storefrontCount } = data;
        const onClickHandler = get(definition, 'data-onClickHandler') as
            | onClickHandlerProp
            | undefined;
        const isStoreFrontFixFFEnabled = useFeatureFlag(
            INSIGHTS_STORE_FRONT_FIX
        );
        const showButton = isStoreFrontFixFFEnabled
            ? !storefrontCount || storefrontCount === 1 || storefrontCount === 2
            : !storefrontCount;

        return (
            <span className={CLASSNAME}>
                {position && size && (
                    <span className={POSITION_CLASSNAME}>
                        {formatMessage('playlists.info.position', {
                            position,
                            total: size,
                        })}
                    </span>
                )}
                {showButton && (
                    <GlyphButton
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();

                            onClickHandler?.(data);
                        }}
                        variant="quartenary"
                        name="position"
                        className={BUTTON_CLASSNAME}
                        tooltip={formatMessage('placement.positionDetails')}
                    />
                )}
            </span>
        );
    },
});
