import { SecondaryDimension, TimeseriesSelector } from '../types';
import { selectTimeseriesByCountry } from './timeseriesByCountry';
import { selectTimeseriesByProduct } from './timeseriesByProduct';
import { selectTimeseriesBySOS } from './timeseriesBySOS';
import { selectTimeseriesByStore } from './timeseriesByStore';
import { selectTimeseriesByTotal } from './timeseriesByTotal';

export const SELECTORS: Record<SecondaryDimension, TimeseriesSelector> = {
    [SecondaryDimension.SourceOfStreams]: selectTimeseriesBySOS,
    [SecondaryDimension.Countries]: selectTimeseriesByCountry,
    [SecondaryDimension.Total]: selectTimeseriesByTotal,
    [SecondaryDimension.Products]: selectTimeseriesByProduct,
    [SecondaryDimension.Stores]: selectTimeseriesByStore,
};

export const getSelector = (secondaryDimension: SecondaryDimension) => {
    const selector = SELECTORS[secondaryDimension];

    if (!selector)
        throw new Error(
            `failed to locate selector for "${secondaryDimension}"`
        );

    return selector;
};
