import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { CountryFlag } from '@theorchard/suite-icons';
import TruncatedText from 'src/components/truncatedText/truncatedText';
import { EMPTY_CHAR } from 'src/constants';
import { getLocalizedName } from 'src/utils/countries';

GridTable.defineColumnTemplate('countryFlagWithName', {
    align: 'left',
    sortable: false,
    Cell: ({ value }) =>
        typeof value === 'string' ? (
            <div className="CountryFlagWithNameCell">
                <CountryFlag showTitle={false} countryCode={value} />
                <TruncatedText value={getLocalizedName(value)} />
            </div>
        ) : (
            <span>{EMPTY_CHAR}</span>
        ),
});
