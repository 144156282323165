import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    selectStarredParticipants,
    selectStarredParticipantsStreams,
} from 'src/apollo/selectors/participant';
import {
    StarredGlobalParticipantsQuery as Query,
    StarredGlobalParticipantsQueryVariables as QueryVars,
} from '../../definitions/StarredGlobalParticipantsQuery';
import {
    StarredGlobalParticipantsStreamsQuery as StreamsQuery,
    StarredGlobalParticipantsStreamsQueryVariables as StreamsQueryVars,
} from '../../definitions/StarredGlobalParticipantsStreamsQuery';
import starredGlobalParticipantsQuery from './starredGlobalParticipants.gql';
import starredGlobalParticipantsStreamsQuery from './starredGlobalParticipantsStreams.gql';

export const useStarredGlobalParticipantsQuery = (variables: QueryVars) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        starredGlobalParticipantsQuery,
        { variables }
    );

    const memoizedData = useMemo(
        () => data && selectStarredParticipants(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useStarredGlobalParticipantsStreamsQuery = (
    variables: StreamsQueryVars
) => {
    const { data, loading, error } = useQuery<StreamsQuery, StreamsQueryVars>(
        starredGlobalParticipantsStreamsQuery,
        { variables }
    );

    const memoizedData = useMemo(
        () => data && selectStarredParticipantsStreams(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
