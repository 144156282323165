import React from 'react';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { Illustration } from '@theorchard/suite-icons';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

function NoResultsIcon200() {
    const isColorSchemeV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);

    return isColorSchemeV2Enabled ? (
        <Illustration name={'noResults'} size={200} />
    ) : (
        <svg
            data-testid="NoResultsIcon200"
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_598_200813)">
                <mask
                    id="mask0_598_200813"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="20"
                    y="20"
                    width="160"
                    height="160"
                >
                    <circle cx="100" cy="100" r="80" fill="#F3F0F0" />
                </mask>
                <g mask="url(#mask0_598_200813)">
                    <circle cx="100" cy="100" r="80" fill="#F3F0F0" />
                    <circle
                        cx="100"
                        cy="100"
                        r="80"
                        fill="#EDE9E8"
                        fillOpacity="0.01"
                    />
                    <rect
                        x="35"
                        y="60"
                        width="130"
                        height="126"
                        rx="3"
                        fill="white"
                    />
                    <rect
                        x="45"
                        y="70"
                        width="110"
                        height="24"
                        rx="3"
                        fill="#F7F5F5"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M62 79C61.2597 79 60.6134 78.5978 60.2676 78H53C52.4477 78 52 77.5523 52 77C52 76.4477 52.4477 76 53 76H60.2676C60.6134 75.4022 61.2597 75 62 75C62.7403 75 63.3866 75.4022 63.7324 76H65C65.5523 76 66 76.4477 66 77C66 77.5523 65.5523 78 65 78H63.7324C63.3866 78.5978 62.7403 79 62 79ZM52 82C52 81.4477 52.4477 81 53 81H54.2676C54.6134 80.4022 55.2597 80 56 80C56.7403 80 57.3866 80.4022 57.7324 81H65C65.5523 81 66 81.4477 66 82C66 82.5523 65.5523 83 65 83H57.7324C57.3866 83.5978 56.7403 84 56 84C55.2597 84 54.6134 83.5978 54.2676 83H53C52.4477 83 52 82.5523 52 82ZM53 86C52.4477 86 52 86.4477 52 87C52 87.5523 52.4477 88 53 88H56.2676C56.6134 88.5978 57.2597 89 58 89C58.7403 89 59.3866 88.5978 59.7324 88H65C65.5523 88 66 87.5523 66 87C66 86.4477 65.5523 86 65 86H59.7324C59.3866 85.4022 58.7403 85 58 85C57.2597 85 56.6134 85.4022 56.2676 86H53Z"
                        fill="#B2B2B2"
                    />
                    <rect
                        x="77"
                        y="79"
                        width="67"
                        height="6"
                        rx="3"
                        fill="#EDE9E8"
                    />
                    <g filter="url(#filter0_i_598_200813)">
                        <circle
                            cx="100"
                            cy="100"
                            r="80"
                            fill="#EDE9E8"
                            fillOpacity="0.01"
                        />
                    </g>
                </g>
                <g filter="url(#filter1_d_598_200813)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M123.213 154.385C126.847 149.541 129 143.522 129 137C129 120.984 116.016 108 100 108C83.9837 108 71 120.984 71 137C71 153.016 83.9837 166 100 166C106.691 166 112.853 163.734 117.76 159.927C117.89 160.152 118.05 160.364 118.243 160.556L138.749 181.062C140.311 182.625 142.843 182.625 144.406 181.062C145.968 179.5 145.968 176.968 144.406 175.406L123.899 154.899C123.691 154.691 123.459 154.52 123.213 154.385Z"
                        fill="#E4E0DF"
                    />
                </g>
                <circle cx="100" cy="137" r="23" fill="#F7F5F5" />
                <g filter="url(#filter2_d_598_200813)">
                    <path
                        d="M114.425 105.689C114.23 105.372 113.77 105.372 113.575 105.689L109.48 112.317C109.439 112.384 109.384 112.439 109.317 112.48L102.689 116.575C102.372 116.77 102.372 117.23 102.689 117.425L109.317 121.52C109.384 121.561 109.439 121.616 109.48 121.683L113.575 128.311C113.77 128.628 114.23 128.628 114.425 128.311L118.52 121.683C118.561 121.616 118.616 121.561 118.683 121.52L125.311 117.425C125.628 117.23 125.628 116.77 125.311 116.575L118.683 112.48C118.616 112.439 118.561 112.384 118.52 112.317L114.425 105.689Z"
                        fill="#FFA166"
                    />
                </g>
                <g filter="url(#filter3_d_598_200813)">
                    <path
                        d="M151.575 129.689C151.77 129.372 152.23 129.372 152.425 129.689L154.993 133.845C155.033 133.911 155.089 133.967 155.155 134.007L159.311 136.575C159.628 136.77 159.628 137.23 159.311 137.425L155.155 139.993C155.089 140.033 155.033 140.089 154.993 140.155L152.425 144.311C152.23 144.628 151.77 144.628 151.575 144.311L149.007 140.155C148.967 140.089 148.911 140.033 148.845 139.993L144.689 137.425C144.372 137.23 144.372 136.77 144.689 136.575L148.845 134.007C148.911 133.967 148.967 133.911 149.007 133.845L151.575 129.689Z"
                        fill="#80BFFF"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_i_598_200813"
                    x="20"
                    y="20"
                    width="160"
                    height="160"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5.236" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.894118 0 0 0 0 0.878431 0 0 0 0 0.87451 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_598_200813"
                    />
                </filter>
                <filter
                    id="filter1_d_598_200813"
                    x="51"
                    y="93"
                    width="114.577"
                    height="114.234"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.666667 0 0 0 0 0.666667 0 0 0 0 0.698039 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_598_200813"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_598_200813"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_598_200813"
                    x="86.4517"
                    y="93.4514"
                    width="55.0967"
                    height="55.0972"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.568627 0 0 0 0 0.811765 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_598_200813"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_598_200813"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_598_200813"
                    x="128.452"
                    y="117.451"
                    width="47.0967"
                    height="47.0972"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.568627 0 0 0 0 0.811765 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_598_200813"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_598_200813"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_598_200813">
                    <rect width="200" height="200" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default NoResultsIcon200;
