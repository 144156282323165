import { formatMessage, getCurrentLocale } from '@theorchard/suite-frontend';
import { memoize, orderBy } from 'lodash';
import { filterData } from 'src/apollo/utils';
import {
    COUNTRIES,
    COUNTRY_GLOBAL,
    COUNTRY_KOSOVO,
    getCountryName,
    getCountryNames,
    WORLDWIDE,
} from 'src/constants/countries';
import { sortedCountries } from 'src/constants/sortedCountries';

export const TERM_WORLDWIDE = 'country.worldwide';
export const TERM_GLOBAL = 'country.global';

export interface LabelValueLike {
    label: string;
    value: string | (string | null);
}
export interface LocalizedCountriesFilter {
    locale?: string;
    excludeGlobal?: boolean;
}

export const orderCountries = <T extends LabelValueLike>(
    countries: T[],
    preferredCountry: string = COUNTRY_GLOBAL
): T[] =>
    orderBy(
        countries,
        ({ label, value }) => [value === preferredCountry ? 0 : 1, label],
        'asc'
    );

export const getLocalizedName = (code: string = WORLDWIDE) => {
    switch (code) {
        case WORLDWIDE:
            return formatMessage(TERM_WORLDWIDE);
        case COUNTRY_GLOBAL:
            return formatMessage(TERM_GLOBAL);
        default:
            return getCountryName(code);
    }
};

export const getFilteredLocalizedCountries = ({
    locale,
    excludeGlobal,
}: LocalizedCountriesFilter = {}) => {
    const countriesWithoutGlobal = getCountryNames(locale).filter(
        c => c.value !== COUNTRY_GLOBAL
    );
    if (excludeGlobal) return countriesWithoutGlobal;

    return [
        { label: getLocalizedName(COUNTRY_GLOBAL), value: COUNTRY_GLOBAL },
        ...countriesWithoutGlobal,
    ];
};

/*
 * Returns a list of countries ordered by name and with GLOBAL first.
 */
export const getLocalizedCountries = memoize(
    (locale = getCurrentLocale()) => getFilteredLocalizedCountries({ locale }),
    locale => locale || getCurrentLocale()
);

export const getSortedListOfCountries = () => {
    const countries = getLocalizedCountries();

    return filterData(
        sortedCountries.map(item =>
            countries.find(country => country.value === item)
        )
    );
};

export const getCountryCodesForRights = (): string[] =>
    Object.keys(COUNTRIES).filter(
        countryCode =>
            countryCode !== COUNTRY_GLOBAL && countryCode !== COUNTRY_KOSOVO
    );
