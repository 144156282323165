import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatDollars, normalizeNumber } from 'src/utils';

GridTable.defineColumnTemplate('dollar', {
    align: 'right',
    Cell: ({ value }) => {
        const normalizedValue = normalizeNumber(value);

        return <span>{formatDollars(normalizedValue)}</span>;
    },
});
