import { useQuery } from '@apollo/client';
import { usePrefetchQuery } from 'src/apollo/utils';
import { RecentlySelectedCountryFilters } from '../definitions/RecentlySelectedCountryFilters';
import recentCountryFiltersQuery from './recentCountryFilters.gql';

export const useRecentlySelectedCountryFiltersQuery = (skip?: boolean) => {
    const { data, loading, error } = useQuery<RecentlySelectedCountryFilters>(
        recentCountryFiltersQuery,
        { skip }
    );

    return {
        data: data?.activeProfile.countryFilters,
        loading,
        error,
    };
};

export const usePrefetchRecentlySelectedCountryFiltersQuery = () => {
    usePrefetchQuery<RecentlySelectedCountryFilters>(
        recentCountryFiltersQuery,
        { variables: {} }
    );
};
