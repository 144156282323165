import { useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import {
    TopGlobalParticipantsResultsByFinLabelQuery as QueryFinLabel,
    TopGlobalParticipantsResultsByFinLabelQueryVariables as QueryFinLabelVars,
} from 'src/apollo/definitions/TopGlobalParticipantsResultsByFinLabelQuery';
import {
    TopGlobalParticipantsResultsQuery as Query,
    TopGlobalParticipantsResultsQueryVariables as QueryVars,
} from 'src/apollo/definitions/TopGlobalParticipantsResultsQuery';
import { selectTopGlobalParticipantsResults } from 'src/apollo/selectors';
import { useDistributorsFilterOptions } from 'src/components/distributorFilter/utils';
import {
    PARTICIPANTS_PAGE_SIZE,
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_DIR,
} from 'src/pages/catalog/pages/participants/constants';
import { useEmployeeIdentity, usePrefetchQuery } from '../../utils';
import topGlobalParticipantsResultsQuery from './topGlobalParticipantsResults.gql';
import topGlobalParticipantsResultsByFinLabelQuery from './topGlobalParticipantsResultsByFinLabel.gql';

export const useTopGlobalParticipantsResultsQuery = (
    variables: QueryVars,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        topGlobalParticipantsResultsQuery,
        { variables, skip }
    );

    const memoizedData = useMemo(
        () => data && selectTopGlobalParticipantsResults(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useTopGlobalParticipantsResultsByFinLabelQuery = (
    variables: QueryFinLabelVars,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<QueryFinLabel, QueryFinLabelVars>(
        topGlobalParticipantsResultsByFinLabelQuery,
        { variables, skip }
    );

    const memoizedData = useMemo(
        () => data && selectTopGlobalParticipantsResults(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchTopGlobalParticipantsQuery = (delay = 0) => {
    const { isEmployee = false } = useEmployeeIdentity();
    const { selectedValue } = useDistributorsFilterOptions();

    usePrefetchQuery<Query, QueryVars>(topGlobalParticipantsResultsQuery, {
        variables: {
            limit: PARTICIPANTS_PAGE_SIZE,
            orderBy: DEFAULT_SORT_FIELD,
            orderDir: DEFAULT_SORT_DIR,
            countries: [],
            distributors: selectedValue,
            globalParticipantIds: [],
            isEmployee,
            labelIds: [],
            subaccountIds: [],
        },
        delay,
    });
};

interface PrefetchQueryOptions {
    skip: boolean;
}

export const prefetchTopGlobalParticipantsQuery = async (
    client: ApolloClient<object>,
    variables: Partial<QueryVars> = {},
    options: PrefetchQueryOptions = { skip: false }
) => {
    const { skip } = options;

    return (
        !skip &&
        (await client.query({
            query: topGlobalParticipantsResultsQuery,
            variables: {
                limit: PARTICIPANTS_PAGE_SIZE,
                orderBy: DEFAULT_SORT_FIELD,
                orderDir: DEFAULT_SORT_DIR,
                countries: [],
                globalParticipantIds: [],
                isEmployee: false,
                labelIds: [],
                subaccountIds: [],
                ...variables,
            },
        }))
    );
};
