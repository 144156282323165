import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import cx from 'classnames';
import CoverArt from 'src/components/coverArt';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_VIDEO } from 'src/constants';
import { TEST_ID as videoSearchResultsId } from 'src/pages/searchES/pages/videoResults/videoResultsGridTable';

GridTable.defineColumnTemplate('thumbnailVideoLinkV2', {
    align: 'left',
    sortable: false,
    Cell: ({
        data: {
            video: { id, name, imageUrl },
        },
        context: {
            props: { testId },
        },
    }) => {
        const shouldLogPageVisit = testId === videoSearchResultsId;

        return (
            <div
                className={cx(
                    'ThumbnailVideoLinkCell',
                    'CoverArtCell',
                    'ThumbnailVideoLinkCellV2'
                )}
                data-testid="ThumbnailVideoLinkCell"
            >
                <CoverArt url={imageUrl} shape="landscape" width="53" />
                <TruncatedText
                    useLink
                    shouldLogPageVisit={shouldLogPageVisit}
                    route={ROUTE_VIDEO}
                    params={{ id }}
                    value={name}
                />
            </div>
        );
    },
});
