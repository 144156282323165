import { saveAs } from 'file-saver';
import { FieldInfo, parse } from 'json2csv';
import { get, isNull, map } from 'lodash';
import { TrendScore } from 'src/apollo/selectors/catalog';
import { getLocalizedName } from 'src/utils/countries';
import { normalizeNumber } from 'src/utils/formatNumber';

export type CsvDataValue = string | number | null | undefined;
export type CsvDataType = Record<string, CsvDataValue>;
export type CsvFieldType<T extends object = object> = string | FieldInfo<T>;

export interface CsvColumnConfig<T> {
    columnName: string;
    label: string;
    valueFormatter?: (row: T) => CsvDataValue;
    forceInclude?: boolean;
    hidden?: boolean;
}

export const exportToCsv = <T extends object>(
    data: T[],
    filename: string,
    fields?: CsvFieldType<T>[]
) => {
    const csv = parse(data, { fields, withBOM: true });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-16' });
    saveAs(blob, `${filename}.csv`, { autoBom: true });
};

export const exportGridToCsv = <T>(
    items: T[],
    visibleColumns: string[],
    configurations: CsvColumnConfig<T>[],
    filename: string
) => {
    const activeFormatters = configurations.filter(
        ({ columnName, forceInclude, hidden }) => {
            if (hidden) return false;
            return forceInclude || visibleColumns.includes(columnName);
        }
    );
    const data = map(items, item =>
        activeFormatters.reduce(
            (acc, { columnName, valueFormatter }) => ({
                ...acc,
                [columnName]: valueFormatter
                    ? valueFormatter(item)
                    : get(item, columnName),
            }),
            {}
        )
    );
    const headers = activeFormatters.map(({ label, columnName }) => ({
        label,
        value: columnName,
    }));

    exportToCsv(data, filename, headers);
};

export const getTrendScoreForCsv = (scores?: TrendScore[] | null) => {
    if (!scores?.length) return null;
    const [{ score, country }] = scores;
    if (!score || !country) return null;

    return `${getLocalizedName(country)}: ${score}`;
};

export const calculateChangeForCsv = (
    currentValue?: number | null,
    prevValue?: number | null
): number | null => {
    const currentNormalizedValue = normalizeNumber(currentValue);
    const normalizedPrevValue = normalizeNumber(prevValue);
    let change = null;

    if (!isNull(normalizedPrevValue) && !isNull(currentNormalizedValue)) {
        change = currentNormalizedValue - normalizedPrevValue;
    }

    return change;
};
