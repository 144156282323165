import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import { EMPTY_CHAR } from 'src/constants';
import { formatPercentage, normalizeNumber } from 'src/utils';

GridTable.defineColumnTemplate('percentage', {
    align: 'right',
    Cell: ({ value, column: { definition } }) => {
        const normalizedValue = normalizeNumber(value);
        const useEmptyChar = get(definition, 'data-useEmptyChar', true);
        if (!normalizedValue)
            return useEmptyChar ? <span>{EMPTY_CHAR}</span> : null;

        return <span>{formatPercentage(normalizedValue)}</span>;
    },
});
