export const BUTTON_CLASSNAME = 'btn';
export const BUTTON_PRIMARY_CLASSNAME = `${BUTTON_CLASSNAME}-primary`;
export const BUTTON_SECONDARY_CLASSNAME = `${BUTTON_CLASSNAME}-secondary`;
export const BUTTON_TERTIARY_CLASSNAME = `${BUTTON_CLASSNAME}-tertiary`;
export const BUTTON_QUARTENARY_CLASSNAME = `${BUTTON_CLASSNAME}-quartenary`;
export const BUTTON_LARGE_CLASSNAME = `${BUTTON_CLASSNAME}-lg`;
export const BUTTON_SMALL_CLASSNAME = `${BUTTON_CLASSNAME}-sm`;

export const BUTTON_VARIANT = {
    PRIMARY: BUTTON_PRIMARY_CLASSNAME,
    SECONDARY: BUTTON_SECONDARY_CLASSNAME,
    TERTIARY: BUTTON_TERTIARY_CLASSNAME,
    QUARTENARY: BUTTON_QUARTENARY_CLASSNAME,
};

export const BUTTON_SIZE = {
    LARGE: BUTTON_LARGE_CLASSNAME,
    SMALL: BUTTON_SMALL_CLASSNAME,
};
