import React, { FC, useState } from 'react';
import { SuitePlugin } from '@theorchard/suite-frontend';
import { ApplicationUrlContext, ApplicationUrlParameters } from 'src/context';

const ApplicationUrlProvider: FC = ({ children }) => {
    const [appUrlParams, setAppUrlParams] = useState<ApplicationUrlParameters>(
        {}
    );

    return (
        <ApplicationUrlContext.Provider
            value={{ appUrlParams, setAppUrlParams }}
        >
            {children}
        </ApplicationUrlContext.Provider>
    );
};

const ApplicationUrlPlugin: SuitePlugin = {
    Wrapper: ({ children }) => (
        <ApplicationUrlProvider>{children}</ApplicationUrlProvider>
    ),
};

export default ApplicationUrlPlugin;
