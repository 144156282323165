import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import AccountsDetailsPopup from 'src/components/accountsDetailsPopup';

GridTable.defineColumnTemplate('brand', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { accounts = [] } }) => (
        <span className="CatalogBrandCell">
            <AccountsDetailsPopup accounts={accounts} showTitle={false} />
        </span>
    ),
});
