import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { AnalyticsDataAvailability as Query } from 'src/apollo/definitions/AnalyticsDataAvailability';
import { DOWNLOADS, STREAMS } from 'src/constants/metrics';
import { usePrefetchQuery } from '../utils';
import QUERY from './analyticsDataAvailability.gql';

export const useAnalyticsDataAvailability = (metric?: string) => {
    const { data, loading, error } = useQuery<Query>(QUERY, {
        fetchPolicy: 'cache-first',
    });

    const endDate =
        data &&
        get(
            data.analyticsDataAvailability,
            metric === DOWNLOADS ? DOWNLOADS : STREAMS
        );

    return { data: endDate, loading, error };
};

export const usePrefetchAnalyticsDataAvailabilityQuery = () => {
    usePrefetchQuery<Query>(QUERY, {
        variables: {},
    });
};
