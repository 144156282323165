import {
    SoundRecordingDownloadsByCountry,
    SoundRecordingDownloadsByCountryVariables,
    SoundRecordingDownloadsByCountry_globalSoundRecordingByIsrc_analytics as DownloadsByCountry,
} from 'src/apollo/definitions/SoundRecordingDownloadsByCountry';
import {
    SoundRecordingDownloadsByProduct,
    SoundRecordingDownloadsByProductVariables,
    SoundRecordingDownloadsByProduct_globalSoundRecordingByIsrc_analytics as DownloadsByProduct,
} from 'src/apollo/definitions/SoundRecordingDownloadsByProduct';
import {
    SoundRecordingDownloadsByStore,
    SoundRecordingDownloadsByStoreVariables,
    SoundRecordingDownloadsByStore_globalSoundRecordingByIsrc_analytics as DownloadsByStore,
} from 'src/apollo/definitions/SoundRecordingDownloadsByStore';
import {
    SoundRecordingDownloadsTotal,
    SoundRecordingDownloadsTotalVariables,
    SoundRecordingDownloadsTotal_globalSoundRecordingByIsrc_analytics as DownloadsTotal,
} from 'src/apollo/definitions/SoundRecordingDownloadsTotal';
import {
    SoundRecordingPerformanceByCountry,
    SoundRecordingPerformanceByCountryVariables,
    SoundRecordingPerformanceByCountry_globalSoundRecordingByIsrc_analytics as PerformanceByCountry,
} from '../../../definitions/SoundRecordingPerformanceByCountry';
import {
    SoundRecordingStreamsByProduct,
    SoundRecordingStreamsByProductVariables,
    SoundRecordingStreamsByProduct_globalSoundRecordingByIsrc_analytics as StreamsByProduct,
} from '../../../definitions/SoundRecordingStreamsByProduct';
import {
    SoundRecordingStreamsBySOS,
    SoundRecordingStreamsBySOSVariables,
    SoundRecordingStreamsBySOS_globalSoundRecordingByIsrc_analytics as StreamsBySOS,
} from '../../../definitions/SoundRecordingStreamsBySOS';
import {
    SoundRecordingStreamsByStore,
    SoundRecordingStreamsByStoreVariables,
    SoundRecordingStreamsByStore_globalSoundRecordingByIsrc_analytics as StreamsByStore,
} from '../../../definitions/SoundRecordingStreamsByStore';
import {
    SoundRecordingStreamsTotal,
    SoundRecordingStreamsTotalVariables,
    SoundRecordingStreamsTotal_globalSoundRecordingByIsrc_analytics as StreamsTotal,
} from '../../../definitions/SoundRecordingStreamsTotal';

/* eslint-disable @typescript-eslint/indent */
export type StreamsVariables = SoundRecordingStreamsBySOSVariables &
    SoundRecordingStreamsByProductVariables &
    SoundRecordingStreamsByStoreVariables &
    SoundRecordingStreamsTotalVariables &
    SoundRecordingPerformanceByCountryVariables;

export type DownloadsVariables = SoundRecordingDownloadsByCountryVariables &
    SoundRecordingDownloadsByProductVariables &
    SoundRecordingDownloadsByStoreVariables &
    SoundRecordingDownloadsTotalVariables;

export type StreamsQuery = Partial<
    SoundRecordingStreamsByProduct &
        SoundRecordingStreamsBySOS &
        SoundRecordingStreamsByStore &
        SoundRecordingStreamsTotal &
        SoundRecordingPerformanceByCountry
>;

export type StreamsAnalyticsQuery = Partial<
    StreamsByProduct &
        StreamsBySOS &
        StreamsByStore &
        StreamsTotal &
        PerformanceByCountry
>;

export type DownloadsQuery = Partial<
    SoundRecordingDownloadsByCountry &
        SoundRecordingDownloadsByProduct &
        SoundRecordingDownloadsByStore &
        SoundRecordingDownloadsTotal
>;

export type DownloadsAnalyticsQuery = Partial<
    DownloadsByCountry & DownloadsByProduct & DownloadsByStore & DownloadsTotal
>;

export type Variables = StreamsVariables &
    DownloadsVariables & { name?: string; releaseDate?: string };
/* eslint-enable @typescript-eslint/indent */

export enum PrimaryDimension {
    Streams = 'streams',
    Downloads = 'downloads',
    Skips = 'skips',
    Saves = 'saves',
}

export enum SecondaryDimension {
    Countries = 'countries',
    Products = 'product',
    SourceOfStreams = 'sourceofstreams',
    Stores = 'store',
    Total = 'total',
}

export interface SongTimeseriesData {
    isrc: string;
    releaseDate?: string;
    name?: string;
    streams?: StreamsAnalyticsQuery;
    downloads?: DownloadsAnalyticsQuery;
}

export interface TimeseriesOptions {
    primaryDimension: PrimaryDimension | string;
    secondaryDimension: SecondaryDimension | string;
    datePeriod: string;
    releaseDate?: string;
}

export interface TimeseriesPointData {
    date: string;
    value: number | null;
    skipRate?: number | null;
    saves?: number | null;
}

interface TimeseriesPoint {
    x: number;
    y: number | null;
    z?: string;
}

export interface TimeseriesSummary {
    id: string;
    code?: string | number;
    name: string;
    title?: string;
    streams?: number | null;
    downloads?: number | null;
    saves?: number | null;
    skips?: number | null;
    className?: string;
    isrc?: string;
}

export type TimeseriesData = TimeseriesPoint[];

export interface Timeseries {
    id: string;
    name: string;
    title?: string;
    data: TimeseriesData;
    summary: TimeseriesSummary;
}

export type TimeseriesSelector = (options: {
    data: SongTimeseriesData[];
    primaryDimension: PrimaryDimension;
    datePeriod: string;
}) => Timeseries[];
