import React, { FC, Fragment } from 'react';
import { Table } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { useEmployeeIdentity } from 'src/apollo/utils';
import { getAccountId, getCompanyIcon } from 'src/utils/accounts';
import AccountLink from '../accountLink';
import { Account, Props } from './types';

export const CLASSNAME = 'AccountsDetailsMenu';
const CLASSNAME_ACCOUNT = `${CLASSNAME}-account`;
const CLASSNAME_ACCOUNT_NAME = `${CLASSNAME_ACCOUNT}-name`;
export const CLASSNAME_ACCOUNT_LINK = `${CLASSNAME_ACCOUNT}-link`;
export const CLASSNAME_ACCOUNT_TIER = `${CLASSNAME_ACCOUNT}-tier`;
export const CLASSNAME_OVERLAY = `${CLASSNAME}-overlay`;

const Tiers: FC<{ tier?: number | string }> = ({ tier }) => (
    <div className={CLASSNAME_ACCOUNT_TIER}>{tier}</div>
);

const getSubtitle = (account: Account) => {
    const isSubAccount = account.subaccountId !== 0;
    if (isSubAccount)
        return `${formatMessage('product.metadata.subAccount')} • ${
            account.vendorName
        } (${account.vendorId})`;

    return account.vendorName || formatMessage('catalog.labelName');
};

const Menu: FC<Props> = ({ accounts, forwardedRef }) => {
    const { isEmployee } = useEmployeeIdentity();

    return (
        <div
            aria-hidden="true"
            role="button"
            className={CLASSNAME}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
            }}
            ref={forwardedRef}
        >
            <Table borderless className={CLASSNAME_ACCOUNT}>
                <tbody>
                    {accounts.map(account => {
                        const {
                            name,
                            tier,
                            type,
                            vendorId,
                            brand,
                            brandAbbrName,
                            parentCompany,
                            parentCompanyAbbrName,
                            subaccountId,
                        } = account;
                        const id = getAccountId(account, type);
                        const accountTitle = `${name} (${id})`;

                        return (
                            <Fragment
                                key={`${name}-${vendorId}-${subaccountId}`}
                            >
                                <tr className={CLASSNAME_ACCOUNT_NAME}>
                                    <td>{formatMessage('account.account')}</td>
                                    <td>
                                        <div>
                                            {isEmployee ? (
                                                <AccountLink
                                                    className={
                                                        CLASSNAME_ACCOUNT_LINK
                                                    }
                                                    accountId={vendorId}
                                                    subaccountId={subaccountId}
                                                    title={accountTitle}
                                                />
                                            ) : (
                                                accountTitle
                                            )}
                                        </div>
                                        <label>{getSubtitle(account)}</label>
                                    </td>
                                </tr>
                                {isEmployee && brand && (
                                    <tr>
                                        <td>
                                            {formatMessage('catalog.brand')}
                                        </td>
                                        <td>
                                            {getCompanyIcon(brandAbbrName)}
                                            {brand}
                                        </td>
                                    </tr>
                                )}
                                {isEmployee && parentCompany && (
                                    <tr>
                                        <td>
                                            {formatMessage(
                                                'catalog.parentCompany'
                                            )}
                                        </td>
                                        <td>
                                            {getCompanyIcon(
                                                parentCompanyAbbrName
                                            )}
                                            {parentCompany}
                                        </td>
                                    </tr>
                                )}
                                {isEmployee && (
                                    <tr>
                                        <td>{formatMessage('catalog.tier')}</td>
                                        <td>
                                            <Tiers tier={tier} />
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>
            {accounts.length > 1 && <div className={CLASSNAME_OVERLAY} />}
        </div>
    );
};

export default Menu;
