import React from 'react';
import { GridTable, HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { get } from 'lodash';
import TruncatedText from 'src/components/truncatedText';
import {
    ROUTE_PARTICIPANT,
    ROUTE_PRODUCT,
    ROUTE_SONG,
    ROUTE_SUBACCOUNT,
} from 'src/constants';
import {
    PARTICIPANT,
    PRODUCT,
    SONG,
    SOURCE_OF_STREAMS,
    SUBACCOUNT,
    TRACK,
} from 'src/constants/metrics';

interface OnlyClickHandler {
    (name: string): void;
}

export const CLASSNAME = 'SummaryRowTitleCell';

GridTable.defineColumnTemplate('summaryRowTitle', {
    align: 'left',
    minWidth: 'min-content',
    maxWidth: 'max-content',
    Cell: ({
        value,
        data: { id, upc, isrc, participantId, subaccountId, vendorId },
        column: { definition },
    }) => {
        if (id === 'TOTAL_ROW')
            return <div className={CLASSNAME}>{formatMessage('total')}</div>;

        const handleOnlyClick = get(definition, 'data-onOnlyClick') as
            | OnlyClickHandler
            | undefined;

        const dimension = get(definition, 'data-dimension') as
            | string
            | undefined;
        const isSosDimention = dimension === SOURCE_OF_STREAMS;

        const renderTitle = () => {
            const title = value || id;
            let titleData = null;

            switch (dimension) {
                case PRODUCT:
                    titleData = { link: ROUTE_PRODUCT, params: { upc } };
                    break;
                case TRACK:
                    titleData = { link: ROUTE_SONG, params: { isrc } };
                    break;
                case SONG:
                    titleData = { link: ROUTE_SONG, params: { isrc } };
                    break;
                case SUBACCOUNT:
                    titleData = {
                        link: ROUTE_SUBACCOUNT,
                        params: { accountId: vendorId, subaccountId },
                    };
                    break;
                case PARTICIPANT:
                    titleData = {
                        link: ROUTE_PARTICIPANT,
                        params: { id: participantId },
                    };
                    break;
            }

            if (titleData)
                return (
                    <span>
                        <TruncatedText
                            value={title?.toString()}
                            useLink
                            params={titleData.params}
                            route={titleData.link}
                        />
                    </span>
                );

            return <span>{title}</span>;
        };

        return (
            <div className={CLASSNAME}>
                <div>
                    {renderTitle()}
                    {isSosDimention && (
                        <HelpTooltip
                            id="pot-title"
                            className={`${CLASSNAME}-info`}
                            message={formatMessage(
                                `sourceOfStreams.tooltip.${id}`
                            )}
                        />
                    )}
                    <span
                        className="only"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleOnlyClick?.(id)}
                        onKeyDown={() => handleOnlyClick?.(id)}
                    >
                        {formatMessage('chart.only')}
                    </span>
                </div>
                {(upc || isrc) && (
                    <div className={`${CLASSNAME}-code`}>{upc || isrc}</div>
                )}
            </div>
        );
    },
});
