import dayjs from 'dayjs';
import { unionBy } from 'lodash';
import { SongAggregatedRankingsV2Query_publicSoundRecording_chartAggregatedRankingsV2 as SongQueryAggregatedRankingV2 } from 'src/apollo/definitions/SongAggregatedRankingsV2Query';
import { SongMetadataQuery_globalSoundRecordingByIsrc_catalogProducts_label } from 'src/apollo/definitions/SongMetadataQuery';
import { assertValue, filterData, nonNullable } from 'src/apollo/utils';
import { Account } from 'src/components/accountsDetailsPopup';
import { SME_DISTRIBUTION_TYPES } from 'src/constants/catalog';
import { PAST_28, PAST_365, PAST_6M, PAST_7 } from 'src/constants/periods';
import { DeltaGrowthPeriod } from '../definitions/globalTypes';
import { AggregatedRanking, Entity } from './types';

export const concatNames = (entities?: { name?: string | null }[]) =>
    unionBy(
        entities?.map(({ name }) => name).filter(name => name),
        name => name && name.toLowerCase()
    ).join(', ');

export const selectLabelName = (
    tracks: { product?: { label?: { name: string | null } | null } | null }[]
) => concatNames(tracks.map(track => track.product?.label || { name: null }));

export const selectReleaseDate = (
    tracks: {
        product?: { releaseDate?: { formatted: string | null } | null } | null;
    }[]
) =>
    tracks
        .map(track => track.product?.releaseDate?.formatted || undefined)
        .filter(date => date)
        .sort()[0];

export const toEntity = ({
    id,
    name,
    imageUrl,
}: {
    id: string;
    name: string | null;
    imageUrl?: string | null;
}) =>
    ({
        id,
        name: nonNullable(name) || '',
        imageUrl: nonNullable(imageUrl),
    } as Entity);

export const PERIOD_FREQ: Record<string, 'weeks' | 'days'> = {
    weekly: 'weeks',
    daily: 'days',
};

const calculatePositionalChange = ({
    frequency,
    mostRecentTimestamp,
    mostRecentPosition,
    previousTimestamp,
    previousPosition,
}: {
    frequency?: string | null;
    mostRecentTimestamp?: string;
    mostRecentPosition?: number;
    previousTimestamp?: string;
    previousPosition?: number;
}) => {
    const period = frequency ? PERIOD_FREQ[frequency] : null;

    const changeRange =
        period && mostRecentTimestamp && previousTimestamp
            ? dayjs(mostRecentTimestamp).diff(previousTimestamp, period)
            : 0;

    return changeRange === 1 && previousPosition && mostRecentPosition
        ? previousPosition - mostRecentPosition
        : undefined;
};

export const selectAccounts = (
    labels:
        | (SongMetadataQuery_globalSoundRecordingByIsrc_catalogProducts_label | null)[]
        | null,
    labelName: string | null
): Account[] => {
    if (!labels) return [];
    const accounts: Account[] = filterData(
        labels.map(label => {
            if (!label) return undefined;
            const baseAccountInfo = {
                id: label.id.subaccountId ?? label.id.vendorId,
                vendorId: label.id.vendorId,
                subaccountId: label.id.subaccountId,
                name: label.name,
                labelName: nonNullable(labelName),
            };
            if ('vendor' in label)
                return {
                    ...baseAccountInfo,
                    tier: label.vendor.serviceTier?.displayName,
                    brand: label.vendor.companyBrand?.displayName,
                    parentCompany:
                        label.vendor.companyBrand?.parentCompany?.displayName,
                    vendorName: label.vendor.name,
                    type: null,
                    brandAbbrName: label.vendor.companyBrand?.name,
                    parentCompanyAbbrName:
                        label.vendor.companyBrand?.parentCompany?.name,
                };
            if ('serviceTier' in label)
                return {
                    ...baseAccountInfo,
                    tier: label.serviceTier?.displayName,
                    brand: label.companyBrand?.displayName,
                    parentCompany:
                        label.companyBrand?.parentCompany?.displayName,
                    type: label.type,
                    brandAbbrName: label.companyBrand?.name,
                    parentCompanyAbbrName:
                        label.companyBrand?.parentCompany?.name,
                };
            return baseAccountInfo;
        })
    );
    // filter out vendors for which we have subaccounts, but leave vendors without subaccounts
    return accounts.filter(
        account =>
            account.subaccountId !== 0 ||
            accounts.find(
                item => item.vendorId === account.vendorId && item !== account
            ) === undefined
    );
};

export const selectAggregateRankingV2 = (
    ranking: Partial<SongQueryAggregatedRankingV2>,
    lastAvailableTimestamp?: string | null,
    frequency?: string | null
): AggregatedRanking => {
    const mostRecentTimestamp = nonNullable(ranking.mostRecentTimestamp) || '';
    const firstTimestamp = assertValue(ranking, 'firstTimestamp');
    const peakTimestamp = assertValue(ranking, 'peakTimestamp');
    const previousTimestamp = nonNullable(ranking.previousTimestamp);

    const peakPosition = assertValue(ranking, 'peakPosition');
    const firstPosition = assertValue(ranking, 'firstPosition');
    const mostRecentPosition = assertValue(ranking, 'mostRecentPosition');
    const daysOnChart = assertValue(ranking, 'daysOnChart');
    const previousPosition = nonNullable(ranking.previousPosition);

    const position =
        ranking.mostRecentTimestamp &&
        lastAvailableTimestamp &&
        dayjs
            .utc(lastAvailableTimestamp)
            .isSameOrBefore(dayjs.utc(mostRecentTimestamp))
            ? nonNullable(ranking.mostRecentPosition)
            : undefined;

    const change = position
        ? calculatePositionalChange({
              frequency,
              mostRecentTimestamp,
              previousTimestamp,
              previousPosition,
              mostRecentPosition,
          })
        : undefined;

    return {
        position,
        mostRecentPosition,
        mostRecentTimestamp,
        previousTimestamp,
        firstTimestamp,
        peakTimestamp,
        peakPosition,
        previousPosition,
        firstPosition,
        daysOnChart,
        change,
    };
};

export const selectImageUrl = (
    imageUrl: string | null | undefined,
    catalogImageUrl: string | null | undefined,
    isSMEProduct: boolean
): string | null | undefined =>
    catalogImageUrl && !isSMEProduct ? catalogImageUrl : imageUrl;

export const isSMEProduct = (
    notForDistribution: string | null | undefined
): boolean => SME_DISTRIBUTION_TYPES.includes(notForDistribution ?? '');

export const getPreviousMonthNames = (lastAvailableDate: string | undefined) =>
    [
        dayjs(lastAvailableDate),
        dayjs(lastAvailableDate).subtract(1, 'month'),
        dayjs(lastAvailableDate).subtract(2, 'month'),
    ].map(day => day.format('MMM'));

export const getGrowthPeriod = (
    dateFilter?: string | null
): DeltaGrowthPeriod => {
    switch (dateFilter) {
        case PAST_7:
            return DeltaGrowthPeriod._7_DAYS;
        case PAST_28:
            return DeltaGrowthPeriod._28_DAYS;
        case PAST_6M:
            return DeltaGrowthPeriod._183_DAYS;
        case PAST_365:
            return DeltaGrowthPeriod._365_DAYS;
        default:
            return DeltaGrowthPeriod._ALL_TIME;
    }
};
