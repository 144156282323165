import React, { FC } from 'react';
import { Tooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { uniqueId } from 'lodash';
import { offerTypesIcons } from 'src/components/offerTypeIcons';

export interface Props {
    offerTypeKey: { name: string; active: boolean; term: string };
}

export const CLASSNAME = 'OfferTypeIcon';

const OfferTypeIcon: FC<Props> = ({ offerTypeKey }) => {
    const key = `${offerTypeKey.name}-${
        offerTypeKey.active ? 'Y' : 'N'
    }` as keyof typeof offerTypesIcons;
    const Icon = offerTypesIcons[key];

    if (!Icon) return null;

    return (
        <Tooltip
            id={`${CLASSNAME}_${offerTypeKey.name}_${uniqueId()}`}
            message={formatMessage(offerTypeKey.term)}
            className={CLASSNAME}
        >
            <Icon />
        </Tooltip>
    );
};

export default OfferTypeIcon;
