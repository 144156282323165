import { filterData } from 'src/apollo/utils';

interface UnfilteredTimeseriesItem {
    date: string | null;
    value?: number | null;
    skipRate?: number | null;
    saves?: number | null;
}

export interface TimeseriesItem {
    date: string;
    value: number | null;
    skipRate: number | null;
    saves: number | null;
}
export type Timeseries = TimeseriesItem[];

export const selectTimeseries = (
    series: (UnfilteredTimeseriesItem | undefined | null)[] | undefined | null
): Timeseries =>
    filterData(series ?? []).reduce<TimeseriesItem[]>(
        (items, item) =>
            item.date !== null
                ? [
                      ...items,
                      {
                          date: item.date,
                          skipRate: item.skipRate ?? null,
                          saves: item.saves ?? null,
                          value: item.value ?? null,
                      },
                  ]
                : items,
        []
    );
