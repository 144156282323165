import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { compact } from 'lodash';
import { VideoQuery, VideoQueryVariables } from '../../definitions/VideoQuery';
import { selectVideo } from '../../selectors';
import { flattenError, useParallelQueries } from '../../utils';
import videoQuery from './video.gql';

export const useVideoQuery = (variables: VideoQueryVariables) => {
    const { data, loading, error } = useQuery<VideoQuery, VideoQueryVariables>(
        videoQuery,
        {
            variables: {
                ...variables,
            },
        }
    );

    return {
        loading,
        data: selectVideo(data),
        error: error && flattenError(error),
    };
};

export const useVideoBatchQuery = (ids?: string[]) => {
    const client = useApolloClient();
    const variables = Array.isArray(ids)
        ? ids.map(id => ({
              id,
          }))
        : [];

    const { data, loading, error } = useParallelQueries<
        VideoQuery,
        VideoQueryVariables
    >(videoQuery, { variables, client, skip: variables.length === 0 });

    const videoData = useMemo(
        () => compact(data?.map(video => selectVideo(video))),
        [data]
    );

    return {
        loading,
        data: videoData,
        error,
    };
};
