import { useQuery } from '@apollo/client';
import { selectChannelMetrics } from 'src/apollo/selectors/channelMetrics';
import {
    ChannelAnalyticsDailyQuery,
    ChannelAnalyticsDailyQueryVariables,
} from '../../definitions/ChannelAnalyticsDailyQuery';
import { flattenError } from '../../utils';
import channelAnalyticsDailyQuery from './channelAnalyticsDaily.gql';

export const useChannelAnalyticsDailyQuery = (
    variables: ChannelAnalyticsDailyQueryVariables
) => {
    const { data, loading, error } = useQuery<
        ChannelAnalyticsDailyQuery,
        ChannelAnalyticsDailyQueryVariables
    >(channelAnalyticsDailyQuery, { variables });

    return {
        loading,
        data: selectChannelMetrics(data?.channel?.analytics.daily),
        error: error && flattenError(error),
    };
};
