import { BRAND_SME, SuitePlugin } from '@theorchard/suite-frontend';

const MAX_POT_COLORS_COUNT = 11;

const OverrideBrandColorsPlugin: SuitePlugin = {
    onLoaded({ identity: { defaultBrand }, config: { brand } }) {
        if (defaultBrand === BRAND_SME || brand === BRAND_SME) {
            const root = document.querySelector<HTMLElement>(':root');
            if (!root) return;

            for (let i = 1; i <= MAX_POT_COLORS_COUNT; i++)
                root.style.removeProperty(`--dataviz-${i}`);
        }
    },
};

export default OverrideBrandColorsPlugin;
