import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import FormattedChange from 'src/components/formattedChange';

GridTable.defineColumnTemplate('changeV2', {
    align: 'right',
    sortable: true,
    Cell: ({ value, column: { definition } }) => {
        const useEmptyChar = get(definition, 'data-useEmptyChar', false);
        return <FormattedChange change={value} useEmptyChar={useEmptyChar} />;
    },
});
