import { useCallback, useState } from 'react';
import { ApolloClient, FieldPolicy } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { get } from 'lodash';
import { assertValue } from './data';

interface OffsetLimit {
    limit?: number | null;
    offset?: number | null;
}

export const useDisplayLimitedData = (defaultCount?: number) => {
    const [count, setCount] = useState<number | undefined>(defaultCount);

    const showCount = useCallback((nextCount?: number) => {
        setCount(nextCount);
    }, []);

    return { showCount, count };
};

export const offsetLimitPaginationField = <V extends OffsetLimit>(
    keyArgs?: string[],
    dataKey?: string
): FieldPolicy => ({
    keyArgs,
    merge(existing, incoming, { args }) {
        const variables = args as V;
        const limit = assertValue(variables, 'limit');
        const offset = assertValue(variables, 'offset');

        if (!incoming) return undefined;

        if (dataKey && !Object.hasOwnProperty.call(incoming, dataKey))
            throw new Error(
                'dataKey property does not exist in incoming object'
            );

        const incomingData = dataKey ? incoming[dataKey] : incoming;
        const existingData = (dataKey ? existing?.[dataKey] : existing) ?? [];

        const end = offset + incomingData.length;
        const isLastPage = end - offset < limit;

        const merged = isLastPage
            ? existingData.slice(0, end)
            : existingData.slice(0);

        for (let i = offset; i < end; ++i) merged[i] = incomingData[i - offset];

        return dataKey
            ? { ...existing, ...incoming, [dataKey]: merged }
            : merged;
    },
});

export const evictQueryFromCache = <T>(
    query: DocumentNode,
    client: ApolloClient<T>
) => {
    const [queryDef] = query.definitions;
    const fieldName = get(queryDef, 'selectionSet.selections[0].name.value');

    client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName,
    });
};
