import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { uniq } from 'lodash';
import { ERROR_UNAUTHENTICATED, ERROR_NETWORK } from 'src/constants';

interface ApolloNetworkError extends Error {
    result: { errors: GraphQLError[] };
}

export interface FlattenedApolloError extends Error {
    data?: object;
}

const NETWORK_ERROR = 'Network error: undefined';
const NETWORK_ERROR_AUTH = 'Network error: Not authenticated';
const ERROR_NAME = 'Error';

const concatErrors = (error: ApolloError) => {
    const { graphQLErrors, networkError } = error;
    const { result: { errors = [] } = {} } = (networkError ||
        {}) as unknown as ApolloNetworkError;
    return [error, networkError]
        .concat(graphQLErrors)
        .concat(errors)
        .filter(e => e)
        .reverse() as Error[];
};

const concatStrings = <T>(values: T[], key: keyof T) =>
    uniq(values.map(value => value[key]).filter(value => value)).join('\n');

/* eslint-disable indent */
const mapErrorMessage = (message: string) => {
    switch (message) {
        case NETWORK_ERROR:
            return 'Failed to connect to graphql server. Please check your network status.';
        default:
            return message;
    }
};

const getErrorName = (name: string, message: string) => {
    if (name !== ERROR_NAME) return name;

    switch (message) {
        case NETWORK_ERROR:
            return ERROR_NETWORK;
        case NETWORK_ERROR_AUTH:
            return ERROR_UNAUTHENTICATED;
        default:
            return ERROR_NAME;
    }
};
/* eslint-enable indent */

export const flattenError = (error: ApolloError): FlattenedApolloError => {
    const { extraInfo, name } = error;
    const errors = concatErrors(error);
    const message = concatStrings(errors, 'message');
    const stack = concatStrings(errors, 'stack');

    const { extensions } = (errors.find(e => 'extensions' in e) ||
        {}) as GraphQLError;

    return {
        message: mapErrorMessage(message),
        name: extensions ? 'GraphQLError' : getErrorName(name, error.message),
        stack,
        data:
            extraInfo || extensions
                ? { ...(extraInfo || {}), ...(extensions || {}) }
                : undefined,
    };
};
