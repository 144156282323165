import { minBy } from 'lodash';
import orderBy from 'lodash/orderBy';
import { AggregateStreamsQuery } from 'src/apollo/definitions/AggregateStreamsQuery';
import { CachedGlobalSoundRecording } from 'src/apollo/definitions/CachedGlobalSoundRecording';
import {
    GlobalSoundRecordingSummaryQuery_globalSoundRecordingByIsrc_analytics_summary_items as SummaryItem,
    GlobalSoundRecordingSummaryQuery as SummaryQuery,
} from 'src/apollo/definitions/GlobalSoundRecordingSummaryQuery';
import { MarketRanksQuery } from 'src/apollo/definitions/MarketRanksQuery';
import {
    SongAggregatedDailyRankingsV2Query,
    SongAggregatedDailyRankingsV2Query_publicSoundRecording_chartAggregatedRankingsV2 as DailyRankings,
} from 'src/apollo/definitions/SongAggregatedDailyRankingsV2Query';
import {
    SongAggregatedRankingsV2Query_publicSoundRecording_chartAggregatedRankingsV2 as ChartAggregatedRankingsV2,
    SongAggregatedRankingsV2Query_publicSoundRecording_chartAggregatedRankingsV2 as SongQueryAggregatedRankingV2,
} from 'src/apollo/definitions/SongAggregatedRankingsV2Query';
import {
    SongMetadataQuery,
    SongMetadataQuery_globalSoundRecordingByIsrc_catalogProducts,
} from 'src/apollo/definitions/SongMetadataQuery';
import { StarredSoundRecordingsQuery } from 'src/apollo/definitions/StarredSoundRecordingsQuery';
import { StarredSoundRecordingsStreamsQuery } from 'src/apollo/definitions/StarredSoundRecordingsStreamsQuery';
import { TikTokGainersQuery } from 'src/apollo/definitions/TikTokGainersQuery';
import { selectAccounts } from 'src/apollo/selectors/utils';
import { assertValue, filterData, nonNullable } from 'src/apollo/utils';
import { Account } from 'src/components/accountsDetailsPopup/types';
import { EMPTY_CHAR } from 'src/constants';
import {
    ChartDefinition,
    ChartDictionary,
    ChartInstance,
    createDefinition,
    getDefinitionKey,
} from 'src/constants/charts';
import { COUNTRY_UNKNOWN } from 'src/constants/countries';
import { STORE_IDS_BY_NAME } from 'src/constants/stores';
import {
    TimeseriesSummaryDataWithPercentage,
    getPercentage,
} from 'src/utils/metrics';
import { DeltaGrowthPeriod } from '../definitions/globalTypes';
import { AggregatedRanking, Entity } from './types';
import {
    concatNames,
    isSMEProduct,
    selectAggregateRankingV2,
    selectImageUrl,
    toEntity,
} from './utils';

export interface SongAggregatedRanking extends AggregatedRanking {
    chart: ChartInstance;
    country: string;
}

export interface SongAggregatedRankingWithMarketSize
    extends SongAggregatedRanking {
    marketSize?: number;
}

export interface SongMetadata {
    id: string;
    publicId?: string;
    isrc: string;
    name?: string;
    imageUrl?: string;
    labelName?: string;
    participants: Entity[];
    displayArtist?: string;
    releaseDate?: string;
    salesStartDate?: string;
    isPartOfCatalog?: boolean;
    isSongTakenDown?: boolean;
    spotifyId?: string;
    following: boolean;
    catalogProducts:
        | SongMetadataQuery_globalSoundRecordingByIsrc_catalogProducts[]
        | null;
    accounts?: Account[];
}

export interface SongAggregateStreams {
    allTime: number;
    growth: number;
}

export interface PublicSoundRecording {
    id: string;
    chartAggregatedRankingsV2?: ChartAggregatedRankingsV2[] | null;
}

export interface SongAggregatedRankings {
    publicSoundRecording: PublicSoundRecording | null;
}

export const selectSongMetadataFromCache = (
    data: CachedGlobalSoundRecording | null
): SongMetadata | undefined => {
    if (!data) return undefined;

    const isrc = assertValue(data, 'isrc');
    const { name, globalParticipants, releaseDateV2, catalogProducts } = data;

    const participants = filterData(globalParticipants).map(toEntity);

    return {
        id: '',
        publicId: undefined,
        isrc,
        name: nonNullable(name) ?? EMPTY_CHAR,
        displayArtist: concatNames(participants),
        participants,
        imageUrl: undefined,
        labelName: undefined,
        releaseDate: nonNullable(
            releaseDateV2 ?? catalogProducts?.[0]?.releaseDate
        ),
        salesStartDate: undefined,
        isPartOfCatalog: undefined,
        isSongTakenDown: undefined,
        spotifyId: undefined,
        following: false,
        catalogProducts: null,
    };
};

export const selectSongMetadata = (
    data: SongMetadataQuery
): SongMetadata | undefined => {
    const globalSoundRecording = data.globalSoundRecordingByIsrc;
    if (!globalSoundRecording) return undefined;

    const isrc = assertValue(globalSoundRecording, 'isrc');
    const {
        id,
        name,
        imageUrl,
        following,
        catalogProducts,
        catalogLabelsV2,
        soundRecording,
        globalParticipants,
        labelSoundRecordings,
        releaseDateV2,
        imprint,
        labelName,
    } = globalSoundRecording;

    const filteredProducts = filterData(catalogProducts);
    const allProductsWereDeleted =
        catalogProducts?.filter(product => product?.deletions === 'true')
            ?.length === catalogProducts?.length;
    const isSongTakenDown =
        allProductsWereDeleted && filteredProducts?.length >= 1;
    const earliestCatalogProduct = minBy(
        filteredProducts,
        ({ releaseDate: productReleaseDate }) =>
            productReleaseDate && Date.parse(productReleaseDate)
    );
    const productsWithoutDeletions = filteredProducts.filter(
        product => product?.deletions === 'false'
    );
    const earliestProductWithoutDeletions = minBy(
        productsWithoutDeletions,
        ({ releaseDate: productReleaseDate }) =>
            productReleaseDate && Date.parse(productReleaseDate)
    );

    const participants = filterData(globalParticipants).map(participant => {
        let role: undefined | string;
        labelSoundRecordings.forEach(({ tracks }) =>
            tracks.map(track =>
                track.participations.forEach(p => {
                    if (p.participant?.globalParticipant?.id === participant.id)
                        role = p.participatedAs;
                })
            )
        );
        return {
            id: participant.id,
            name: nonNullable(participant.name) || '',
            imageUrl: nonNullable(participant.imageUrl),
            role,
        };
    });
    return {
        id,
        publicId: soundRecording?.id,
        isrc,
        name: nonNullable(name) ?? EMPTY_CHAR,
        displayArtist: concatNames(participants),
        participants,
        imageUrl: nonNullable(
            selectImageUrl(
                imageUrl,
                earliestProductWithoutDeletions?.imageLocation
                    ? earliestProductWithoutDeletions.imageLocation
                    : catalogProducts?.[0]?.imageLocation,
                isSMEProduct(catalogProducts?.[0]?.notForDistribution)
            )
        ),
        labelName: labelName ?? nonNullable(imprint),
        releaseDate: nonNullable(
            releaseDateV2 ?? earliestCatalogProduct?.releaseDate
        ),
        salesStartDate: earliestCatalogProduct?.releaseDate ?? undefined,
        isPartOfCatalog: Boolean(
            labelSoundRecordings?.length || filteredProducts?.length
        ),
        isSongTakenDown: Boolean(isSongTakenDown),
        spotifyId:
            soundRecording?.tracks.find(track => track.spotifyId)?.spotifyId ??
            undefined,
        following,
        catalogProducts: filteredProducts,
        accounts: selectAccounts(catalogLabelsV2, labelName),
    };
};

export const selectSongAggregatedRankingsV2 = (
    rankings: SongQueryAggregatedRankingV2[],
    chartsById: ChartDictionary
): SongAggregatedRanking[] =>
    filterData(
        rankings.map(row => {
            if (!row.chartId)
                throw new Error('Missing "chart" in song ranking');
            const chart = chartsById[row.chartId];
            // If the chart is not in the charts index, it most likely means it is under feature control.
            if (!chart) return undefined;

            return {
                ...selectAggregateRankingV2(
                    row,
                    chart.lastAvailableTimestamp,
                    chart.frequency
                ),
                key:
                    row.publicSoundRecordingId && row.trackId
                        ? `${row.chartId}-${row.publicSoundRecordingId}-${row.trackId}`
                        : row.chartId,
                chart,
                country: chart.country || COUNTRY_UNKNOWN,
            };
        })
    );

export const selectSongRankingsV2 = (
    chartsById: ChartDictionary,
    data: SongAggregatedRankings
): SongAggregatedRanking[] => {
    const song = data.publicSoundRecording;
    return song && song.chartAggregatedRankingsV2
        ? selectSongAggregatedRankingsV2(
              song.chartAggregatedRankingsV2,
              chartsById
          )
        : [];
};

export const selectSongAggregateStreams = (
    aggregateStreams: AggregateStreamsQuery
): SongAggregateStreams => ({
    allTime:
        aggregateStreams?.globalSoundRecordingByIsrc?.analytics
            ?.aggregateStreams.allTime ?? 0,
    growth:
        aggregateStreams?.globalSoundRecordingByIsrc?.analytics
            ?.aggregateStreams.growthPercentage7Days.growthPercentage ?? 0,
});

export interface StarredSong {
    isrc: string;
    songName: string;
    imageUrl: string | null;
    participants: Entity[];
    releaseDate: string | null;
    labelName: string | null;
    isPartOfCatalog: boolean;
    following: boolean;
    dateFollowed: string;
}

export interface StarredSongStreams {
    isrc: string;
    streamsAllTime: number | null;
}

export const selectStarredSoundRecordings = (
    data: StarredSoundRecordingsQuery
): StarredSong[] =>
    data.starredSoundRecordings.map(
        ({
            isrc,
            name,
            imageUrl,
            catalogProducts,
            globalParticipants,
            releaseDateV2,
            labelName,
            labelSoundRecordings,
            following,
            dateFollowed,
        }) => ({
            isrc,
            songName: name,
            imageUrl:
                selectImageUrl(
                    imageUrl,
                    catalogProducts?.[0]?.imageLocation,
                    isSMEProduct(catalogProducts?.[0]?.notForDistribution)
                ) ?? null,
            participants: filterData(globalParticipants).map(toEntity),
            releaseDate: releaseDateV2 ?? null,
            labelName,
            isPartOfCatalog: Boolean(
                labelSoundRecordings.length ||
                    filterData(catalogProducts)?.length
            ),
            following,
            dateFollowed,
        })
    );

export const selectStarredSoundRecordingsStreams = (
    data: StarredSoundRecordingsStreamsQuery
): StarredSongStreams[] =>
    data.starredSoundRecordings.map(({ isrc, analytics }) => ({
        isrc,
        streamsAllTime: analytics?.streams.aggregate.allTime ?? null,
    }));

export const selectGlobalSoundRecordingSummary = (data?: SummaryQuery) => {
    if (!data) return undefined;

    const items = filterData(
        data?.globalSoundRecordingByIsrc?.analytics?.summary.items
    );

    return items;
};

export const selectPerformanceOverTimeSummary = (
    data?: SummaryItem[],
    totals?: Partial<SummaryItem>[]
): (SummaryItem & TimeseriesSummaryDataWithPercentage)[] | undefined => {
    if (!data || !totals) return data;

    const [total] = totals;
    const timeseriesWithPercentage = total
        ? data.map(summary => ({
              ...summary,
              totalStreamsPercentage: getPercentage(summary.value, total.value),
          }))
        : data;

    return timeseriesWithPercentage;
};

export interface TiktokGainers {
    index: number;
    isrc: string;
    imprint: string | null;
    imageUrl: string | null;
    songName: string;
    participants: Entity[];
    accounts: Account[];
    creations1Day: number | null;
    creations1DayPrev?: number | null;
    creationsGrowthPercentage1Day?: number | null;
    creations7Days: number | null;
    creations7DaysPrev?: number | null;
    creationsGrowthPercentage7Days?: number | null;
    activeStreams1Day?: number | null;
    activeStreams1DayPrev?: number | null;
    activeGrowthPercentage1Day?: number | null;
    activeStreams7Days?: number | null;
    activeStreams7DaysPrev?: number | null;
    activeGrowthPercentage7Days?: number | null;
}

export const selectTiktokGainers = (
    data: TikTokGainersQuery,
    offset?: number | null
) => {
    const [first] = data.topGlobalSoundRecordingsTiktok;
    const totalCount = first?.analytics?.streams.totalCount ?? 0;

    const items: TiktokGainers[] = data.topGlobalSoundRecordingsTiktok.map(
        (
            {
                isrc,
                name,
                imageUrl,
                catalogLabelsV2,
                globalParticipants,
                analytics,
                labelName,
                imprint,
                releaseDateV2,
            },
            index
        ) => {
            const tiktokAggregations = analytics?.tiktokAggregations;
            const growthPeriods = analytics?.streams?.growthPeriods || [];
            const growthPeriod1Day = growthPeriods.find(
                ({ period }) => period === DeltaGrowthPeriod._1_DAY
            );
            const growthPeriod7Days = growthPeriods.find(
                ({ period }) => period === DeltaGrowthPeriod._7_DAYS
            );

            return {
                index: index + (offset || 0) + 1,
                isrc,
                imageUrl,
                imprint,
                songName: name,
                releaseDate: releaseDateV2,
                participants: filterData(globalParticipants).map(toEntity),
                accounts: selectAccounts(catalogLabelsV2, labelName),
                creations1Day: tiktokAggregations?.creations_1_day ?? null,
                creations1DayPrev:
                    tiktokAggregations?.creations_1_day_prev ?? null,
                creationsGrowthPercentage1Day:
                    tiktokAggregations?.creations_growth_percentage_1_day ??
                    null,
                creations7Days: tiktokAggregations?.creations_7_days ?? null,
                creations7DaysPrev:
                    tiktokAggregations?.creations_7_days_prev ?? null,
                creationsGrowthPercentage7Days:
                    tiktokAggregations?.creations_growth_percentage_7_days ??
                    null,
                activeStreams1Day: growthPeriod1Day?.activeValue ?? null,
                activeStreams1DayPrev:
                    growthPeriod1Day?.prevActiveValue ?? null,
                activeGrowthPercentage1Day:
                    growthPeriod1Day?.activeGrowthPercentage ?? null,
                activeStreams7Days: growthPeriod7Days?.activeValue ?? null,
                activeStreams7DaysPrev:
                    growthPeriod7Days?.prevActiveValue ?? null,
                activeGrowthPercentage7Days:
                    growthPeriod7Days?.activeGrowthPercentage ?? null,
            };
        }
    );

    return {
        totalCount,
        items,
    };
};

export interface SongAggregatedDailyRankings {
    chart: ChartInstance;
    country: string;
    marketSize?: number;
    position?: number;
    peakDate?: string;
    peakPosition?: number;
}
export const selectSongAggregatedDailyRankings = (
    data?: SongAggregatedDailyRankingsV2Query,
    markets?: MarketRanksQuery,
    chartDefinitions?: ChartDefinition[]
): SongAggregatedDailyRankings[] => {
    let rankings = data?.publicSoundRecording?.chartAggregatedRankingsV2;
    if (!rankings) return [];

    if (chartDefinitions?.length) {
        const countryRankings = chartDefinitions.flatMap(definition => {
            const key = getDefinitionKey(definition);
            const isChartInRankings = rankings?.some(
                ({ chart }) => getDefinitionKey(chart) === key
            );
            if (!isChartInRankings) return [];

            const {
                platform,
                type = null,
                target = null,
                frequency = null,
                genre = null,
            } = definition;
            const missingCountries = definition.countries.filter(
                ({ value }) =>
                    !rankings?.some(
                        ({ chart }) =>
                            getDefinitionKey(chart) === key &&
                            chart.country === value
                    )
            );

            const missingDailyRankings = missingCountries.map<DailyRankings>(
                ({ id, value, lastAvailableTimestamp = null }) => ({
                    chart: {
                        id,
                        country: value,
                        lastAvailableTimestamp,
                        platform,
                        type,
                        target,
                        frequency,
                        genre,
                        modifiedAt: null,
                    },
                    isrc: null,
                    peakDate: '',
                    peakPosition: 0,
                    onDate: {
                        position: 0,
                    },
                })
            );
            return missingDailyRankings;
        });
        rankings = [...rankings, ...countryRankings];
    }

    return orderBy(
        filterData(
            rankings.map<SongAggregatedDailyRankings | null>(row => {
                if (!row.chart) return null;

                const chartInstance = {
                    id: assertValue(row.chart, 'id'),
                    modifiedAt: nonNullable(row.chart.modifiedAt),
                    lastAvailableTimestamp: nonNullable(
                        row.chart.lastAvailableTimestamp
                    ),
                    platform: nonNullable(row.chart.platform),
                    type: nonNullable(row.chart.type),
                    genre: nonNullable(row.chart.genre),
                    frequency: nonNullable(row.chart.frequency),
                    target: nonNullable(row.chart.target),
                    country: nonNullable(row.chart.country),
                };
                const chart: ChartInstance = {
                    ...chartInstance,
                    definition: createDefinition(chartInstance),
                };

                const country = chart.country || COUNTRY_UNKNOWN;
                const chartMarketRanks = markets?.marketRanks.find(
                    item =>
                        item.storeId ===
                            STORE_IDS_BY_NAME[chart.platform || ''] &&
                        item.countryCode === country
                );
                const marketSize = chartMarketRanks?.marketRank;

                return {
                    chart,
                    country,
                    marketSize,
                    position: row.onDate.position || undefined,
                    peakDate: row.peakDate || undefined,
                    peakPosition: row.peakPosition || undefined,
                };
            })
        ),
        'marketSize'
    );
};
