import React from 'react';
import { GridTable, Tooltip } from '@theorchard/suite-components';
import { uniqueId } from 'lodash';
import TruncatedText from 'src/components/truncatedText';
import { EMPTY_CHAR, ROUTE_PARTICIPANT } from 'src/constants';

export const CLASSNAME = 'ParticipantLinkWithCounterCell';
export const CLASSNAME_COUNTER = `${CLASSNAME}_counter`;

GridTable.defineColumnTemplate('participantLinkWithCounter', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { participants } }) => {
        const message = participants?.map(({ name }) => name).join(', ');

        if (!participants || !message) return <span>{EMPTY_CHAR}</span>;

        const title = participants[0].name;
        const id = participants[0].id;
        const isMultipleArtists = participants.length > 1;
        const invalidId = id === EMPTY_CHAR;

        if (invalidId) return <span>{title}</span>;

        return (
            <Tooltip
                id={`participantLinkWithCounter_${uniqueId()}`}
                message={message}
                className={CLASSNAME}
            >
                <TruncatedText
                    value={title}
                    useLink
                    route={ROUTE_PARTICIPANT}
                    params={{ id }}
                    withOverlay={false}
                />
                {isMultipleArtists && (
                    <span className={CLASSNAME_COUNTER}>{`+${
                        participants.length - 1
                    }`}</span>
                )}
            </Tooltip>
        );
    },
});
