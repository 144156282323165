import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import { concatNames } from 'src/apollo/selectors';
import TruncatedText from 'src/components/truncatedText';
import SongLinkCell from './songLinkCell';

GridTable.defineColumnTemplate('thumbnailSongLinkWithIsrcAndArtist', {
    align: 'left',
    Cell: ({ data, ...rest }) => {
        const { participants } = data;
        const areParticipantsAvailable = participants?.length !== 0;

        return (
            <div className="ThumbnailSongLinkWithIsrcCell CoverArtCell">
                <CoverArt url={data.imageUrl} width="30" />
                <div className="ThumbnailSongLinkWithIsrcCell-label">
                    <SongLinkCell data={data} {...rest} />
                    {(data.isrc || areParticipantsAvailable) && (
                        <div className="ThumbnailSongLinkWithIsrcCell-irsc-v2">
                            {areParticipantsAvailable && (
                                <TruncatedText
                                    value={concatNames(participants)}
                                />
                            )}
                            {data.isrc && areParticipantsAvailable && (
                                <>&nbsp;&#8209;&nbsp;</>
                            )}
                            {data.isrc}
                        </div>
                    )}
                </div>
            </div>
        );
    },
});
