import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { compact } from 'lodash';
import participantStatsV2Query from 'src/apollo/queries/participant/globalParticipantStatsV2.gql';
import { GlobalParticipantAnalyticsQuery } from '../../definitions/GlobalParticipantAnalyticsQuery';
import {
    GlobalParticipantQuery as Query,
    GlobalParticipantQueryVariables as Vars,
} from '../../definitions/GlobalParticipantQuery';
import { GlobalParticipantStatsV2Query } from '../../definitions/GlobalParticipantStatsV2Query';
import {
    selectParticipant,
    selectParticipantStatsV2,
    selectParticipantAnalytics,
} from '../../selectors/participant';
import { flattenError, useParallelQueries } from '../../utils';
import participantQuery from './globalParticipant.gql';
import participantAnalyticsQuery from './globalParticipantAnalytics.gql';

export const useParticipantQuery = (id?: string) => {
    const { data, loading, error } = useQuery<Query>(participantQuery, {
        variables: { id },
        skip: !id,
    });

    return {
        loading,
        data: data && selectParticipant(data),
        error: error && flattenError(error),
    };
};

export const useParticipantBatchQuery = (ids?: string[]) => {
    const client = useApolloClient();
    const variables: Vars[] = Array.isArray(ids)
        ? ids.map<Vars>(id => ({ id }))
        : [];
    const { data, loading, error } = useParallelQueries<
        GlobalParticipantAnalyticsQuery,
        Vars
    >(participantAnalyticsQuery, { variables, client });
    const participantsData = useMemo(
        () =>
            compact(
                data?.map(participant =>
                    selectParticipantAnalytics(participant)
                )
            ),
        [data]
    );

    return {
        loading,
        data: participantsData,
        error,
    };
};

export const useParticipantStatsQuery = (id?: string) => {
    const { data, loading, error } = useQuery<GlobalParticipantStatsV2Query>(
        participantStatsV2Query,
        { variables: { id }, skip: !id }
    );

    return {
        loading,
        data: data && selectParticipantStatsV2(data),
        error: error && flattenError(error),
    };
};
