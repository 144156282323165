import { useCallback } from 'react';
import { useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { LABELS_LIMIT } from 'src/constants/catalog';
import {
    OrchardLabelSearch,
    OrchardLabelSearchVariables,
} from '../../definitions/OrchardLabelSearch';
import { Label, selectOrchardLabels } from '../../selectors/labels';
import { flattenError } from '../../utils';
import orchardLabelSearchQuery from './orchardLabelSearch.gql';

interface OrchardLabelSearchResponse {
    loading: boolean;
    error?: Error;
    data?: Label[];
}

interface OrchardLabelSearchhandler {
    (
        variables: QueryLazyOptions<OrchardLabelSearchVariables>
    ): Promise<OrchardLabelSearchResponse>;
}

export type OrchardLabelsSearchResult = [
    OrchardLabelSearchhandler,
    OrchardLabelSearchResponse
];

export const useOrchardLabelSearchQuery: () => OrchardLabelsSearchResult =
    () => {
        const [internalExecuteSearch, { data, loading, error }] = useLazyQuery<
            OrchardLabelSearch,
            OrchardLabelSearchVariables
        >(orchardLabelSearchQuery, {
            variables: { limit: LABELS_LIMIT, term: '' },
        });

        const executeSearch = useCallback<OrchardLabelSearchhandler>(
            async args => {
                const { data: searchData } = await internalExecuteSearch(args);
                return {
                    loading: false,
                    data:
                        searchData &&
                        selectOrchardLabels(searchData.orchardLabelSearch),
                };
            },
            [internalExecuteSearch]
        );

        return [
            executeSearch,
            {
                loading,
                data: data && selectOrchardLabels(data.orchardLabelSearch),
                error: error && flattenError(error),
            },
        ];
    };
