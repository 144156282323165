import React, { FC, ReactEventHandler } from 'react';
import cx from 'classnames';
import { fallbackImage } from 'src/constants';

export const CLASSNAME = 'CoverArt';
export const CLASSNAME_EMPTY = `${CLASSNAME}-empty`;
export const CLASSNAME_IMG = `${CLASSNAME}-image`;

export type Width =
    | '100pct'
    | '20'
    | '30'
    | '40'
    | '50'
    | '53'
    | '60'
    | '70'
    | '80'
    | '120'
    | '160';
export type Shape = 'square' | 'round' | 'letterbox' | 'landscape';
export type LoadingType = 'eager' | 'lazy';

export interface Props {
    url?: string | null;
    shape?: Shape;
    width?: Width;
    className?: string;
    loadingType?: LoadingType;
    onError?: ReactEventHandler<HTMLImageElement>;
    usePlaceholder?: boolean;
}

// @NOTE: be careful, `loading` must be always before `src` otherwise it does not work with Firefox, it's a bug from them.
const CoverArt: FC<Props> = ({
    url,
    className,
    width = '80',
    shape = 'square',
    children,
    loadingType = 'lazy',
    onError,
    usePlaceholder = false,
}) => {
    const handleImageError: ReactEventHandler<HTMLImageElement> = ({
        currentTarget,
    }) => {
        currentTarget.onerror = null;
        currentTarget.src = fallbackImage;
    };

    return (
        <div
            className={cx(CLASSNAME, className, {
                [shape]: shape,
                [width === '100pct' ? `w${width}` : `w${width}px`]: width,
            })}
        >
            {(url || usePlaceholder) && (
                <img
                    className={CLASSNAME_IMG}
                    alt="cover art"
                    loading={loadingType}
                    src={url || fallbackImage}
                    onError={onError || handleImageError}
                />
            )}
            {!url && !usePlaceholder && (
                <div className={cx(CLASSNAME_EMPTY, CLASSNAME_IMG)} />
            )}
            {children}
        </div>
    );
};

export default CoverArt;
