import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import AccountsDetailsPopup, {
    Placement,
} from 'src/components/accountsDetailsPopup';

GridTable.defineColumnTemplate('accountDetails', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { accounts = [] }, column: { definition } }) => {
        const placement = get(definition, 'data-placement') as
            | Placement
            | undefined;
        return (
            <span className="AccountCell" data-testid="AccountCell">
                <AccountsDetailsPopup
                    accounts={accounts}
                    placement={placement}
                />
            </span>
        );
    },
});
