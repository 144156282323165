import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';

GridTable.defineColumnTemplate('discTrackNumbers', {
    align: 'right',
    sortable: false,
    Cell: ({ data: { volumeNumber, trackNumber } }) => {
        const value =
            volumeNumber > 1
                ? `${volumeNumber}/${trackNumber}`
                : trackNumber.toString();

        return <TruncatedText className="DiscTrackNumbersCell" value={value} />;
    },
});
