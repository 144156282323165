import { useQuery } from '@apollo/client';
import {
    SoundRecordingPlaylistAllStreamsByStore as QueryData,
    SoundRecordingPlaylistAllStreamsByStoreVariables as QueryVars,
} from '../../definitions/SoundRecordingPlaylistAllStreamsByStore';
import Query from './soundRecordingPlaylistAllStreamsByStore.gql';

export const useSoundRecordingPlaylistAllStreamsByStore = (
    variables: QueryVars,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<QueryData, QueryVars>(Query, {
        variables,
        skip,
    });
    const result =
        data?.globalSoundRecordingByIsrc?.analytics
            ?.totalVsPlaylistStreamsByStore?.allStoresAggregation;

    return {
        loading,
        data: result,
        error,
    };
};
