import { useQuery } from '@apollo/client';
import { filterChartsData } from 'src/utils/charts';
import {
    ChartsV2Query,
    ChartsV2Query_chartsV2 as ChartsQueryChart,
} from '../../definitions/ChartsV2Query';
import { selectChartsV2 } from '../../selectors';
import { flattenError } from '../../utils';
import chartsV2Query from './chartsV2.gql';

const mapAmazonAndITunesCharts = (data: ChartsQueryChart[]) =>
    data.map(chart => ({
        ...chart,
        type:
            chart.platform !== 'amazon' && chart.platform !== 'itunes'
                ? chart.type
                : null,
    }));

export const useChartsQuery = () => {
    const { data, loading, error } = useQuery<ChartsV2Query>(chartsV2Query, {
        fetchPolicy: 'no-cache',
    });

    const charts = data && filterChartsData(data.chartsV2);
    const mapped = charts && mapAmazonAndITunesCharts(charts);

    return {
        loading,
        data: mapped && selectChartsV2(mapped),
        error: error && flattenError(error),
    };
};
