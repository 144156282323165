import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { EMPTY_CHAR } from 'src/constants';

GridTable.defineColumnTemplate('positionWithHash', {
    align: 'right',
    Cell: ({ data: { position } }) => {
        if (!position) return <span>{EMPTY_CHAR}</span>;
        return <span>{`#${position}`}</span>;
    },
});
