import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import EmptyCell from './emptyCell';
import ExpandedIconWithNumberCell from './expandedIconWithNumberCell';

export const CLASSNAME = 'StorefrontCountCellV2';

interface ToggleHandler {
    (res: { key: string; data: object }): void;
}

GridTable.defineColumnTemplate('expandedCreations', {
    align: 'right',
    minWidth: 'min-content',
    maxWidth: 'max-content',
    Cell: ({
        value,
        data,
        rowKey,
        context: {
            props: { expandedRowTables },
        },
        column: { definition },
    }) => {
        // We don't need to show cell for creation Entity;
        if (data.creationId) return <EmptyCell />;

        const isExpanded = expandedRowTables
            ? rowKey in expandedRowTables
            : false;
        const handleToggle = get(definition, 'data-onToggle') as
            | ToggleHandler
            | undefined;

        return (
            <ExpandedIconWithNumberCell
                expanded={isExpanded}
                value={Number(value)}
                onClick={() => handleToggle?.({ key: rowKey, data })}
                className={CLASSNAME}
            />
        );
    },
});
