import { useMemo } from 'react';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { usePlaylistPlacementPositionTimeseries } from 'src/apollo/queries/playlists/playlistPlacementPositionTimeseries';
import { usePlaylistPlacementStreamsTimeseries } from 'src/apollo/queries/playlists/playlistPlacementStreamsTimeseries';
import { selectPlaylistPlacementSeries } from 'src/apollo/selectors/playlist';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

interface PlaylistPlacementSeriesParams {
    isrc?: string;
    storeId?: number | null;
    playlistId?: string;
    storefront?: string | null;
    peakPosition?: number | null;
    startDate?: string;
    days?: number;
    streamCountries?: string[] | null;
}

export const usePlaylistPlacementSeries = (
    {
        isrc = '',
        storeId = null,
        playlistId = '',
        storefront = null,
        peakPosition = 0,
        startDate,
        days,
        streamCountries,
    }: PlaylistPlacementSeriesParams,
    skipPositions?: boolean
) => {
    const isColorSchemaV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);
    const {
        data: positions,
        loading: positionsLoading,
        error: positionsError,
    } = usePlaylistPlacementPositionTimeseries(
        { isrc, storeId, playlistId, storefront, startDate, days },
        peakPosition,
        skipPositions
    );

    const {
        data: streams,
        loading: streamsLoading,
        error: streamsError,
    } = usePlaylistPlacementStreamsTimeseries({
        isrc,
        storeId,
        playlistId,
        storefront,
        streamCountries,
        startDate,
        days,
    });

    const data = useMemo(() => {
        const positionsData = skipPositions ? [] : positions;
        return selectPlaylistPlacementSeries(
            streams,
            positionsData,
            isColorSchemaV2Enabled
        );
    }, [streams, positions, skipPositions]);

    return {
        loading: streamsLoading || positionsLoading,
        data,
        error: positionsError || streamsError,
    };
};
