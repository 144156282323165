import { TopChannelsQuery } from 'src/apollo/definitions/TopChannelsQuery';
import { Entity } from './types';
import { toEntity, getPreviousMonthNames } from './utils';

export interface CatalogChannel {
    channel: Entity;
    participants: Entity[];
    labelName: string;
    subscribers: number;
    allTimeViews: number;
    allTimeRevenue: number;
    avgViewDuration: number;
    month1Views?: number;
    month1Change: number | null;
    month2Views?: number;
    month2Change: number | null;
    month3Views?: number;
    month3Change: number | null;
}

export interface CatalogChannelResults {
    totalCount: number;
    items: CatalogChannel[];
    months: string[];
}

export const selectTopChannels = (
    data: TopChannelsQuery
): CatalogChannelResults => {
    const items: CatalogChannel[] = data.topChannels.channels.map(channel => ({
        channel: toEntity({
            id: channel.channelId,
            name: channel.name,
            imageUrl: channel.thumbnailUrl,
        }),
        labelName: channel.vendor?.name ?? '',
        subscribers: channel.analytics.metrics?.subscribers ?? 0,
        participants:
            channel.globalParticipant?.id && channel.globalParticipant.name
                ? [
                      {
                          id: channel.globalParticipant.id,
                          name: channel.globalParticipant.name ?? '',
                      },
                  ]
                : [],
        allTimeViews: channel.analytics.metrics?.views ?? 0,
        allTimeRevenue: channel.analytics.metrics?.estimatedGrossRevenue ?? 0,
        avgViewDuration:
            channel.analytics.metrics?.averageViewDurationSeconds ?? 0,
        month1Views:
            channel.analytics.metrics?.growthPeriods[0].value || undefined,
        month1Change:
            channel.analytics.metrics?.growthPeriods[0].growthPercentage ?? 0,
        month2Views:
            channel.analytics.metrics?.growthPeriods[1].value || undefined,
        month2Change:
            channel.analytics.metrics?.growthPeriods[1].growthPercentage ?? 0,
        month3Views:
            channel.analytics.metrics?.growthPeriods[2].value || undefined,
        month3Change:
            channel.analytics.metrics?.growthPeriods[2].growthPercentage ?? 0,
    }));

    const lastAvailableDate =
        data.topChannels.channels[0]?.analytics.metrics?.lastAvailableDate;

    return {
        totalCount: data.topChannels.totalCount,
        items,
        months: getPreviousMonthNames(lastAvailableDate),
    };
};
