import { flatMap } from 'lodash';
import { selectTimeseries } from 'src/apollo/selectors';
import { PrimaryDimension, TimeseriesSelector } from '../types';
import { getTimeseriesData, getTimeseriesSummary } from '../utils';

export const selectTimeseriesByTotal: TimeseriesSelector = ({
    data,
    primaryDimension,
    datePeriod,
}) =>
    flatMap(data, ({ isrc, name, releaseDate, streams, downloads }) => {
        const streamTotals = streams?.streamsTotal;
        const downloadTotals = downloads?.downloadsTotal;

        const item =
            primaryDimension === PrimaryDimension.Downloads
                ? downloadTotals
                : streamTotals;

        return [
            {
                id: isrc,
                name: isrc,
                title: name,
                data: getTimeseriesData({
                    data: selectTimeseries(item?.items),
                    primaryDimension,
                    datePeriod,
                    releaseDate,
                }),
                summary: getTimeseriesSummary({
                    id: isrc,
                    name: isrc,
                    title: name ?? '',
                    streams: streamTotals?.items
                        ? selectTimeseries(streamTotals.items)
                        : null,
                    downloads: downloadTotals?.items,
                    isrc,
                }),
                isrc,
            },
        ];
    });
