import { useQuery } from '@apollo/client';
import {
    GlobalParticipantChartHighlightsV2 as QueryType,
    GlobalParticipantChartHighlightsV2Variables as Vars,
} from '../../definitions/GlobalParticipantChartHighlightsV2';
import { flattenError, nonNullable } from '../../utils';
import GlobalParticipantChartHighlightsV2Query from './globalParticipantChartHighlightsV2.gql';

export const useGlobalParticipantChartHighlightsQuery = (variables: Vars) => {
    const { data, loading, error } = useQuery<QueryType, Vars>(
        GlobalParticipantChartHighlightsV2Query,
        { variables }
    );
    const result = data?.globalParticipantByGpId?.[0]?.chartHighlightsV2;

    return {
        loading,
        data: nonNullable(result),
        error: error && flattenError(error),
    };
};
