import { flatMap, keyBy } from 'lodash';
import { SoundRecordingDownloadsByCountry_globalSoundRecordingByIsrc_analytics_downloadsByCountry_countries as CountryData } from 'src/apollo/definitions/SoundRecordingDownloadsByCountry';
import { getLocalizedName } from 'src/utils/countries';
import {
    PrimaryDimension,
    StreamsAnalyticsQuery,
    TimeseriesSelector,
} from '../types';
import { getTimeseriesData, getTimeseriesSummary } from '../utils';

interface JsonCountry {
    country_code: string;
    items: {
        date: string;
        streams: number;
        skips: number;
        skip_rate: number;
        saves: number;
    }[];
}

const deserializeCountries = (
    streams?: StreamsAnalyticsQuery
): CountryData[] => {
    if (!streams?.performanceByCountry?.countries) return [];

    return JSON.parse(streams.performanceByCountry.countries).map(
        (country: JsonCountry) => ({
            countryCode: country.country_code,
            items:
                country.items?.map(item => ({
                    value: item.streams,
                    skipRate: item.skip_rate,
                    saves: item.saves,
                    date: item.date,
                })) ?? [],
        })
    );
};

export const selectTimeseriesByCountry: TimeseriesSelector = ({
    data,
    primaryDimension,
    datePeriod,
}) =>
    flatMap(data, ({ isrc, releaseDate, streams, downloads }) => {
        const streamCountries = deserializeCountries(streams);
        const downloadCountries =
            downloads?.downloadsByCountry?.countries ?? [];

        const primaryData =
            primaryDimension === PrimaryDimension.Downloads
                ? downloadCountries
                : streamCountries;

        const streamsByCountry = keyBy(
            streamCountries,
            item => item.countryCode
        );
        const downloadsByCountry = keyBy(
            downloadCountries,
            item => item.countryCode
        );

        return primaryData.map(item => {
            const id = `${isrc}_${item.countryCode}`;
            const name = `${item.countryCode}`;
            const countryName = getLocalizedName(item.countryCode);

            return {
                id,
                name,
                title: `${countryName} ${primaryDimension}`,
                data: getTimeseriesData({
                    data: item.items ?? [],
                    primaryDimension,
                    datePeriod,
                    releaseDate,
                }),
                summary: getTimeseriesSummary({
                    id,
                    name,
                    title: countryName,
                    streams: streamsByCountry[item.countryCode]?.items,
                    downloads: downloadsByCountry[item.countryCode]?.items,
                }),
            };
        });
    });
