import { useMutation } from '@apollo/client';
import {
    RecordRecentCountryFilter,
    RecordRecentCountryFilterVariables,
} from '../definitions/RecordRecentCountryFilter';
import { flattenError } from '../utils';
import recordRecentCountryFilter from './recordRecentCountryFilter.gql';

export const useRecordRecentlySelectedCountryFilterMutation = (
    variables: RecordRecentCountryFilterVariables
) => {
    const [setRecord, recordResult] = useMutation<
        RecordRecentCountryFilter,
        RecordRecentCountryFilterVariables
    >(recordRecentCountryFilter, { variables });

    const { loading } = recordResult;
    const { error } = recordResult;

    return {
        setRecord,
        loading,
        error: error && flattenError(error),
    };
};
