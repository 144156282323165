export const FILTER_DATE_PERIOD = 'dateFilter';
export const FILTER_DATE_PREV_PERIOD = 'dateFilterPrev';
export const FILTER_DATE_PERIOD_END = 'dateFilterEnd';
export const FILTER_DATE_PERIOD_START = 'dateFilterStart';
export const FILTER_SONG_IDS = 'selectedSongIds';
export const FILTER_SOURCE_METRIC = 'sourceMetric';
export const FILTER_DISTRIBUTOR = 'distributorFilter';
export const FILTER_COUNTRY_CODE = 'countryFilter';
export const FILTER_STORE_ID = 'storeFilter';
export const FILTER_TIKTOK_TYPE = 'tiktokType';
export const FILTER_STORE_STREAMS = 'storeStreams';
export const FILTER_SOS_DETAILS = 'sosDetailsFilter';
export const FILTER_PLAYLIST_APPEARANCES = 'playlistAppearancesFilter';
export const FILTER_CHART_TYPE = 'type';

export const QUERY_PARAM_DATE_PERIOD = 'period';
export const QUERY_PARAM_DATE_PREV_PERIOD = 'prev-period';
export const QUERY_PARAM_IDS = 'ids';
export const QUERY_PARAM_POT_METRIC = 'pot';
export const QUERY_PARAM_POT_IDS = 'potIds';
export const QUERY_PARAM_SOURCE_METRIC = 'source';
export const QUERY_PARAM_DIMENSION = 'dimension';
export const QUERY_PARAM_TIKTOK_TYPE = 'tiktok-type';
export const QUERY_PARAM_STORE_STREAMS = 'store-streams';
export const QUERY_PARAM_STORE = 'store';
export const QUERY_PARAM_DISTRIBUTOR = 'distributors';
export const QUERY_PARAM_PLAYLIST_APPEARANCES = 'playlistAppearances';
export const QUERY_PARAM_SOS_DETAILS = 'sosDetails';
export const QUERY_PARAM_CURRENT = 'current';
export const QUERY_PARAM_PAST = 'past';
