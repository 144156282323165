import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    ChannelVideosQuery,
    ChannelVideosQueryVariables,
} from '../../definitions/ChannelVideosQuery';
import { selectChannelVideos } from '../../selectors';
import { flattenError } from '../../utils';
import channelVideosQuery from './channelVideos.gql';

export const useChannelVideosQuery = (
    variables: ChannelVideosQueryVariables
) => {
    const { data, loading, error } = useQuery<
        ChannelVideosQuery,
        ChannelVideosQueryVariables
    >(channelVideosQuery, { variables });

    return {
        loading,
        data: useMemo(() => selectChannelVideos(data), [data]),
        error: error && flattenError(error),
    };
};
