import { useQuery } from '@apollo/client';
import { DEFAULT_FILTER_PLAYLIST_TYPE } from 'src/constants/playlists';
import {
    AppRecentsQuery,
    AppRecentsQuery_appRecents,
    AppRecentsQuery_appRecents_recentChannels,
    AppRecentsQuery_appRecents_recentCompanyBrands,
    AppRecentsQuery_appRecents_recentCompareParticipants,
    AppRecentsQuery_appRecents_recentCompareVideos,
    AppRecentsQuery_appRecents_recentLabelManagers,
    AppRecentsQuery_appRecents_recentLabels,
    AppRecentsQuery_appRecents_recentParticipants,
    AppRecentsQuery_appRecents_recentServiceTiers,
    AppRecentsQuery_appRecents_recentSoundRecordings,
} from '../definitions/AppRecentsQuery';
import { PlaylistType } from '../definitions/globalTypes';
import { flattenError } from '../utils/error';
import QUERY from './appRecents.gql';

export type RecentSoundRecording =
    AppRecentsQuery_appRecents_recentSoundRecordings;
export type RecentParticipant = AppRecentsQuery_appRecents_recentParticipants;
export type RecentCompanyBrand = AppRecentsQuery_appRecents_recentCompanyBrands;
export type RecentServiceTier = AppRecentsQuery_appRecents_recentServiceTiers;
export type RecentCompareParticipant =
    AppRecentsQuery_appRecents_recentCompareParticipants;
export type RecentLabel = AppRecentsQuery_appRecents_recentLabels;
export type RecentLabelManager = AppRecentsQuery_appRecents_recentLabelManagers;
export type RecentChannel = AppRecentsQuery_appRecents_recentChannels;
export type RecentCompareVideo = AppRecentsQuery_appRecents_recentCompareVideos;
export type RecentPlaylistTypeSelection = PlaylistType[];

export const DEFAULT_APP_RECENTS: AppRecentsQuery_appRecents = {
    recentSoundRecordings: [],
    recentLabels: [],
    recentLabelManagers: [],
    recentParticipants: [],
    recentCompanyBrands: [],
    recentServiceTiers: [],
    recentChannels: [],
    recentCompareParticipants: [],
    recentCompareVideos: [],
    recentPlaylistTypeSelection: DEFAULT_FILTER_PLAYLIST_TYPE,
};

export const useAppRecentsQuery = () => {
    const { data, loading, error, refetch } = useQuery<AppRecentsQuery>(QUERY);

    return {
        data: data?.appRecents ?? DEFAULT_APP_RECENTS,
        loading,
        error: error && flattenError(error),
        refetch,
    };
};
