import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { formatDate } from '@theorchard/suite-frontend';
import dayjs, { Dayjs } from 'dayjs';
import { groupBy, minBy, orderBy, values } from 'lodash';
import { DATE_FORMAT } from 'src/constants';
import { TOP_ORDERED_STORES_IDS } from 'src/constants/metrics';
import {
    Source,
    SOURCE_DOWNLOADS,
    SOURCE_STREAMS,
    SourceType,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_SPOTIFY,
    STORE_ID_TIKTOK,
} from 'src/constants/stores';
import { selectSourcesByTypes } from 'src/utils/sources';
import { AnalyticsFeedsStatus } from '../definitions/AnalyticsFeedsStatus';
import { Distributor } from '../definitions/globalTypes';
import { filterData, nonNullable } from '../utils';
import AnalyticsFeedsStatusQuery from './analyticsFeedsStatus.gql';

export const STORE_ID_ITUNES = 9999;
export const STORE_ID_YOUTUBE_CLAIM = 45302;
export const STORE_ID_AMAZON_DOWNLOADS = 16;
export const EXCLUDED_STORES_IDS = [STORE_ID_YOUTUBE_CLAIM, STORE_ID_ITUNES];
export const EXCLUDED_FEEDS = [STORE_ID_AMAZON_DOWNLOADS];
export const ANALYTICS_SOURCES = [
    STORE_ID_SPOTIFY,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_TIKTOK,
];

export const useAnalyticsFeedsStatus = (
    types: SourceType[] = [SOURCE_STREAMS, SOURCE_DOWNLOADS]
) => {
    const { data, loading, error } = useQuery<AnalyticsFeedsStatus>(
        AnalyticsFeedsStatusQuery,
        { fetchPolicy: 'cache-first' }
    );

    const storesStatus = useMemo(() => {
        const rawFeeds = filterData(data?.analyticFeedStatuses.feedStatus);
        const storesData: Source[] = rawFeeds
            // Filter out Feeds excluded from statuses calculations
            .filter(({ feed }) => !EXCLUDED_FEEDS.includes(feed.id))
            // Transform Feeds to Stores Sources
            .map(storeData => {
                const {
                    feed: { store },
                    storeHighWaterMark,
                    error: storeError,
                    missingDatesBeforeStoreHighWatermark: missingDates,
                } = storeData;

                return {
                    ...store,
                    missingDates:
                        missingDates.length > 0 ? missingDates : undefined,
                    latestDate: storeHighWaterMark,
                    error: storeError
                        ? {
                              code: nonNullable(storeError?.code),
                              types: filterData(storeError?.types),
                              message: nonNullable(storeError?.message),
                          }
                        : undefined,
                };
            })
            // Filter out stores that are not present on Insights Portal
            .filter(({ storeId }) => !EXCLUDED_STORES_IDS.includes(storeId));

        const sourcesById = groupBy(storesData, 'storeId');
        const sourcesByEarliestDate = values(sourcesById).reduce(
            (acc, feedsByStoreId): Source[] => {
                const storesWithError = feedsByStoreId.filter(
                    ({ error }) => error
                );
                const selectedStores =
                    // If some stores have error - prioritize them
                    storesWithError.length > 0
                        ? storesWithError
                        : feedsByStoreId;

                const storeWithEarliestDate = minBy(
                    selectedStores,
                    'latestDate'
                );

                return storeWithEarliestDate
                    ? [...acc, storeWithEarliestDate]
                    : acc;
            },
            []
        );

        const supportedSources = selectSourcesByTypes(
            sourcesByEarliestDate,
            types
        );

        return orderBy(
            supportedSources,
            [
                ({ storeId }) =>
                    TOP_ORDERED_STORES_IDS.includes(storeId)
                        ? TOP_ORDERED_STORES_IDS.indexOf(storeId)
                        : TOP_ORDERED_STORES_IDS.length,
                'storeName',
            ],
            'asc'
        );
    }, [data, types]);

    return {
        data: storesStatus,
        loading,
        error,
    };
};

export const useAnalyticsLatestDate = () => {
    const { data, loading, error } = useQuery<AnalyticsFeedsStatus>(
        AnalyticsFeedsStatusQuery,
        { fetchPolicy: 'cache-first' }
    );

    const earliestDate = useMemo(() => {
        const feedStatuses = filterData(data?.analyticFeedStatuses.feedStatus);

        if (feedStatuses.length === 0) return undefined;

        const analyticSources = feedStatuses
            .filter(
                ({
                    feed: {
                        store: { storeId },
                    },
                }) => ANALYTICS_SOURCES.includes(storeId)
            )
            .filter(
                ({ distributor }) =>
                    distributor === Distributor.SME ||
                    distributor === Distributor.THEORCHARD
            );

        const latestDate = analyticSources.reduce<Dayjs | undefined>(
            (result, { storeHighWaterMark }) => {
                if (!storeHighWaterMark) return result;

                const date = dayjs(storeHighWaterMark);
                if (!date.isValid()) return result;
                if (!result) return date;

                return date.isBefore(result) ? date : result;
            },
            undefined
        );

        return latestDate;
    }, [data]);

    return {
        data: earliestDate ? formatDate(earliestDate, DATE_FORMAT) : undefined,
        loading,
        error,
    };
};
