import React, { FC } from 'react';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import PopoverFooter from 'src/components/sourcesStatus/popoverFooter';
import SourcesStatusTable from 'src/components/sourcesStatus/sourcesTable';
import SourcesModalContent from 'src/components/sourcesStatusV2/sourcesModalContent';
import { INSIGHTS_SOURCES_TABLE_POPUP_V2 } from 'src/constants/featuresFlags';
import { Source, SourceType } from 'src/constants/stores';

interface Props {
    sources: Source[];
    sourcesTypes: SourceType[];
}

const SourceModalContent: FC<Props> = ({ sources, sourcesTypes }) => {
    return (
        <FeatureFlagPanel
            featureFlag={INSIGHTS_SOURCES_TABLE_POPUP_V2}
            fallback={
                <>
                    <SourcesStatusTable
                        sources={sources}
                        sourceTypes={sourcesTypes}
                    />
                    <PopoverFooter />
                </>
            }
        >
            <SourcesModalContent sources={sources} sourceTypes={sourcesTypes} />
        </FeatureFlagPanel>
    );
};

export default SourceModalContent;
