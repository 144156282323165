import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { VendorType } from 'src/apollo/definitions/globalTypes';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_ACCOUNT, ROUTE_SUBACCOUNT } from 'src/constants';

GridTable.defineColumnTemplate('catalogAccount', {
    align: 'left',
    sortable: false,
    Cell: ({
        data: {
            label: { name, subaccountId, vendorId, type, vendorName },
        },
    }) => {
        const isSubaccount = subaccountId > 0;
        const isD3 = type === VendorType.D3;
        let subTitle = '';
        if (isD3) subTitle = `D3 (${vendorId})`;
        else
            subTitle = isSubaccount
                ? `${formatMessage(
                      'product.metadata.subAccount'
                  )} (${subaccountId}) \u2022 ${vendorName} (${vendorId})`
                : `${formatMessage('catalog.labelName')} (${vendorId})`;
        const route = isSubaccount ? ROUTE_SUBACCOUNT : ROUTE_ACCOUNT;
        const routeParams = isSubaccount
            ? { accountId: vendorId, subaccountId }
            : { accountId: vendorId };

        return (
            <div
                className="CatalogAccountCell"
                data-testid="CatalogAccountCell"
            >
                <div className="CatalogAccountCell-account">
                    <div className="CatalogAccountCell-account-name">
                        <TruncatedText
                            value={name}
                            route={route}
                            params={routeParams}
                            useLink
                        />
                    </div>
                    <div className="CatalogAccountCell-account-type">
                        <TruncatedText value={subTitle} />
                    </div>
                </div>
            </div>
        );
    },
});
