import { selectChannelLink, ChannelParams } from './channel';
import { selectParticipantLink, ParticipantParams } from './participant';
import { selectProductLink, ProductParams } from './product';
import { selectSongLink, SongParams } from './songs';
import { selectVideoLink, VideoParams } from './video';

export type UniversalLinkInput =
    | SongParams
    | ParticipantParams
    | ProductParams
    | VideoParams
    | ChannelParams;

export const selectUniversalLinksEngine = (data: UniversalLinkInput) => {
    switch (data.type) {
        case 'participant':
            return selectParticipantLink(data);
        case 'product':
            return selectProductLink(data);
        case 'song':
            return selectSongLink(data);
        case 'video':
            return selectVideoLink(data);
        case 'channel':
            return selectChannelLink(data);
        default:
            return undefined;
    }
};
