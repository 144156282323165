import { useIdentity } from '@theorchard/suite-frontend';
import {
    DISTRIBUTORS_ORCH_AWAL,
    DISTRIBUTORS_ORCH_SME_AWAL,
    DISTRIBUTOR_OPTIONS,
    DISTRIBUTOR_OPTIONS_WITH_AWAL_AND_SME,
} from 'src/constants';
import {
    INSIGHTS_DISTRIBUTOR_TOGGLE,
    INSIGHTS_SHOW_SME_DATA,
} from 'src/constants/featuresFlags';

export const useDistributorsFilterOptions = (
    selectedValue?: string | string[] | undefined,
    defaultValue: string = DISTRIBUTORS_ORCH_SME_AWAL
) => {
    const identity = useIdentity();
    const enableDistributorFilter =
        identity.features[INSIGHTS_DISTRIBUTOR_TOGGLE];
    const showSmeData = identity.features[INSIGHTS_SHOW_SME_DATA];

    let distributors = showSmeData ? defaultValue : DISTRIBUTORS_ORCH_AWAL;
    let distributorsDropdownDisabled = true;

    // With INSIGHTS_DISTRIBUTOR_TOGGLE: enabled
    if (enableDistributorFilter) {
        if (typeof selectedValue === 'string' && selectedValue)
            distributors = selectedValue;
        distributorsDropdownDisabled = false;
    }

    return {
        selectedValue: distributors,
        disabled: distributorsDropdownDisabled,
    };
};

export const useDistributorsOptions = () => {
    const identity = useIdentity();
    const showSmeData = identity.features[INSIGHTS_SHOW_SME_DATA];
    const distributorsOptions = showSmeData
        ? DISTRIBUTOR_OPTIONS_WITH_AWAL_AND_SME
        : DISTRIBUTOR_OPTIONS;

    return distributorsOptions;
};
