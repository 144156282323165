export const API_AUTH_CALLBACK_PATH = '/callback/spotify';
export const API_AUTH_SCOPES = [
    'streaming',
    'user-read-email',
    'user-read-private',
];
export const API_AUTH_URL = 'https://accounts.spotify.com/authorize';
export const API_PLAY_URL = 'https://api.spotify.com/v1/me/player/play';
export const API_PLAYLISTS_URL = 'https://api.spotify.com/v1/playlists';
export const PLAYER_NAME = 'InsightsSpotifyPlayer';
export const STORAGE_AUTH_STATE_NAME = 'spotify:state';
export const STORAGE_ENABLED_NAME = 'spotify:enabled';
export const STORAGE_TOKEN_NAME = 'spotify:accessToken';
