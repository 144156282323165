import { useMemo } from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { GlobalParticipantSongsQueryVariables as QueryVariables } from 'src/apollo/definitions/GlobalParticipantSongsQuery';
import {
    GlobalParticipantSongsQueryV2,
    GlobalParticipantSongsQueryV2Variables as QueryVariablesV2,
} from 'src/apollo/definitions/GlobalParticipantSongsQueryV2';
import {
    selectTopGlobalSoundRecordings,
    TopGlobalSoundRecordingsQuery,
} from 'src/apollo/selectors/catalog';
import { selectParticipantSongs } from 'src/apollo/selectors/participantSongs';
import { usePrefetchQuery } from '../../utils';
import globalParticipantSongsQuery from './globalParticipantSongs.gql';
import globalParticipantSongsQueryV2 from './globalParticipantSongsV2.gql';

export const useGlobalParticipantSongsQuery = (
    variables: QueryVariables,
    options?: QueryHookOptions<TopGlobalSoundRecordingsQuery, QueryVariables>,
    store?: string
) => {
    const { data, loading, error } = useQuery<
        TopGlobalSoundRecordingsQuery,
        QueryVariables
    >(globalParticipantSongsQuery, { variables, ...options });

    const memoizedData = useMemo(
        () => data && selectTopGlobalSoundRecordings(data, store),
        [data, store]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useGlobalParticipantSongsQueryV2 = (
    variables: QueryVariablesV2,
    options?: QueryHookOptions<GlobalParticipantSongsQueryV2, QueryVariablesV2>
) => {
    const { data, loading, error } = useQuery<
        GlobalParticipantSongsQueryV2,
        QueryVariablesV2
    >(globalParticipantSongsQueryV2, { variables, ...options });

    const memoizedData = useMemo(
        () => data && selectParticipantSongs(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchGlobalParticipantSongsQuery = (
    delay = 0,
    variables: QueryVariables
) => {
    usePrefetchQuery<TopGlobalSoundRecordingsQuery, QueryVariables>(
        globalParticipantSongsQuery,
        { variables, delay }
    );
};
