import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatDate } from '@theorchard/suite-frontend';
import { EMPTY_CHAR, SONG_DATE_FORMAT } from 'src/constants';

GridTable.defineColumnTemplate('songExitDate', {
    align: 'right',
    Cell: ({ data: { position }, value }) => {
        if (position || !value) return <>{EMPTY_CHAR}</>;

        return <>{formatDate(value, SONG_DATE_FORMAT)}</>;
    },
});
