import { ALL } from 'src/constants';
import { PrimaryDimension, SecondaryDimension } from './types';

export const parsePrimaryDimension = (dimension: string) => {
    switch (dimension.toLowerCase()) {
        case PrimaryDimension.Streams:
            return PrimaryDimension.Streams;
        case PrimaryDimension.Downloads:
            return PrimaryDimension.Downloads;
        case PrimaryDimension.Skips:
            return PrimaryDimension.Skips;
        case PrimaryDimension.Saves:
            return PrimaryDimension.Saves;
        default:
            return PrimaryDimension.Streams;
    }
};

export const parseSecondaryDimension = (dimension: string) => {
    switch (dimension.toLowerCase()) {
        case SecondaryDimension.SourceOfStreams:
            return SecondaryDimension.SourceOfStreams;
        case SecondaryDimension.Stores:
            return SecondaryDimension.Stores;
        case ALL:
        case SecondaryDimension.Total:
            return SecondaryDimension.Total;
        case SecondaryDimension.Countries:
            return SecondaryDimension.Countries;
        case SecondaryDimension.Products:
            return SecondaryDimension.Products;
        default:
            return SecondaryDimension.SourceOfStreams;
    }
};
