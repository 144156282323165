import { sortBy } from 'lodash';
import { VideoAnalyticsAllTimeQuery_video_analytics_allTime as AllTimeVideoMetricsData } from 'src/apollo/definitions/VideoAnalyticsAllTimeQuery';
import {
    VideoAnalyticsDailyQuery_video_analytics_daily as VideoMetricsData,
    VideoAnalyticsDailyQuery_video_analytics_daily_aggregate as VideoMetricsAggregate,
} from 'src/apollo/definitions/VideoAnalyticsDailyQuery';
import { EMPTY_CHAR } from 'src/constants';
import {
    VIEWS,
    WATCH_TIME,
    AVG_DURATION,
    AVG_PERCENTAGE,
    COMMENTS,
} from 'src/constants/metrics';
import { ChannelAnalyticsTopVideosQuery_channel_analytics_topVideos_channelVideos as ChannelVideo } from '../definitions/ChannelAnalyticsTopVideosQuery';
import dateDiff from './dates';
import { Video } from './video';

export interface VideoMetrics {
    byDay: DayVideoMetrics[];
    aggregate: AggregateVideoMetrics;
}

interface Metrics {
    views: number;
    premiumViews: number;
    watchTime?: number;
    premiumWatchTime?: number;
    averageViewDuration?: number;
    averageViewPercentage?: number;
    estimatedMonetizedPlaybacks: number;
    estimatedPartnerAdRevenue: number;
    estimatedPartnerPremiumRevenue: number;
    estimatedPartnerRevenue: number;
    cpm: number;
    rpm: number;
    adFillRate: number;
    comments: number | null;
}

export interface DayVideoMetrics extends Metrics {
    date: string;
}

export interface AllTimeVideoMetrics extends Metrics {
    videoId: string;
}

export interface AggregateVideoMetrics extends Metrics {
    videoId: string;
    standardViews: number;
}

export interface ChannelVideoMetrics {
    videoId: string;
    name: string | undefined | null;
    views: number;
    premiumViews: number;
    watchTime: number;
    premiumWatchTime: number;
    averageViewDuration: number;
    averageViewPercentage: number;
}

export const selectAllTimeVideoMetrics = (
    allTime?: AllTimeVideoMetricsData | null
): AllTimeVideoMetrics | null => {
    const data = allTime?.data;
    if (!data?.videoId || !data?.views) return null;

    return {
        videoId: data.videoId,
        views: data.views,
        premiumViews: data.premiumViews ?? 0,
        averageViewDuration: data?.averageViewDurationSeconds ?? undefined,
        averageViewPercentage: data.averageViewDurationPercentage
            ? data.averageViewDurationPercentage / 100
            : undefined,
        watchTime: data?.watchTimeMinutes ? data.watchTimeMinutes / 60 : 0,
        premiumWatchTime: data?.premiumWatchTimeMinutes ?? 0,
        estimatedPartnerAdRevenue: data.estimatedPartnerAdRevenue ?? 0,
        estimatedPartnerPremiumRevenue:
            data.estimatedPartnerPremiumRevenue ?? 0,
        estimatedPartnerRevenue:
            (data.estimatedPartnerPremiumRevenue ?? 0) +
            (data.estimatedPartnerAdRevenue ?? 0),
        estimatedMonetizedPlaybacks: data.estimatedMonetizedPlaybacks ?? 0,
        cpm: data.cpm ?? 0,
        rpm: data.rpm ?? 0,
        adFillRate: data.adFillRate ?? 0,
        comments: data.comments ?? null,
    };
};

export const selectVideoMetricsByDay = (
    metrics?: VideoMetricsData | null
): DayVideoMetrics[] => {
    const data = (metrics?.data ?? [])
        .map(day => ({
            date: day?.downloadActivityDate,
            views: day?.views ?? 0,
            premiumViews: day?.premiumViews ?? 0,
            watchTime: day?.watchTimeMinutes ? day.watchTimeMinutes / 60 : 0,
            averageViewDuration: day?.averageViewDurationSeconds ?? null,
            averageViewPercentage: day?.averageViewDurationPercentage
                ? day.averageViewDurationPercentage / 100
                : null,
            rpm: day?.rpm ?? 0,
            cpm: day?.cpm ?? 0,
            estimatedMonetizedPlaybacks: day?.estimatedMonetizedPlaybacks ?? 0,
            estimatedPartnerAdRevenue: day?.estimatedPartnerAdRevenue ?? 0,
            estimatedPartnerPremiumRevenue:
                day?.estimatedPartnerPremiumRevenue ?? 0,
            estimatedPartnerRevenue:
                (day?.estimatedPartnerPremiumRevenue ?? 0) +
                (day?.estimatedPartnerAdRevenue ?? 0),
            adFillRate: day?.adFillRate ? day?.adFillRate : 0,
            comments: day?.comments ?? 0,
        }))
        .filter(day => day.date);

    if (!data?.length) return [];
    return sortBy(data, day => day.date) as DayVideoMetrics[];
};

export const selectAggregateVideoMetrics = (
    aggregate?: VideoMetricsAggregate
): AggregateVideoMetrics => ({
    videoId: aggregate?.videoId ?? '',
    views: aggregate?.views ?? 0,
    premiumViews: aggregate?.premiumViews ?? 0,
    standardViews: (aggregate?.views ?? 0) - (aggregate?.premiumViews ?? 0),
    watchTime: aggregate?.watchTimeMinutes
        ? aggregate.watchTimeMinutes / 60
        : 0,
    averageViewDuration: aggregate?.averageViewDurationSeconds ?? undefined,
    averageViewPercentage: aggregate?.averageViewDurationPercentage
        ? aggregate.averageViewDurationPercentage / 100
        : undefined,
    rpm: aggregate?.rpm ?? 0,
    cpm: aggregate?.cpm ?? 0,
    estimatedMonetizedPlaybacks: aggregate?.estimatedMonetizedPlaybacks ?? 0,
    estimatedPartnerAdRevenue: aggregate?.estimatedPartnerAdRevenue ?? 0,
    estimatedPartnerPremiumRevenue:
        aggregate?.estimatedPartnerPremiumRevenue ?? 0,
    estimatedPartnerRevenue:
        (aggregate?.estimatedPartnerPremiumRevenue ?? 0) +
        (aggregate?.estimatedPartnerAdRevenue ?? 0),
    adFillRate: aggregate?.adFillRate ? aggregate?.adFillRate : 0,
    comments: aggregate?.comments ?? 0,
});

export const selectVideoMetrics = (
    data?: VideoMetricsData
): VideoMetrics | null => {
    if (!data) return null;

    const byDay = selectVideoMetricsByDay(data);
    const aggregate = selectAggregateVideoMetrics(data.aggregate);

    return {
        aggregate,
        byDay,
    };
};

const getItemValue = (item: DayVideoMetrics, selectedMetric: string) => {
    if (selectedMetric === VIEWS) return item.views ?? 0;
    if (selectedMetric === WATCH_TIME) return item.watchTime ?? 0;
    if (selectedMetric === AVG_DURATION) return item.averageViewDuration ?? 0;
    if (selectedMetric === AVG_PERCENTAGE)
        return item.averageViewPercentage ? item.averageViewPercentage : 0;
    if (selectedMetric === COMMENTS) return item.comments ?? 0;
    return 0;
};

const selectAbsoluteSeries = (
    item: DayVideoMetrics,
    selectedMetric: string,
    releaseDate?: string
) => ({
    x: new Date(item.date).getTime(),
    y: getItemValue(item, selectedMetric),
    z: dateDiff(item.date, releaseDate),
});

export const selectVideoMetricsSeries = (
    video: Video,
    selectedMetric: string,
    metrics: VideoMetrics | null
) => ({
    name: video.name || EMPTY_CHAR,
    data:
        metrics?.byDay.map(item =>
            selectAbsoluteSeries(item, selectedMetric, video.releaseDate)
        ) ?? [],
});

export const selectChannelVideo = (
    videos: ChannelVideo[] | undefined
): ChannelVideoMetrics[] =>
    (videos ?? []).map(videoStats => ({
        videoId: videoStats.video.videoId,
        name: videoStats.video.name,
        views: videoStats.views,
        premiumViews: videoStats.premiumViews,
        watchTime: videoStats.watchTimeMinutes
            ? videoStats.watchTimeMinutes / 60
            : 0,
        premiumWatchTime: videoStats.premiumWatchTimeMinutes
            ? videoStats.premiumWatchTimeMinutes / 60
            : 0,
        averageViewDuration: videoStats.averageViewDurationSeconds,
        averageViewPercentage: videoStats.averageViewDurationPercentage,
    }));
