import { SortDirection } from 'src/components/table/types';

export const PRODUCTS_PAGE_SIZE = 50;

export const PRODUCT_NAME = 'catalog.productName';
export const IMPRINT = 'chart.imprint';
export const UPC = 'product.metadata.upc';
export const PRODUCT_FORMAT = 'song.format';
export const COUNTRY = 'default.country.country';
export const ARTISTS = 'catalog.artists';
export const RELEASE_DATE = 'catalog.releaseDate';
export const STREAMS_1 = 'catalog.streams1';
export const GROWTH_1 = 'catalog.growthPercentage1Day';
export const STREAMS_7 = 'catalog.streams7';
export const GROWTH_7 = 'catalog.growthPercentage7Days';
export const STREAMS_28 = 'catalog.streams28';
export const GROWTH_28 = 'catalog.growthPercentage28Days';
export const STREAMS_ALL = 'catalog.streamsAll';
export const ACCOUNT = 'account.account';

export const DEFAULT_SORT_FIELD = 'streams_7_days';
export const DEFAULT_SORT_DIR: SortDirection = 'desc';

export const CATALOG_PRODUCTS_SORTING_FIELDS_MAP = {
    releaseDate: 'release_date',
    streams1Day: 'streams_1_day',
    growthPercentage1Day: 'growth_percentage_1_day',
    streams7Days: 'streams_7_days',
    growthPercentage7Days: 'growth_percentage_7_days',
    streams28Days: 'streams_28_days',
    growthPercentage28Days: 'growth_percentage_28_days',
    streamsAllTime: 'streams_all_time',
};
