import { useQuery } from '@apollo/client';
import { selectTopVideoTrafficSources } from 'src/apollo/selectors/videoTrafficSources';
import {
    ChannelAnalyticsTopTrafficQuery,
    ChannelAnalyticsTopTrafficQueryVariables,
} from '../../definitions/ChannelAnalyticsTopTrafficQuery';
import { flattenError } from '../../utils';
import topChannelTrafficSourcesQuery from './channelAnalyticsTopTraffic.gql';

export const useChannelAnalyticsTopTrafficQuery = (
    variables: ChannelAnalyticsTopTrafficQueryVariables
) => {
    const { data, loading, error } = useQuery<
        ChannelAnalyticsTopTrafficQuery,
        ChannelAnalyticsTopTrafficQueryVariables
    >(topChannelTrafficSourcesQuery, { variables });

    return {
        loading,
        data: selectTopVideoTrafficSources(
            data?.channel?.analytics.topTrafficSources.channelTrafficSources
        ),
        error: error && flattenError(error),
    };
};
