import { DocumentNode } from '@apollo/client';
import { CreateGlobalProductLink } from 'src/apollo/definitions/CreateGlobalProductLink';
import createGlobalProductLink from 'src/apollo/mutations/universalLinks/createGlobalProductLink.gql';

export interface ChannelParams {
    type: 'channel';
    page?: string;
    id: string;
}

export interface ChannelLinkData {
    query: DocumentNode;
    variables: { id: string };
    selector: (res?: CreateGlobalProductLink | null) => string | undefined;
    type: 'channel';
}

export const selectChannelLink = (data: ChannelParams): ChannelLinkData => ({
    query: createGlobalProductLink,
    variables: { id: data.id },
    // use mock link for now, because we don't have api for that
    selector: () => window.location.href,
    type: 'channel',
});
