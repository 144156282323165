import { VendorType } from 'src/apollo/definitions/globalTypes';
import {
    OrchardLabels_orchardLabels_Subaccount,
    OrchardLabels_orchardLabels_Vendor,
} from 'src/apollo/definitions/OrchardLabels';

export interface Label {
    subaccountId: number;
    vendorId: number;
    name: string;
    type: VendorType | null;
    vendorName: string | null;
}

type UnionOrchardLabel = OrchardLabels_orchardLabels_Subaccount &
    OrchardLabels_orchardLabels_Vendor;

type OrchardLabel = Omit<UnionOrchardLabel, 'type' | 'vendor'> &
    Partial<Pick<UnionOrchardLabel, 'type' | 'vendor'>>;

export const selectOrchardLabel = (label: OrchardLabel): Label => ({
    name: label.name,
    subaccountId: label.id.subaccountId,
    vendorId: label.id.vendorId,
    type: label.type ?? null,
    vendorName: label.vendor?.name ?? null,
});

export const selectOrchardLabels = (labels: OrchardLabel[]): Label[] =>
    labels.map(label => selectOrchardLabel(label));
