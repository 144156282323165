import { useQuery, QueryHookOptions } from '@apollo/client';
import { selectAllTimeChannelMetrics } from 'src/apollo/selectors/channelMetrics';
import {
    ChannelAnalyticsAllTimeQuery,
    ChannelAnalyticsAllTimeQueryVariables,
} from '../../definitions/ChannelAnalyticsAllTimeQuery';
import { flattenError } from '../../utils';
import channelAnalyticsAllTime from './channelAnalyticsAllTime.gql';

export const useChannelAnalyticsAllTimeQuery = (
    channelId: string,
    queryOptions?: QueryHookOptions<
        ChannelAnalyticsAllTimeQuery,
        ChannelAnalyticsAllTimeQueryVariables
    >
) => {
    const { data, loading, error } = useQuery<
        ChannelAnalyticsAllTimeQuery,
        ChannelAnalyticsAllTimeQueryVariables
    >(channelAnalyticsAllTime, {
        variables: {
            channelId,
        },
        ...queryOptions,
    });

    return {
        loading,
        data:
            selectAllTimeChannelMetrics(data?.channel?.analytics.allTime) ??
            null,
        error: error && flattenError(error),
    };
};
