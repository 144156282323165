import React, { FC } from 'react';
import { LoadingIndicator } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { OutlineIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import InfoMessage from '../infoMessage';

const CLASSNAME = 'DataPanel';
const TERM_ERROR = 'error.zero.title';
const TERM_ERROR_BODY = 'error.zero.message';

export interface Props {
    className?: string;
    isLoading?: boolean;
    error?: Error;
}

const DataPanel: FC<Props> = ({ className, isLoading, error, children }) => {
    if (isLoading || error)
        return (
            <div className={cx(CLASSNAME, className)}>
                {isLoading && !error && <LoadingIndicator />}
                {error && (
                    <InfoMessage
                        Icon={<OutlineIcon name="ServerErrorOutlineIcon" />}
                        message={formatMessage(TERM_ERROR)}
                        body={formatMessage(TERM_ERROR_BODY)}
                    />
                )}
            </div>
        );

    return <>{children}</>;
};

export default DataPanel;
