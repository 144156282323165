import { ChartColumnName } from './types';

export const CHART_REGION = 'chart.region';
export const CHART_OTHERS = 'chart.others';
export const CHART_NO_POSITIONS_GIVEN_CRITERIA =
    'chart.noPositionsForGivenCriteria';

export const MULTI_COUNTRY_COLUMNS: ChartColumnName[] = [
    'chart',
    'currentAppearances',
    'totalAppearances',
    'highlights',
    'firstTimestamp',
];
export const SINGLE_COUNTRY_COLUMNS: ChartColumnName[] = [
    'chart',
    'position',
    'daysOnChart',
    'peakPosition',
    'change',
    'firstTimestamp',
];
