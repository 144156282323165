export const BG_PREFIX = 'bg-dataviz-';
export const COLOR_PREFIX = 'color-dataviz-';
export const BORDER_PREFIX = 'border-dataviz-';
export const SERIES_ICON_COLOR_PREFIX = `series-icon-${COLOR_PREFIX}`;
export const BG_COLOR_OTHER_BAR_CLASSNAME = 'bg-dataviz-other-bar'; // this className doesn't apply the color, it just reset the color to the default one

export function getPlatformBGColorClassName(platform: string) {
    // Ex.: 'bg-dataviz-platform-spotify'
    return `${BG_PREFIX}${platform}`;
}

export function getIndexedBGColorClassName(index: number) {
    // Ex.: 'bg-dataviz-0'
    return `${BG_PREFIX}${index}`;
}

export function getPlatformColorClassName(platform: string) {
    // Ex.: 'color-dataviz-platform-spotify'
    return `${COLOR_PREFIX}${platform}`;
}

export function getIndexedColorClassName(index: number) {
    // Ex.: 'color-dataviz-0'
    return `${COLOR_PREFIX}${index}`;
}

export function getPlatformBorderColorClassName(platform: string) {
    // Ex.: 'border-dataviz-platform-spotify'
    return `${BORDER_PREFIX}${platform}`;
}

export function getIndexedBorderColorClassName(index: number) {
    // Ex.: 'border-dataviz-0'
    return `${BORDER_PREFIX}${index}`;
}

export function getSeriesIconColorClassName(index: number, subIndex?: number) {
    // Ex.: 'series-icon-color-dataviz-0' or 'series-icon-color-dataviz-0-1'
    return subIndex !== undefined
        ? `${SERIES_ICON_COLOR_PREFIX}${index}-${subIndex}`
        : `${SERIES_ICON_COLOR_PREFIX}${index}`;
}

export function getBGColorClassName(index: number | string) {
    // Ex.: 'bg-dataviz-0' or 'bg-dataviz-platform-spotify'
    return Number.isInteger(index)
        ? getIndexedBGColorClassName(index as number)
        : getPlatformBGColorClassName(index as string);
}

export function getColorClassName(index: number | string) {
    // Ex.: 'color-dataviz-0' or 'color-dataviz-platform-spotify'
    return Number.isInteger(index)
        ? getIndexedColorClassName(index as number)
        : getPlatformColorClassName(index as string);
}

export function getBorderColorClassName(index: number | string) {
    // Ex.: 'border-dataviz-0' or 'border-dataviz-platform-spotify'
    return Number.isInteger(index)
        ? getIndexedBorderColorClassName(index as number)
        : getPlatformBorderColorClassName(index as string);
}
