import { formatMessage } from '@theorchard/suite-frontend';
import { flatMap } from 'lodash';
import { selectTimeseries } from 'src/apollo/selectors';
import { PrimaryDimension, TimeseriesSelector } from '../types';
import { getTimeseriesData, getTimeseriesSummary } from '../utils';

export const selectTimeseriesBySOS: TimeseriesSelector = ({
    data,
    primaryDimension,
    datePeriod,
}) =>
    flatMap(data, ({ isrc, streams, releaseDate }) => {
        if (primaryDimension === PrimaryDimension.Downloads) return [];

        const sourceOfStreams =
            streams?.streamsBySourceOfStreams?.sourceOfStreams;
        return (
            sourceOfStreams?.map(item => {
                const name = `${item.source}`;
                const id = `${isrc}_${item.source}`;
                const title = formatMessage(
                    `sourceOfStreams.${item.source?.toLowerCase()}`
                );

                return {
                    id,
                    name,
                    title: `${title} ${primaryDimension}`,
                    className: `chart-series-${item.source} chart-series-default`,
                    data: getTimeseriesData({
                        data: selectTimeseries(item.items),
                        primaryDimension,
                        datePeriod,
                        releaseDate,
                    }),
                    summary: getTimeseriesSummary({
                        id,
                        name,
                        title,
                        streams: selectTimeseries(item.items),
                    }),
                };
            }) ?? []
        );
    });
