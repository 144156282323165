import React from 'react';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { Illustration } from '@theorchard/suite-icons';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

function NoResultsIcon100() {
    const isColorSchemeV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);

    return isColorSchemeV2Enabled ? (
        <Illustration name={'noResults'} size={100} />
    ) : (
        <svg
            data-testid="NoResultsIcon100"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_654_3217)">
                <mask
                    id="mask0_654_3217"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="10"
                    y="10"
                    width="80"
                    height="80"
                >
                    <circle cx="50" cy="50" r="40" fill="#F3F0F0" />
                </mask>
                <g mask="url(#mask0_654_3217)">
                    <circle cx="50" cy="50" r="40" fill="#F3F0F0" />
                    <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="#EDE9E8"
                        fillOpacity="0.01"
                    />
                    <rect
                        x="17.5"
                        y="30"
                        width="65"
                        height="63"
                        rx="3"
                        fill="white"
                    />
                    <rect
                        x="22.5"
                        y="35"
                        width="55"
                        height="12"
                        rx="3"
                        fill="#F7F5F5"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M31 39.5C30.6299 39.5 30.3067 39.2989 30.1338 39H26.5C26.2239 39 26 38.7761 26 38.5C26 38.2239 26.2239 38 26.5 38H30.1338C30.3067 37.7011 30.6299 37.5 31 37.5C31.3701 37.5 31.6933 37.7011 31.8662 38H32.5C32.7761 38 33 38.2239 33 38.5C33 38.7761 32.7761 39 32.5 39H31.8662C31.6933 39.2989 31.3701 39.5 31 39.5ZM26 41C26 40.7239 26.2239 40.5 26.5 40.5H27.1338C27.3067 40.2011 27.6299 40 28 40C28.3701 40 28.6933 40.2011 28.8662 40.5H32.5C32.7761 40.5 33 40.7239 33 41C33 41.2761 32.7761 41.5 32.5 41.5H28.8662C28.6933 41.7989 28.3701 42 28 42C27.6299 42 27.3067 41.7989 27.1338 41.5H26.5C26.2239 41.5 26 41.2761 26 41ZM26.5 43C26.2239 43 26 43.2239 26 43.5C26 43.7761 26.2239 44 26.5 44H28.1338C28.3067 44.2989 28.6299 44.5 29 44.5C29.3701 44.5 29.6933 44.2989 29.8662 44H32.5C32.7761 44 33 43.7761 33 43.5C33 43.2239 32.7761 43 32.5 43H29.8662C29.6933 42.7011 29.3701 42.5 29 42.5C28.6299 42.5 28.3067 42.7011 28.1338 43H26.5Z"
                        fill="#B2B2B2"
                    />
                    <rect
                        x="38.5"
                        y="39.5"
                        width="33.5"
                        height="3"
                        rx="1.5"
                        fill="#EDE9E8"
                    />
                    <g filter="url(#filter0_i_654_3217)">
                        <circle
                            cx="50"
                            cy="50"
                            r="40"
                            fill="#F3F2F7"
                            fillOpacity="0.01"
                        />
                    </g>
                </g>
                <g filter="url(#filter1_d_654_3217)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M61.5125 77.3167C63.3863 74.8736 64.5 71.8169 64.5 68.5C64.5 60.4919 58.0082 54 50 54C41.9919 54 35.5 60.4919 35.5 68.5C35.5 76.5081 41.9919 83 50 83C53.3996 83 56.5259 81.8301 58.9984 79.8709C59.0942 80.1488 59.253 80.4099 59.4749 80.6317L69.3744 90.5312C70.1554 91.3123 71.4218 91.3123 72.2028 90.5312C72.9839 89.7502 72.9839 88.4838 72.2028 87.7028L62.3033 77.8033C62.0735 77.5735 61.8016 77.4113 61.5125 77.3167Z"
                        fill="#E4E0DF"
                    />
                </g>
                <circle cx="50" cy="68.5" r="11.5" fill="#F7F5F5" />
                <g filter="url(#filter2_d_654_3217)">
                    <path
                        d="M57.4254 53.1887C57.23 52.8724 56.77 52.8724 56.5746 53.1887L54.7711 56.1084C54.7302 56.1746 54.6746 56.2302 54.6084 56.2711L51.6887 58.0746C51.3724 58.27 51.3724 58.73 51.6887 58.9254L54.6084 60.7289C54.6746 60.7698 54.7302 60.8254 54.7711 60.8916L56.5746 63.8113C56.77 64.1276 57.23 64.1276 57.4254 63.8113L59.2289 60.8916C59.2698 60.8254 59.3254 60.7698 59.3916 60.7289L62.3113 58.9254C62.6276 58.73 62.6276 58.27 62.3113 58.0746L59.3916 56.2711C59.3254 56.2302 59.2698 56.1746 59.2289 56.1084L57.4254 53.1887Z"
                        fill="#FFA166"
                    />
                </g>
                <g filter="url(#filter3_d_654_3217)">
                    <path
                        d="M75.5746 65.1887C75.77 64.8724 76.23 64.8724 76.4254 65.1887L77.4653 66.8721C77.5061 66.9382 77.5618 66.9939 77.6279 67.0347L79.3113 68.0746C79.6276 68.27 79.6276 68.73 79.3113 68.9254L77.6279 69.9653C77.5618 70.0061 77.5061 70.0618 77.4653 70.1279L76.4254 71.8113C76.23 72.1276 75.77 72.1276 75.5746 71.8113L74.5347 70.1279C74.4939 70.0618 74.4382 70.0061 74.3721 69.9653L72.6887 68.9254C72.3724 68.73 72.3724 68.27 72.6887 68.0746L74.3721 67.0347C74.4382 66.9939 74.4939 66.9382 74.5347 66.8721L75.5746 65.1887Z"
                        fill="#80BFFF"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_i_654_3217"
                    x="10"
                    y="10"
                    width="80"
                    height="80"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5.236" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.894118 0 0 0 0 0.878431 0 0 0 0 0.87451 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_654_3217"
                    />
                </filter>
                <filter
                    id="filter1_d_654_3217"
                    x="15.5"
                    y="39"
                    width="77.2886"
                    height="77.117"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.666667 0 0 0 0 0.666667 0 0 0 0 0.698039 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_654_3217"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_654_3217"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_654_3217"
                    x="35.4514"
                    y="40.9514"
                    width="43.0971"
                    height="43.0972"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.568627 0 0 0 0 0.811765 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_654_3217"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_654_3217"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_654_3217"
                    x="56.4514"
                    y="52.9514"
                    width="39.0971"
                    height="39.0972"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.568627 0 0 0 0 0.811765 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_654_3217"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_654_3217"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_654_3217">
                    <rect width="100" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default NoResultsIcon100;
