import { LabelManagerSearch_labelManagerSearch } from 'src/apollo/definitions/LabelManagerSearch';

export const selectLabelManager = (
    labelManager: LabelManagerSearch_labelManagerSearch
): LabelManagerSearch_labelManagerSearch => ({
    profileName: labelManager.profileName,
    profileId: labelManager.profileId,
});

export const selectLabelManagers = (
    labelManagers: LabelManagerSearch_labelManagerSearch[]
): LabelManagerSearch_labelManagerSearch[] =>
    labelManagers.map(labelManager => selectLabelManager(labelManager));
