import { Segment } from '@theorchard/suite-frontend';

export const trackPageView = (
    page: string,
    properties: object,
    label?: string
) => {
    Segment.trackEvent(
        `View ${page} page`,
        {
            category: 'View',
            url: window.location.href,
            referrer: document.referrer,
            ...properties,
        },
        label
    );
};

export const trackSongPageView = (properties: {
    brand?: string;
    title: string;
    isrc: string;
    tab: string;
}) => {
    trackPageView('Song', { ...properties, page: 'song' });
};

export const trackArtistPageView = (properties: {
    brand?: string;
    title: string;
    id: string;
    tab: string;
}) => {
    trackPageView('Artist', { ...properties, page: 'artist' });
};

export const trackProductPageView = (properties: {
    brand?: string;
    title: string;
    upc: string;
    tab: string;
}) => {
    trackPageView('Product', { ...properties, page: 'product' });
};

export const trackChannelPageView = (properties: {
    brand?: string;
    title: string;
    tab: string;
    channelId: string;
}) => {
    trackPageView('Youtube', { ...properties, page: 'youtube' });
};

export const trackVideoPageView = (properties: {
    brand?: string;
    title: string;
    tab: string;
    videoId: string;
}) => {
    trackPageView('Video', { ...properties, page: 'video' });
};

export const trackAccountPageView = (properties: {
    brand?: string;
    title: string;
    tab: string;
    accountId: number;
    subaccountId?: number;
}) => {
    const page = Number(properties.subaccountId) ? 'subaccount' : 'account';
    trackPageView(page, { ...properties, page });
};

export const trackCatalogPageView = (properties: {
    brand?: string;
    tab: string;
}) => {
    trackPageView('Catalog', { ...properties, page: 'catalog' });
};

export const trackFavoritesPageView = (properties: {
    brand?: string;
    tab: string;
}) => {
    trackPageView('Favorites', {
        ...properties,
        page: 'favorites',
    });
};

export const trackChartsPageView = (properties: {
    brand?: string;
    tab: string;
}) => {
    trackPageView('Charts', { ...properties, page: 'charts' });
};
export const trackSearchPageView = (properties: {
    brand?: string;
    tab: string;
}) => {
    trackPageView('Search', { ...properties, page: 'search' });
};
