import { QueryHookOptions, useQuery } from '@apollo/client';
import { PreSavedMarkets } from '../definitions/PreSavedMarkets';
import { usePrefetchQuery } from '../utils';
import preSavedMarketsQuery from './preSavedMarkets.gql';

export const usePreSavedMarketsQuery = (
    options?: QueryHookOptions<PreSavedMarkets>
) => {
    const { data, loading, error } = useQuery<PreSavedMarkets>(
        preSavedMarketsQuery,
        {
            fetchPolicy: 'cache-first',
            ...options,
        }
    );

    const preSavedMarkets = data?.activeProfile.preSavedMarkets ?? [];

    return {
        data: preSavedMarkets,
        loading,
        error,
    };
};

export const usePrefetchPreSavedMarketsQuery = () => {
    usePrefetchQuery<PreSavedMarkets>(preSavedMarketsQuery, {
        variables: {},
    });
};
