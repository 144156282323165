import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { ExternalLinkGlyph, GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import TruncatedText from 'src/components/truncatedText';
import { EMPTY_CHAR } from 'src/constants';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import { YoutubeTypeLinks, buildYoutubeLink } from 'src/utils/charts';
import EmptyCell from './emptyCell';

const CLASSNAME = 'ExternalChannelLinkCell';
const CLASSNAME_LINK = `${CLASSNAME}-link`;
const CLASSNAME_LINK_CONTAINER = `${CLASSNAME}-linkContainer`;
const CLASSNAME_TEXT = `${CLASSNAME}-text`;

GridTable.defineColumnTemplate('externalChannelLink', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { channel, channelId, channelName } }) => {
        const id = channelId || channel?.id;
        const name = channelName || channel?.name || EMPTY_CHAR;
        if (!id) return <EmptyCell />;

        return (
            <div className={cx(CLASSNAME, 'ExternalLinkCell')}>
                <FeatureFlagPanel
                    featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                    fallback={
                        <a
                            className={CLASSNAME_LINK}
                            href={buildYoutubeLink({
                                channel: id,
                                type: YoutubeTypeLinks.channel,
                            })}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TruncatedText
                                className={CLASSNAME_TEXT}
                                value={name}
                            />
                            <ExternalLinkGlyph className="ml-2" />
                        </a>
                    }
                >
                    <div className={CLASSNAME_LINK_CONTAINER}>
                        <TruncatedText
                            className={CLASSNAME_TEXT}
                            value={name}
                        />
                        <a
                            href={buildYoutubeLink({
                                channel: id,
                                type: YoutubeTypeLinks.channel,
                            })}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <GlyphIcon
                                name="externalLink"
                                size={12}
                                className="ml-2"
                            />
                        </a>
                    </div>
                </FeatureFlagPanel>
            </div>
        );
    },
});
