import React, { FC, ReactNode } from 'react';
import { formatNumber } from '@theorchard/suite-frontend';
import { CountryFlag } from '@theorchard/suite-icons';
import cx from 'classnames';
import { Link } from 'react-router-dom';

export interface Props {
    className?: string;
    score?: number | null;
    country?: string | null;
    reverse?: boolean;
    link?: string;
}

export const CLASSNAME = 'TiktokTrendScoreValue';
export const CLASSNAME_LINK = `${CLASSNAME}-link`;

const TiktokTrendScoreValue: FC<Props> = ({
    className,
    country,
    score,
    reverse,
    link,
}) => {
    if (!score || !country) return null;

    const nodes: ReactNode[] = [
        formatNumber(score),
        <CountryFlag key={country} countryCode={country} showTitle={false} />,
    ];
    if (link)
        return (
            <Link
                className={cx(CLASSNAME, CLASSNAME_LINK, className)}
                to={link}
            >
                {reverse ? nodes.reverse() : nodes}
            </Link>
        );

    return (
        <div className={cx(CLASSNAME, className)}>
            {reverse ? nodes.reverse() : nodes}
        </div>
    );
};

export default TiktokTrendScoreValue;
