import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { EMPTY_CHAR } from 'src/constants';

GridTable.defineColumnTemplate('simpleAppearances', {
    align: 'left',
    Cell: ({ value }) => {
        if (!value) return <span>{EMPTY_CHAR}</span>;

        return (
            <TruncatedText value={value.toString()}>
                <span>{value}</span>
            </TruncatedText>
        );
    },
});
