import React from 'react';
import { GridTable as SuiteGridTable } from '@theorchard/suite-components';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import ContentPanel from 'src/components/contentPanel';
import DataPanel from 'src/components/dataPanel';

SuiteGridTable.addExtension({
    name: 'expanded-row-table',
    isEnabled: props => !!props.expandedRowTables,
    onPropsInit: props => ({
        ...props,
        columnDefs: [
            ...props.columnDefs,
            {
                name: 'expandable-row-indicator',
                maxWidth: 'min-content',
                minWidth: 'min-content',
                title: '',
                configurable: false,
                visibility: props.showExpandedRowTablesIndicator
                    ? 'visible'
                    : 'hidden',
                Cell: ({
                    row,
                    context: {
                        props: { expandedRowTables },
                    },
                }) => {
                    const isExpanded = expandedRowTables
                        ? row.key in expandedRowTables
                        : false;

                    const iconName = isExpanded ? 'chevronUp' : 'chevronDown';

                    return <GlyphIcon name={iconName} size={16} />;
                },
            },
        ],
    }),
    onRowsCreated(rows, { expandedRowTables, expandedRowTableKey }) {
        if (!expandedRowTables || Object.keys(expandedRowTables).length === 0)
            return rows;

        const allRows = rows.flatMap(row => {
            const expandedRowData = expandedRowTables[row.key];
            if (!expandedRowData) return [row];

            const { loading, data = [], error } = expandedRowData;

            if (loading || error || data.length === 0)
                return [
                    row,
                    {
                        ...row,
                        key: `${row.key}-expanded-row-table`,
                        className: cx(row.className, 'expanded'),
                        isExpanded: true,
                        Content: () => (
                            <ContentPanel
                                className="expanded-row-table-status"
                                isEmpty={!loading && !error}
                            >
                                <DataPanel
                                    className="expanded-row-table-status"
                                    isLoading={loading}
                                    error={error}
                                />
                            </ContentPanel>
                        ),
                    },
                ];

            return [
                {
                    ...row,
                    className: cx(row.className, 'expanded'),
                    isExpanded: true,
                },
                ...data.map((rowData, index) => ({
                    ...row,
                    data: rowData,
                    key: expandedRowTableKey
                        ? expandedRowTableKey(rowData)
                        : `${row.key}-expanded-row-table-content-${index}`,
                    className: cx(row.className, 'expanded-row-table-content'),
                    isClickable: false,
                })),
            ];
        });

        return allRows.map((row, index) => ({
            ...row,
            index: index + 1,
        }));
    },
});
