import dayjs from 'dayjs';
import { EMPTY_CHAR } from 'src/constants';

export default (fromDate: string, toDate?: string) => {
    let diff = EMPTY_CHAR;
    if (toDate) {
        const momentToDate = dayjs.utc(toDate);
        const momentFromDate = dayjs.utc(fromDate);

        diff = momentFromDate.isSameOrAfter(momentToDate, 'day')
            ? (momentFromDate.diff(momentToDate, 'days') + 1).toString()
            : EMPTY_CHAR;
    }

    return diff;
};
