import React, { FC } from 'react';
import { Table } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import {
    Source,
    SOURCE_DOWNLOADS,
    SOURCE_SAVES,
    SOURCE_SKIPS,
    SOURCE_STREAMS,
    SOURCE_VIEWS,
    SOURCE_SOS,
    TYPE_SOS,
    SourceType,
    STORE_SOURCES,
    TYPE_DOWNLOADS,
    TYPE_SKIPS_SAVES,
    TYPE_STREAMS,
    TYPE_VIEWS,
} from 'src/constants/stores';
import { CLASSNAME } from './constants';

export interface Props {
    sources: Source[];
    sourceTypes: SourceType[];
}

export const CLASSNAME_TABLE = `${CLASSNAME}-table`;
export const MARKER_CLASSNAME_ERROR = 'bg-danger';
export const MARKER_CLASSNAME_OK = 'bg-success';

export const SOURCE_TYPES = [
    { key: SOURCE_STREAMS, type: TYPE_STREAMS },
    { key: SOURCE_DOWNLOADS, type: TYPE_DOWNLOADS },
    { key: SOURCE_SKIPS, type: TYPE_SKIPS_SAVES },
    { key: SOURCE_SAVES, type: TYPE_SKIPS_SAVES },
    { key: SOURCE_VIEWS, type: TYPE_VIEWS },
    { key: SOURCE_SOS, type: TYPE_SOS },
];

const [DEFAULT_SOURCE] = SOURCE_TYPES;

export const hasType = (source: Source, type: string) => {
    const types = STORE_SOURCES[source.storeId] || [DEFAULT_SOURCE.type];
    return types.includes(type);
};

export const hasIssue = (source: Source, type: string) =>
    source.error && (!source.error.types || source.error.types.includes(type));

const renderStatus = (source: Source, type: string) => (
    <div
        className={cx(
            'dot',
            'dot-sm',
            hasIssue(source, type)
                ? MARKER_CLASSNAME_ERROR
                : MARKER_CLASSNAME_OK
        )}
    />
);

const SourcesStatusTable: FC<Props> = ({ sources, sourceTypes }) => (
    <Table className={CLASSNAME_TABLE} data-testid={CLASSNAME_TABLE} borderless>
        <thead>
            <tr>
                <td />
                {SOURCE_TYPES.filter(({ key }) =>
                    sourceTypes.includes(key)
                ).map(({ key }) => (
                    <td key={key}>{formatMessage(`sources.types.${key}`)}</td>
                ))}
            </tr>
        </thead>
        <tbody>
            {sources.map(source => (
                <tr
                    key={source.storeId}
                    data-testid={`store-${source.storeId}`}
                >
                    <td>{source.storeName}</td>
                    {SOURCE_TYPES.filter(({ key }) =>
                        sourceTypes.includes(key)
                    ).map(({ type, key }) => {
                        return (
                            <td key={key} data-type={type}>
                                {hasType(source, type) &&
                                    renderStatus(source, type)}
                            </td>
                        );
                    })}
                </tr>
            ))}
        </tbody>
    </Table>
);

export default SourcesStatusTable;
