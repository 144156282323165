import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import PlusMoreValue from 'src/components/plusMoreValue';
import { EMPTY_CHAR, PLAYLIST_GENRES_LIMIT } from 'src/constants';

GridTable.defineColumnTemplate('genre', {
    align: 'left',
    Cell: ({ data: { genres }, column: { definition } }) => {
        const useEmptyChar = get(definition, 'data-useEmptyChar', true);
        const emptyValue = useEmptyChar ? <span>{EMPTY_CHAR}</span> : null;

        return (
            <div className="GenreCell" data-testid="GenreCell">
                <PlusMoreValue
                    defaultValue={emptyValue}
                    displayThreshold={PLAYLIST_GENRES_LIMIT}
                    values={genres}
                />
            </div>
        );
    },
});
