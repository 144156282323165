import React, { FC } from 'react';
import { Button, HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import {
    STORE_ID_AMAZON_MUSIC,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_SPOTIFY,
} from 'src/constants/stores';

export interface Props {
    onClick: () => void;
    isError: boolean;
    disabled: boolean;
}

export const CLASSNAME = `sourcesTrigger`;
export const CLASSNAME_BTN = `${CLASSNAME}-btn`;
export const INFO_GLYPH_CLASSNAME = `${CLASSNAME}-info`;
export const ERROR_GLYPH_CLASSNAME = `${CLASSNAME}-error`;
export const TOOLTIP_CLASSNAME = `${CLASSNAME}-tooltip`;
export const STREAMS_STATUS_STORES = [
    STORE_ID_AMAZON_MUSIC,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_SPOTIFY,
];

const SourceTrigger: FC<Props> = ({ onClick, isError, disabled }) => (
    <div data-testid={CLASSNAME} className={CLASSNAME}>
        <Button
            size="sm"
            variant="quartenary"
            onClick={onClick}
            className={CLASSNAME_BTN}
            data-testid={CLASSNAME_BTN}
            disabled={disabled}
        >
            {formatMessage('sources.modal.storesStatus')}
        </Button>

        <GlyphIcon name="info" size={12} className={INFO_GLYPH_CLASSNAME} />

        {isError && (
            <HelpTooltip
                id="TableSourcesErrorTooltip"
                overlayClassName={TOOLTIP_CLASSNAME}
                message={formatMessage('error.tableSourcesError')}
            >
                <GlyphIcon
                    name="warning"
                    size={16}
                    className={ERROR_GLYPH_CLASSNAME}
                    testId={ERROR_GLYPH_CLASSNAME}
                />
            </HelpTooltip>
        )}
    </div>
);

export default SourceTrigger;
