import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { SpotifyPlayButton } from 'src/components/spotifyPlayer';
import { STORE_ID_SPOTIFY } from 'src/constants/stores';
import EmptyCell from './emptyCell';

GridTable.defineColumnTemplate('playlistPlay', {
    align: 'right',
    Cell: ({ data: { playlistId, storeId } }) => {
        if (storeId !== STORE_ID_SPOTIFY) return <EmptyCell />;

        return (
            <SpotifyPlayButton
                helpText={formatMessage('spotifyPlayer.playlistPlayHint')}
                playlistId={playlistId}
            />
        );
    },
});
