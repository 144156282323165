import { DocumentNode } from '@apollo/client';
import {
    CreateGlobalProductLink,
    CreateGlobalProductLinkVariables,
} from 'src/apollo/definitions/CreateGlobalProductLink';
import { PotGraphType } from 'src/apollo/definitions/globalTypes';
import createGlobalProductLink from 'src/apollo/mutations/universalLinks/createGlobalProductLink.gql';

export interface ProductParams {
    type: 'product';
    upc: string;
    countryFilter?: string[];
    potIds?: string[];
    storeFilter?: number[];
    potMetric?: string;
    potDimension?: string;
    dateFilter?: string;
    dateFilterStart?: string;
    dateFilterEnd?: string;
    page?: string;
    potGraphType?: PotGraphType;
}

export interface ProductLinkData {
    query: DocumentNode;
    variables: CreateGlobalProductLinkVariables;
    selector: (res?: CreateGlobalProductLink | null) => string | undefined;
    type: 'product';
}

export const selectProductLink = (data: ProductParams): ProductLinkData => ({
    query: createGlobalProductLink,
    variables: {
        upc: data.upc,
        countries: data.countryFilter,
        storeIds: data.storeFilter,
        pot: data.potMetric,
        dimension: data.potDimension,
        period: data.dateFilter,
        startDate: data.dateFilterStart,
        endDate: data.dateFilterEnd,
        page: data.page,
        potIds: data.potIds,
        potGraphType: data.potGraphType,
    },
    selector: res => res?.createGlobalProductLink,
    type: 'product',
});
