import { useEmployeeIdentity } from 'src/apollo/utils';
import { BRAND_AWAL } from 'src/constants';

export const useGdaEmployeeIdentity = () => {
    const identity = useEmployeeIdentity();
    const { isGDAUser, defaultBrand } = identity;
    const isNotGDAUser = isGDAUser === false;
    const isNotAWALUser = defaultBrand !== BRAND_AWAL;

    return {
        identity,
        isNotAwalGdaUser: isNotGDAUser && isNotAWALUser,
    };
};
