import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import { ExternalLinkGlyph, GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import TruncatedText from 'src/components/truncatedText';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import { YoutubeTypeLinks, buildYoutubeLink } from 'src/utils/charts';
import EmptyCell from './emptyCell';

const CLASSNAME = 'ExternalVideoLinkCell';
const CLASSNAME_LINK = `${CLASSNAME}-link`;
const CLASSNAME_LINK_CONTAINER = `${CLASSNAME}-linkContainer`;
const CLASSNAME_TEXT = `${CLASSNAME}-text`;

GridTable.defineColumnTemplate('externalVideoLink', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { video } }) => {
        if (!video?.id) return <EmptyCell />;
        return (
            <div className={cx(CLASSNAME, 'ExternalLinkCell CoverArtCell')}>
                <CoverArt url={video.imageUrl} shape="landscape" width="60" />
                <FeatureFlagPanel
                    featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                    fallback={
                        <a
                            className={CLASSNAME_LINK}
                            href={buildYoutubeLink({
                                videoId: video.id,
                                type: YoutubeTypeLinks.video,
                            })}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TruncatedText
                                className={CLASSNAME_TEXT}
                                value={video.name}
                            />
                            <ExternalLinkGlyph className="ml-2" />
                        </a>
                    }
                >
                    <div className={CLASSNAME_LINK_CONTAINER}>
                        <TruncatedText
                            className={CLASSNAME_TEXT}
                            value={video.name}
                        />
                        <a
                            href={buildYoutubeLink({
                                videoId: video.id,
                                type: YoutubeTypeLinks.video,
                            })}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <GlyphIcon
                                name="externalLink"
                                size={12}
                                className="ml-2"
                            />
                        </a>
                    </div>
                </FeatureFlagPanel>
            </div>
        );
    },
});
