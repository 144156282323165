import React, { FC } from 'react';
import { formatDate } from '@theorchard/suite-frontend';
import cx from 'classnames';
import PlatformIcon from 'src/components/platformIcon';
import { SOURCE_DATE_FORMAT } from 'src/constants';
import { Source, STORES_BY_ID } from 'src/constants/stores';
import { CLASSNAME } from './constants';
import MissingDatesHint from './missingDatesHint';
import { getSourcesStatusClass, getSourceTerm } from './utils';

export const CLASSNAME_ROW = `${CLASSNAME}-row`;
export const CLASSNAME_STATUS = `${CLASSNAME}-status`;
export const CLASSNAME_STORE = `${CLASSNAME}-store`;
export const CLASSNAME_DATE = `${CLASSNAME}-date`;

const SourceItem: FC<Source> = ({
    latestDate,
    storeName,
    storeId,
    missingDates,
    error,
}) => {
    const displayDate = latestDate
        ? formatDate(latestDate, SOURCE_DATE_FORMAT)
        : 'N/A';

    return (
        <div
            key={storeId}
            data-testid={`store-${storeId}`}
            className={CLASSNAME_ROW}
        >
            <div className={CLASSNAME_STORE}>
                <PlatformIcon
                    platform={STORES_BY_ID[storeId]}
                    storeId={storeId}
                />
                <div>{storeName}</div>
            </div>

            <div className={CLASSNAME_STATUS}>
                <div
                    className={cx(
                        'dot',
                        'dot-md',
                        getSourcesStatusClass(error, missingDates)
                    )}
                />

                {getSourceTerm(error, missingDates)}

                <MissingDatesHint
                    storeId={storeId}
                    error={error}
                    missingDates={missingDates}
                />
            </div>

            <div className={CLASSNAME_DATE}>{displayDate}</div>
        </div>
    );
};

export default SourceItem;
