import { SuitePlugin } from '@theorchard/suite-frontend';
import { defaultStorage } from 'src/utils/storage';

const PersistedCacheInitPlugin: SuitePlugin = {
    onAppDataLoad({ identity: { id, profileType, profileId } }) {
        const cacheKey = `${id}-${profileType}-${profileId}`;
        defaultStorage.initKeyPrefix(cacheKey);
    },
};

export default PersistedCacheInitPlugin;
