import React from 'react';
import { GridTable, Tooltip } from '@theorchard/suite-components';
import { uniqueId } from 'lodash';
import { EMPTY_CHAR } from 'src/constants';
import { formatPercentage } from 'src/utils/formatNumber';

GridTable.defineColumnTemplate('growthPercentage', {
    align: 'right',
    minWidth: '120px',
    defaultSortDirection: 'desc',
    Cell: ({ value }) => {
        const valueNumber = Number(value);
        const isNilNumber = value === null || value === undefined;

        if (
            isNilNumber ||
            value === '' ||
            Number.isNaN(valueNumber) ||
            valueNumber === 0
        )
            return <span>{EMPTY_CHAR}</span>;

        const className =
            valueNumber >= 0 ? 'percentageIncrease' : 'percentageDecrease';

        if (valueNumber > 9.99)
            return (
                <span className={className}>
                    <Tooltip
                        id={`growthPercentage_${valueNumber}_${uniqueId()}`}
                        message={formatPercentage(valueNumber)}
                    >
                        {'>999%'}
                    </Tooltip>
                </span>
            );

        return (
            <span className={className}>{formatPercentage(valueNumber)}</span>
        );
    },
});
