import {
    ApolloError,
    DocumentNode,
    LazyQueryHookOptions,
    useLazyQuery,
    useQuery,
    QueryLazyOptions,
} from '@apollo/client';
import {
    GlobalSoundRecordingSearchFacets,
    GlobalSoundRecordingSearchFacets_globalSoundRecordingSearchES_facets as SearchFacets,
    GlobalSoundRecordingSearchFacetsVariables,
} from 'src/apollo/definitions/GlobalSoundRecordingSearchFacets';
import { GlobalSoundRecordingSearchOrderByInput } from 'src/apollo/definitions/globalTypes';
import { PARAM_EXACT_PHRASE } from 'src/pages/searchES/constants';
import {
    GlobalSoundRecordingSearchES,
    GlobalSoundRecordingSearchESVariables,
} from '../../definitions/GlobalSoundRecordingSearchES';
import {
    selectGlobalSoundRecordingSearchESResults,
    SoundRecordingSearchResult,
} from '../../selectors/soundRecordingSearch';
import globalSoundRecordingSearchES from './globalSoundRecordingSearchES.gql';
import globalSoundRecordingSearchFacets from './globalSoundRecordingSearchFacets.gql';

interface Variables extends GlobalSoundRecordingSearchESVariables {
    queryId?: number;
    catalogOnly?: boolean;
    orderBy?: GlobalSoundRecordingSearchOrderByInput[];
}

interface GlobalSoundRecordingVariablesSearchQueryWithBrands
    extends GlobalSoundRecordingSearchESVariables {
    shouldIncludeLabelsCompanyBrand?: boolean;
}

export interface SoundRecordingSearchQueryResult {
    loading: boolean;
    error?: ApolloError;
    data?: SoundRecordingSearchResult;
    variables?: Variables;
}

export type SoundRecordingSearchLazyQueryResult = [
    (variables: QueryLazyOptions<Variables>) => void,
    SoundRecordingSearchQueryResult
];

interface SearchEngine {
    options?: LazyQueryHookOptions;
    getQuery: (isCatalog: boolean) => DocumentNode;
    selectData: (
        data: GlobalSoundRecordingSearchES,
        isSearchV2FFEnabled: boolean
    ) => SoundRecordingSearchResult;
}

const useSearchEngine = (): SearchEngine => ({
    getQuery: () => globalSoundRecordingSearchES,
    selectData: selectGlobalSoundRecordingSearchESResults,
});

export const useLazySoundRecordingSearch = (
    isCatalog: boolean,
    shouldUseGSRName = false
): SoundRecordingSearchLazyQueryResult => {
    const { getQuery, selectData } = useSearchEngine();

    const [executeSearch, { data, loading, error }] = useLazyQuery<
        GlobalSoundRecordingSearchES,
        Variables
    >(getQuery(isCatalog));

    return [
        executeSearch,
        {
            data: data && selectData(data, shouldUseGSRName),
            loading,
            error,
        },
    ];
};

const EMPTY_FACETS: SearchFacets = {
    vendors: { items: [] },
    participantCountries: { items: [] },
    participants: { items: [] },
};

export const useSoundRecordingSearchFacetsQuery = (
    variables: GlobalSoundRecordingSearchFacetsVariables
) => {
    const { loading, error, data } = useQuery<
        GlobalSoundRecordingSearchFacets,
        GlobalSoundRecordingSearchFacetsVariables
    >(globalSoundRecordingSearchFacets, {
        variables,
        fetchPolicy: 'cache-first',
    });

    return {
        loading,
        data: data?.globalSoundRecordingSearchES.facets ?? EMPTY_FACETS,
        error,
    };
};

export const useSoundRecordingSearchQuery = ({
    variables,
    skip,
}: {
    variables: GlobalSoundRecordingVariablesSearchQueryWithBrands;
    skip?: boolean;
}): SoundRecordingSearchQueryResult => {
    const { loading, error, data } = useQuery<
        GlobalSoundRecordingSearchES,
        GlobalSoundRecordingVariablesSearchQueryWithBrands
    >(globalSoundRecordingSearchES, {
        variables,
        skip,
    });

    const shouldUseGSRName =
        Boolean(variables?.isSearchV2FFEnabled) &&
        variables?.queryType !== PARAM_EXACT_PHRASE;

    return {
        loading,
        data:
            data &&
            selectGlobalSoundRecordingSearchESResults(data, shouldUseGSRName),
        error,
    };
};
