import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    selectStarredProducts,
    selectStarredProductsStreams,
} from 'src/apollo/selectors/product';
import {
    StarredGlobalProductsQuery as Query,
    StarredGlobalProductsQueryVariables as QueryVars,
} from '../../definitions/StarredGlobalProductsQuery';
import {
    StarredGlobalProductsStreamsQuery as StreamsQuery,
    StarredGlobalProductsStreamsQueryVariables as StreamsQueryVars,
} from '../../definitions/StarredGlobalProductsStreamsQuery';
import starredGlobalProductsQuery from './starredGlobalProducts.gql';
import starredGlobalProductsStreamsQuery from './starredGlobalProductsStreams.gql';

export const useStarredGlobalProductsQuery = (variables: QueryVars) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        starredGlobalProductsQuery,
        { variables }
    );

    const memoizedData = useMemo(
        () => data && selectStarredProducts(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useStarredGlobalProductsStreamsQuery = (
    variables: StreamsQueryVars
) => {
    const { data, loading, error } = useQuery<StreamsQuery, StreamsQueryVars>(
        starredGlobalProductsStreamsQuery,
        { variables }
    );

    const memoizedData = useMemo(
        () => data && selectStarredProductsStreams(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
