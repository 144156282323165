import { useQuery, QueryHookOptions } from '@apollo/client';
import { selectAllTimeVideoMetrics } from 'src/apollo/selectors/videoMetrics';
import {
    VideoAnalyticsAllTimeQuery,
    VideoAnalyticsAllTimeQueryVariables,
} from '../../definitions/VideoAnalyticsAllTimeQuery';
import { flattenError } from '../../utils';
import videoAnalyticsAllTimeQuery from './videoAnalyticsAllTime.gql';

export const useVideoAnalyticsAllTimeQuery = (
    videoId: string,
    variables?: QueryHookOptions<
        VideoAnalyticsAllTimeQuery,
        VideoAnalyticsAllTimeQueryVariables
    >
) => {
    const { data, loading, error } = useQuery<
        VideoAnalyticsAllTimeQuery,
        VideoAnalyticsAllTimeQueryVariables
    >(videoAnalyticsAllTimeQuery, {
        variables: {
            videoId,
        },
        ...variables,
    });

    return {
        loading,
        data: selectAllTimeVideoMetrics(data?.video?.analytics.allTime) ?? null,
        error: error && flattenError(error),
    };
};
