import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_PRODUCT } from 'src/constants';
import { TEST_ID as productSearchResultsId } from 'src/pages/searchES/pages/productResults/productResultsGridTable';

GridTable.defineColumnTemplate('thumbnailProductLink', {
    align: 'left',
    Cell: ({
        data: { upc, productName, imageLocation },
        context: {
            props: { testId },
        },
    }) => {
        const shouldLogPageVisit = testId === productSearchResultsId;

        return (
            <div
                className="ThumbnailProductLinkCell CoverArtCell"
                data-testid="ThumbnailProductLinkCell"
            >
                <CoverArt url={imageLocation} width="30" />
                <TruncatedText
                    value={productName}
                    useLink={!!upc}
                    shouldLogPageVisit={shouldLogPageVisit}
                    route={ROUTE_PRODUCT}
                    params={{ upc }}
                    maxLength={30}
                />
            </div>
        );
    },
});
