import React from 'react';

export const CLASSNAME = 'AlbumDownloadsInactiveIcon';

const AlbumDownloadsInactiveIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className={CLASSNAME}
    >
        {/* eslint-disable max-len */}
        <path fill="#fff" d="M0 0H24V24H0z" />
        <circle cx="12" cy="12" r="11" stroke="#A8A0A0" strokeWidth="2" />
        <path
            fill="#A8A0A0"
            d="M4.535 16a.244.244 0 01-.252-.252l.024-.108 2.88-7.74c.08-.2.224-.3.432-.3h1.32c.208 0 .352.1.432.3l2.88 7.74.024.108a.227.227 0 01-.084.18.23.23 0 01-.168.072h-1.068a.348.348 0 01-.348-.228l-.552-1.452H6.503l-.552 1.452a.348.348 0 01-.348.228H4.535zm2.388-3.072h2.712L8.28 9.232l-1.356 3.696zM13.671 16a.278.278 0 01-.204-.084.278.278 0 01-.084-.204V7.9a.32.32 0 01.072-.216.292.292 0 01.216-.084H16.6c2.36 0 3.564 1.088 3.612 3.264.016.464.024.776.024.936 0 .152-.008.46-.024.924-.032 1.12-.336 1.948-.912 2.484-.568.528-1.448.792-2.64.792h-2.988zm2.928-1.392c.664 0 1.144-.148 1.44-.444.296-.296.452-.788.468-1.476.016-.48.024-.78.024-.9 0-.128-.008-.424-.024-.888-.016-.656-.18-1.136-.492-1.44-.312-.312-.804-.468-1.476-.468h-1.464v5.616H16.6z"
        />
        <path stroke="#A8A0A0" strokeWidth="2" d="M20 20L4 4" />
        {/* eslint-enable max-len */}
    </svg>
);

export default AlbumDownloadsInactiveIcon;
