import React, { VFC } from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GridTableColumnTextProps } from './types';

const TotalStreamsHeaderCell: VFC<GridTableColumnTextProps> = () => (
    <>
        <span>{formatMessage('chart.totalStreams')}</span>
        <HelpTooltip
            id="totalStreams-tooltip"
            message={formatMessage('chart.totalStreamsTooltip')}
            tooltipPlacement="right"
        />
    </>
);

export default TotalStreamsHeaderCell;
