import { MutationTuple, useMutation } from '@apollo/client';
import {
    SetPreSavedMarkets,
    SetPreSavedMarketsVariables,
} from 'src/apollo/definitions/SetPreSavedMarkets';
import setPreSavedMarketsMutation from './setPreSavedMarkets.gql';

export const useSetPreSavedMarketsMutation = (): MutationTuple<
    SetPreSavedMarkets,
    SetPreSavedMarketsVariables
> => {
    const [setMarkets, state] = useMutation<
        SetPreSavedMarkets,
        SetPreSavedMarketsVariables
    >(setPreSavedMarketsMutation);

    return [setMarkets, state];
};
