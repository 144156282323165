import React from 'react';
import { GridTable, Tag, Tooltip } from '@theorchard/suite-components';
import { uniqueId } from 'lodash';
import { getBrandIconValue } from 'src/utils/accounts';

const CLASSNAME = 'BrandTagCell';
export const TEST_ID_TOOLTIP = `${CLASSNAME}-tooltip`;

GridTable.defineColumnTemplate('brandTag', {
    align: 'center',
    Cell: ({ data }) => {
        const tooltip = data.brands?.[0]?.label;
        const tooltipId = `${CLASSNAME}_${uniqueId()}`;

        if (!tooltip) return null;

        return (
            <Tooltip id={tooltipId} message={tooltip} testId={TEST_ID_TOOLTIP}>
                <Tag
                    brand={getBrandIconValue(data.brands?.[0].brandAbbrName)}
                />
            </Tooltip>
        );
    },
});
