import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import {
    GlyphIcon,
    PauseGlyph,
    PlayGlyph,
    SpotifyStoreIcon,
    WarningGlyph,
} from '@theorchard/suite-icons';
import cx from 'classnames';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import { FC } from 'src/utils/react';
import SpotifyWebApi from './spotifyWebApi';
import useSpotifyPlayer from './useSpotifyPlayer';

export const CLASSNAME = 'SpotifyPlayButton';
export const TEST_ID = CLASSNAME;
export const TEST_CONTENT_ID = `${TEST_ID}-content`;

export const TERM_PLAY_HINT = 'spotifyPlayer.playHint';

export type Positions = 'left' | 'right' | 'top' | 'bottom';
export interface Props {
    helpText?: string;
    trackIds?: string[];
    playlistId?: string;
    tooltipPlacement?: Positions;
}

const SpotifyPlayButton: FC<Props> = ({
    helpText,
    playlistId,
    trackIds,
    tooltipPlacement,
}) => {
    const initialErrorValue =
        !!SpotifyWebApi.errorLoadingPlaylistTracks &&
        playlistId === SpotifyWebApi.currentPlaylistId;
    const [{ playing, error }, play] = useSpotifyPlayer({
        trackIds: trackIds || [],
        playlistId,
    });
    const [errorSearchPlaylistItems, setErrorSearchPlaylistItems] =
        useState<boolean>(initialErrorValue);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        SpotifyWebApi.onReady(() => setReady(true));
    }, []);

    const getPlaylistTracks = async (id?: string) => {
        const playlistTracks = await SpotifyWebApi.getPlaylistTracks(id);
        const isErrorGettingPlaylistTracks =
            SpotifyWebApi.errorLoadingPlaylistTracks;

        if (isErrorGettingPlaylistTracks) {
            setErrorSearchPlaylistItems(true);
            return [];
        }

        return playlistTracks || [];
    };

    const handleClick = async (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        const tracks = playlistId ? await getPlaylistTracks(playlistId) : [];
        if (playlistId && !tracks.length) return;

        play(tracks, playlistId);
    };

    if (!ready || (!trackIds?.length && !playlistId))
        return (
            <div className={cx(CLASSNAME, 'disabled')}>
                <FeatureFlagPanel
                    featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                    fallback={<PlayGlyph />}
                >
                    <GlyphIcon name="play" size={12} />
                </FeatureFlagPanel>
            </div>
        );

    if (playlistId && errorSearchPlaylistItems)
        return (
            <div>
                <FeatureFlagPanel
                    featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                    fallback={<WarningGlyph />}
                >
                    <GlyphIcon name="warning" size={12} />
                </FeatureFlagPanel>
            </div>
        );

    const formattedHelpPlayButtonText =
        helpText || formatMessage(TERM_PLAY_HINT);
    return (
        <div
            className={cx(CLASSNAME, { playing, error })}
            data-track-id={trackIds ? trackIds[0] : playlistId}
            role="button"
            tabIndex={0}
            onClick={handleClick}
            onKeyPress={async e => await (e.key === 'Enter' && handleClick(e))}
            data-testid={TEST_ID}
        >
            {playing && (
                <FeatureFlagPanel
                    featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                    fallback={<PauseGlyph />}
                >
                    <GlyphIcon name="pause" size={16} />
                </FeatureFlagPanel>
            )}
            {!playing && (
                <HelpTooltip
                    id={`spotifyPlayerHint_${
                        trackIds ? trackIds[0] : playlistId
                    }`}
                    tooltipPlacement={tooltipPlacement}
                    message={
                        <div className={`${CLASSNAME}-hint`}>
                            <SpotifyStoreIcon
                                className={`${CLASSNAME}-spotify-icon`}
                            />
                            {formattedHelpPlayButtonText}
                        </div>
                    }
                >
                    <span data-testid={TEST_CONTENT_ID}>
                        <FeatureFlagPanel
                            featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                            fallback={<PlayGlyph />}
                        >
                            <GlyphIcon name="play" size={12} />
                        </FeatureFlagPanel>
                    </span>
                </HelpTooltip>
            )}
        </div>
    );
};

SpotifyPlayButton.className = CLASSNAME;

export default SpotifyPlayButton;
