import { useQuery } from '@apollo/client';
import {
    selectVideoRelatedContent,
    selectRelatedContentSources,
} from 'src/apollo/selectors';
import {
    VideoRelatedContent,
    VideoRelatedContentVariables,
} from '../../definitions/VideoRelatedContent';
import { flattenError } from '../../utils';
import videoRelatedContentQuery from './videoRelatedContent.gql';

export const useVideoRelatedContentQuery = (
    variables: VideoRelatedContentVariables
) => {
    const { data, loading, error } = useQuery<
        VideoRelatedContent,
        VideoRelatedContentVariables
    >(videoRelatedContentQuery, { variables });

    const relatedContent = selectVideoRelatedContent(
        data?.video ?? undefined,
        variables.videoId
    );
    const sources = selectRelatedContentSources(
        data?.video?.globalSoundRecording?.[0]
    );

    return {
        loading,
        data: {
            relatedContent,
            sources,
        },
        error: error && flattenError(error),
    };
};
