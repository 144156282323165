import { DocumentNode } from '@apollo/client';
import {
    CreateGlobalSoundRecordingLink,
    CreateGlobalSoundRecordingLinkVariables,
} from 'src/apollo/definitions/CreateGlobalSoundRecordingLink';
import { PotGraphType } from 'src/apollo/definitions/globalTypes';
import createGlobalSoundRecordingLink from 'src/apollo/mutations/universalLinks/createGlobalSoundRecordingLink.gql';

export interface SongParams {
    type: 'song';
    isrc: string;
    countryFilter?: string[];
    storeFilter?: number[];
    potMetric?: string;
    potDimension?: string;
    dateFilter?: string;
    dateFilterStart?: string;
    dateFilterEnd?: string;
    page?: string;
    curatorFilter?: string;
    playlistAppearances?: string;
    playlistTypeFilter?: string[];
    territoryFilter?: string;
    chart?: string;
    sosDetails?: boolean;
    potGraphType?: PotGraphType;
}

export interface SongLinkData {
    query: DocumentNode;
    variables: CreateGlobalSoundRecordingLinkVariables;
    selector: (
        res?: CreateGlobalSoundRecordingLink | null
    ) => string | undefined;
    type: 'song';
}

export const selectSongLink = (data: SongParams): SongLinkData => ({
    query: createGlobalSoundRecordingLink,
    variables: {
        isrc: data.isrc,
        countries: data.countryFilter,
        storeIds: data.storeFilter,
        pot: data.potMetric,
        dimension: data.potDimension,
        period: data.dateFilter,
        startDate: data.dateFilterStart,
        endDate: data.dateFilterEnd,
        page: data.page,
        playlistAppearances: data.playlistAppearances,
        playlistCuratorMkt: data.curatorFilter,
        playlistTypes: data.playlistTypeFilter,
        playlistTerritory: data.territoryFilter,
        chart: data.chart,
        potGraphType: data.potGraphType,
        sosDetails: data.sosDetails,
    },
    selector: res => res?.createGlobalSoundRecordingLink,
    type: 'song',
});
