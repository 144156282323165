import { gql, useMutation } from '@apollo/client';
import {
    FollowGlobalParticipant,
    FollowGlobalParticipantVariables,
    FollowGlobalParticipant_followGlobalParticipant,
} from '../definitions/FollowGlobalParticipant';
import {
    UnfollowGlobalParticipant,
    UnfollowGlobalParticipantVariables,
    UnfollowGlobalParticipant_unfollowGlobalParticipant,
} from '../definitions/UnfollowGlobalParticipant';
import { flattenError } from '../utils';
import followGlobalParticipant from './followGlobalParticipant.gql';
import unfollowGlobalParticipant from './unfollowGlobalParticipant.gql';

type FollowGPVariables = FollowGlobalParticipantVariables &
    UnfollowGlobalParticipantVariables;

type FollowGPMutationResult = [
    (value: boolean) => Promise<unknown>,
    { loading: boolean; error: Error | undefined }
];

const UPDATE_FRAGMENT = gql`
    fragment UpdateGP on GlobalParticipant {
        id
        following
    }
`;

export const useFollowGlobalParticipantMutation = (
    variables: FollowGPVariables
): FollowGPMutationResult => {
    const { participantId } = variables;

    const [setFollowing, followingResult] = useMutation<
        FollowGlobalParticipant,
        FollowGlobalParticipantVariables
    >(followGlobalParticipant, {
        variables,
        update: (cache, { data }) => {
            cache.writeFragment({
                data: data?.followGlobalParticipant,
                id: participantId,
                fragment: UPDATE_FRAGMENT,
            });
        },
        optimisticResponse: {
            __typename: 'Mutation',
            followGlobalParticipant: {
                id: participantId,
                following: true,
                __typename: 'GlobalParticipant',
            } as FollowGlobalParticipant_followGlobalParticipant,
        } as FollowGlobalParticipant,
    });

    const [setUnfollowing, unfollowingResult] = useMutation<
        UnfollowGlobalParticipant,
        UnfollowGlobalParticipantVariables
    >(unfollowGlobalParticipant, {
        variables,
        update: (cache, { data }) => {
            cache.writeFragment({
                data: data?.unfollowGlobalParticipant,
                id: participantId,
                fragment: UPDATE_FRAGMENT,
            });
        },
        optimisticResponse: {
            __typename: 'Mutation',
            unfollowGlobalParticipant: {
                id: participantId,
                following: false,
                __typename: 'GlobalParticipant',
            } as UnfollowGlobalParticipant_unfollowGlobalParticipant,
        } as UnfollowGlobalParticipant,
    });

    const error = followingResult.error || unfollowingResult.error;
    const loading = followingResult.loading || unfollowingResult.loading;

    return [
        async (value: boolean) => {
            if (value) return await setFollowing({ variables });
            return await setUnfollowing({ variables });
        },
        {
            loading,
            error: error && flattenError(error),
        },
    ];
};
