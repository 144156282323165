import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatDate } from '@theorchard/suite-frontend';
import { get } from 'lodash';
import { EMPTY_CHAR, SONG_DATE_FORMAT } from 'src/constants';

GridTable.defineColumnTemplate('date', {
    align: 'right',
    defaultSortDirection: 'desc',
    Cell: ({ value, column: { definition } }) => {
        const useEmptyChar = get(definition, 'data-useEmptyChar', true);
        const emptyValue = useEmptyChar ? EMPTY_CHAR : null;

        return <>{value ? formatDate(value, SONG_DATE_FORMAT) : emptyValue}</>;
    },
});
