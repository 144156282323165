import React from 'react';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { Illustration } from '@theorchard/suite-icons';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

function ErrorIcon100() {
    const isColorSchemeV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);

    return isColorSchemeV2Enabled ? (
        <Illustration name={'dataError'} size={100} />
    ) : (
        <svg
            data-testid="ErrorIcon100"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1513_28064"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="10"
                y="10"
                width="80"
                height="80"
            >
                <circle cx="50" cy="50" r="40" fill="#F3F0F0" />
            </mask>
            <g mask="url(#mask0_1513_28064)">
                <circle cx="50" cy="50" r="40" fill="#F3F0F0" />
            </g>
            <path
                d="M76.5746 16.6887C76.77 16.3724 77.23 16.3724 77.4254 16.6887L79.6108 20.2266C79.6516 20.2927 79.7073 20.3484 79.7734 20.3892L83.3113 22.5746C83.6276 22.77 83.6276 23.23 83.3113 23.4254L79.7734 25.6108C79.7073 25.6516 79.6516 25.7073 79.6108 25.7734L77.4254 29.3113C77.23 29.6276 76.77 29.6276 76.5746 29.3113L74.3892 25.7734C74.3484 25.7073 74.2927 25.6516 74.2266 25.6108L70.6887 23.4254C70.3724 23.23 70.3724 22.77 70.6887 22.5746L74.2266 20.3892C74.2927 20.3484 74.3484 20.2927 74.3892 20.2266L76.5746 16.6887Z"
                fill="#80BFFF"
            />
            <path
                d="M21.5746 67.6887C21.77 67.3724 22.23 67.3724 22.4254 67.6887L25.3744 72.4629C25.4153 72.529 25.471 72.5847 25.5371 72.6256L30.3113 75.5746C30.6276 75.77 30.6276 76.23 30.3113 76.4254L25.5371 79.3744C25.471 79.4153 25.4153 79.471 25.3744 79.5371L22.4254 84.3113C22.23 84.6276 21.77 84.6276 21.5746 84.3113L18.6256 79.5371C18.5847 79.471 18.529 79.4153 18.4629 79.3744L13.6887 76.4254C13.3724 76.23 13.3724 75.77 13.6887 75.5746L18.4629 72.6256C18.529 72.5847 18.5847 72.529 18.6256 72.4629L21.5746 67.6887Z"
                fill="#80BFFF"
            />
            <g filter="url(#filter0_d_1513_28064)">
                <circle cx="50" cy="50" r="16" fill="white" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.8682 42.5195L57.145 53.5039C57.526 54.1706 57.0446 55.0001 56.2768 55.0001H43.7232C42.9553 55.0001 42.474 54.1706 42.8549 53.5039L49.1317 42.5195C49.5156 41.8477 50.4843 41.8477 50.8682 42.5195ZM50 45.5C50.5523 45.5 51 45.9477 51 46.5V49.5C51 50.0523 50.5523 50.5 50 50.5C49.4477 50.5 49 50.0523 49 49.5V46.5C49 45.9477 49.4477 45.5 50 45.5ZM50 53.5C50.5523 53.5 51 53.0523 51 52.5C51 51.9477 50.5523 51.5 50 51.5C49.4477 51.5 49 51.9477 49 52.5C49 53.0523 49.4477 53.5 50 53.5Z"
                fill="#847C7C"
            />
            <defs>
                <filter
                    id="filter0_d_1513_28064"
                    x="14"
                    y="19"
                    width="72"
                    height="72"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.698039 0 0 0 0 0.698039 0 0 0 0 0.698039 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1513_28064"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1513_28064"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default ErrorIcon100;
