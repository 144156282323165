import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { GlyphIcon } from '@theorchard/suite-icons';
import { get } from 'lodash';
import {
    getColorClassName,
    getSeriesIconColorClassName,
} from 'src/utils/colors';

GridTable.defineColumnTemplate('dot', {
    align: 'right',
    Cell: ({
        // `value` is custom color index
        value,
        column: { definition },
        row: { index },
    }) => {
        const className = value
            ? getColorClassName(value)
            : // `index` is the row index starting from 1
              getSeriesIconColorClassName(index - 1);
        const size = get(definition, 'data-size', 16);

        return <GlyphIcon name="dot" size={size} className={className} />;
    },
});
