import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import TruncatedText from 'src/components/truncatedText';

GridTable.defineColumnTemplate('territoryRights', {
    align: 'left',
    Cell: ({ data: { territoryRights } }) => (
        <TruncatedText
            value={formatMessage(`product.territoryRights.${territoryRights}`)}
        />
    ),
});
