import React, { FC, useRef, useState } from 'react';
import { BsOverlay } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { TrendScore } from 'src/apollo/selectors/catalog';
import { EMPTY_CHAR } from 'src/constants';
import { getLocalizedName } from 'src/utils/countries';
import TiktokTrendScoreValue from './tiktokTrendScoreValue';

export const CLASSNAME = 'TrendScoresPopup';
export const CLASSNAME_TOOLTIP = `${CLASSNAME}-tooltip`;
export const CLASSNAME_TOOLTIP_HEADER = `${CLASSNAME_TOOLTIP}-header`;
export const CLASSNAME_TOOLTIP_HEADER_NAME = `${CLASSNAME_TOOLTIP_HEADER}-name`;
export const CLASSNAME_TOOLTIP_HEADER_TITLE = `${CLASSNAME_TOOLTIP_HEADER}-title`;
export const CLASSNAME_TOOLTIP_HEADER_TITLE_SONG_NAME = `${CLASSNAME_TOOLTIP_HEADER_TITLE}-song-name`;
export const CLASSNAME_TOOLTIP_HEADER_SUBTITLE = `${CLASSNAME_TOOLTIP_HEADER}-subtitle`;
export const CLASSNAME_TOOLTIP_CONTENT = `${CLASSNAME_TOOLTIP}-content`;
export const CLASSNAME_TOOLTIP_ROW = `${CLASSNAME_TOOLTIP}-row`;
export const CLASSNAME_TOOLTIP_ROW_COUNTRY = `${CLASSNAME_TOOLTIP}-row-country`;
export const TEST_ID = CLASSNAME;

export interface Props {
    tikTokTrendScores: TrendScore[];
    songName: string;
    link?: string;
    participants: string;
}

const TrendScoresPopup: FC<Props> = ({
    tikTokTrendScores,
    songName,
    link,
    participants,
}) => {
    const [show, setShow] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const targetRef = useRef<HTMLDivElement>(null);
    const handleFocus = () => {
        setShow(true);
    };
    const handleBlur = () => {
        setShow(false);
    };
    const [topScore] = tikTokTrendScores;

    return (
        <div className={CLASSNAME} ref={containerRef} data-testid={TEST_ID}>
            <div
                ref={targetRef}
                onMouseOver={handleFocus}
                onFocus={handleFocus}
                onMouseOut={handleBlur}
                onBlur={handleBlur}
            >
                <TiktokTrendScoreValue
                    country={topScore?.country}
                    score={topScore?.score}
                    link={link}
                />
            </div>
            <BsOverlay
                show={show}
                target={targetRef.current}
                placement="bottom-start"
                flip
                container={containerRef.current || undefined}
            >
                {({ arrowProps, show: _, popper, ...props }) => (
                    <div className={CLASSNAME_TOOLTIP} {...props}>
                        <div
                            className={`${CLASSNAME_TOOLTIP_ROW} ${CLASSNAME_TOOLTIP_HEADER}`}
                        >
                            <div className={CLASSNAME_TOOLTIP_HEADER_NAME}>
                                {formatMessage('catalog.topTrending')}
                            </div>
                            <div className={CLASSNAME_TOOLTIP_HEADER_TITLE}>
                                <h5
                                    className={
                                        CLASSNAME_TOOLTIP_HEADER_TITLE_SONG_NAME
                                    }
                                >
                                    {songName}
                                </h5>
                                <div
                                    className={
                                        CLASSNAME_TOOLTIP_HEADER_SUBTITLE
                                    }
                                >
                                    {participants}
                                </div>
                            </div>
                        </div>
                        <div className={CLASSNAME_TOOLTIP_CONTENT}>
                            {tikTokTrendScores.map(({ country, score }) => (
                                <div
                                    key={country}
                                    className={CLASSNAME_TOOLTIP_ROW}
                                >
                                    <div
                                        className={
                                            CLASSNAME_TOOLTIP_ROW_COUNTRY
                                        }
                                    >
                                        {country
                                            ? getLocalizedName(country)
                                            : EMPTY_CHAR}
                                    </div>
                                    <TiktokTrendScoreValue
                                        country={country}
                                        score={score}
                                        reverse
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </BsOverlay>
        </div>
    );
};

export default TrendScoresPopup;
