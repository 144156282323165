import React, { FC, useEffect, useState } from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import {
    CloseGlyph,
    GlyphIcon,
    PauseGlyph,
    PlayGlyph,
} from '@theorchard/suite-icons';
import cx from 'classnames';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import CoverArt from '../coverArt';
import TruncatedText from '../truncatedText';
import SpotifyWebApiInstance from './spotifyWebApi';

export const CLASSNAME = 'SpotifyPlayer';
const CLASSNAME_BODY = `${CLASSNAME}-body`;
const CLASSNAME_TITLE = `${CLASSNAME}-title`;
const CLASSNAME_ARTISTS = `${CLASSNAME}-artists`;
const CLASSNAME_CONTROLS = `${CLASSNAME}-controls`;
const CLASSNAME_PLAY_BTN = `${CLASSNAME}-play-btn`;
const CLASSNAME_CLOSE_BTN = `${CLASSNAME}-close-btn`;

export const TEST_ID = CLASSNAME;
export const TERM_CLOSE = 'spotifyPlayer.close';

const SpotifyPlayer: FC = () => {
    const [currentTrack, setCurrentTrack] = useState<
        Spotify.PlayerTrack | undefined
    >();
    const [playing, setPlaying] = useState(false);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleStateChange = (event: Spotify.PlayerState | null) => {
            setCurrentTrack(event?.track_window.current_track);
            setPlaying(!!event && !event.paused);
        };

        SpotifyWebApiInstance.onReady(() => {
            const { player } = SpotifyWebApiInstance;
            player?.getCurrentState().then(handleStateChange);
            player?.addListener('player_state_changed', handleStateChange);
        });
    }, []);

    useEffect(() => {
        if (playing) setVisible(true);
    }, [playing]);

    const handleClick = () => {
        const { player } = SpotifyWebApiInstance;
        player?.togglePlay();
    };

    const handleClose = () => {
        const { player } = SpotifyWebApiInstance;
        if (playing) player?.togglePlay();
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div
            className={cx(CLASSNAME, { ready: !!currentTrack })}
            data-testid={TEST_ID}
        >
            <a
                target="_blank"
                rel="noreferrer"
                href={`https://open.spotify.com/track/${currentTrack?.id}`}
            >
                <CoverArt width="50" url={currentTrack?.album.images[0].url} />
            </a>
            <div className={CLASSNAME_BODY}>
                <div className={CLASSNAME_TITLE}>
                    <TruncatedText
                        disableTooltipPointerEvents
                        placement="top"
                        value={currentTrack?.name}
                    />
                </div>
                <div className={CLASSNAME_ARTISTS}>
                    <TruncatedText
                        disableTooltipPointerEvents
                        placement="top"
                        value={currentTrack?.artists
                            .map(artist => artist.name)
                            .join(', ')}
                    />
                </div>
            </div>
            <div className={CLASSNAME_CONTROLS}>
                <div
                    role="button"
                    tabIndex={0}
                    className={CLASSNAME_PLAY_BTN}
                    onClick={handleClick}
                    onKeyPress={e => e.key === 'Enter' && handleClick()}
                >
                    {playing && (
                        <FeatureFlagPanel
                            featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                            fallback={<PauseGlyph />}
                        >
                            <GlyphIcon name="pause" size={16} />
                        </FeatureFlagPanel>
                    )}
                    {!playing && (
                        <FeatureFlagPanel
                            featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                            fallback={<PlayGlyph />}
                        >
                            <GlyphIcon name="play" size={12} />
                        </FeatureFlagPanel>
                    )}
                </div>
                <HelpTooltip
                    id="spotifyPlayerClose"
                    message={formatMessage(TERM_CLOSE)}
                >
                    <div
                        role="button"
                        tabIndex={0}
                        className={CLASSNAME_CLOSE_BTN}
                        onClick={handleClose}
                        onKeyPress={e => e.key === 'Enter' && handleClose()}
                    >
                        <FeatureFlagPanel
                            featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                            fallback={<CloseGlyph />}
                        >
                            <GlyphIcon name="close" size={12} />
                        </FeatureFlagPanel>
                    </div>
                </HelpTooltip>
            </div>
        </div>
    );
};

export default SpotifyPlayer;
