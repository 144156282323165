import React from 'react';
import { GridTable, Tooltip } from '@theorchard/suite-components';
import { ExternalLinkGlyph, GlyphIcon } from '@theorchard/suite-icons';
import { uniqueId } from 'lodash';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import TruncatedText from 'src/components/truncatedText';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import { YoutubeTypeLinks, buildYoutubeLink } from 'src/utils/charts';

GridTable.defineColumnTemplate('songWithVideoLink', {
    align: 'left',
    sortable: true,
    Cell: ({ data }) => {
        const { videoId, name, songName } = data;
        const externalLink = buildYoutubeLink({
            videoId,
            type: YoutubeTypeLinks.video,
        });

        return (
            <div className="ExternalLinkCell d-flex align-items-center">
                <TruncatedText value={songName || name} />
                {videoId && (
                    <a
                        className="ExternalLinkCell-link d-flex d-print-none align-items-center"
                        href={externalLink}
                        onClick={event => event.stopPropagation()}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Tooltip
                            id={`externalLinkCell_${uniqueId()}`}
                            message={externalLink}
                        >
                            <FeatureFlagPanel
                                featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                                fallback={
                                    <ExternalLinkGlyph className="ml-2" />
                                }
                            >
                                <GlyphIcon
                                    name="externalLink"
                                    size={12}
                                    className="ml-2"
                                />
                            </FeatureFlagPanel>
                        </Tooltip>
                    </a>
                )}
            </div>
        );
    },
});
