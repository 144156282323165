import React, { ReactNode } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { AlgorithmicGlyph, PersonalizedGlyph } from '@theorchard/suite-icons';
import { PlaylistType } from 'src/apollo/definitions/globalTypes';

interface PlaylistTypeDefinition {
    icon: ReactNode;
    text: string;
}

export const getPlaylistTypeDefinition = (
    type?: string
): PlaylistTypeDefinition | null => {
    switch (type) {
        case PlaylistType.ALGORITHMIC:
            return {
                icon: <AlgorithmicGlyph />,
                text: formatMessage('playlists.info.algorithmic'),
            };
        case PlaylistType.PERSONALIZED:
            return {
                icon: <PersonalizedGlyph />,
                text: formatMessage('playlists.info.personalized'),
            };
        default:
            return null;
    }
};
