import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { SoundRecordingPerformanceByCountry } from 'src/apollo/definitions/SoundRecordingPerformanceByCountry';
import { selectPerformanceByCountry } from 'src/apollo/selectors';
import { SoundRecordingStreamsByProduct as SoundRecordingStreamsByProductQuery } from '../../definitions/SoundRecordingStreamsByProduct';
import {
    SoundRecordingStreamsBySOS as SoundRecordingStreamsBySOSQuery,
    SoundRecordingStreamsBySOSVariables as SecondaryDimensionVariables,
} from '../../definitions/SoundRecordingStreamsBySOS';
import { SoundRecordingStreamsByStore as SoundRecordingStreamsByStoreQuery } from '../../definitions/SoundRecordingStreamsByStore';
import { SoundRecordingStreamsTotal as SoundRecordingStreamsTotalQuery } from '../../definitions/SoundRecordingStreamsTotal';
import { useParallelQueries, usePrefetchQuery } from '../../utils';
import soundRecordingPerformanceByCountryQuery from './soundRecordingPerformanceByCountry.gql';
import soundRecordingStreamsByProductQuery from './soundRecordingStreamsByProduct.gql';
import soundRecordingStreamsBySOSQuery from './soundRecordingStreamsBySOS.gql';
import soundRecordingStreamsByStoreQuery from './soundRecordingStreamsByStore.gql';
import soundRecordingStreamsTotalQuery from './soundRecordingStreamsTotal.gql';

export const useSongStreamBySOSQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<
        SoundRecordingStreamsBySOSQuery,
        SecondaryDimensionVariables
    >(soundRecordingStreamsBySOSQuery, {
        variables,
        skip,
    });

    return {
        loading,
        data: useMemo(() => data, [data]),
        error,
    };
};

export const usePrefetchSongStreamBySOSQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean,
    delay?: number
) => {
    usePrefetchQuery<
        SoundRecordingStreamsBySOSQuery,
        SecondaryDimensionVariables
    >(soundRecordingStreamsBySOSQuery, { variables, delay, skip });
};

export const useSongStreamByStoreQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<
        SoundRecordingStreamsByStoreQuery,
        SecondaryDimensionVariables
    >(soundRecordingStreamsByStoreQuery, {
        variables,
        skip,
    });

    return {
        loading,
        data: useMemo(() => data, [data]),
        error,
    };
};

export const usePrefetchSongStreamByStoreQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean,
    delay?: number
) => {
    usePrefetchQuery<
        SoundRecordingStreamsByStoreQuery,
        SecondaryDimensionVariables
    >(soundRecordingStreamsByStoreQuery, { variables, delay, skip });
};

export const useSongPerformanceByCountryQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<
        SoundRecordingPerformanceByCountry,
        SecondaryDimensionVariables
    >(soundRecordingPerformanceByCountryQuery, {
        variables,
        skip,
    });
    const memoData = useMemo(
        () => data && selectPerformanceByCountry(data),
        [data]
    );
    return {
        loading,
        data: memoData,
        error,
    };
};

export const usePrefetchSongPerformanceByCountryQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean,
    delay?: number
) => {
    usePrefetchQuery<
        SoundRecordingPerformanceByCountry,
        SecondaryDimensionVariables
    >(soundRecordingPerformanceByCountryQuery, { variables, delay, skip });
};

export const useSongStreamsTotalQuery = (
    variables: SecondaryDimensionVariables[],
    skip?: boolean
) => {
    const client = useApolloClient();
    const { loading, data, error } = useParallelQueries<
        SoundRecordingStreamsTotalQuery,
        SecondaryDimensionVariables
    >(soundRecordingStreamsTotalQuery, { variables, client, skip });

    return {
        loading,
        data: useMemo(() => data, [data]),
        error,
    };
};

export const usePrefetchSongStreamsTotalQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean,
    delay?: number
) => {
    usePrefetchQuery<
        SoundRecordingStreamsTotalQuery,
        SecondaryDimensionVariables
    >(soundRecordingStreamsTotalQuery, { variables, delay, skip });
};

export const useSongStreamByProductQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean
) => {
    const { data, loading, error } = useQuery<
        SoundRecordingStreamsByProductQuery,
        SecondaryDimensionVariables
    >(soundRecordingStreamsByProductQuery, {
        variables,
        skip,
    });

    return {
        loading,
        data: useMemo(() => data, [data]),
        error,
    };
};

export const usePrefetchSongStreamByProductQuery = (
    variables: SecondaryDimensionVariables,
    skip?: boolean,
    delay?: number
) => {
    usePrefetchQuery<
        SoundRecordingStreamsByProductQuery,
        SecondaryDimensionVariables
    >(soundRecordingStreamsByProductQuery, { variables, delay, skip });
};
