import { gql, useMutation } from '@apollo/client';
import {
    FollowGlobalProduct,
    FollowGlobalProductVariables,
    FollowGlobalProduct_followGlobalProduct,
} from '../definitions/FollowGlobalProduct';
import {
    UnfollowGlobalProduct,
    UnfollowGlobalProductVariables,
    UnfollowGlobalProduct_unfollowGlobalProduct,
} from '../definitions/UnfollowGlobalProduct';
import { flattenError } from '../utils';
import followGlobalProduct from './followGlobalProduct.gql';
import unfollowGlobalProduct from './unfollowGlobalProduct.gql';

type FollowGPVariables = FollowGlobalProductVariables &
    UnfollowGlobalProductVariables;

type FollowGPMutationResult = [
    (value: boolean) => Promise<unknown>,
    { loading: boolean; error: Error | undefined }
];

const UPDATE_FRAGMENT = gql`
    fragment UpdateGlobalProduct on GlobalProduct {
        upc
        following
    }
`;

export const useFollowGlobalProductMutation = (
    variables: FollowGPVariables
): FollowGPMutationResult => {
    const { upc } = variables;

    const [setFollowing, followingResult] = useMutation<
        FollowGlobalProduct,
        FollowGlobalProductVariables
    >(followGlobalProduct, {
        variables,
        update: (cache, { data }) => {
            cache.writeFragment({
                data: data?.followGlobalProduct,
                id: upc,
                fragment: UPDATE_FRAGMENT,
            });
        },
        optimisticResponse: {
            __typename: 'Mutation',
            followGlobalProduct: {
                upc,
                following: true,
                __typename: 'GlobalProduct',
            } as FollowGlobalProduct_followGlobalProduct,
        } as FollowGlobalProduct,
    });

    const [setUnfollowing, unfollowingResult] = useMutation<
        UnfollowGlobalProduct,
        UnfollowGlobalProductVariables
    >(unfollowGlobalProduct, {
        variables,
        update: (cache, { data }) => {
            cache.writeFragment({
                data: data?.unfollowGlobalProduct,
                id: upc,
                fragment: UPDATE_FRAGMENT,
            });
        },
        optimisticResponse: {
            __typename: 'Mutation',
            unfollowGlobalProduct: {
                upc,
                following: false,
                __typename: 'GlobalProduct',
            } as UnfollowGlobalProduct_unfollowGlobalProduct,
        } as UnfollowGlobalProduct,
    });

    const error = followingResult.error || unfollowingResult.error;
    const loading = followingResult.loading || unfollowingResult.loading;

    return [
        async (value: boolean) => {
            if (value) return await setFollowing({ variables });
            return await setUnfollowing({ variables });
        },
        {
            loading,
            error: error && flattenError(error),
        },
    ];
};
