import React, { FC } from 'react';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import TruncatedText from 'src/components/truncatedText';

export interface Props {
    className?: string;
    hideExternalIcon?: boolean;
    playlistUrl?: string;
    title?: string;
}

export const CLASSNAME = 'PlaylistLink';
export const CLASSNAME_LINK = `${CLASSNAME}-link`;
export const TEST_ID = CLASSNAME;

const PlaylistLink: FC<Props> = ({
    className,
    playlistUrl,
    title,
    hideExternalIcon,
}) => {
    if (!playlistUrl) return <TruncatedText value={title} />;

    return (
        <div className={cx(CLASSNAME, className)} data-testid={TEST_ID}>
            <TruncatedText value={title} disableTooltipPointerEvents />
            {!hideExternalIcon && (
                <a
                    className={CLASSNAME_LINK}
                    href={playlistUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <GlyphIcon name="externalLink" size={12} />
                </a>
            )}
        </div>
    );
};

export default PlaylistLink;
