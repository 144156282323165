export type FinModeValue = 'on' | 'off';

export const SME_GERMANY_ID = 34563;

export const RCA_ID = 34536;

export const FIN_MODE_SUPPORTED_ACCOUNTS_ID = [SME_GERMANY_ID, RCA_ID];

export const PROFIT_CENTER_SUPPORTED_ACCOUNTS_ID = [SME_GERMANY_ID];

export const PROFIT_CENTER_OPTIONS = [
    { label: '1991 Artist & Label', value: '_1991_artist_label' },
    { label: 'Ariola/Hansa', value: 'ariola_hansa' },
    { label: 'Catalog', value: 'catalog' },
    { label: 'Century Media', value: 'century_media' },
    { label: 'Classic', value: 'classic' },
    { label: 'Columbia', value: 'columbia' },
    { label: 'Comedy', value: 'comedy' },
    { label: 'EPIC', value: 'epic' },
    { label: 'Family Entert', value: 'family_entert' },
    { label: 'Famouz', value: 'famouz' },
    { label: 'Four Music', value: 'four_music' },
    { label: 'GOLD LEAGUE', value: 'gold_league' },
    { label: 'Jive', value: 'jive' },
    { label: 'Media Compilations', value: 'media_compilations' },
    { label: 'NITRON', value: 'nitron' },
    { label: 'RCA', value: 'rca' },
];

export const RCA_FIN_LABEL_ID = 'E009';

export const TOTAL_COMPARE_IDS_COUNT = 5;
