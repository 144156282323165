import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import ProductLinkWithUpcCell from './productLinkWithUpcCell';

GridTable.defineColumnTemplate('thumbnailProductLinkWithUpc', {
    align: 'left',
    Cell: ({ data, ...rest }) => (
        <div
            className="ThumbnailProductLinkWithUpcCell CoverArtCell"
            data-testid="ThumbnailProductLinkWithUpcCell"
        >
            <CoverArt url={data.imageLocation} width="30" />
            <ProductLinkWithUpcCell data={data} {...rest} />
        </div>
    ),
});
