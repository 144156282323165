import React from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { EmptyStarGlyph, GlyphIcon, StarGlyph } from '@theorchard/suite-icons';
import cx from 'classnames';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import { FC } from 'src/utils/react';

export const CLASSNAME = 'StarredIcon';
export const TERM_UNFOLLOW_HINT = 'favorites.removeFromFavorites';
export const TERM_FOLLOW_HINT = 'favorites.addToFavorites';
const TOOLTIP_ID = `${CLASSNAME}-starred-tooltip`;

export interface Props {
    className?: string;
    filled?: boolean;
    onClick?: () => void;
}

const StarredIcon: FC<Props> = ({ className, filled, onClick }) => (
    <HelpTooltip
        id={TOOLTIP_ID}
        message={formatMessage(filled ? TERM_UNFOLLOW_HINT : TERM_FOLLOW_HINT)}
    >
        <div
            className={cx(CLASSNAME, className)}
            onClick={() => onClick?.()}
            onKeyPress={e => e.key === 'Enter' && onClick?.()}
            role="button"
            tabIndex={0}
        >
            <FeatureFlagPanel
                featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                fallback={filled ? <StarGlyph /> : <EmptyStarGlyph />}
            >
                <GlyphIcon name={filled ? 'starred' : 'notStarred'} size={24} />
            </FeatureFlagPanel>
        </div>
    </HelpTooltip>
);
StarredIcon.className = CLASSNAME;

export default StarredIcon;
