import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { SoundRecordingPerformanceByCountry } from 'src/apollo/definitions/SoundRecordingPerformanceByCountry';
import { selectTimeseries } from 'src/apollo/selectors/timeseries';
import { filterData } from 'src/apollo/utils';
import { DATE_FORMAT_LONG } from 'src/constants';
import { DOWNLOADS, SAVES, SKIPS } from 'src/constants/metrics';
import { isAbsolutePeriod } from 'src/utils/datePeriod';
import dateDiff from './dates';

interface ValueItem {
    value?: number | null;
    saves?: number | null;
    skipRate?: number | null;
}

export const getItemValue = (item: ValueItem, potMetric: string) => {
    if (potMetric === SAVES) return item.saves ? item.saves : 0;

    if (potMetric === SKIPS) return item.skipRate ? item.skipRate : 0;

    return item.value ? item.value : 0;
};

export interface AxisData {
    date: string | null;
    value: number | null;
    skipRate?: number | null;
    saves?: number | null;
}

export const getAxis = (
    data: AxisData[],
    metric: string,
    datePeriod: string,
    releaseDate?: string
) =>
    selectTimeseries(data).map((item, index) => ({
        x: isAbsolutePeriod(datePeriod)
            ? new Date(item.date).getTime()
            : index + 1,
        y: metric === DOWNLOADS ? item.value : getItemValue(item, metric),
        z: isAbsolutePeriod(datePeriod)
            ? dateDiff(item.date, releaseDate)
            : dayjs.utc(item.date).format(DATE_FORMAT_LONG),
    }));

interface StreamsTimeseries {
    items:
        | {
              date: string | null;
              value: number | null;
              skipRate: number | null;
              saves: number | null;
          }[]
        | null;
}

export const sortStreamSeries =
    (metric: string) => (a: StreamsTimeseries, b: StreamsTimeseries) => {
        if (metric === SKIPS) {
            const firstSkipsLength =
                filterData(a.items?.map(stream => stream.skipRate)).length || 1;
            const firstSum =
                sumBy(a.items, timeseries => timeseries.skipRate || 0) /
                firstSkipsLength;
            const secondSkipsLength =
                filterData(b.items?.map(stream => stream.skipRate)).length || 1;
            const secondSum =
                sumBy(b.items, timeseries => timeseries.skipRate || 0) /
                secondSkipsLength;

            return firstSum < secondSum ? 1 : -1;
        }

        const firstSum = sumBy(a.items, item => getItemValue(item, metric));
        const secondSum = sumBy(b.items, item => getItemValue(item, metric));
        return firstSum < secondSum ? 1 : -1;
    };

interface CountryStreamsPayloadItem {
    date: string | null;
    streams: number | null;
    skip_rate: number | null;
    saves: number | null;
}

interface CountryStreamsPayload {
    country_code: string;
    items: CountryStreamsPayloadItem[];
}

interface CountryStreamsItem {
    date: string | null;
    value: number | null;
    skipRate: number | null;
    saves: number | null;
}

export interface CountryStreams {
    countryCode: string;
    items: CountryStreamsItem[];
}

export const formatStreamByCountry = (
    countriesPayload?: string
): CountryStreams[] => {
    if (!countriesPayload) return [];
    const countries = JSON.parse(countriesPayload) as CountryStreamsPayload[];
    return countries.map(({ country_code, items }) => ({
        countryCode: country_code,
        items: items.map(({ date, streams, saves, skip_rate }) => ({
            date,
            value: streams || 0,
            saves: saves || 0,
            skipRate: skip_rate || 0,
        })),
    }));
};

export const selectPerformanceByCountry = (
    data: SoundRecordingPerformanceByCountry
) =>
    formatStreamByCountry(
        data?.globalSoundRecordingByIsrc?.analytics?.performanceByCountry
            ?.countries
    );
