import React from 'react';
import { GridTable, HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { get } from 'lodash';

interface OnlyClickHandler {
    (name: string): void;
}

export const CLASSNAME = 'SummaryTitleWithOnlyCell';

GridTable.defineColumnTemplate('summaryTitleWithOnly', {
    align: 'left',
    minWidth: 'min-content',
    maxWidth: 'max-content',
    Cell: ({ value, data: { id, info }, column: { definition } }) => {
        const isTotalRow = id === 'TOTAL_ROW';

        const handleOnlyClick = get(definition, 'data-onOnlyClick') as
            | OnlyClickHandler
            | undefined;

        return (
            <div className={CLASSNAME} data-testid={CLASSNAME}>
                <span>{value}</span>
                {info && (
                    <HelpTooltip
                        id="pot-title"
                        className="SummaryRowTitleCell-info"
                        message={info}
                    />
                )}
                {!isTotalRow && id && (
                    <span
                        className="only"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleOnlyClick?.(id)}
                        onKeyDown={() => handleOnlyClick?.(id)}
                    >
                        {formatMessage('chart.only')}
                    </span>
                )}
            </div>
        );
    },
});
