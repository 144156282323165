import { useQuery } from '@apollo/client';
import { ServiceTiers } from 'src/apollo/definitions/ServiceTiers';
import { flattenError, filterData } from '../../utils';
import serviceTiersQuery from './serviceTiers.gql';

export const useServiceTiersQuery = () => {
    const { data, loading, error, observable } = useQuery<ServiceTiers>(
        serviceTiersQuery,
        {
            fetchPolicy: 'cache-first',
        }
    );

    return {
        loading,
        data: data && filterData(data.serviceTiers),
        error: error && flattenError(error),
        fetchResult: async () => await observable.result(),
    };
};
