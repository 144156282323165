import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { toNumber } from 'lodash';
import { CHART_REGION } from 'src/components/chartHighlights/constants';
import TruncatedText from 'src/components/truncatedText';
import { EMPTY_CHAR } from 'src/constants';

GridTable.defineColumnTemplate('appearances', {
    align: 'left',
    Cell: ({ value }) => {
        if (!value) return <span>{EMPTY_CHAR}</span>;

        const regions = formatMessage(CHART_REGION, { count: toNumber(value) });
        return (
            <TruncatedText value={`${value} ${regions}`}>
                <span>
                    {value} <small>{regions}</small>
                </span>
            </TruncatedText>
        );
    },
});
