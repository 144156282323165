import dayjs from 'dayjs';
import { getPeriodName } from 'src/apollo/selectors/filters';
import { assertValue } from 'src/apollo/utils';
import {
    ABSOLUTE_PERIODS,
    ALL_TIME,
    ALL_TIME_DATE_PARAM,
    HIGH_WATERMARK,
    LARGE_PERIODS,
    PAST_28,
    PAST_365,
    PAST_3M,
    PAST_6M,
    PAST_7,
    RELATIVE_PERIODS,
    CUSTOM,
    getSelectedCustomPeriod,
} from 'src/constants/periods';
import { getRelativeDays } from './metrics';

interface FilterEntity {
    id: string;
    startDate?: string;
    days?: number;
    potMetric?: string;
}

interface DecodedDateFilter {
    id: string;
    startDate: string;
    days: number;
}

export const isRelativePeriod = (datePeriod: string) =>
    RELATIVE_PERIODS.includes(datePeriod);
export const isAbsolutePeriod = (datePeriod: string) =>
    ABSOLUTE_PERIODS.includes(datePeriod);
export const isLargePeriod = (datePeriod: string) =>
    LARGE_PERIODS.includes(datePeriod);

export const getDaysPast = (
    period?: string,
    fallbackPeriod = PAST_28
): number => {
    switch (period) {
        case PAST_7:
            return -7;
        case PAST_28:
            return -28;
        case PAST_3M:
            return -91;
        case PAST_6M:
            return -183;
        case PAST_365:
            return -365;
        default:
            return getDaysPast(fallbackPeriod);
    }
};

export const getAbsoluteDates = (
    period: string,
    entities: FilterEntity[]
): DecodedDateFilter[] => {
    const days = getDaysPast(period);
    return entities.map(entity => ({
        ...entity,
        startDate: HIGH_WATERMARK,
        days,
    }));
};

export const getAllTimeDates = (
    entities: FilterEntity[]
): DecodedDateFilter[] => {
    const [entity] = entities;
    if (entity.potMetric)
        return entities.map(date => ({
            ...date,
            startDate: ALL_TIME_DATE_PARAM,
            days: dayjs(date.startDate).diff(dayjs(), 'days'),
        }));
    return entities.map(date => ({
        ...date,
        startDate: HIGH_WATERMARK,
        days: dayjs(date.startDate).diff(dayjs(), 'days'),
    }));
};

const getCustomDates = (
    entities: FilterEntity[],
    startDate: string,
    endDate?: string
): DecodedDateFilter[] =>
    entities.map(date => ({
        ...date,
        startDate,
        days: dayjs(endDate || dayjs()).diff(startDate || dayjs(), 'days') + 1,
    }));

export const getRelativeDates = (
    period: string,
    entities: FilterEntity[]
): DecodedDateFilter[] => {
    const daysPast = getRelativeDays(period);

    return entities.map(date => ({
        ...date,
        startDate: assertValue(date, 'startDate'),
        days: daysPast,
    }));
};

export const decodeDateFilter = (
    entities: FilterEntity[],
    dateFilter?: string,
    startDate?: string,
    endDate?: string
) => {
    if (!dateFilter) return [];
    if (isRelativePeriod(dateFilter))
        return getRelativeDates(dateFilter, entities);
    if (dateFilter === ALL_TIME) return getAllTimeDates(entities);
    if (dateFilter === CUSTOM) {
        if (!startDate)
            throw new Error(
                'startDate is required when using "CUSTOM" dateFilter.'
            );
        return getCustomDates(entities, startDate, endDate);
    }
    if (isAbsolutePeriod(dateFilter))
        return getAbsoluteDates(dateFilter, entities);

    return [];
};

export interface PeriodSubHeaderProps {
    dateFilter: string;
    dateFilterStart?: string;
    dateFilterEnd?: string;
}

export const createPeriodSubHeader = ({
    dateFilter,
    dateFilterEnd,
    dateFilterStart,
}: PeriodSubHeaderProps) => {
    if (dateFilter === CUSTOM)
        return getSelectedCustomPeriod(dateFilterStart, dateFilterEnd);

    return getPeriodName(dateFilter);
};
