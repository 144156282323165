import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import NumberWithPercentageAndArrowCell from '../numberWithPercentageAndArrowCell';

GridTable.defineColumnTemplate('creations1DayChange', {
    align: 'right',
    Cell: props => (
        <NumberWithPercentageAndArrowCell
            {...props}
            percentageProperty="creationsGrowthPercentage1Day"
            currentValueProperty="creations1Day"
        />
    ),
});
