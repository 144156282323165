import { FieldPolicy } from '@apollo/client';
import {
    defaultStorage as storage,
    ExpirationRangeType,
} from 'src/utils/storage';

const withPolicyExpiration = <T>(
    { read, merge, ...rest }: FieldPolicy<T, T>,
    expiration: ExpirationRangeType,
    key?: string
): FieldPolicy<T, T> => ({
    ...rest,
    read: read
        ? (existing, options) => {
              const value = read(existing, options);
              const storedValue = storage.getItem<T>(storage.getKey(key));

              return !storedValue ? value : storedValue;
          }
        : undefined,
    merge:
        typeof merge === 'function'
            ? (existing, incoming, options) => {
                  const existingValue =
                      existing ||
                      storage.getItem(storage.getKey(key)) ||
                      undefined;
                  const value = merge(existingValue, incoming, options);
                  storage.setItem(storage.getKey(key), value, expiration);

                  return value;
              }
            : merge,
});

export default withPolicyExpiration;
