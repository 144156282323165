import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    TikTokGainersQuery as Query,
    TikTokGainersQueryVariables as Variables,
} from 'src/apollo/definitions/TikTokGainersQuery';
import { selectTiktokGainers } from 'src/apollo/selectors/song';
import query from './tiktokGainers.gql';

export const useTiktokGainers = (variables: Variables) => {
    const { data, loading, error } = useQuery<Query, Variables>(query, {
        variables,
    });
    const memoizedData = useMemo(
        () => data && selectTiktokGainers(data, variables.offset),
        [data]
    );

    return {
        loading,
        error,
        data: memoizedData,
    };
};
