import { useMemo } from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { selectProductTracks } from 'src/apollo/selectors/product';
import {
    GlobalProductTracksQuery as Query,
    GlobalProductTracksQueryVariables as QueryVariables,
} from '../../definitions/GlobalProductTracksQuery';
import globalProductTracksQuery from './globalProductTracks.gql';

export const useGlobalProductTracksQuery = (
    variables: QueryVariables,
    options?: QueryHookOptions<Query, QueryVariables>
) => {
    const { data, loading, error } = useQuery<Query, QueryVariables>(
        globalProductTracksQuery,
        { variables, ...options }
    );
    const memoizedData = useMemo(
        () => data && selectProductTracks(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};
