import { useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import {
    TopAccounts as Query,
    TopAccountsVariables as QueryVars,
} from 'src/apollo/definitions/TopAccounts';
import { selectCatalogAccounts } from 'src/apollo/selectors/accounts';
import { useEmployeeIdentity, usePrefetchQuery } from 'src/apollo/utils';
import {
    ACCOUNTS_PAGE_SIZE,
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_DIR,
} from 'src/pages/catalog/pages/accounts/constants';
import topAccountsQuery from './topAccounts.gql';

export interface TopAccountsQueryProps {
    variables: QueryVars;
    skip?: boolean;
}

export const useTopAccountsQuery = ({
    variables,
    skip,
}: TopAccountsQueryProps) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        topAccountsQuery,
        { variables, skip }
    );
    const memoizedData = useMemo(
        () => data && selectCatalogAccounts(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchTopAccountsQuery = () => {
    const { isEmployee } = useEmployeeIdentity();

    usePrefetchQuery(topAccountsQuery, {
        variables: {
            limit: ACCOUNTS_PAGE_SIZE,
            orderBy: DEFAULT_SORT_FIELD,
            orderDir: DEFAULT_SORT_DIR,
            countries: [],
            includeSubaccounts: true,
            isEmployee: Boolean(isEmployee),
            labelIds: [],
            storeIds: [],
            subaccountIds: [],
        },
    });
};

interface PrefetchQueryOptions {
    skip: boolean;
}

export const prefetchTopAccountsQuery = async (
    client: ApolloClient<object>,
    variables: Partial<QueryVars> = {},
    options: PrefetchQueryOptions = { skip: false }
) => {
    const { skip } = options;

    return (
        !skip &&
        (await client.query({
            query: topAccountsQuery,
            variables: {
                limit: ACCOUNTS_PAGE_SIZE,
                orderBy: DEFAULT_SORT_FIELD,
                orderDir: DEFAULT_SORT_DIR,
                countries: [],
                includeSubaccounts: true,
                isEmployee: false,
                labelIds: [],
                storeIds: [],
                subaccountIds: [],
                ...variables,
            },
        }))
    );
};
