import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { FREQUENCY_WEEKLY } from 'src/constants/charts';
import { formatTimeOnChart } from 'src/utils';

GridTable.defineColumnTemplate('daysOnChart', {
    align: 'left',
    Cell: ({ data: { chart, daysOnChart } }) => {
        const frequency = chart?.frequency;
        const timeOnChart =
            frequency === FREQUENCY_WEEKLY
                ? Math.floor(daysOnChart / 7)
                : daysOnChart;

        return <span>{formatTimeOnChart(frequency, timeOnChart)}</span>;
    },
});
