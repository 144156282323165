import { useEffect } from 'react';
import {
    DocumentNode,
    OperationVariables,
    useApolloClient,
} from '@apollo/client';
import { get } from 'lodash';
import { useDeepMemo } from './data';

export const getQueryName = (doc: DocumentNode): string | undefined =>
    get(
        doc.definitions.find(def => def.kind === 'OperationDefinition'),
        'name.value'
    );

export const usePrefetchQuery = <
    Q,
    V extends OperationVariables = OperationVariables
>(
    query: DocumentNode,
    {
        variables,
        delay = 0,
        skip = false,
    }: {
        variables: V;
        delay?: number;
        skip?: boolean;
    },
    logger: { log: (msg: string) => void } = console
) => {
    const client = useApolloClient();
    const vars = useDeepMemo(() => variables, [variables]);

    useEffect(() => {
        if (skip) return;

        setTimeout(() => {
            const cache = client.readQuery<Q, V>({
                query,
                variables: vars,
            });

            if (!cache) {
                logger.log(`Prefetching: "${getQueryName(query)}"`);
                client.query<Q, V>({
                    query,
                    variables: vars,
                });
            } else
                logger.log(`Prefetch already cached: "${getQueryName(query)}"`);
        }, delay);
    }, [client, delay, logger, query, skip, vars]);
};
