import React, { FC } from 'react';
import { GridTable } from '@theorchard/suite-components';
import {
    ArrowDownGlyph,
    ArrowUpGlyph,
    GlyphIcon,
} from '@theorchard/suite-icons';
import { get } from 'lodash';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import { EMPTY_CHAR } from 'src/constants';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';
import { getFormatter } from 'src/utils';

interface Props {
    value?: string | number | null;
    format?: string;
    positiveZero?: boolean;
    useEmptyChar?: boolean;
}

export const FormattedChangeCell: FC<Props> = ({
    value,
    format = 'default',
    positiveZero,
    useEmptyChar,
}) => {
    const render = () => {
        if (value === null || value === undefined || typeof value === 'string')
            return useEmptyChar ? EMPTY_CHAR : null;

        if (value === 0 && !positiveZero)
            return (
                <div className="noChange">
                    <div className="Glyph" /> 0
                </div>
            );

        const absoluteValue = Math.abs(value);
        const formattedValue = format
            ? getFormatter(format)(absoluteValue)
            : absoluteValue;

        return value >= 0 ? (
            <span className="moveUp">
                <FeatureFlagPanel
                    featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                    fallback={<ArrowUpGlyph />}
                >
                    <GlyphIcon name="arrowUp" size={12} />
                </FeatureFlagPanel>
                {formattedValue}
            </span>
        ) : (
            <span className="moveDown">
                <FeatureFlagPanel
                    featureFlag={INSIGHTS_COLOR_SCHEME_V2}
                    fallback={<ArrowDownGlyph />}
                >
                    <GlyphIcon name="arrowDown" size={12} />
                </FeatureFlagPanel>
                {formattedValue}
            </span>
        );
    };
    return <div className="ChangeCell">{render()}</div>;
};

GridTable.defineColumnTemplate('formattedChange', {
    align: 'right',
    defaultSortDirection: 'asc',
    Cell: ({ value, column: { definition } }) => {
        const format = get(definition, 'data-format', 'default') as string;
        const positiveZero = get(definition, 'data-positiveZero') as
            | boolean
            | undefined;
        const useEmptyChar = get(definition, 'data-useEmptyChar', true);

        return (
            <FormattedChangeCell
                value={value}
                format={format}
                positiveZero={positiveZero}
                useEmptyChar={useEmptyChar}
            />
        );
    },
});
