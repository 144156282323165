import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import {
    selectSoundRecordingPlacementBreakdown,
    selectSoundRecordingPlacementsBreakdown,
} from 'src/apollo/selectors';
import { getDistributorsArray } from 'src/utils/filters';
import {
    GlobalSoundRecordingPlacementsBreakdownQuery as GSRBreakdownQuery,
    GlobalSoundRecordingPlacementsBreakdownQueryVariables as GSRBreakdownQueryVariables,
} from '../../definitions/GlobalSoundRecordingPlacementsBreakdownQuery';
import { useParallelQueries } from '../../utils';
import GlobalSoundRecordingPlacementsBreakdownQuery from './globalSoundRecordingPlacementsBreakdown.gql';

interface PlacementBreakdownVariables {
    isrc: string;
    dateFilter: string;
    distributors: string;
}

export const useBatchSoundRecordingPlacementsBreakdownQuery = (
    variables: GSRBreakdownQueryVariables[],
    dateFilter: string
) => {
    const client = useApolloClient();

    const {
        loading,
        data: records = [],
        error,
    } = useParallelQueries<GSRBreakdownQuery, GSRBreakdownQueryVariables>(
        GlobalSoundRecordingPlacementsBreakdownQuery,
        { variables, client }
    );

    const data = useMemo(
        () =>
            records &&
            selectSoundRecordingPlacementsBreakdown(records, dateFilter),
        [records, dateFilter]
    );

    return { loading, data, error };
};

export const useSoundRecordingPlacementsBreakdownQuery = (
    variables: PlacementBreakdownVariables
) => {
    const { isrc, dateFilter, distributors } = variables;
    const { loading, data, error } = useQuery<
        GSRBreakdownQuery,
        GSRBreakdownQueryVariables
    >(GlobalSoundRecordingPlacementsBreakdownQuery, {
        variables: {
            isrc,
            distributors: getDistributorsArray(distributors),
        },
    });

    return {
        loading,
        data: useMemo(
            () =>
                data &&
                selectSoundRecordingPlacementBreakdown(data, dateFilter),
            [data, dateFilter]
        ),
        error,
    };
};
