import React from 'react';
import { Field, SuitePlugin } from '@theorchard/suite-frontend';
import { SpotifyWebApi, SpotifyPlayer } from 'src/components/spotifyPlayer';

const SpotifyPlayerPlugin: SuitePlugin = {
    onInit: ({ config }) => {
        const spotifyClientId = Field.optStr(config, 'spotifyClientId');
        if (spotifyClientId) SpotifyWebApi.init(spotifyClientId);
    },
    Wrapper: ({ children }) => (
        <>
            <SpotifyPlayer />
            {children}
        </>
    ),
};

export default SpotifyPlayerPlugin;
