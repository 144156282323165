import { get, uniqBy } from 'lodash';
import { TopGlobalParticipantsMetadataQuery as MetadataQuery } from 'src/apollo/definitions/TopGlobalParticipantsMetadataQuery';
import { TopGlobalParticipantsResultsQuery } from 'src/apollo/definitions/TopGlobalParticipantsResultsQuery';
import { selectAccounts } from 'src/apollo/selectors/utils';
import { filterData, nonNullable } from 'src/apollo/utils';
import { Account } from 'src/components/accountsDetailsPopup';
import { EMPTY_CHAR } from 'src/constants';
import { ChartDictionary } from 'src/constants/charts';
import { GlobalParticipantAggregatedRankingsV2Query_globalParticipantByGpId as GlobalParticipantByGpIdV2 } from '../definitions/GlobalParticipantAggregatedRankingsV2Query';
import { GlobalParticipantAnalyticsQuery } from '../definitions/GlobalParticipantAnalyticsQuery';
import { GlobalParticipantChartedSongsV2 } from '../definitions/GlobalParticipantChartedSongsV2';
import { GlobalParticipantQuery } from '../definitions/GlobalParticipantQuery';
import { GlobalParticipantResourceAccess } from '../definitions/GlobalParticipantResourceAccess';
import {
    GlobalParticipantStatsV2Query,
    GlobalParticipantStatsV2Query_globalParticipantByGpId_channels as ParticipantChannel,
    GlobalParticipantStatsV2Query_globalParticipantByGpId_publicParticipantChartmetric_accountStatsV2 as SocialAccount,
} from '../definitions/GlobalParticipantStatsV2Query';
import { StarredGlobalParticipantsQuery } from '../definitions/StarredGlobalParticipantsQuery';
import { StarredGlobalParticipantsStreamsQuery } from '../definitions/StarredGlobalParticipantsStreamsQuery';
import { SongAggregatedRanking } from './song';

export const POSITION_NEW = 1000000000;
export interface ParticipantAggregatedRanking extends SongAggregatedRanking {
    songId?: string;
    isrc?: string;
    songName?: string;
}

export interface Participant {
    id: string;
    name: string;
    isPartOfCatalog: boolean;
    imageUrl: string | null;
    following: boolean;
    country?: string;
    participatedAs?: string;
}

export type ParticipantMetadata = Pick<Participant, 'id' | 'name' | 'imageUrl'>;

export interface AggregatedParticipantQueryType {
    globalParticipantByGpId?: (GlobalParticipantByGpIdV2 | null)[];
}

export const selectParticipant = (
    data: GlobalParticipantQuery
): Participant | undefined => {
    const [first] = filterData(data.globalParticipantByGpId);
    return first
        ? {
              id: first.id,
              name: first.name || EMPTY_CHAR,
              imageUrl: first.imageUrl,
              isPartOfCatalog: first.isPartOfCatalog,
              following: first.following,
              country: nonNullable(first.countryCode),
          }
        : undefined;
};

export interface ParticipantStats {
    id: string;
    monthlyListeners: number | null;
    streamsAllTime: number | null;
    socialAccounts: SocialAccount[];
    channels?: ParticipantChannel[];
    accounts?: Account[];
}

export const selectParticipantStatsV2 = (
    data: GlobalParticipantStatsV2Query
): ParticipantStats | undefined => {
    const [first] = filterData(data.globalParticipantByGpId);
    return first
        ? {
              id: first.id,
              monthlyListeners:
                  first.publicParticipantChartmetric?.socialStatsV2
                      ?.monthlyListeners ?? null,
              streamsAllTime: first.analytics?.streamsAllTime ?? null,
              socialAccounts: filterData(
                  first?.publicParticipantChartmetric?.accountStatsV2
              ),
              channels: first.channels ? first.channels : [],
              accounts: selectAccounts(first.catalogLabelsV2, null),
          }
        : undefined;
};

export interface ParticipantWithAnalytics {
    id: string;
    name: string;
    imageUrl: string | null;
    streamsAllTime: number | null;
    streams1Day?: number;
    streams7Days?: number;
    streams28Days?: number;
    streams183Days?: number;
    streams365Days?: number;
    isPartOfCatalog: boolean;
}

export const selectParticipantAnalytics = (
    data: GlobalParticipantAnalyticsQuery
): ParticipantWithAnalytics | undefined => {
    const [first] = filterData(data.globalParticipantByGpId);
    return first
        ? {
              id: first.id,
              name: first.name || EMPTY_CHAR,
              imageUrl: first.imageUrl,
              streams1Day:
                  first.analytics?.growthPeriods?.[0]?.value ?? undefined,
              streams7Days:
                  first.analytics?.growthPeriods?.[1]?.value ?? undefined,
              streams28Days:
                  first.analytics?.growthPeriods?.[2]?.value ?? undefined,
              streams183Days:
                  first.analytics?.growthPeriods?.[3]?.value ?? undefined,
              streams365Days:
                  first.analytics?.growthPeriods?.[4]?.value ?? undefined,
              streamsAllTime: first.analytics?.streamsAllTime ?? null,
              isPartOfCatalog: first.isPartOfCatalog,
          }
        : undefined;
};

export const selectGlobalParticipantData = <T, U extends object>(data: T) => {
    const participants = get(data, 'globalParticipantByGpId') as unknown as U[];
    const [first] = filterData(participants);

    return first;
};

export const selectParticipantAggregatedRankingsV2 = (
    chartsById: ChartDictionary,
    data?: AggregatedParticipantQueryType
): ParticipantAggregatedRanking[] =>
    filterData(
        data?.globalParticipantByGpId?.[0]?.aggregatedChartRankingsV2?.map(
            row => {
                const chart = chartsById[row.chartId];
                if (!chart) return null;

                return {
                    key: `${row.chartId}-${row.publicSoundRecordingId}-${row.trackId}-${row.isrc}-${row.mostRecentPosition}`,
                    chart,
                    songId: row.publicSoundRecordingId ?? undefined,
                    isrc: row.isrc ?? undefined,
                    songName: row.soundRecordingName ?? undefined,

                    country: chart.country ?? '',

                    position: row.position ?? undefined,
                    firstPosition: row.firstPosition,
                    firstTimestamp: row.firstTimestamp,
                    peakPosition: row.peakPosition,
                    peakTimestamp: row.peakTimestamp ?? '',
                    daysOnChart: row.daysOnChart ?? 0,
                    change:
                        row.change === POSITION_NEW
                            ? null
                            : row.change ?? undefined,
                    mostRecentTimestamp: row.mostRecentTimestamp ?? undefined,
                    mostRecentPosition: row.mostRecentPosition ?? undefined,
                    previousTimestamp: row.previousTimestamp ?? undefined,
                    previousPosition: row.previousPosition ?? undefined,
                };
            }
        )
    );

export interface ParticipantOmitFollowing
    extends Omit<Participant, 'following'> {
    streamsAllTime?: number | null;
    streams1Day?: number;
    streams7Days?: number;
    streams28Days?: number;
    growthPercentage1Day?: number;
    growthPercentage7Days?: number;
    growthPercentage28Days?: number;
    accounts?: Account[];
}

export interface ParticipantsResults {
    participants: ParticipantOmitFollowing[];
    totalCount: number;
}

export const selectTopGlobalParticipantsMetadata = (
    data: MetadataQuery
): ParticipantMetadata[] =>
    data.topParticipantsMetadata.participants.map(({ id, name, imageUrl }) => ({
        id,
        name: name || '',
        imageUrl,
    }));

export const selectTopGlobalParticipantsResults = (
    data: TopGlobalParticipantsResultsQuery
): ParticipantsResults => {
    const totalCount = data.topGlobalParticipantsResults.totalParticipants;

    return {
        totalCount,
        participants: data.topGlobalParticipantsResults.participants.map(
            ({
                id,
                name,
                imageUrl,
                countryCode,
                analytics,
                catalogLabelsV2,
            }) => ({
                id,
                imageUrl,
                country: countryCode || '',
                name: name || '',
                isPartOfCatalog: true,
                monthlyListeners: null,
                socialAccounts: [],
                streams1Day: analytics?.growthPeriods?.[0]?.value ?? undefined,
                streams7Days: analytics?.growthPeriods?.[1]?.value ?? undefined,
                streams28Days:
                    analytics?.growthPeriods?.[2]?.value ?? undefined,
                streamsAllTime: analytics?.streamsAllTime ?? undefined,
                growthPercentage1Day:
                    analytics?.growthPeriods?.[0]?.growthPercentage ??
                    undefined,
                growthPercentage7Days:
                    analytics?.growthPeriods?.[1]?.growthPercentage ??
                    undefined,
                growthPercentage28Days:
                    analytics?.growthPeriods?.[2]?.growthPercentage ??
                    undefined,
                accounts: selectAccounts(catalogLabelsV2, null),
            })
        ),
    };
};

export interface ParticipantStreams {
    streams1Day?: number;
    streams7Days?: number;
    streams28Days?: number;
    streams183Days?: number;
    streams365Days?: number;
    streamsAllTime: number | null;
}

export interface StarredParticipant {
    id: string;
    name: string | null;
    imageUrl: string | null;
    isPartOfCatalog: boolean;
    monthlyListeners: number | null;
    following: boolean;
    dateFollowed: string | null;
}

export interface StarredParticipantStreams {
    id: string;
    streamsAllTime: number | null;
}

export const selectStarredParticipants = (
    data: StarredGlobalParticipantsQuery
): StarredParticipant[] =>
    data.starredGlobalParticipants.map(
        ({
            id,
            name,
            imageUrl,
            isPartOfCatalog,
            publicParticipantChartmetric,
            following,
            dateFollowed,
        }) => ({
            id,
            name: name || '',
            imageUrl,
            isPartOfCatalog,
            monthlyListeners:
                publicParticipantChartmetric?.socialStatsV2?.monthlyListeners ??
                null,
            following,
            dateFollowed,
        })
    );

export const selectStarredParticipantsStreams = (
    data: StarredGlobalParticipantsStreamsQuery
): StarredParticipantStreams[] =>
    data.starredGlobalParticipants.map(({ id, analytics }) => ({
        id,
        streamsAllTime: analytics?.streamsAllTime ?? null,
    }));

export const selectGlobalParticipantChartedSongs = (
    data: GlobalParticipantChartedSongsV2 | undefined
) =>
    data?.globalParticipantByGpId?.[0]?.aggregatedRankingFacetsV2?.songs?.map(
        song => ({
            isrc: song.isrc,
            soundRecordingName: song.songName ?? '',
            placementCount: song.count,
        })
    );

export const selectParticipantResourceAccess = (
    data?: GlobalParticipantResourceAccess[]
) => {
    if (!data) return [];

    const labelParticipants = data.map(
        paricipant =>
            paricipant.globalParticipantByGpId?.[0]?.labelParticipants
                .labelParticipants
    );
    return uniqBy(filterData(labelParticipants).flat(), 'id');
};
