import React from 'react';

export const CLASSNAME = 'DownloadsInactiveIcon';

const DownloadsInactiveIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className={CLASSNAME}
    >
        {/* eslint-disable max-len */}
        <path fill="#fff" d="M0 0H24V24H0z" />
        <circle cx="12" cy="12" r="11" stroke="#A8A0A0" strokeWidth="2" />
        <path
            fill="#A8A0A0"
            d="M7.215 16a.278.278 0 01-.204-.084.278.278 0 01-.084-.204V9.1h-2.22a.278.278 0 01-.204-.084.292.292 0 01-.084-.216v-.9a.32.32 0 01.072-.216.292.292 0 01.216-.084h6.132c.088 0 .16.028.216.084a.292.292 0 01.084.216v.9c0 .088-.028.16-.084.216a.292.292 0 01-.216.084H8.63v6.612c0 .08-.028.148-.084.204a.254.254 0 01-.204.084H7.215zm5.429 0a.278.278 0 01-.204-.084.278.278 0 01-.084-.204V7.9a.32.32 0 01.072-.216.292.292 0 01.216-.084h2.928c2.36 0 3.564 1.088 3.612 3.264.016.464.024.776.024.936 0 .152-.008.46-.024.924-.032 1.12-.336 1.948-.912 2.484-.568.528-1.448.792-2.64.792h-2.988zm2.928-1.392c.664 0 1.144-.148 1.44-.444.296-.296.452-.788.468-1.476.016-.48.024-.78.024-.9 0-.128-.008-.424-.024-.888-.016-.656-.18-1.136-.492-1.44-.312-.312-.804-.468-1.476-.468h-1.464v5.616h1.524z"
        />
        <path stroke="#A8A0A0" strokeWidth="2" d="M20 20L4 4" />
        {/* eslint-enable max-len */}
    </svg>
);

export default DownloadsInactiveIcon;
