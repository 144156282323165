import { QueryHookOptions, useQuery } from '@apollo/client';
import {
    AggregateStreamsQuery,
    AggregateStreamsQueryVariables,
} from '../../definitions/AggregateStreamsQuery';
import { selectSongAggregateStreams } from '../../selectors';
import { flattenError } from '../../utils';
import aggregateStreamsQuery from './aggregateStreams.gql';

export const useAggregateStreamsQuery = (
    variables: AggregateStreamsQueryVariables,
    options: QueryHookOptions<
        AggregateStreamsQuery,
        AggregateStreamsQueryVariables
    > = {}
) => {
    const { data, loading, error } = useQuery<
        AggregateStreamsQuery,
        AggregateStreamsQueryVariables
    >(aggregateStreamsQuery, { ...options, variables });

    return {
        loading,
        data: data && selectSongAggregateStreams(data),
        error: error && flattenError(error),
    };
};
