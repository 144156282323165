import { selectSources } from 'src/utils/sources';
import { SoundRecordingAppleDemographics_globalSoundRecordingByIsrc_analytics as Analytics } from '../definitions/SoundRecordingAppleDemographics';
import { DemographicsBreakdownData } from './soundRecordingDemographicsBreakdown';

const EMPTY_DATA = {
    age: {
        _18: { value: 0, percentValue: 0 },
        _18_24: { value: 0, percentValue: 0 },
        _25_34: { value: 0, percentValue: 0 },
        _35_44: { value: 0, percentValue: 0 },
        _45_54: { value: 0, percentValue: 0 },
        _55_64: { value: 0, percentValue: 0 },
        _65_: { value: 0, percentValue: 0 },
        UNKNOWN: { value: 0, percentValue: 0 },
        total: 0,
    },
    gender: {
        male: { value: 0, percentValue: 0 },
        female: { value: 0, percentValue: 0 },
        unknown: { value: 0, percentValue: 0 },
        total: 0,
    },
};

export interface AppleDemographicsBreakdownAge {
    _18: DemographicsBreakdownData;
    _18_24: DemographicsBreakdownData;
    _25_34: DemographicsBreakdownData;
    _35_44: DemographicsBreakdownData;
    _45_54: DemographicsBreakdownData;
    _55_64: DemographicsBreakdownData;
    _65_: DemographicsBreakdownData;
    UNKNOWN: DemographicsBreakdownData;
    total: number;
    isrc?: string;
}

interface AnalyticsWithIsrc {
    analytics?: Analytics | null;
    isrc?: string;
}

export const selectAppleDemographicsBreakdownAge = (
    analyticsWithIsrc: AnalyticsWithIsrc[]
) =>
    analyticsWithIsrc.map(({ analytics, isrc }) => {
        const { demographicBreakdownApple } = analytics || {};
        const emptyData = { ...EMPTY_DATA.age, isrc };
        if (!demographicBreakdownApple) return emptyData;

        const data = demographicBreakdownApple.demographics.age;
        const age = {
            _18: data?._18 ?? 0,
            _18_24: data?._18_24 ?? 0,
            _25_34: data?._25_34 ?? 0,
            _35_44: data?._35_44 ?? 0,
            _45_54: data?._45_54 ?? 0,
            _55_64: data?._55_64 ?? 0,
            _65_: data?._65_ ?? 0,
            UNKNOWN: data?.UNKNOWN ?? 0,
            isrc,
        };

        const total =
            age._18 +
            age._18_24 +
            age._25_34 +
            age._35_44 +
            age._45_54 +
            age._55_64 +
            age._65_ +
            age.UNKNOWN;

        return age
            ? {
                  _18: {
                      value: age._18,
                      percentValue: total ? age._18 / total : 0,
                  },
                  _18_24: {
                      value: age._18_24,
                      percentValue: total ? age._18_24 / total : 0,
                  },
                  _25_34: {
                      value: age._25_34,
                      percentValue: total ? age._25_34 / total : 0,
                  },
                  _35_44: {
                      value: age._35_44,
                      percentValue: total ? age._35_44 / total : 0,
                  },
                  _45_54: {
                      value: age._45_54,
                      percentValue: total ? age._45_54 / total : 0,
                  },
                  _55_64: {
                      value: age._55_64,
                      percentValue: total ? age._55_64 / total : 0,
                  },
                  _65_: {
                      value: age._65_,
                      percentValue: total ? age._65_ / total : 0,
                  },
                  UNKNOWN: {
                      value: age.UNKNOWN,
                      percentValue: total ? age.UNKNOWN / total : 0,
                  },
                  isrc,
                  total,
              }
            : emptyData;
    });

export const selectAppleDemographicsBreakdownGender = (
    analyticsWithIsrc: AnalyticsWithIsrc[]
) =>
    analyticsWithIsrc.map(({ analytics, isrc }) => {
        const { demographicBreakdownApple } = analytics || {};
        const emptyData = { ...EMPTY_DATA.gender, isrc };
        if (!demographicBreakdownApple) return emptyData;

        const data = demographicBreakdownApple.demographics.gender;
        const gender = {
            male: data?.male ?? 0,
            female: data?.female ?? 0,
            unknown: data?.unknown ?? 0,
            isrc,
        };

        const total = gender.male + gender.female + gender.unknown;

        return {
            male: {
                value: gender.male,
                percentValue: total ? gender.male / total : 0,
            },
            female: {
                value: gender.female,
                percentValue: total ? gender.female / total : 0,
            },
            unknown: {
                value: gender.unknown,
                percentValue: total ? gender.unknown / total : 0,
            },
            isrc,
            total,
        };
    });

export const selectAppleDemographicSources = (
    analyticsWithIsrc: AnalyticsWithIsrc[]
) => {
    const [first] = analyticsWithIsrc;

    return selectSources(first?.analytics?.demographicBreakdownApple?.sources);
};
