import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    ChannelNameQuery,
    ChannelNameQueryVariables,
} from '../../definitions/ChannelNameQuery';
import { selectChannelName } from '../../selectors';
import { flattenError } from '../../utils';
import channelNameQuery from './channelName.gql';

export const useChannelNameQuery = (channelId?: string) => {
    const variables = {
        channelId: channelId ?? '',
    };
    const { data, loading, error } = useQuery<
        ChannelNameQuery,
        ChannelNameQueryVariables
    >(channelNameQuery, { variables, skip: !channelId });

    return {
        loading,
        data: useMemo(() => selectChannelName(data), [data]),
        error: error && flattenError(error),
    };
};
