import { DocumentNode } from '@apollo/client';
import { Identity } from '@theorchard/suite-frontend';
import soundRecordingDownloadsByCountryQuery from '../soundRecordingDownloadsByCountry.gql';
import soundRecordingDownloadsByProductQuery from '../soundRecordingDownloadsByProduct.gql';
import soundRecordingDownloadsByStoreQuery from '../soundRecordingDownloadsByStore.gql';
import soundRecordingDownloadsTotalQuery from '../soundRecordingDownloadsTotal.gql';
import soundRecordingPerformanceByCountry from '../soundRecordingPerformanceByCountry.gql';
import soundRecordingStreamsByProductQuery from '../soundRecordingStreamsByProduct.gql';
import soundRecordingStreamsBySOSQuery from '../soundRecordingStreamsBySOS.gql';
import soundRecordingStreamsByStoreQuery from '../soundRecordingStreamsByStore.gql';
import soundRecordingStreamsTotalQuery from '../soundRecordingStreamsTotal.gql';
import { SecondaryDimension } from './types';

export const QUERIES: Record<
    SecondaryDimension,
    (identity: Identity) => {
        streamsQuery?: DocumentNode;
        downloadsQuery?: DocumentNode;
    }
> = {
    [SecondaryDimension.SourceOfStreams]: () => ({
        streamsQuery: soundRecordingStreamsBySOSQuery,
    }),
    [SecondaryDimension.Stores]: () => ({
        streamsQuery: soundRecordingStreamsByStoreQuery,
        downloadsQuery: soundRecordingDownloadsByStoreQuery,
    }),
    [SecondaryDimension.Countries]: () => ({
        streamsQuery: soundRecordingPerformanceByCountry,
        downloadsQuery: soundRecordingDownloadsByCountryQuery,
    }),
    [SecondaryDimension.Total]: () => ({
        streamsQuery: soundRecordingStreamsTotalQuery,
        downloadsQuery: soundRecordingDownloadsTotalQuery,
    }),
    [SecondaryDimension.Products]: () => ({
        streamsQuery: soundRecordingStreamsByProductQuery,
        downloadsQuery: soundRecordingDownloadsByProductQuery,
    }),
};

export const getQuery = (secondaryDimension: SecondaryDimension) => {
    const query = QUERIES[secondaryDimension];

    if (!query)
        throw new Error(
            `Failed to load query for the dimension: "${secondaryDimension}"`
        );

    return query;
};
