import React from 'react';
import { Button } from '@theorchard/suite-components';
import { formatMessage, useFeatureFlag } from '@theorchard/suite-frontend';
import {
    CLASSNAME as SEARCH_ERROR_CLASSNAME,
    SharedErrorProps,
} from 'src/components/error/error';
import ErrorIcon100 from 'src/components/infoMessageV2/icons/100x100/errorIcon100';
import ErrorIcon200 from 'src/components/infoMessageV2/icons/200x200/errorIcon200';
import InfoMessage from 'src/components/infoMessageV2/infoMessageV2';
import { INSIGHTS_COLOR_SCHEME_V2 } from 'src/constants/featuresFlags';

const FailedToLoad: React.FC<SharedErrorProps> = ({ size = 100 }) => {
    const title = formatMessage('error.fatal.title');
    const message = formatMessage('error.dataFailed.message');
    const isColorSchemeV2Enabled = useFeatureFlag(INSIGHTS_COLOR_SCHEME_V2);

    const Footer = () => {
        return (
            <Button
                variant="secondary"
                onClick={() => window.location.reload()}
                className={`${SEARCH_ERROR_CLASSNAME}__button`}
                size={isColorSchemeV2Enabled ? 'lg' : undefined}
            >
                {formatMessage('error.errorPage.refresh')}
            </Button>
        );
    };
    let Icon;

    switch (size) {
        default:
        case 100:
            Icon = ErrorIcon100;
            break;
        case 200:
            Icon = ErrorIcon200;
            break;
    }

    return (
        <InfoMessage
            type="error"
            title={title}
            message={message}
            Icon={Icon}
            Footer={Footer}
        />
    );
};

export default FailedToLoad;
