import { useQuery } from '@apollo/client';
import {
    ChartDatesV2Query,
    ChartDatesV2QueryVariables,
} from '../../definitions/ChartDatesV2Query';
import { filterData, flattenError } from '../../utils';
import chartDatesV2Query from './chartDatesV2.gql';

export const useChartDatesQuery = (variables: ChartDatesV2QueryVariables) => {
    const { data, loading, error } = useQuery<ChartDatesV2Query>(
        chartDatesV2Query,
        { variables }
    );

    return {
        loading,
        data: data ? filterData(data.chartAvailableDatesV2) : [],
        error: error && flattenError(error),
    };
};
