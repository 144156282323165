import { asError } from '@theorchard/suite-frontend';
import dayjs from 'dayjs';
import { Logger } from 'src/utils/logger';

interface StoreItem<T> {
    payload?: T | null;
    expirationTimestamp?: number;
}

type ExpirationUnit = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';

export interface ExpirationRangeType {
    value: number;
    unit: ExpirationUnit;
}

export interface StorageWrapperOptions {
    storage?: Storage;
    logger?: Logger;
    keyPrefix?: string;
}

export default class StorageWrapper {
    #storage: Storage;
    #logger?: Logger;
    #keyPrefix = '';

    constructor(options: StorageWrapperOptions = {}) {
        const {
            storage = window.localStorage ?? window.sessionStorage,
            logger,
        } = options;

        this.#storage = storage;
        this.#logger = logger;
    }

    initKeyPrefix(prefix: string) {
        this.#keyPrefix = prefix;
    }

    getKey(key?: string) {
        if (!key) return this.#keyPrefix;

        return this.#keyPrefix ? `Insights:${this.#keyPrefix}:${key}` : key;
    }

    hasItem(key: string) {
        return Object.getOwnPropertyNames(this.#storage).includes(key);
    }

    getItem<T = unknown>(
        key: string,
        defaultValue?: T | null
    ): T | null | undefined {
        const existingValue = this.#storage.getItem(key);
        if (existingValue === null) return defaultValue;

        try {
            const data: StoreItem<T> = JSON.parse(existingValue);
            if (data && typeof data === 'object') {
                const { payload, expirationTimestamp } = data;
                const isExpired = expirationTimestamp
                    ? new Date() > new Date(expirationTimestamp)
                    : false;
                if (!isExpired) return payload;

                this.#storage.removeItem(key);
            }
        } catch (error) {
            this.#logger?.error(asError(error));
        }

        return defaultValue;
    }

    setItem<T = unknown>(
        key: string,
        newValue?: T | null,
        expiration?: ExpirationRangeType
    ) {
        const storeItem: StoreItem<T> = {
            payload: newValue,
            expirationTimestamp: expiration
                ? dayjs().add(expiration.value, expiration.unit).valueOf()
                : undefined,
        };
        const stringValue = JSON.stringify(storeItem);

        try {
            this.#storage.setItem(key, stringValue);
            return stringValue;
        } catch (error) {
            this.#logger?.error(asError(error));
        }
    }

    removeItem(key: string) {
        try {
            this.#storage.removeItem(key);
        } catch (error) {
            this.#logger?.error(asError(error));
        }
    }

    clear() {
        try {
            this.#storage.clear();
        } catch (error) {
            this.#logger?.error(asError(error));
        }
    }
}

const defaultStorage = new StorageWrapper();

export { defaultStorage };
