import { useQuery } from '@apollo/client';
import { ChartDefinition } from 'src/constants/charts';
import {
    SongRankingsV2Query,
    SongRankingsV2QueryVariables,
    SongRankingsV2Query_publicSoundRecording_chartRankingsHistory,
} from '../../definitions/SongRankingsV2Query';
import { flattenError } from '../../utils';
import rankingsV2Query from './songRankingsV2.gql';

interface Variables extends SongRankingsV2QueryVariables {
    chart?: ChartDefinition;
    trackId?: string;
    country?: string;
}

export interface PublicSoundRecordingChartRankings {
    chartId: string;
    timestamp: string | null;
    position: number;
}

export const selectSongRankingsV2 = (
    chartId: string,
    rankings: SongRankingsV2Query_publicSoundRecording_chartRankingsHistory[]
) =>
    rankings.map<PublicSoundRecordingChartRankings>(
        ({ chartDate, position }) => ({
            chartId,
            timestamp: chartDate,
            position,
        })
    );

export const useSongRankingsQuery = (variables: Variables) => {
    const { id, chartId } = variables;
    const { data, loading, error } = useQuery<SongRankingsV2Query>(
        rankingsV2Query,
        { variables: { id, chartId } }
    );

    const song = data?.publicSoundRecording;

    return {
        loading,
        data: song
            ? selectSongRankingsV2(chartId || '', song.chartRankingsHistory)
            : undefined,
        error: error && flattenError(error),
    };
};
