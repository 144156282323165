import React, { FC } from 'react';
import { GridTableCellProps } from '@theorchard/suite-components';
import { formatNumber } from '@theorchard/suite-frontend';
import { get } from 'lodash';
import { formatPercentage, normalizeNumber } from 'src/utils';

interface CellProps extends GridTableCellProps<object> {
    percentageProperty: string;
}

const NumberWithPercentageCell: FC<CellProps> = ({
    data,
    value,
    percentageProperty,
}) => {
    const percentage: number | string | null | undefined = get(
        data,
        percentageProperty
    );
    const percentageValue = normalizeNumber(percentage);
    const dataValue = formatNumber(value ?? null);
    if (percentageValue === null) return <>{dataValue}</>;
    return (
        <div className="NumberWithPercentageCell">
            <div
                className="NumberWithPercentageCell-percentage"
                data-testid="NumberWithPercentageCell-percentage"
            >
                {formatPercentage(percentageValue)}
            </div>
            <span>{dataValue}</span>
        </div>
    );
};

export default NumberWithPercentageCell;
