import { useQuery } from '@apollo/client';
import { LABELS_LIMIT } from 'src/constants/catalog';
import {
    OrchardLabels,
    OrchardLabelsVariables,
} from '../../definitions/OrchardLabels';
import { selectOrchardLabels } from '../../selectors/labels';
import { flattenError } from '../../utils';
import { useAppRecentsQuery } from '../appRecents';
import orchardLabelsQuery from './orchardLabels.gql';

export const useOrchardLabelsQuery = () => {
    const { data, loading, error } = useQuery<
        OrchardLabels,
        OrchardLabelsVariables
    >(orchardLabelsQuery, {
        variables: { first: LABELS_LIMIT },
        fetchPolicy: 'cache-first',
    });

    return {
        loading,
        data: data && selectOrchardLabels(data.orchardLabels),
        error: error && flattenError(error),
    };
};

export const useRecentOrchardLabelsQuery = () => {
    const { data, loading, error } = useAppRecentsQuery();

    return {
        loading,
        data: data.recentLabels,
        error,
    };
};
