import { useApolloClient } from '@apollo/client';
import {
    GlobalParticipantDemographics,
    GlobalParticipantDemographicsVariables,
} from '../../definitions/GlobalParticipantDemographics';
import { useParallelQueries } from '../../utils';
import GlobalParticipantDemographicsBreakdownQuery from './globalParticipantDemographics.gql';

export const useParticipantBatchDemographicsBreakdownQuery = (
    variables: GlobalParticipantDemographicsVariables[]
) => {
    const client = useApolloClient();
    const { loading, data, error } = useParallelQueries<
        GlobalParticipantDemographics,
        GlobalParticipantDemographicsVariables
    >(GlobalParticipantDemographicsBreakdownQuery, { variables, client });

    return { loading, data, error };
};
