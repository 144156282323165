import { formatMessage } from '@theorchard/suite-frontend';
import { camelCase } from 'lodash';
import { PlaylistType as GlobalPlaylistType } from 'src/apollo/definitions/globalTypes';
import {
    PlaylistStoreBreakdown,
    PlaylistType,
} from 'src/apollo/selectors/playlist';
import {
    getDatePeriodName,
    PAST_28,
    PAST_365,
    PAST_6M,
    PAST_7,
    ALL_TIME,
} from 'src/constants/periods';
import {
    COMPLETIONS_THIS_PERIOD_FIELD,
    PLAYLIST_SORTING_FIELDS,
    PLAYLIST_STOREFRONT_SORTING_FIELDS,
    STREAMS_THIS_PERIOD_FIELD,
    RADIO_STATIONS_TYPE,
} from 'src/constants/playlists';

export const getLocalizedPlaylistType = (type: string): string => {
    switch (type) {
        case GlobalPlaylistType.ALGORITHMIC:
        case GlobalPlaylistType.CHART:
        case GlobalPlaylistType.CURATED:
        case GlobalPlaylistType.EDITORIAL:
        case GlobalPlaylistType.PERSONALIZED:
        case GlobalPlaylistType.RADIO:
        case GlobalPlaylistType.STATION:
        case GlobalPlaylistType.USER_GENERATED:
        case GlobalPlaylistType.NEW_MUSIC_FRIDAY:
            return formatMessage(`playlist.types.${camelCase(type)}`);
        case RADIO_STATIONS_TYPE:
            return formatMessage('playlist.types.radio_stations');
        default:
            return type;
    }
};

export const getStreamsSortingField = (dateFilter?: string) => {
    switch (dateFilter) {
        case PAST_7:
            return PLAYLIST_SORTING_FIELDS.STREAMS_LAST_7_DAYS;
        case PAST_28:
            return PLAYLIST_SORTING_FIELDS.STREAMS_LAST_28_DAYS;
        case PAST_6M:
            return PLAYLIST_SORTING_FIELDS.STREAMS_LAST_183_DAYS;
        case PAST_365:
            return PLAYLIST_SORTING_FIELDS.STREAMS_LAST_365_DAYS;
        default:
            return PLAYLIST_SORTING_FIELDS.STREAMS_ALL_TIME;
    }
};

export const getCompletionsSortingField = (dateFilter?: string) => {
    switch (dateFilter) {
        case PAST_7:
            return PLAYLIST_SORTING_FIELDS.COMPLETIONS_LAST_7_DAYS;
        case PAST_28:
            return PLAYLIST_SORTING_FIELDS.COMPLETIONS_LAST_28_DAYS;
        case PAST_6M:
            return PLAYLIST_SORTING_FIELDS.COMPLETIONS_LAST_183_DAYS;
        case PAST_365:
            return PLAYLIST_SORTING_FIELDS.COMPLETIONS_LAST_365_DAYS;
        default:
            return PLAYLIST_SORTING_FIELDS.COMPLETIONS_ALL_TIME;
    }
};

export const getSortingField = (
    orderBy?: string | null,
    dateFilter?: string
) => {
    switch (orderBy) {
        case STREAMS_THIS_PERIOD_FIELD:
            return getStreamsSortingField(dateFilter);
        case COMPLETIONS_THIS_PERIOD_FIELD:
            return getCompletionsSortingField(dateFilter);
        default:
            return orderBy;
    }
};

export const getStorefrontsSortingField = (
    orderBy?: string | null,
    dateFilter?: string
) => {
    if (orderBy) {
        const sortingField = getSortingField(orderBy, dateFilter);

        if (
            sortingField &&
            Object.values(PLAYLIST_STOREFRONT_SORTING_FIELDS).includes(
                sortingField
            )
        )
            return sortingField;
    }
    return undefined;
};

export const getStreamsTitleByDatePeriod = (dateFilter?: string) => {
    switch (dateFilter) {
        case PAST_7:
            return formatMessage('catalog.streams7');
        case PAST_28:
            return formatMessage('catalog.streams28');
        case PAST_6M:
            return formatMessage('catalog.streams180');
        case PAST_365:
            return formatMessage('catalog.streams365');
        default:
            return formatMessage('catalog.streamsAll');
    }
};

export const getShortStreamsTitleByDatePeriod = (dateFilter?: string) => {
    switch (dateFilter) {
        case PAST_7:
            return formatMessage('catalog.streamsDaysShort', { days: 7 });
        case PAST_28:
            return formatMessage('catalog.streamsDaysShort', { days: 28 });
        case PAST_6M:
            return formatMessage('catalog.streams180Short');
        case PAST_365:
            return formatMessage('catalog.streams365Short');
        default:
            return formatMessage('allTimeStreams.streamsLabel');
    }
};

export const getStreamsSubheaderByDatePeriod = (dateFilter?: string) => {
    switch (dateFilter) {
        case PAST_7:
        case PAST_28:
        case PAST_6M:
        case PAST_365:
            return getDatePeriodName(dateFilter);
        default:
            return getDatePeriodName(ALL_TIME);
    }
};

export const getCompletionRateTitleByDatePeriod = (dateFilter?: string) => {
    switch (dateFilter) {
        case PAST_7:
            return formatMessage('playlists.table.completion_rate_7_days');
        case PAST_28:
            return formatMessage('playlists.table.completion_rate_28_days');
        case PAST_6M:
            return formatMessage('playlists.table.completion_rate_183_days');
        case PAST_365:
            return formatMessage('playlists.table.completion_rate_365_days');
        default:
            return formatMessage('playlists.table.completion_rate_all_days');
    }
};

export const getStreamsPercentage = ({
    totalStreams,
    playlistStreams,
}: Partial<PlaylistStoreBreakdown>) =>
    totalStreams && playlistStreams
        ? playlistStreams / totalStreams
        : undefined;

export const getPlaylistKey = ({
    playlistId,
    storeId,
}: Pick<PlaylistType, 'playlistId' | 'storeId'>) => `${playlistId}_${storeId}`;
