import {
    formatMessage,
    FormatMessageFunction,
    LocalizationManager,
} from '@theorchard/suite-frontend';
import { FREQUENCY_WEEKLY } from 'src/constants/charts';

const formatMessageWithFallback = (key: string, fallback: string) => {
    const isKey = LocalizationManager.instance.hasMessage(key);

    if (!isKey) {
        // eslint-disable-next-line no-console
        console.error(`i18n: the key "${key}" does not exist.`);

        return fallback;
    }

    return formatMessage(key);
};

const noopFormatter: FormatMessageFunction = (term: string) => term;

const getMessageToFormat = (
    frequency: string | undefined,
    singular: boolean
) => {
    let msg = singular ? 'chart.day' : 'chart.days';
    if (frequency === FREQUENCY_WEEKLY)
        msg = singular ? 'chart.week' : 'chart.weeks';
    return msg;
};

const formatTimeOnChart = (
    frequency: string | undefined,
    timeOnChart: number
) => {
    const singular = timeOnChart === 1;
    const msg = getMessageToFormat(frequency, singular);
    const formattedString = formatMessage(msg);
    return `${timeOnChart} ${formattedString}`;
};

export {
    formatMessage,
    formatMessageWithFallback,
    noopFormatter,
    formatTimeOnChart,
};
