import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import {
    selectParticipantPlacementsBreakdown,
    selectParticipantPlacementBreakdown,
} from 'src/apollo/selectors';
import { getDistributorsArray } from 'src/utils/filters';
import {
    GlobalParticipantPlacementsBreakdownQuery as GSRBreakdownQuery,
    GlobalParticipantPlacementsBreakdownQueryVariables as GSRBreakdownQueryVariables,
} from '../../definitions/GlobalParticipantPlacementsBreakdownQuery';
import { useParallelQueries } from '../../utils';
import GlobalParticipantPlacementsBreakdownQuery from './globalParticipantPlacementsBreakdown.gql';

interface PlacementBreakdownVariables {
    id: string;
    dateFilter: string;
    distributors: string;
}

export const useBatchParticipantPlacementsBreakdownQuery = (
    variables: GSRBreakdownQueryVariables[],
    dateFilter: string
) => {
    const client = useApolloClient();

    const {
        loading,
        data: records = [],
        error,
    } = useParallelQueries<GSRBreakdownQuery, GSRBreakdownQueryVariables>(
        GlobalParticipantPlacementsBreakdownQuery,
        { variables, client }
    );

    const data = useMemo(
        () =>
            records &&
            selectParticipantPlacementsBreakdown(records, dateFilter),
        [records, dateFilter]
    );

    return { loading, data, error };
};

export const useParticipantPlacementsBreakdownQuery = (
    variables: PlacementBreakdownVariables
) => {
    const { id, dateFilter, distributors } = variables;
    const { loading, data, error } = useQuery<
        GSRBreakdownQuery,
        GSRBreakdownQueryVariables
    >(GlobalParticipantPlacementsBreakdownQuery, {
        variables: {
            id,
            distributors: getDistributorsArray(distributors),
        },
    });
    const breakdownData = useMemo(
        () => data && selectParticipantPlacementBreakdown(data, dateFilter),
        [data, dateFilter]
    );

    return {
        loading,
        data: breakdownData,
        error,
    };
};
