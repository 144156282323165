import React, { ReactElement, ReactText, VFC } from 'react';
import { Tooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { uniqueId } from 'lodash';
import TruncatedText from 'src/components/truncatedText';
import { EMPTY_CHAR } from 'src/constants';

export const CLASSNAME = 'PlusMoreValue';

export interface Props {
    values?: ReactText[] | null;
    defaultValue?: ReactElement | null;
    displayThreshold?: number;
    concatSymbol?: string;
    moreFormatter?: (num: number) => string;
}

const PlusMoreValue: VFC<Props> = ({
    values,
    defaultValue = <span>{EMPTY_CHAR}</span>,
    displayThreshold,
    concatSymbol = ', ',
    moreFormatter,
}) => {
    if (!values?.length) return defaultValue;
    const concatString = values.join(concatSymbol);
    if (
        !displayThreshold ||
        displayThreshold < 0 ||
        displayThreshold >= values.length
    )
        return (
            <TruncatedText
                className={`${CLASSNAME} ${CLASSNAME}-text`}
                value={concatString}
            />
        );
    const extraValuesLength = values.length - displayThreshold;
    const limitedValues = values.slice(0, displayThreshold).join(concatSymbol);
    return (
        <Tooltip
            id={`${CLASSNAME}_${uniqueId()}`}
            className={`${CLASSNAME} ${CLASSNAME}-tooltip`}
            message={concatString}
        >
            <span className={`${CLASSNAME}-value`}>{limitedValues}</span>
            <span className={`${CLASSNAME}-more`}>
                {moreFormatter
                    ? moreFormatter(extraValuesLength)
                    : formatMessage('list.plusXMore', { x: extraValuesLength })}
            </span>
        </Tooltip>
    );
};

export default PlusMoreValue;
