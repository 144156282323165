import React, { FC } from 'react';
import { createHref } from '@theorchard/suite-frontend';
import { Link, LinkProps } from 'react-router-dom';
import SeeAll from 'src/components/seeAll';
import { ROUTE_ACCOUNT, ROUTE_SUBACCOUNT } from 'src/constants';

export interface Props extends Omit<LinkProps, 'to'> {
    accountId: number;
    subaccountId?: number;
    isSeeAllLink?: boolean;
    fromComponent?: string;
    params?: object;
}

export const getAccountLink = (
    accountId: number,
    subaccountId: number,
    params?: object
) => {
    const isSubaccount = subaccountId > 0;
    return isSubaccount
        ? createHref(ROUTE_SUBACCOUNT, { accountId, subaccountId, ...params })
        : createHref(ROUTE_ACCOUNT, { accountId, ...params });
};

const AccountLink: FC<Props> = ({
    accountId,
    subaccountId = 0,
    title,
    params,
    fromComponent,
    isSeeAllLink = false,
    ...linkProps
}) => {
    const toHref = getAccountLink(accountId, subaccountId, params);

    if (isSeeAllLink)
        return (
            <SeeAll
                className="d-print-hide"
                to={toHref}
                fromComponent={fromComponent}
                {...linkProps}
            >
                {title}
            </SeeAll>
        );

    return (
        <Link className="d-print-hide" to={toHref} {...linkProps}>
            {title}
        </Link>
    );
};

export default AccountLink;
