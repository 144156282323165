export const ENTITY_TYPES = {
    ViDEO: 'video',
    SONG: 'song',
};

export const TABS = [
    { id: ENTITY_TYPES.ViDEO, term: 'video.videos' },
    { id: ENTITY_TYPES.SONG, term: 'catalog.songs' },
];

export enum RelatedEntityTypes {
    SoundRecording,
    Video,
}
