import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import SongLinkCell from './songLinkCell';

GridTable.defineColumnTemplate('thumbnailSongLink', {
    align: 'left',
    Cell: ({ data, ...rest }) => (
        <div className="ThumbnailSongLinkCell CoverArtCell">
            <CoverArt url={data.imageUrl} width="30" />
            <SongLinkCell data={data} {...rest} />
        </div>
    ),
});
