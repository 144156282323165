import React, { FC } from 'react';
import { StoreIcon, StoreIconName } from '@theorchard/suite-icons';
import cx from 'classnames';
import { STORE_ICON_NAME_BY_NAME } from 'src/components/platformIcon/constants';
import * as stores from 'src/constants/stores';
import { STORES_BY_ID } from 'src/constants/stores';

export const CLASSNAME = 'PlatformIcon';

interface Props {
    platform?: string | null;
    storeId?: number | null;
    className?: string;
    size?: '12' | '16' | '24';
}

const PlatformIcon: FC<Props> = props => {
    const { platform, storeId, className, ...rest } = props;
    if (!platform && !storeId) {
        return null;
    }

    const iconName = storeId
        ? STORE_ICON_NAME_BY_NAME[STORES_BY_ID[storeId]]
        : platform && STORE_ICON_NAME_BY_NAME[platform];
    const storeName = platform || (storeId && stores.STORES_BY_ID[storeId]);
    const platformClassName = storeName && `${CLASSNAME}-${storeName}`;

    return (
        <StoreIcon
            name={iconName as StoreIconName}
            className={cx(className, CLASSNAME, platformClassName)}
            {...rest}
        />
    );
};

export default PlatformIcon;
