import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import NumberWithPercentageAndArrowCell from '../numberWithPercentageAndArrowCell';

GridTable.defineColumnTemplate('views7DaysChange', {
    align: 'right',
    Cell: props => (
        <NumberWithPercentageAndArrowCell
            {...props}
            percentageProperty="viewsGrowthPercentage7Days"
            currentValueProperty="views7Days"
        />
    ),
});
