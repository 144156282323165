import React from 'react';
import { SkeletonLoader } from '@theorchard/suite-components';
import cx from 'classnames';
import { LoadingIndicatorCommonProps } from 'src/components/table/cells/loadingIndicators/loadingIndicators';

const CLASSNAME = 'catalogLoadingIndicator';

const CatalogLoadingIndicator: React.FC<LoadingIndicatorCommonProps> = ({
    className,
}) => (
    <div className={cx(CLASSNAME, className)}>
        <SkeletonLoader className="catalog" />
    </div>
);

export default CatalogLoadingIndicator;
