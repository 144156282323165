import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import SongLinkToCompareCell from 'src/components/table/cells/songLinkToCompareCell';

GridTable.defineColumnTemplate('thumbnailSongLinkToCompareWithIsrc', {
    align: 'left',
    Cell: ({ data, ...rest }) => (
        <div className="ThumbnailSongLinkWithIsrcCell CoverArtCell">
            <CoverArt url={data.imageUrl} width="30" />
            <div className="ThumbnailSongLinkWithIsrcCell-label">
                <SongLinkToCompareCell data={data} {...rest} />
                {data.isrc && (
                    <div className="ThumbnailSongLinkWithIsrcCell-irsc">
                        {data.isrc}
                    </div>
                )}
            </div>
        </div>
    ),
});
