import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import NumberWithPercentageCell from './numberWithPercentageCell';

GridTable.defineColumnTemplate('trackDownloadsWithPercentage', {
    align: 'right',
    minWidth: 'auto',
    maxWidth: 'max-content',
    Cell: props => (
        <NumberWithPercentageCell
            {...props}
            percentageProperty="totalTrackDownloadsPercentage"
        />
    ),
});
