import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import NumberWithPercentageAndArrowCell from '../numberWithPercentageAndArrowCell';

GridTable.defineColumnTemplate('views1DayChange', {
    align: 'right',
    Cell: props => (
        <NumberWithPercentageAndArrowCell
            {...props}
            percentageProperty="viewsGrowthPercentage1Day"
            currentValueProperty="views1Day"
        />
    ),
});
