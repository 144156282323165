import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    ChannelQuery,
    ChannelQueryVariables,
} from '../../definitions/ChannelQuery';
import { selectChannel } from '../../selectors';
import { flattenError } from '../../utils';
import channelQuery from './channel.gql';

export const useChannelQuery = (channelId?: string) => {
    const variables = {
        channelId: channelId ?? '',
    };

    const { data, loading, error } = useQuery<
        ChannelQuery,
        ChannelQueryVariables
    >(channelQuery, { variables, skip: !channelId });

    return {
        loading,
        data: useMemo(() => selectChannel(data), [data]),
        error: error && flattenError(error),
    };
};
