import { GlobalParticipantSearchOrderByField } from 'src/apollo/definitions/globalTypes';

export const PARAM_LABEL_NAME = 'labelName';
export const PARAM_COUNTRIES = 'countries';
export const PARAM_VENDOR_IDS = 'vendorIds';
export const PARAM_QUERY_TYPE = 'queryType';
export const PARAM_CATALOG_ONLY = 'catalog';

export const PARAM_TERM = 'term';
export const PARAM_LIMIT = 'limit';
export const PARAM_OFFSET = 'offset';
export const PARAM_ORDER_BY = 'orderBy';
export const PARAM_ORDER_DIR = 'orderDir';

export const FILTER_PARAMS = [
    PARAM_QUERY_TYPE,
    PARAM_LABEL_NAME,
    PARAM_COUNTRIES,
    PARAM_VENDOR_IDS,
];

export const SEARCH_ARTIST_SORTING_FIELDS_MAP: Record<string, string> = {
    monthlyListeners: GlobalParticipantSearchOrderByField.MONTHLY_LISTENERS,
    streams7Days: GlobalParticipantSearchOrderByField.STREAMS_7_DAYS,
    streams28Days: GlobalParticipantSearchOrderByField.STREAMS_28_DAYS,
    streamsAllTime: GlobalParticipantSearchOrderByField.STREAMS_ALL_TIME,
    popularity: GlobalParticipantSearchOrderByField.POPULARITY,
    score: GlobalParticipantSearchOrderByField.SCORE,
};
