import { useCallback } from 'react';
import { QueryLazyOptions, useLazyQuery } from '@apollo/client';
import { selectLabelManagers } from 'src/apollo/selectors/labelManager';
import { LABEL_MANAGERS_LIMIT } from 'src/constants/catalog';
import {
    LabelManagerSearch,
    LabelManagerSearch_labelManagerSearch,
    LabelManagerSearchVariables,
} from '../../definitions/LabelManagerSearch';
import { flattenError } from '../../utils';
import labelManagerSearchQuery from './labelManagerSearch.gql';

interface OrchardLabelManagerSearchResponse {
    loading: boolean;
    error?: Error;
    data?: LabelManagerSearch_labelManagerSearch[];
}

interface OrchardLabelManagerSearchhandler {
    (
        variables: QueryLazyOptions<LabelManagerSearchVariables>
    ): Promise<OrchardLabelManagerSearchResponse>;
}

export type LabelManagersSearchResult = [
    OrchardLabelManagerSearchhandler,
    OrchardLabelManagerSearchResponse
];

export const useLabelManagerSearchQuery: () => LabelManagersSearchResult =
    () => {
        const [internalExecuteSearch, { data, loading, error }] = useLazyQuery<
            LabelManagerSearch,
            LabelManagerSearchVariables
        >(labelManagerSearchQuery, {
            variables: { limit: LABEL_MANAGERS_LIMIT },
        });

        const executeSearch = useCallback<OrchardLabelManagerSearchhandler>(
            async args => {
                const { data: searchData } = await internalExecuteSearch(args);
                return {
                    loading: false,
                    data:
                        searchData &&
                        selectLabelManagers(searchData.labelManagerSearch),
                };
            },
            [internalExecuteSearch]
        );

        return [
            executeSearch,
            {
                loading,
                data: data && selectLabelManagers(data?.labelManagerSearch),
                error: error && flattenError(error),
            },
        ];
    };
