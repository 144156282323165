import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    selectStarredSoundRecordings,
    selectStarredSoundRecordingsStreams,
} from 'src/apollo/selectors/song';
import { usePrefetchQuery } from 'src/apollo/utils';
import { FAVORITES_PAGE_SONGS_SIZE } from 'src/pages/favorites/pages/songs/constants';
import {
    StarredSoundRecordingsQuery as Query,
    StarredSoundRecordingsQueryVariables as QueryVars,
} from '../../definitions/StarredSoundRecordingsQuery';
import {
    StarredSoundRecordingsStreamsQuery as StreamsQuery,
    StarredSoundRecordingsStreamsQueryVariables as StreamsQueryVars,
} from '../../definitions/StarredSoundRecordingsStreamsQuery';
import starredSoundRecordingsQuery from './starredSoundRecordings.gql';
import starredSoundRecordingsStreamsQuery from './starredSoundRecordingsStreams.gql';

export const useStarredSoundRecordingsQuery = (variables: QueryVars) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        starredSoundRecordingsQuery,
        { variables }
    );

    const memoizedData = useMemo(
        () => data && selectStarredSoundRecordings(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const useStarredSoundRecordingsStreamsQuery = (
    variables: StreamsQueryVars
) => {
    const { data, loading, error } = useQuery<StreamsQuery, StreamsQueryVars>(
        starredSoundRecordingsStreamsQuery,
        { variables }
    );

    const memoizedData = useMemo(
        () => data && selectStarredSoundRecordingsStreams(data),
        [data]
    );

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchStarredSoundRecordingsQuery = () => {
    usePrefetchQuery<Query, QueryVars>(starredSoundRecordingsQuery, {
        variables: { limit: FAVORITES_PAGE_SONGS_SIZE },
    });

    usePrefetchQuery<StreamsQuery, StreamsQueryVars>(
        starredSoundRecordingsStreamsQuery,
        { variables: { limit: FAVORITES_PAGE_SONGS_SIZE } }
    );
};
