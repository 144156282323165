import { getCurrentLocale, formatMessage } from '@theorchard/suite-frontend';
import { memoize, orderBy } from 'lodash';
import countries from '../../assets/countries.json';

export const COUNTRY_UNKNOWN = 'UNK';
export const COUNTRY_GLOBAL = 'GLOBAL';
export const COUNTRY_NAME_GLOBAL = 'Global';
export const WORLDWIDE = 'WW';
export const COUNTRY_KOSOVO = 'XK';

interface Country {
    name: string;
    alpha3: string;
    population: number;
    locales: Record<string, string>;
}

export const COUNTRIES = countries as Record<string, Country>;

export const GLOBAL = formatMessage('default.country.global');

export const getCountryName = (code: string, locale = getCurrentLocale()) => {
    const item = COUNTRIES[code];
    if (item && locale && item.locales[locale]) return item.locales[locale];
    if (item) return item.name;
    return code;
};

export const getCountries = (countryValues: string | string[]) => {
    const countryNames =
        typeof countryValues === 'string'
            ? getCountryName(countryValues)
            : countryValues.map(country => getCountryName(country)).join(', ');
    return countryNames;
};

export const getCountryNames = memoize(
    (locale = getCurrentLocale()) =>
        orderBy(
            Object.entries(COUNTRIES).map(([value, item]) => ({
                value,
                label: (locale && item.locales[locale]) || item.name,
            })),
            item => item.label
        ),
    locale => locale || getCurrentLocale()
);

export const getCountryAlpha3Code = (code: string) =>
    COUNTRIES[code]?.alpha3 || code;
