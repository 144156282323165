import { useCallback, useEffect, useState } from 'react';
import { asError } from '@theorchard/suite-frontend';
import SpotifyApi from './spotifyWebApi';

type ResultTuple = [
    { loaded: boolean; playing: boolean; error?: Error },
    (tracks?: string[], playlistId?: string) => void
];

const SPOTIFY_TRACK_IDENTIFIER = 'spotify:track:';

const createUri = (trackId: string) => `${SPOTIFY_TRACK_IDENTIFIER}${trackId}`;

const useSpotifyPlayer = ({
    trackIds,
    playlistId,
}: {
    trackIds: string[];
    playlistId?: string;
}): ResultTuple => {
    const [trackId] = trackIds;
    const trackUri = trackId ? createUri(trackId) : undefined;
    const [loaded, setLoaded] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [tracksFromPlaylist, setTracksFromPlaylist] = useState<
        string[] | undefined
    >();
    const [error, setError] = useState<Error | undefined>();

    const handleError = () => {
        setError(new Error('failed to play'));
        setLoaded(false);
    };

    const handleStateChange = useCallback(
        (event: Spotify.PlayerState | null) => {
            const tracksIds = trackIds.length
                ? trackIds
                : tracksFromPlaylist || SpotifyApi.currentPlaylistTracks || [];
            const isThisTrack = tracksIds.length
                ? tracksIds.some(id => {
                      const uri = id.includes(SPOTIFY_TRACK_IDENTIFIER)
                          ? id
                          : createUri(id);
                      return (
                          event?.track_window.current_track?.uri === uri ||
                          event?.track_window.current_track?.linked_from
                              ?.uri === uri
                      );
                  })
                : false;

            const isThisCurrentPlaylist =
                SpotifyApi.currentPlaylistId === playlistId;

            setLoaded(isThisTrack && isThisCurrentPlaylist);
            setPlaying(
                !!event && !event.paused && isThisTrack && isThisCurrentPlaylist
            );
        },
        [trackIds, tracksFromPlaylist, playlistId]
    );

    useEffect(() => {
        const { player } = SpotifyApi;
        player?.getCurrentState().then(handleStateChange);
        player?.addListener('player_state_changed', handleStateChange);
        player?.addListener('playback_error', handleError);
        return () => {
            player?.removeListener('player_state_changed', handleStateChange);
            player?.removeListener('playback_error', handleError);
        };
    }, [handleStateChange, playlistId]);

    const play = useCallback(
        async (playlistTracks?: string[], actualPlaylistId?: string) => {
            try {
                const { player } = SpotifyApi;
                if (loaded && player) {
                    player.togglePlay();
                    return;
                }

                if (!trackUri && !playlistTracks) return;
                SpotifyApi.clearState();

                setTracksFromPlaylist(playlistTracks);
                const playableTracks = playlistTracks?.length
                    ? playlistTracks
                    : [trackUri];

                await SpotifyApi.playTracks(
                    playableTracks,
                    false,
                    actualPlaylistId
                );

                setLoaded(true);
                setError(undefined);
            } catch (e) {
                setError(asError(e));
                setLoaded(false);
            }
        },
        [loaded, trackUri]
    );

    return [{ loaded, playing, error }, play];
};

export default useSpotifyPlayer;
