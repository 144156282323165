import { formatMessage, formatNumber } from '@theorchard/suite-frontend';
import { get, map, startCase } from 'lodash';
import { PlaylistPlacementPositionTimeseries_playlistPlacementPositionTimeseries_items as PositionTimeseriesItems } from 'src/apollo/definitions/PlaylistPlacementPositionTimeseries';
import { PlaylistPlacementStreamsTimeseries_playlistPlacementStreamsTimeseries_items as StreamsTimeseriesItems } from 'src/apollo/definitions/PlaylistPlacementStreamsTimeseries';
import {
    ProductPlaylistPlacements_globalProductByUpc_catalogProduct_playlistPlacements_placements as ProductPlaylistPlacement,
    ProductPlaylistPlacements,
} from 'src/apollo/definitions/ProductPlaylistPlacements';
import { TopSoundRecordingPlaylistPlacements } from 'src/apollo/definitions/TopSoundRecordingPlaylistPlacements';
import { ChartPoint } from 'src/components/graph/definitions';
import { EMPTY_CHAR } from 'src/constants';
import { COUNTRY_GLOBAL } from 'src/constants/countries';
import { DEFAULT_START_DATE } from 'src/constants/periods';
import {
    STORES_BY_ID,
    STORE_ALL,
    STORE_AMAZON_MUSIC,
    STORE_APPLE_MUSIC,
    STORE_SPOTIFY,
    STORE_ID_APPLE_MUSIC,
} from 'src/constants/stores';
import { ProductPlaylistType } from 'src/pages/product/pages/playlists/types';
import { formatNumberChange, formatPercentage } from 'src/utils';
import { getLocalizedName } from 'src/utils/countries';
import { getLocalizedPlaylistType } from 'src/utils/playlists';
import peakPositionIconBase64 from '../../../assets/peak-position-icon-base64';
import peakPositionIconBase64V2 from '../../../assets/peak-position-icon-base64-v2';
import {
    GlobalParticipantPlaylistPlacements,
    GlobalParticipantPlaylistPlacements_globalParticipantByGpId_playlistPlacements_placements as ParticipantPlaylistPlacement,
} from '../definitions/GlobalParticipantPlaylistPlacements';
import {
    DeltaGrowthPeriod,
    PlaylistType as GlobalPlaylistType,
} from '../definitions/globalTypes';
import { PlaylistPlacement as PlaylistPlacementData } from '../definitions/PlaylistPlacement';
import { PlaylistPlacementBreakdownByCountry } from '../definitions/PlaylistPlacementBreakdownByCountry';
import {
    SoundRecordingPlaylistPlacements,
    SoundRecordingPlaylistPlacements_globalSoundRecordingByIsrc_playlistPlacements_placements as SoundRecordingPlaylistPlacement,
    SoundRecordingPlaylistPlacements_globalSoundRecordingByIsrc_playlistPlacements_placements_playlist_analytics as PlaylistAnalytics,
} from '../definitions/SoundRecordingPlaylistPlacements';
import { SoundRecordingPlaylistStreamsByStore_globalSoundRecordingByIsrc_analytics_totalVsPlaylistStreamsByStore as StreamsByStore } from '../definitions/SoundRecordingPlaylistStreamsByStore';
import { assertValue, filterData } from '../utils';
import { SongMetadata } from './song';
import { getGrowthPeriod, isSMEProduct, selectImageUrl } from './utils';

export interface PlaylistType {
    isrc?: string;
    playlistId: string;
    playlistName: string;
    playlistUri: string;
    streams?: number | null;
    imageLocation?: string | null;
    position?: number | null;
    change?: number | null;
    peakPosition?: number | null;
    lastAdded?: string | null;
    firstAdded?: string | null;
    firstPlay?: string | null;
    daysOn?: number | null;
    dateRemoved?: string | null;
    followers?: number | null;
    curator?: string | null;
    topMarket?: string | null;
    genres?: string[];
    completionRate?: number | null;
    storeId?: number;
    storefront?: string | null;
    size?: number | null;
    averageDays?: number;
    totalStreams?: number;
    playlistType: {
        type?: string;
        name?: string;
    };
    storefrontCount?: number | null;
    entityStreams7Days?: number | null;
    entityStreams28Days?: number | null;
    streams_7_days?: number | null;
    streams_28_days?: number | null;
    streams_183_days?: number | null;
    streams_365_days?: number | null;
    streams_all_time?: number | null;
    completion_rate_7_days?: number | null;
    completion_rate_28_days?: number | null;
    completion_rate_183_days?: number | null;
    completion_rate_365_days?: number | null;
    completion_rate_all_time?: number | null;
}

export interface ProductPlaylistsCount<T = ProductPlaylistType> {
    totalCount: number;
    items: T[];
}

export interface PlaylistSeriesType {
    isrc: string;
    playlist: PlaylistType;
}

export interface ParticipantPlaylistType extends PlaylistType {
    isrc: string;
    imageUrl?: string | null;
    songName: string;
    participants: { id: string; name: string | null }[];
    song: Pick<
        SongMetadata,
        'isrc' | 'releaseDate' | 'imageUrl' | 'name' | 'participants'
    >;
}

export interface PlaylistStoreStreams {
    totalStreams: number | null;
    playlistStreams: number | null;
}

export interface PlaylistStoreSeries {
    totalTimeSeries: ChartPoint[];
    playlistTimeSeries: ChartPoint[];
}

export type PlaylistStoreBreakdown = PlaylistStoreStreams & PlaylistStoreSeries;

export interface PlaylistStreamsStores {
    [STORE_ALL]: PlaylistStoreBreakdown;
    [STORE_SPOTIFY]: PlaylistStoreBreakdown;
    [STORE_AMAZON_MUSIC]: PlaylistStoreBreakdown;
    [STORE_APPLE_MUSIC]: PlaylistStoreBreakdown;
}

interface StoresTimeseries {
    timestamp: string | null;
    playlistStreams: number | null;
    streams: number | null;
}

interface StreamsByStoreType {
    storeId?: string;
    totalPlaylistStreams: number | null;
    totalStreams: number | null;
    timeseries: (StoresTimeseries | null)[] | null;
}

interface PlaylistPositionsWithPeaks {
    x: number;
    y: number | null;
    marker?: object;
}

interface PlaylistCuratorType {
    curatorId: string | null;
    curatorCountry: string | null;
    curatorName: string | null;
}

interface PlaylistPlacementSourceType {
    storeId: number;
}

interface PlacementPlaylistType {
    playlistId: string;
    playlistName: string;
    playlistUri: string;
    playlistArtworkUrl: string | null;
    playlistFollowerCount: number | null;
    playlistTrackCount: number | null;
    playlistType: GlobalPlaylistType;
    playlistGenres: string[];
    playlistCurator: PlaylistCuratorType;
    source: PlaylistPlacementSourceType;
    analytics?: PlaylistAnalytics;
}

interface PlacementStreamsGrowthPeriodsType {
    period: DeltaGrowthPeriod | null;
    value: number | null;
    completionRate: number | null;
}

interface PlaylistPlacementStreamsType {
    growthPeriods: PlacementStreamsGrowthPeriodsType[];
}

export interface CommonPlaylistPlacementType {
    lastAddedOnDate?: string | null;
    dateRemoved?: string | null;
    firstPlay?: string | null;
    currentPosition?: number | null;
    peakPosition?: number | null;
    previousPosition?: number | null;
    positionChange?: number | null;
    daysOnPlaylist?: number | null;
    playlistPlacementStreams: PlaylistPlacementStreamsType;
    playlist: PlacementPlaylistType | null;
}

export interface PlaylistsCountData<T = PlaylistType> {
    totalCount: number;
    items: T[];
}

interface PlaylistPlacementWithOptionalAnalytic
    extends Omit<SoundRecordingPlaylistPlacement, 'playlist'> {
    playlist: PlacementPlaylistType;
}

const selectPlaylist = (playlist: PlacementPlaylistType): PlaylistType => {
    const {
        playlistArtworkUrl,
        playlistCurator: { curatorName, curatorCountry },
        playlistFollowerCount,
        playlistGenres,
        playlistId,
        playlistName,
        playlistUri,
        playlistTrackCount,
        playlistType,
        source: { storeId },
        analytics,
    } = playlist;
    const playlistTypeValue = {
        type: playlistType,
        name: getLocalizedPlaylistType(playlistType),
    };
    return {
        playlistId,
        playlistName,
        playlistUri,
        followers: playlistFollowerCount,
        imageLocation: playlistArtworkUrl,
        topMarket: curatorCountry || COUNTRY_GLOBAL,
        size: playlistTrackCount,
        playlistType: playlistTypeValue,
        genres: map(playlistGenres, genre => startCase(genre)),
        storeId,
        curator: curatorName,
        entityStreams7Days: analytics?.streams7Days,
        entityStreams28Days: analytics?.streams28Days,
    };
};

const selectCommonPlaylistPlacement = (
    placement: CommonPlaylistPlacementType | null | undefined,
    growthPeriod: DeltaGrowthPeriod
): PlaylistType | null => {
    if (!placement?.playlist) return null;

    const {
        currentPosition,
        peakPosition,
        dateRemoved,
        daysOnPlaylist,
        lastAddedOnDate,
        positionChange,
        firstPlay,
        playlistPlacementStreams: { growthPeriods },
        playlist,
    } = placement;
    const selectedPeriod = growthPeriods?.find(
        ({ period }) => period === growthPeriod
    );
    const normalizedGrowthPeriods = growthPeriods.reduce<Partial<PlaylistType>>(
        (acc, periodItem) => {
            const { period, value, completionRate } = periodItem;
            const streamsKey = `streams${period}`.toLowerCase();
            const completionRateKey = `completion_rate${period}`.toLowerCase();

            return {
                ...acc,
                [streamsKey]: value,
                [completionRateKey]: completionRate,
            };
        },
        {
            streams: selectedPeriod?.value,
            completionRate: selectedPeriod?.completionRate,
        }
    );

    return {
        ...selectPlaylist(playlist),
        change: positionChange,
        dateRemoved,
        daysOn: daysOnPlaylist,
        position: currentPosition,
        peakPosition,
        lastAdded: lastAddedOnDate,
        firstPlay,
        ...normalizedGrowthPeriods,
    };
};

const selectPlaylists = (
    placement: PlaylistPlacementWithOptionalAnalytic | null,
    selectedPeriod: DeltaGrowthPeriod,
    storefront?: string | null
): PlaylistType | null => {
    if (!placement) return null;
    const playlistPlacementData = selectCommonPlaylistPlacement(
        placement,
        selectedPeriod
    );
    if (playlistPlacementData) {
        const { storefrontCount } = placement;

        return {
            ...playlistPlacementData,
            storefront,
            storefrontCount,
        };
    }

    return null;
};

export const selectPlaylistPlacement = (
    data: PlaylistPlacementData,
    dateFilter?: string
): PlaylistType | null => {
    const growthPeriod = getGrowthPeriod(dateFilter);
    return selectPlaylists(data.playlistPlacement, growthPeriod);
};

export const selectPlaylistPlacementBreakdownByCountry = (
    data: PlaylistPlacementBreakdownByCountry,
    dateFilter?: string
): PlaylistType[] => {
    if (!data.playlistPlacement?.playlist) return [];
    const growthPeriod = getGrowthPeriod(dateFilter);
    const {
        playlistPlacement: { breakdownByCountry, playlist },
    } = data;

    return filterData(
        map(
            breakdownByCountry?.countries,
            ({
                country,
                currentPosition,
                dateRemoved,
                firstPlay,
                lastAddedOnDate,
                peakPosition,
                previousPosition,
                positionChange,
                daysOnPlaylist,
                playlistPlacementStreams,
                totalPlaylistStreamsLast7Days,
                totalPlaylistStreamsLast28Days,
            }) => {
                const placement: PlaylistPlacementWithOptionalAnalytic = {
                    positionChange,
                    daysOnPlaylist,
                    currentPosition,
                    dateRemoved,
                    firstPlay,
                    lastAddedOnDate,
                    peakPosition,
                    playlist: {
                        ...playlist,
                        analytics: {
                            playlist,
                            streams7Days: totalPlaylistStreamsLast7Days,
                            streams28Days: totalPlaylistStreamsLast28Days,
                        },
                        playlistCurator: {
                            ...playlist.playlistCurator,
                            curatorCountry: country,
                        },
                    },
                    playlistPlacementStreams,
                    previousPosition,
                    storefrontCount: null,
                };
                return selectPlaylists(placement, growthPeriod, country);
            }
        )
    );
};

export const selectSongPlaylists = (
    data: SoundRecordingPlaylistPlacements,
    dateFilter?: string
): PlaylistsCountData => {
    const dataRoot = data?.globalSoundRecordingByIsrc?.playlistPlacements;
    const growthPeriod = getGrowthPeriod(dateFilter);
    const playlists = filterData(
        map<SoundRecordingPlaylistPlacement | null, PlaylistType | null>(
            dataRoot?.placements,
            placement => selectPlaylists(placement, growthPeriod, null)
        )
    );
    return {
        totalCount: dataRoot?.totalCount || 0,
        items: playlists,
    };
};

export const selectProductPlaylists = (
    data: ProductPlaylistPlacements,
    dateFilter?: string
): ProductPlaylistsCount => {
    const { placements = [], totalCount = 0 } = get(
        data,
        ['globalProductByUpc', 'catalogProduct', 'playlistPlacements'],
        {}
    );
    const growthPeriod = getGrowthPeriod(dateFilter);

    const placementsData = placements
        .filter(Boolean)
        .map((placement: ProductPlaylistPlacement) => {
            const {
                globalSoundRecording: {
                    globalParticipants,
                    imageUrl,
                    isrc,
                    name,
                    releaseDate,
                },
                playlist: { source },
                storefrontCount,
            } = placement;

            const song = {
                isrc,
                name,
                imageUrl,
                participants: globalParticipants,
                releaseDate: releaseDate?.formatted ?? DEFAULT_START_DATE,
            };
            const resolvedStorefrontsCount =
                source.storeId === STORE_ID_APPLE_MUSIC &&
                Number(storefrontCount) > 1
                    ? Number(storefrontCount) - 1
                    : storefrontCount;

            return {
                isrc,
                song,
                songName: song.name,
                imageUrl: song.imageUrl,
                storefrontCount: resolvedStorefrontsCount,
                ...selectCommonPlaylistPlacement(placement, growthPeriod),
            };
        });

    return {
        totalCount,
        items: placementsData,
    };
};

export const selectPlaylistPlacementPositionStreams = (
    items: StreamsTimeseriesItems[] = []
) =>
    items.map(item => ({
        x: new Date(item.timestamp).getTime(),
        y: item.streams,
    }));

export const selectPlaylistPlacementPositionTimeseries = (
    items: PositionTimeseriesItems[] = [],
    peakPosition: number | null,
    isColorSchemaV2Enabled?: boolean
) => {
    const peakPositionIcon = isColorSchemaV2Enabled
        ? peakPositionIconBase64V2
        : peakPositionIconBase64;
    const size = isColorSchemaV2Enabled ? 24 : 34;
    const positions: PlaylistPositionsWithPeaks[] = [];
    let isPeakPositionSet = false;
    items.forEach(item => {
        const itemTimestamp = new Date(item.timestamp).setUTCHours(0, 0, 0, 0);
        if (
            peakPosition &&
            item.position === peakPosition &&
            !isPeakPositionSet
        ) {
            isPeakPositionSet = true;
            positions.push({
                x: itemTimestamp,
                y: item.position,
                marker: {
                    enabled: true,
                    symbol: `url(${peakPositionIcon})`,
                    width: size,
                    height: size,
                },
            });
        } else
            positions.push({
                x: itemTimestamp,
                y: item.position,
            });
    });
    return positions;
};

export const selectPlaylistPlacementSeries = (
    streams: ChartPoint[] = [],
    positions: ChartPoint[] = [],
    isColorSchemaV2Enabled?: boolean
) => {
    return isColorSchemaV2Enabled
        ? [
              {
                  data: positions,
                  title: formatMessage('playlists.storefronts.table.position'),
                  name: 'Position',
                  stepLines: true,
              },
              {
                  data: streams,
                  title: formatMessage('playlists.storefronts.table.streams'),
                  name: 'Streams',
                  yAxis: 1,
              },
          ]
        : [
              {
                  data: streams,
                  title: formatMessage('playlists.storefronts.table.streams'),
                  name: 'Streams',
                  yAxis: 1,
              },
              {
                  data: positions,
                  title: formatMessage('playlists.storefronts.table.position'),
                  name: 'Position',
                  stepLines: true,
              },
          ];
};

export const selectPlaylistSeries = (
    playlist?: PlaylistType | null,
    storefront?: string | null
) => {
    if (!playlist)
        return {
            completionRate: EMPTY_CHAR,
            topMarketLocalized: EMPTY_CHAR,
        };
    const {
        topMarket,
        daysOn,
        averageDays,
        followers: playlistFollowers,
        completionRate: rate,
    } = playlist;
    const curatorCountry = storefront || topMarket;
    const topMarketLocalized = curatorCountry
        ? getLocalizedName(curatorCountry)
        : EMPTY_CHAR;
    const daysDev =
        daysOn && averageDays
            ? formatNumberChange(daysOn - averageDays)
            : undefined;
    const followers =
        playlistFollowers !== undefined
            ? formatNumber(playlistFollowers)
            : undefined;
    const completionRate =
        rate !== undefined && rate !== null
            ? formatPercentage(rate)
            : EMPTY_CHAR;

    return {
        daysDev,
        topMarketLocalized,
        followers,
        completionRate,
    };
};

export const selectPlaylistStreamsByStore = (
    streamsByStoreData?: StreamsByStore | null
): PlaylistStreamsStores => {
    const stores = [
        streamsByStoreData?.allStoresAggregation,
        ...(streamsByStoreData?.stores || []),
    ];
    const streamsByStore = filterData<StreamsByStoreType>(stores).reduce(
        (
            result,
            { totalStreams, totalPlaylistStreams, timeseries, storeId }
        ) => {
            const storeName = storeId
                ? STORES_BY_ID[Number(storeId)]
                : STORE_ALL;
            if (!storeName) return result;

            const series = filterData(timeseries).reduce<PlaylistStoreSeries>(
                (innerResult, { timestamp, playlistStreams, streams }) => {
                    if (timestamp) {
                        const x = new Date(timestamp).getTime();
                        innerResult.playlistTimeSeries.push({
                            x,
                            y: playlistStreams,
                        });
                        innerResult.totalTimeSeries.push({
                            x,
                            y: streams,
                        });
                    }
                    return innerResult;
                },
                {
                    totalTimeSeries: [],
                    playlistTimeSeries: [],
                }
            );

            return {
                ...result,
                [storeName]: {
                    totalStreams,
                    playlistStreams: totalPlaylistStreams,
                    ...series,
                },
            };
        },
        {
            [STORE_ALL]: {
                totalStreams: null,
                playlistStreams: null,
                totalTimeSeries: [],
                playlistTimeSeries: [],
            },
            [STORE_APPLE_MUSIC]: {
                totalStreams: null,
                playlistStreams: null,
                totalTimeSeries: [],
                playlistTimeSeries: [],
            },
            [STORE_SPOTIFY]: {
                totalStreams: null,
                playlistStreams: null,
                totalTimeSeries: [],
                playlistTimeSeries: [],
            },
            [STORE_AMAZON_MUSIC]: {
                totalStreams: null,
                playlistStreams: null,
                totalTimeSeries: [],
                playlistTimeSeries: [],
            },
        }
    );
    return streamsByStore;
};

export const selectParticipantPlaylists = (
    data: GlobalParticipantPlaylistPlacements,
    dateFilter?: string
): PlaylistsCountData<ParticipantPlaylistType> => {
    const dataRoot = data?.globalParticipantByGpId?.[0]?.playlistPlacements;
    const growthPeriod = getGrowthPeriod(dateFilter);
    const playlists = filterData(
        map<
            ParticipantPlaylistPlacement | null,
            ParticipantPlaylistType | null
        >(dataRoot?.placements, placement => {
            if (!placement) return null;
            const {
                globalSoundRecording: {
                    isrc,
                    name: songName,
                    imageUrl,
                    catalogImageUrl,
                    catalogProducts,
                    globalParticipants,
                    releaseDate,
                },
                storefrontCount,
            } = placement;

            const playlistPlacementData = selectCommonPlaylistPlacement(
                placement,
                growthPeriod
            );
            if (playlistPlacementData) {
                const selectedImageUrl = selectImageUrl(
                    imageUrl,
                    catalogImageUrl,
                    isSMEProduct(catalogProducts?.[0]?.notForDistribution)
                );

                return {
                    ...playlistPlacementData,
                    isrc,
                    songName,
                    imageUrl: selectedImageUrl,
                    participants: filterData(globalParticipants),
                    storefrontCount,
                    song: {
                        isrc,
                        name: songName,
                        releaseDate: releaseDate?.formatted ?? undefined,
                        participants: filterData(globalParticipants)
                            .filter(p => p.name)
                            .map(p => ({
                                id: p.id,
                                name: assertValue(p, 'name'),
                            })),
                        imageUrl: selectedImageUrl ?? undefined,
                    },
                };
            }

            return null;
        })
    );
    return {
        totalCount: dataRoot?.totalCount || 0,
        items: playlists,
    };
};

export const selectSongTopPlaylistPlacements = (
    data?: TopSoundRecordingPlaylistPlacements,
    dateFilter?: string
): PlaylistType[] | undefined => {
    const growthPeriod = getGrowthPeriod(dateFilter);
    const placements =
        data?.globalSoundRecordingByIsrc?.analytics?.topPlaylistPlacements
            .placements;

    if (!placements || placements.length === 0) return undefined;

    return filterData(
        placements.map(item => selectPlaylists(item, growthPeriod))
    );
};
