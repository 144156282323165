import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    GlobalSoundRecordingByIsrc,
    GlobalSoundRecordingByIsrcVariables,
} from '../../definitions/GlobalSoundRecordingByIsrc';
import { flattenError } from '../../utils';
import globalSoundRecordingByIsrcQuery from './globalSoundRecordingByIsrc.gql';

export const useGlobalSoundRecordingByIsrcQuery = (isrc?: string) => {
    const [getByIsrc, { data, loading, error }] = useLazyQuery<
        GlobalSoundRecordingByIsrc,
        GlobalSoundRecordingByIsrcVariables
    >(globalSoundRecordingByIsrcQuery);
    const soundRecording = data?.globalSoundRecordingByIsrc;

    useEffect(() => {
        if (isrc) getByIsrc({ variables: { isrc } });
    }, [isrc, getByIsrc]);

    return {
        loading,
        data: isrc ? soundRecording : undefined,
        error: error && flattenError(error),
    };
};
