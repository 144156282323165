import React, { FC } from 'react';
import { GridTable, GridTableCellProps } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_VIDEO } from 'src/constants';
import { getVideoType } from 'src/utils/videos';
import { VideoLinkWithType } from './types';

const VideoLinkWithTypeCell: FC<GridTableCellProps<VideoLinkWithType>> = ({
    data: { video },
}) => (
    <div className="VideoLinkWithTypeCell">
        <TruncatedText
            useLink
            route={ROUTE_VIDEO}
            params={{ id: video.id }}
            value={video.name}
        />
        <div className="VideoLinkWithTypeCell-type">
            {`${getVideoType(video.channelOwner, video.videoContentType)} - ${
                video.id
            }`}
        </div>
    </div>
);

GridTable.defineColumnTemplate('videoLinkWithType', {
    align: 'left',
    sortable: false,
    Cell: VideoLinkWithTypeCell,
});

export default VideoLinkWithTypeCell;
