import { SortDirection } from 'src/components/table/types';

export const PARTICIPANTS_PAGE_SIZE = 50;

export const ARTIST_NAME = 'catalog.artistName';
export const COUNTRY = 'default.country.country';
export const STREAMS_1 = 'catalog.streams1';
export const GROWTH_1 = 'catalog.growthPercentage1Day';
export const STREAMS_7 = 'catalog.streams7';
export const GROWTH_7 = 'catalog.growthPercentage7Days';
export const STREAMS_28 = 'catalog.streams28';
export const GROWTH_28 = 'catalog.growthPercentage28Days';
export const STREAMS_ALL = 'catalog.streamsAll';
export const ACCOUNT = 'account.account';

export const DEFAULT_SORT_FIELD = 'streams_7_days';
export const DEFAULT_SORT_DIR: SortDirection = 'desc';

export const CATALOG_ARTISTS_SORTING_FIELDS_MAP = {
    streams1Day: 'streams_1_day',
    growthPercentage1Day: 'growth_percentage_1_day',
    streams7Days: 'streams_7_days',
    growthPercentage7Days: 'growth_percentage_7_days',
    streams28Days: 'streams_28_days',
    growthPercentage28Days: 'growth_percentage_28_days',
    streamsAllTime: 'streams_all_time',
};
