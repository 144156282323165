import React, { VFC } from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GridTableColumnTextProps } from './types';

const DailyStreamsHeaderCell: VFC<GridTableColumnTextProps> = () => (
    <>
        {formatMessage('chart.streams')}
        <HelpTooltip
            id="chartStreamsInfo"
            message={formatMessage('chart.dailyStreamsTooltip')}
            tooltipPlacement="right"
        />
    </>
);

export default DailyStreamsHeaderCell;
