import { useQuery } from '@apollo/client';
import { selectVideoCountries } from 'src/apollo/selectors';
import {
    ChannelAnalyticsTopCountriesQuery,
    ChannelAnalyticsTopCountriesQueryVariables,
} from '../../definitions/ChannelAnalyticsTopCountriesQuery';
import { flattenError } from '../../utils';
import channelCountryViewsQuery from './channelAnalyticsTopCountries.gql';

export const useChannelAnalyticsTopCountriesQuery = (
    variables: ChannelAnalyticsTopCountriesQueryVariables,
    selectedCountries?: string[]
) => {
    const { data, loading, error } = useQuery<
        ChannelAnalyticsTopCountriesQuery,
        ChannelAnalyticsTopCountriesQueryVariables
    >(channelCountryViewsQuery, { variables });

    return {
        loading,
        data: selectVideoCountries(
            data?.channel?.analytics.topCountries.countries,
            selectedCountries
        ),
        error: error && flattenError(error),
    };
};
