import React, { FC } from 'react';
import { GridTable, GridTableCellProps } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_CHANNEL } from 'src/constants';
import { TEST_ID as channelSearchResultsId } from 'src/pages/searchES/pages/channelResults/channelResultsGridTable';
import { ChannelType } from './types';

const ChannelLinkCell: FC<GridTableCellProps<ChannelType>> = ({
    data: { channel },
    context: {
        props: { testId },
    },
}) => {
    if (!channel) return <div>&nbsp;</div>;

    const shouldLogPageVisit = testId === channelSearchResultsId;

    return (
        <TruncatedText
            useLink
            shouldLogPageVisit={shouldLogPageVisit}
            route={ROUTE_CHANNEL}
            params={{ id: channel.id }}
            value={channel.name}
        />
    );
};

GridTable.defineColumnTemplate('channelLinkCell', {
    align: 'left',
    sortable: false,
    Cell: ChannelLinkCell,
});

export default ChannelLinkCell;
