import { useMemo } from 'react';
import { useIdentity } from '@theorchard/suite-frontend';
import { GlobalParticipantResourceAccessVariables as ResourceAccessVariables } from '../definitions/GlobalParticipantResourceAccess';
import { useParticipantResourceAccess } from '../queries';
import { useSettingsProfileQuery } from '../queries/settingsProfile';

export const useSettingsLabelParticipants = (
    variables: ResourceAccessVariables[]
) => {
    const { id } = useIdentity();
    const { settingsProfile, loading: settingsProfileLoading } =
        useSettingsProfileQuery(id);
    const { data, loading, error } = useParticipantResourceAccess(variables);

    const adminLabelParticipants = useMemo(() => {
        if (data.length == 0 || !settingsProfile) return [];

        const { resources: adminResources } = settingsProfile;
        const starResource = adminResources.some(
            ({ id, type }) => id === '*' && type === 'Vendor'
        );
        if (starResource) return data.map(({ id }) => id);

        return data
            .filter(({ label }) => {
                if (!label) return false;

                const {
                    id: { vendorId, subaccountId },
                } = label;
                return adminResources.some(({ id, type }) => {
                    if (subaccountId > 0)
                        return (
                            id === String(subaccountId) && type === 'Subaccount'
                        );
                    return id === String(vendorId) && type === 'Vendor';
                });
            })
            .map(({ id }) => id);
    }, [data, settingsProfile]);

    return {
        data: adminLabelParticipants,
        loading: loading || settingsProfileLoading,
        error: error,
    };
};
