import { useApolloClient } from '@apollo/client';
import {
    SoundRecordingDemographics,
    SoundRecordingDemographicsVariables,
} from '../../definitions/SoundRecordingDemographics';
import { useParallelQueries } from '../../utils';
import SoundRecordingDemographicsBreakdownQuery from './soundRecordingDemographics.gql';

export const useBatchDemographicsBreakdownQuery = (
    variables: SoundRecordingDemographicsVariables[],
    skip?: boolean
) => {
    const client = useApolloClient();
    const { loading, data, error } = useParallelQueries<
        SoundRecordingDemographics,
        SoundRecordingDemographicsVariables
    >(SoundRecordingDemographicsBreakdownQuery, { variables, client, skip });

    return { loading, data, error };
};
