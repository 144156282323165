import React from 'react';
import { SuitePlugin } from '@theorchard/suite-frontend';
import OrchardGoBanner from 'src/components/orchardGoBanner';

const OrchardGoBannerPlugin: SuitePlugin = {
    Wrapper: ({ children }) => (
        <>
            <OrchardGoBanner />
            {children}
        </>
    ),
};

export default OrchardGoBannerPlugin;
