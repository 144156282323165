import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { createHref } from '@theorchard/suite-frontend';
import TrendScoresPopup from 'src/components/trendScoresPopup';
import { EMPTY_CHAR, ROUTE_SONG } from 'src/constants';
import { PAGE_TIKTOK } from 'src/constants/page';

GridTable.defineColumnTemplate('tiktokTrendScore', {
    align: 'right',
    Cell: ({ data: { tikTokTrendScores, participants, songName, isrc } }) => {
        if (!tikTokTrendScores?.length) return <span>{EMPTY_CHAR}</span>;

        const trendScoreLink = createHref(ROUTE_SONG, {
            isrc,
            page: PAGE_TIKTOK,
            dimension: 'countries',
        });
        const participantsString =
            participants && participants.length
                ? participants.map(({ name }) => name).join(', ')
                : EMPTY_CHAR;

        return (
            <TrendScoresPopup
                tikTokTrendScores={tikTokTrendScores}
                participants={participantsString}
                songName={songName}
                link={trendScoreLink}
            />
        );
    },
});
