import { useApolloClient } from '@apollo/client';
import {
    SoundRecordingAppleDemographics as Query,
    SoundRecordingAppleDemographicsVariables as Variables,
} from '../../definitions/SoundRecordingAppleDemographics';
import { useParallelQueries } from '../../utils';
import query from './soundRecordingAppleDemographics.gql';

export const useBatchAppleDemographicsBreakdownQuery = (
    variables: Variables[],
    skip?: boolean
) => {
    const client = useApolloClient();
    const { loading, data, error } = useParallelQueries<Query, Variables>(
        query,
        { variables, client, skip }
    );

    return { loading, data, error };
};
