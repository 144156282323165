export const getVideoType = (
    channelOwner?: string,
    videoContentType?: string
) => {
    switch (channelOwner) {
        case 'VEVO': {
            return 'VEVO';
        }
        case 'MCN': {
            return 'MCN';
        }
        default: {
            let type = 'UGC';

            if (videoContentType === 'ART_TRACK_VIDEO')
                type = 'ART TRACK VIDEO';

            return type;
        }
    }
};
