import { useQuery } from '@apollo/client';
import {
    TopSoundRecordingPlaylistPlacements as Query,
    TopSoundRecordingPlaylistPlacementsVariables as Variables,
} from 'src/apollo/definitions/TopSoundRecordingPlaylistPlacements';
import { selectSongTopPlaylistPlacements } from 'src/apollo/selectors/playlist';
import query from './topSoundRecordingPlaylistPlacements.gql';

export const useTopSoundRecordingPlaylistPlacementsQuery = (
    variables: Variables,
    dateFilter?: string
) => {
    const { data, loading, error } = useQuery<Query, Variables>(query, {
        variables,
    });

    return {
        data: selectSongTopPlaylistPlacements(data, dateFilter),
        loading,
        error,
    };
};
