import { sortBy } from 'lodash';
import { ChannelAnalyticsAllTimeQuery_channel_analytics_allTime as AllTimeChannelMetricsData } from 'src/apollo/definitions/ChannelAnalyticsAllTimeQuery';
import {
    ChannelAnalyticsDailyQuery_channel_analytics_daily as ChannelMetricsData,
    ChannelAnalyticsDailyQuery_channel_analytics_daily_aggregate as ChannelMetricsAggregate,
} from 'src/apollo/definitions/ChannelAnalyticsDailyQuery';
import { EMPTY_CHAR } from 'src/constants';
import {
    VIEWS,
    WATCH_TIME,
    AVG_DURATION,
    AVG_PERCENTAGE,
    COMMENTS,
} from 'src/constants/metrics';
import { ChannelMetadata } from './channel';

export interface ChannelMetrics {
    byDay: DayChannelMetrics[];
    aggregate: AggregateChannelMetrics;
}

interface Metrics {
    views: number;
    premiumViews: number;
    watchTime?: number;
    premiumWatchTime?: number;
    averageViewDuration?: number;
    averageViewPercentage?: number;
    estimatedMonetizedPlaybacks: number;
    estimatedPartnerAdRevenue: number;
    estimatedPartnerPremiumRevenue: number;
    estimatedPartnerRevenue: number;
    cpm: number;
    rpm: number;
    adFillRate: number;
    comments: number | null;
}

export interface DayChannelMetrics extends Metrics {
    date: string;
}

export interface AllTimeChannelMetrics extends Metrics {
    channelId: string;
}

export interface AggregateChannelMetrics extends Metrics {
    channelId: string;
    standardViews: number;
}

export const selectAllTimeChannelMetrics = (
    allTime?: AllTimeChannelMetricsData | null
): AllTimeChannelMetrics | null => {
    const data = allTime?.data;
    if (!data?.channelId || !data?.views) return null;

    return {
        channelId: data.channelId,
        views: data.views,
        premiumViews: data.premiumViews ?? 0,
        averageViewDuration: data?.averageViewDurationSeconds ?? undefined,
        averageViewPercentage: data.averageViewDurationPercentage
            ? data.averageViewDurationPercentage / 100
            : undefined,
        watchTime: data?.watchTimeMinutes ? data.watchTimeMinutes / 60 : 0,
        premiumWatchTime: data?.premiumWatchTimeMinutes ?? 0,
        estimatedPartnerAdRevenue: data.estimatedPartnerAdRevenue ?? 0,
        estimatedPartnerPremiumRevenue:
            data.estimatedPartnerPremiumRevenue ?? 0,
        estimatedPartnerRevenue:
            (data.estimatedPartnerPremiumRevenue ?? 0) +
            (data.estimatedPartnerAdRevenue ?? 0),
        estimatedMonetizedPlaybacks: data.estimatedMonetizedPlaybacks ?? 0,
        cpm: data.cpm ?? 0,
        rpm: data.rpm ?? 0,
        adFillRate: data.adFillRate ?? 0,
        comments: data.comments ?? null,
    };
};

export const selectChannelMetricsByDay = (
    metrics?: ChannelMetricsData | null
): DayChannelMetrics[] => {
    const data = (metrics?.data ?? [])
        .map(day => ({
            date: day?.downloadActivityDate,
            views: day?.views ?? 0,
            premiumViews: day?.premiumViews ?? 0,
            watchTime: day?.watchTimeMinutes ? day.watchTimeMinutes / 60 : 0,
            averageViewDuration: day?.averageViewDurationSeconds ?? null,
            averageViewPercentage: day?.averageViewDurationPercentage
                ? day.averageViewDurationPercentage / 100
                : null,
            rpm: day?.rpm ?? 0,
            cpm: day?.cpm ?? 0,
            estimatedMonetizedPlaybacks: day?.estimatedMonetizedPlaybacks ?? 0,
            estimatedPartnerAdRevenue: day?.estimatedPartnerAdRevenue ?? 0,
            estimatedPartnerPremiumRevenue:
                day?.estimatedPartnerPremiumRevenue ?? 0,
            estimatedPartnerRevenue:
                (day?.estimatedPartnerPremiumRevenue ?? 0) +
                (day?.estimatedPartnerAdRevenue ?? 0),
            adFillRate: day?.adFillRate ? day?.adFillRate : 0,
            comments: day?.comments ?? 0,
        }))
        .filter(day => day.date);

    if (!data?.length) return [];
    return sortBy(data, day => day.date) as DayChannelMetrics[];
};

export const selectAggregateChannelMetrics = (
    aggregate?: ChannelMetricsAggregate
): AggregateChannelMetrics => ({
    channelId: aggregate?.channelId ?? '',
    views: aggregate?.views ?? 0,
    premiumViews: aggregate?.premiumViews ?? 0,
    standardViews: (aggregate?.views ?? 0) - (aggregate?.premiumViews ?? 0),
    watchTime: aggregate?.watchTimeMinutes
        ? aggregate.watchTimeMinutes / 60
        : 0,
    averageViewDuration: aggregate?.averageViewDurationSeconds ?? undefined,
    averageViewPercentage: aggregate?.averageViewDurationPercentage
        ? aggregate.averageViewDurationPercentage / 100
        : undefined,
    rpm: aggregate?.rpm ?? 0,
    cpm: aggregate?.cpm ?? 0,
    estimatedMonetizedPlaybacks: aggregate?.estimatedMonetizedPlaybacks ?? 0,
    estimatedPartnerAdRevenue: aggregate?.estimatedPartnerAdRevenue ?? 0,
    estimatedPartnerPremiumRevenue:
        aggregate?.estimatedPartnerPremiumRevenue ?? 0,
    estimatedPartnerRevenue:
        (aggregate?.estimatedPartnerPremiumRevenue ?? 0) +
        (aggregate?.estimatedPartnerAdRevenue ?? 0),
    adFillRate: aggregate?.adFillRate ? aggregate?.adFillRate : 0,
    comments: aggregate?.comments ?? 0,
});

export const selectChannelMetrics = (
    data?: ChannelMetricsData
): ChannelMetrics | null => {
    if (!data) return null;

    const byDay = selectChannelMetricsByDay(data);
    const aggregate = selectAggregateChannelMetrics(data.aggregate);

    return {
        aggregate,
        byDay,
    };
};

const getItemValue = (item: DayChannelMetrics, selectedMetric: string) => {
    if (selectedMetric === VIEWS) return item.views ?? 0;
    if (selectedMetric === WATCH_TIME) return item.watchTime ?? 0;
    if (selectedMetric === AVG_DURATION) return item.averageViewDuration ?? 0;
    if (selectedMetric === AVG_PERCENTAGE)
        return item.averageViewPercentage ? item.averageViewPercentage : 0;
    if (selectedMetric === COMMENTS) return item.comments ?? 0;
    return 0;
};

const selectAbsoluteSeries = (
    item: DayChannelMetrics,
    selectedMetric: string
) => ({
    x: new Date(item.date).getTime(),
    y: getItemValue(item, selectedMetric),
});

export const selectChannelMetricsSeries = (
    channel: ChannelMetadata,
    selectedMetric: string,
    metrics: ChannelMetrics | null
) => ({
    name: channel.name || EMPTY_CHAR,
    data:
        metrics?.byDay.map(item =>
            selectAbsoluteSeries(item, selectedMetric)
        ) ?? [],
});
