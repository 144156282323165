import dayjs from 'dayjs';
import { DATE_FORMAT, EMPTY_CHAR } from 'src/constants';
import {
    ALL_TIME,
    CUSTOM,
    FIRST_365,
    FIRST_6M,
    FIRST_7,
} from 'src/constants/periods';
import {
    getDaysPast,
    isAbsolutePeriod,
    isRelativePeriod,
} from 'src/utils/datePeriod';
import { normalizeNumber } from './formatNumber';

export interface TimeseriesSummaryData {
    streams?: number | null;
    downloads?: number | null;
    trackDownloads?: number | null;
    albumDownloads?: number | null;
}

export interface TimeseriesSummaryDataWithPercentage {
    totalStreamsPercentage?: number | null;
    totalDownloadsPercentage?: number | null;
}

export type TimeseriesSummaryProcuctDataWithPercentage =
    TimeseriesSummaryDataWithPercentage & {
        totalTrackDownloadsPercentage?: number | null;
    };

export const getPercentage = (value?: number | null, total?: number | null) => {
    const normalizedValue = normalizeNumber(value);
    const normalizedTotal = normalizeNumber(total);

    if (normalizedValue === null || normalizedTotal === null) return null;

    if (normalizedValue === 0 || normalizedTotal === 0) return 0;

    return normalizedValue / normalizedTotal;
};

export const getTimeseriesWithPercentage = <T extends TimeseriesSummaryData>(
    isTotalDimestion: boolean,
    timeseries: T[],
    timeseriesTotals?: TimeseriesSummaryData[] | null
) => {
    if (isTotalDimestion || !timeseriesTotals?.length) return timeseries;

    const [totals] = timeseriesTotals;
    const timeseriesWithPercentage = timeseries.map(summary => ({
        ...summary,
        totalStreamsPercentage: getPercentage(summary.streams, totals.streams),
        totalDownloadsPercentage: getPercentage(
            summary.downloads,
            totals.downloads
        ),
        totalTrackDownloadsPercentage: getPercentage(
            summary.trackDownloads,
            totals.trackDownloads
        ),
        totalAlbumDownloadsPercentage: getPercentage(
            summary.albumDownloads,
            totals.albumDownloads
        ),
    }));
    return timeseriesWithPercentage;
};

export const getDateDiff = (fromDate: string, toDate?: string | null) => {
    let diff = EMPTY_CHAR;
    if (toDate) {
        const momentToDate = dayjs.utc(toDate);
        const momentFromDate = dayjs.utc(fromDate);

        diff = momentFromDate.isSameOrAfter(momentToDate, 'day')
            ? (momentFromDate.diff(momentToDate, 'days') + 1).toString()
            : EMPTY_CHAR;
    }

    return diff;
};

export const getResolution = (startDate?: string, endDate?: string) => {
    if (!startDate || !endDate) return undefined;

    if (dayjs(endDate).diff(startDate, 'years') > 2) return 'low';
    return undefined;
};

export const getRelativeDays = (period: string) => {
    if (period === FIRST_7) return 7;
    if (period === FIRST_6M) return 183;
    if (period === FIRST_365) return 365;
    return 28;
};

export const getDateRange = ({
    start,
    period,
    highwatermark,
    customStartDate,
    customEndDate,
}: {
    start?: string | null;
    period: string;
    highwatermark?: string;
    customStartDate?: string;
    customEndDate?: string;
}) => {
    if (period === ALL_TIME) return {};

    if (period === CUSTOM)
        return { startDate: customStartDate, endDate: customEndDate };

    if (isRelativePeriod(period)) {
        if (!start) return {};

        const days = getRelativeDays(period);
        return {
            startDate: start,
            endDate: dayjs(start)
                .add(days - 1, 'days')
                .format(DATE_FORMAT),
        };
    }

    const daysPast = getDaysPast(period) * -1;
    const today = dayjs(highwatermark);

    return {
        startDate: today
            .clone()
            .subtract(daysPast - 1, 'days')
            .format(DATE_FORMAT),
        endDate: today.format(DATE_FORMAT),
    };
};

export const getTimeseriesDateRange = ({
    datePeriod,
    highwatermark,
    summaryStartDate,
    summaryEndDate,
    releaseDate,
}: {
    datePeriod: string;
    highwatermark?: string;
    summaryStartDate?: string;
    summaryEndDate?: string;
    releaseDate?: string | null;
}) => {
    const isAbsolute = isAbsolutePeriod(datePeriod);
    const startDate = summaryStartDate ?? releaseDate ?? '1999-01-01';
    const endDate = summaryEndDate ?? dayjs(highwatermark).format(DATE_FORMAT);

    const resolution = isAbsolute
        ? getResolution(startDate, endDate)
        : undefined;

    return {
        resolution,
        startDate,
        endDate,
    };
};
